import { useEffect, useState } from 'react';
import { Badge, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CollaborationGroupsPage from './CollaborationGroupsPage';
import { useQuery } from '@tanstack/react-query';
import { listCollaborationGroupInvitationInbox } from './Requests/CollaborationGroupInvitationRequests';
import CollaborationGroupInvitationInboxPage from './CollaborationGroupInvitationInboxPage';
import { useSelector } from 'react-redux';
import { StoreState, UserState } from '../../redux-types';
import { PrivilegeLevels } from '../../shared/constants/PrivilegeLevels';
import AdminPrivilegeProtected from '../../shared/protectedWrappers/AdminPrivilegeProtectedWrapper';
import SharedMediaInteractionsPage from './SharedMediaInteractionsPage';
import { useLocation, useNavigate } from 'react-router-dom';
import SharedJournalistsPage from './SharedJournalistsPage';

const useStyles = makeStyles((theme) => ({
  collaborationPageContainer: {
    width: 'calc(100% - 60px)',
    margin: '0 auto',
    marginBottom: 30,
  },
  pageHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  headerActionsContainer: {
    display: 'flex',
    gap: '0.5rem',
  },
}));

const CollaborationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const user = useSelector<StoreState, UserState>((state) => state.user);
  const isAdminPrivilegeProtected = user.userInfo.privilege > PrivilegeLevels.manager.level;

  const searchParams = new URLSearchParams(location.search);
  const toggleParam = searchParams.get('toggle') ?? 'groups';
  const [selectedToggleButton, setSelectedToggleButton] = useState<string>(toggleParam);

  const { data } = useQuery({
    queryKey: ['collaboration/group/invitation/inbox'],
    queryFn: listCollaborationGroupInvitationInbox,
    enabled: isAdminPrivilegeProtected,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const toggle = searchParams.get('toggle') ?? 'groups';
    setSelectedToggleButton(toggle);
  }, [location.search]);

  const getSelectedButtonVariant = (button: string) => {
    if (selectedToggleButton === button) {
      return 'contained';
    } else {
      return 'outlined';
    }
  };

  const getButtonColor = () => {
    if (selectedToggleButton === 'interactions') {
      return 'mediaInteraction';
    }
    return 'primary';
  };

  const handleButtonClick = (selection: string) => {
    setSelectedToggleButton(selection);
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('toggle', selection);
    navigate(`?${newSearchParams.toString()}`, { replace: true });
  };

  const renderPageComponent = () => {
    switch (selectedToggleButton) {
      case 'groups':
        return <CollaborationGroupsPage />;
      case 'journalists':
        return <SharedJournalistsPage />;
      case 'interactions':
        return <SharedMediaInteractionsPage />;
      case 'inbox':
        return (
          <AdminPrivilegeProtected>
            <CollaborationGroupInvitationInboxPage invitations={data ? data : []} />
          </AdminPrivilegeProtected>
        );
      default:
        return null;
    }
  };

  return (
    <Box className={classes.collaborationPageContainer}>
      <Box className={classes.pageHeaderContainer}>
        <Box className={classes.headerActionsContainer}>
          <Button
            data-cy="collaboration-page-tab-groups"
            color={getButtonColor()}
            variant={getSelectedButtonVariant('groups')}
            onClick={() => handleButtonClick('groups')}
          >
            Groups
          </Button>
          <Button
            data-cy="collaboration-page-tab-interactions"
            color={getButtonColor()}
            variant={getSelectedButtonVariant('interactions')}
            onClick={() => handleButtonClick('interactions')}
          >
            Shared Interactions
          </Button>
          <Button
            data-cy="collaboration-page-tab-journalists"
            color={getButtonColor()}
            variant={getSelectedButtonVariant('journalists')}
            onClick={() => handleButtonClick('journalists')}
          >
            Shared Journalists
          </Button>
        </Box>
        <AdminPrivilegeProtected>
          <Badge
            badgeContent={data ? data.length : 0}
            color="error"
            data-cy="collaboration-page-tab-invitation-inbox-badge"
          >
            <Button
              data-cy="collaboration-page-tab-invitation-inbox"
              color={getButtonColor()}
              variant={getSelectedButtonVariant('inbox')}
              onClick={() => handleButtonClick('inbox')}
            >
              Invitation Inbox
            </Button>
          </Badge>
        </AdminPrivilegeProtected>
      </Box>
      {renderPageComponent()}
    </Box>
  );
};

export default CollaborationPage;
