import { IconButton, Box, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getMMMMYYYYFromString, getYYYYMFromString } from '../../shared/functions/dateProcessor';
import makeStyles from '@mui/styles/makeStyles';

interface MonthSelectorProps {
  disabled: boolean;
  currMonth: string;
  onMonthChange: (month: string) => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  button: {
    backgroundColor: '#F0F0F0',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#DEDEDE',
    },
    padding: 0,
  },
  icon: {
    color: '#525252',
    fontSize: 24,
  },
});

export default function MonthSelector(props: MonthSelectorProps) {
  const classes = useStyles();
  const { disabled, currMonth, onMonthChange } = props;

  const handleClickNextMonth = () => {
    const nextMonth = getYYYYMFromString(currMonth, 1);
    onMonthChange(nextMonth);
  };

  const handleClickPrevMonth = () => {
    const prevMonth = getYYYYMFromString(currMonth, -1);
    onMonthChange(prevMonth);
  };

  return (
    <Box className={classes.container}>
      <IconButton
        disabled={disabled}
        className={classes.button}
        size="small"
        onClick={() => handleClickPrevMonth()}
        data-cy="tracker-table-month-selector-prev-month"
      >
        <ChevronLeftIcon className={classes.icon} />
      </IconButton>
      <Typography variant="h6">{getMMMMYYYYFromString(currMonth)}</Typography>
      <IconButton
        disabled={disabled}
        className={classes.button}
        size="small"
        onClick={() => handleClickNextMonth()}
        data-cy="tracker-table-month-selector-next-month"
      >
        <ChevronRightIcon fontSize="inherit" className={classes.icon} />
      </IconButton>
    </Box>
  );
}
