import { useState } from 'react';
import { SharedJournalistsQuickSearchProps } from './Types/componentProps';
import CollaborationSharedQuickSearch from './Shared/CollaborationSharedQuickSearch';
import { sharedJournalistsSearchCategories } from './Utils/constants';

const SharedJournalistsQuickSearch = (props: SharedJournalistsQuickSearchProps) => {
  const { onSearch } = props;
  const [selectedCategory, setSelectedCategory] = useState<string>(sharedJournalistsSearchCategories[0].value);
  const [searchText, setSearchText] = useState('');

  const handleClickSearch = () => {
    onSearch(selectedCategory, searchText);
  };

  const handleClickClear = () => {
    setSearchText('');
    onSearch('', '');
  };

  return (
    <CollaborationSharedQuickSearch
      searchCategoryOptions={sharedJournalistsSearchCategories}
      selectedCategory={selectedCategory}
      onSelectedCategoryChange={setSelectedCategory}
      searchText={searchText}
      onSearchTextChange={setSearchText}
      onSearchButtonClick={handleClickSearch}
      onClearButtonClick={handleClickClear}
    />
  );
};

export default SharedJournalistsQuickSearch;
