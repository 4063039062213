import { Tooltip } from '@mui/material';
import { CSVLink } from 'react-csv';
import { useRef } from 'react';
import DownloadCSVButtonUI from '../../../shared/UIComponents/DownloadCSVButtonUI';
import { TrackerDataType } from '../../../shared/constants/constants';

interface ExportCSVButtonProps<T> {
  tooltipTitle?: string;
  filename: string;
  data: T[];
  formatCSVCallback: (data: T[]) => any[];
  type?: string;
}

const ExportCSVButton = <T,>({ tooltipTitle, filename, data, formatCSVCallback, type }: ExportCSVButtonProps<T>) => {
  const csvLinkRef = useRef<any>(null);

  const handleDownload = () => {
    if (csvLinkRef) {
      csvLinkRef.current.link.click();
    }
  };

  const getColor = () => {
    if (type === TrackerDataType.MEDIA_INTERACTION) {
      return 'mediaInteraction';
    }
    return 'primary';
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <DownloadCSVButtonUI color={getColor()} onClick={() => handleDownload()} />
      </Tooltip>
      <CSVLink
        filename={`${Date.now()}-${filename}.csv`}
        className="hidden"
        data={formatCSVCallback(data)}
        ref={csvLinkRef}
        separator={','}
      />
    </>
  );
};

export default ExportCSVButton;
