import { AutoTrackerKeys, IssueAndServiceSharedValues, IssueKeys, ServiceKeys } from '../constants/IssueAndServiceKeys';
import dayjs from '../../utils/dayjs';

export const processFromDBForAutoComplete = (object, keys, dropdownTable) => {
  const objectCopy = JSON.parse(JSON.stringify(object));

  Object.keys(objectCopy).forEach((key) => {
    if (keys[key] && object[key]) {
      objectCopy[keys[key]] = object[key];
      delete objectCopy[key];
    }
  });

  if (objectCopy[IssueAndServiceSharedValues.teamMembers]) {
    objectCopy[IssueAndServiceSharedValues.teamMembers] = dropdownTable['lead'].find((member) => {
      return member._id === objectCopy[IssueAndServiceSharedValues.teamMembers];
    });
  }

  return objectCopy;
};

export const processFromAIScrapeForAutoComplete = (object, message, keys, dropdownTable, users) => {
  const objectCopy = JSON.parse(JSON.stringify(object));

  // TODO find a better way to match these and handle the appropriate cases
  Object.keys(keys).forEach((key) => {
    if (keys[key] && object[keys[key]]) {
      if (key === 'date') {
        // Setting the field to this date at the current time
        let date = dayjs(object[keys[key]]);
        if (date.isValid()) {
          date = date.set('hour', dayjs().hour())
            .set('minute', dayjs().minute())
            .set('second', dayjs().second());
          objectCopy[key] = date.toDate();
        } else {
          objectCopy[key] = dayjs().toDate();
        }
      } else if (key === 'topic' || key === 'service') {
        objectCopy[key] = object[keys[key]];
      } else if (key === 'journalist') {
        const journalistName = object[keys[key]]?.name || object[keys[key]];
        objectCopy[key] =
          dropdownTable[key].find((value) => {
            const match = value.name === journalistName;
            if (object['Outlet']) {
              return !!match && objectCopy['outlet'] === value.Org;
            }
            return !!match;
          }) || null;
      } else if (key === 'outlet') {
        const outletName = object[keys[key]]?.name || object[keys[key]];
        const match = dropdownTable[key].find((value) => {
          return value.name === outletName;
        })?._id;
        if (match) {
          objectCopy[key] = match;
        }
      } else if (key === 'dept' || key === 'units') {
        const nameValue = object[keys[key]]?.name || object[keys[key]];
        const match = dropdownTable[key].find((value) => {
          return value.name === nameValue;
        })?.name;
        if (match) {
          objectCopy[key] = [match];
        }
      } else if (key === 'status' || key === 'contactMethod') {
        objectCopy[key] =
          dropdownTable[key].find((value) => {
            const matches = value.name.toLowerCase().match(object[keys[key]].toLowerCase());
            return !!matches?.length;
          })?.name || null;
      } else if (key === 'type') {
        let typesArray;
        if (typeof object[keys[key]] === 'string') {
          typesArray =
            typeof object[keys[key]] === 'string'
              ? object[keys[key]].split(',').map((type) => type.trim())
              : object[keys[key]];
        } else {
          typesArray = object[keys[key]].map((type) => type.name.trim());
        }
        objectCopy[key] = typesArray
          .map((type) => {
            return (
              dropdownTable[key].find((value) => {
                const matches = value.name.toLowerCase().match((type.name || type).toLowerCase());
                return !!matches?.length;
              }) || null
            );
          })
          .filter(Boolean);
      } else if (key === 'expert' || key === 'unitLead') {
        const matches = object[keys[key]]
          ?.map((expert) => {
            return dropdownTable['expert'].find((value) => {
              return value.name === (expert.name || expert);
            });
          })
          .filter(Boolean);
        if (matches) {
          objectCopy[key] = matches;
        }
      } else if (key === 'complexity') {
        objectCopy[key] = Number(object[keys[key]]) || null;
      } else if (key === 'keyMessaging') {
        objectCopy[key] = object[keys[key]] ? 'AUTOTRACKER DRAFT MESSAGING: NOT YET APPROVED\n' + object[keys[key]] : '';
      } else if (key === 'campaign') {
        objectCopy[key] = [dropdownTable['campaigns'].find((value) => value.name === object[keys[key]]?.name)].filter(
          Boolean
        );
      } else if (key === 'actions' || key === 'response') {
        objectCopy[key] = object[keys[key]] ?? message ?? '';
      } else {
        const match = dropdownTable[key].find((value) => {
          const matches = value.name.toLowerCase().match(object[keys[key]].toLowerCase());
          return !!matches?.length;
        });
        objectCopy[key] = match ? [match] : null;
      }
    }
    delete objectCopy[keys[key]];
  });

  if (users?.length > 0) {
    if (Object.keys(keys).includes('journalist')) {
      objectCopy['lead'] = users
        .map((user) => dropdownTable['lead'].find((member) => member._id === user))
        .filter(Boolean);
    } else {
      objectCopy['teamMember'] = users
        .map((user) => dropdownTable['lead'].find((member) => member._id === user))
        .filter(Boolean);
    }
  }
  return objectCopy;
};

export const processFromResultForAIScrape = (result, fields, dropdownTable) => {
  Object.keys(result).forEach((key) => {
    switch (key) {
      case IssueAndServiceSharedValues.date:
        result[key] = dayjs(fields.date).format('YYYY-MM-DD');
        break;
      case IssueKeys.topic:
        result[key] = fields.topic;
        break;
      case IssueAndServiceSharedValues.type:
        result[key] = fields.type;
        break;
      case IssueAndServiceSharedValues.unit: {
        const unit = dropdownTable['units'].find((value) => value.name === fields.units?.[0]);
        if (unit) {
          result[key] = unit;
        }
        break;
      }
      case IssueKeys.outlet: {
        const outlet = dropdownTable['outlet'].find((value) => value._id === fields.journalist?.Org);
        if (outlet) {
          result[key] = outlet;
        }
        break;
      }
      case IssueKeys.journalist: {
        const journalist = dropdownTable['journalist'].find((value) => value.name === fields.journalist?.name);
        if (journalist) {
          result[key] = journalist;
          result[AutoTrackerKeys.email] = journalist.email;
          result[AutoTrackerKeys.phone] = journalist.phone;
        }
        break;
      }
      case IssueAndServiceSharedValues.department: {
        const dept = dropdownTable['dept'].find((value) => value.name === fields.dept?.[0]);
        if (dept) {
          result[key] = dept;
        }
        break;
      }
      case IssueAndServiceSharedValues.leadExpert: {
        result[key] =
          fields.expert
            ?.map((expert) => {
              return dropdownTable['expert'].find((value) => value.name === expert.name);
            })
            .filter(Boolean) ??
          fields.unitLead
            ?.map((expert) => {
              return dropdownTable['expert'].find((value) => value.name === expert.name);
            })
            .filter(Boolean);
        break;
      }
      case IssueAndServiceSharedValues.status:
        result[key] = fields.status;
        break;
      case IssueKeys.contactMethod:
        result[key] = fields.contactMethod;
        break;
      case ServiceKeys.service:
        result[key] = fields.service;
        break;
      case IssueAndServiceSharedValues.campaign:
        if (fields.campaign?.length > 0) {
          result[key] = fields.campaign[0];
        }
        break;
      case ServiceKeys.complexity:
        result[key] = fields.complexity;
        break;
      case IssueAndServiceSharedValues.keyMessaging:
        result[key] = fields.keyMessaging;
        break;
      case IssueAndServiceSharedValues.backgroundResponse:
        result[key] = fields.actions ?? fields.backgroundResponse ?? '';
        break;
      default:
        break;
    }
  });
  return result;
};

export const processAttachmentsFromAutotracker = (attachments) => {
  if (attachments !== null && attachments !== undefined) {
    const files = attachments.map((f) => {
      return { name: f.name, path: f.url, description: '', type: 'autotrackerAttachment' };
    });
    return files;
  }
  return [];
};

export const processCellsForAutoComplete = (object, dropdownTable, id) => {
  const objectCopy = JSON.parse(JSON.stringify(object));
  if (objectCopy[IssueAndServiceSharedValues.teamMembers]) {
    objectCopy[IssueAndServiceSharedValues.teamMembers] = dropdownTable['lead'].find((member) => {
      return member._id === objectCopy[IssueAndServiceSharedValues.teamMembers];
    });
  }
  objectCopy._id = id;
  return objectCopy;
};
