import { TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { cellColorsNew } from '../../shared/constants/commonCellColors';
import stylizeCell from '../../shared/functions/cellStyling';
import CellSelector from '../Cells/CellSelector';
import CellViewer from '../Cells/CellViewer';
import { IssueAndServiceSharedValues } from '../../shared/constants/IssueAndServiceKeys';

const useStyles = makeStyles(({ palette }) => ({
  tableCell: {
    maxWidth: 210,
  },
  ...cellColorsNew,
  input: {
    width: 130,
    height: 40,
  },
  cell: {
    maxWidth: 150,
  },
  label: {
    minWidth: 150,
  },
}));

export default function TrackerTableDataCell({
  cells,
  index,
  isEditMode,
  onEdit,
  dropdownTable,
  className,
  id,
  type,
  isSharedResource = false,
}) {
  const classes = useStyles();

  let cellClassName = '';
  let cellStyle = null;
  if (index === IssueAndServiceSharedValues.campaign) {
    cellClassName = [classes.tableCell, className].join(' ');
  } else if (!isEditMode) {
    cellClassName = [classes.tableCell, stylizeCell(cells[index], classes, index), className].join(' ');
  } else {
    cellClassName = [classes.tableCell, stylizeCell(cells[index], classes, index)].join(' ');
  }

  if (!isEditMode) {
    return (
      <TableCell style={cellStyle} className={cellClassName} align="left">
        <CellViewer
          type={type}
          cells={cells}
          index={index}
          dropdownTable={dropdownTable}
          id={id}
          isSharedResource={isSharedResource}
        />
      </TableCell>
    );
  } else {
    return (
      <TableCell style={cellStyle} className={cellClassName} align="left">
        <CellSelector
          type={type}
          cells={cells}
          col={index}
          dropdownTable={dropdownTable}
          callBack={onEdit}
          id={id}
          classes={classes}
        />
      </TableCell>
    );
  }
}
