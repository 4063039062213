import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import MainApp from './MainApp';
import { Provider } from 'react-redux';
import store from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PaletteColor, PaletteColorOptions } from '@mui/material';

const queryClient = new QueryClient();

declare module '@mui/material/styles' {
  interface Palette {
    primaryDarker: PaletteColor;
    primaryDark: PaletteColor;
    primaryDefault: PaletteColor;
    primaryLight: PaletteColor;
    primaryLighter: PaletteColor;
    secondaryDarker: PaletteColor;
    secondaryDark: PaletteColor;
    secondaryDefault: PaletteColor;
    secondaryLight: PaletteColor;
    secondaryLighter: PaletteColor;
    tertiary: PaletteColor;
    tertiaryDarker: PaletteColor;
    tertiaryDark: PaletteColor;
    tertiaryDefault: PaletteColor;
    tertiaryLight: PaletteColor;
    tertiaryLighter: PaletteColor;
    mediaInteraction: PaletteColor;
    whiteScale: PaletteColor;
    whiteScaleDarker: PaletteColor;
    whiteScaleDark: PaletteColor;
    whiteScaleDefault: PaletteColor;
    whiteScaleLight: PaletteColor;
    whiteScaleLighter: PaletteColor;
    statusCancelled: PaletteColor;
    statusInProgress: PaletteColor;
    statusCompleted: PaletteColor;
    statusParticipation: PaletteColor;
    complexityOne: PaletteColor;
    complexityTwo: PaletteColor;
    complexityThree: PaletteColor;
    complexityFour: PaletteColor;
    complexityFive: PaletteColor;
    typeReactive: PaletteColor;
    typeProactive: PaletteColor;
    tonePositive: PaletteColor;
    toneNegative: PaletteColor;
    toneNeutral: PaletteColor;
  }

  interface PaletteOptions {
    primaryDarker?: PaletteColorOptions;
    primaryDark?: PaletteColorOptions;
    primaryDefault?: PaletteColorOptions;
    primaryLight?: PaletteColorOptions;
    primaryLighter?: PaletteColorOptions;
    secondaryDarker?: PaletteColorOptions;
    secondaryDark?: PaletteColorOptions;
    secondaryDefault?: PaletteColorOptions;
    secondaryLight?: PaletteColorOptions;
    secondaryLighter?: PaletteColorOptions;
    tertiary?: PaletteColorOptions;
    tertiaryDarker?: PaletteColorOptions;
    tertiaryDark?: PaletteColorOptions;
    tertiaryDefault?: PaletteColorOptions;
    tertiaryLight?: PaletteColorOptions;
    tertiaryLighter?: PaletteColorOptions;
    mediaInteraction?: PaletteColorOptions;
    whiteScale?: PaletteColorOptions;
    whiteScaleDarker?: PaletteColorOptions;
    whiteScaleDark?: PaletteColorOptions;
    whiteScaleDefault?: PaletteColorOptions;
    whiteScaleLight?: PaletteColorOptions;
    whiteScaleLighter?: PaletteColorOptions;
    statusCancelled?: PaletteColorOptions;
    statusInProgress?: PaletteColorOptions;
    statusCompleted?: PaletteColorOptions;
    statusParticipation?: PaletteColorOptions;
    complexityOne?: PaletteColorOptions;
    complexityTwo?: PaletteColorOptions;
    complexityThree?: PaletteColorOptions;
    complexityFour?: PaletteColorOptions;
    complexityFive?: PaletteColorOptions;
    typeReactive?: PaletteColorOptions;
    typeProactive?: PaletteColorOptions;
    tonePositive?: PaletteColorOptions;
    toneNegative?: PaletteColorOptions;
    toneNeutral?: PaletteColorOptions;
  }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    'mediaInteraction': true;
    'whiteScale': true;
    'complexityOne': true;
    'complexityTwo': true;
    'complexityThree': true;
    'complexityFour': true;
    'complexityFive': true;
    'primaryDarker': true;
    'primaryLighter': true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    'whiteScale': true;
    'mediaInteraction': true;
    'tonePositive': true;
    'toneNegative': true;
    'toneNeutral': true;
    'primaryLighter': true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    'statusCancelled': true;
    'statusInProgress': true;
    'statusCompleted': true;
    'statusParticipation': true;
    'tonePositive': true;
    'toneNegative': true;
    'toneNeutral': true;
    'mediaInteraction': true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'mediaInteraction': true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    smallCustom: true; // Add custom size variant
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2379D3',
      contrastText: '#FFFFFF',
    },
    primaryDarker: {
      main: '#174AA2',
      contrastText: '#FFFFFF',
    },
    primaryDark: {
      main: '#1F68C1',
      contrastText: '#FFFFFF',
    },
    primaryDefault: {
      main: '#2379D3',
      contrastText: '#FFFFFF',
    },
    primaryLight: {
      main: '#298BE6',
      contrastText: '#FFFFFF',
    },
    primaryLighter: {
      main: '#2D99F4',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F2AF2A',
      contrastText: '#000000',
    },
    secondaryDarker: {
      main: '#ED721F',
      contrastText: '#000000',
    },
    secondaryDark: {
      main: '#EF8E24',
      contrastText: '#000000',
    },
    secondaryDefault: {
      main: '#F19E26',
      contrastText: '#000000',
    },
    secondaryLight: {
      main: '#F2AF2A',
      contrastText: '#000000',
    },
    secondaryLighter: {
      main: '#F3BC2F',
      contrastText: '#000000',
    },
    tertiary: {
      main: '#5FBA7D',
      contrastText: '#000000',
    },
    tertiaryDarker: {
      main: '#3B9F5D',
      contrastText: '#FFFFFF',
    },
    tertiaryDark: {
      main: '#43AE67',
      contrastText: '#FFFFFF',
    },
    tertiaryDefault: {
      main: '#5FBA7D',
      contrastText: '#000000',
    },
    tertiaryLight: {
      main: '#7BC694',
      contrastText: '#000000',
    },
    tertiaryLighter: {
      main: '#A1D5B2',
      contrastText: '#000000',
    },
    mediaInteraction: {
      main: '#174AA2',
      contrastText: '#FFFFFF',
    },
    whiteScale: {
      main: '#D9D9D9',
      contrastText: '#1E1E1E',
    },
    whiteScaleDarker: {
      main: '#DEDEDE',
      contrastText: '#000000',
    },
    whiteScaleDark: {
      main: '#F0F0F0',
      contrastText: '#000000',
    },
    whiteScaleDefault: {
      main: '#F5F5F5',
      contrastText: '#000000',
    },
    whiteScaleLight: {
      main: '#FAFAFA',
      contrastText: '#000000',
    },
    whiteScaleLighter: {
      main: '#FFFFFF',
      contrastText: '#000000',
    },
    statusCancelled: {
      main: '#C0B6F2',
      contrastText: '#000000',
    },
    statusInProgress: {
      main: '#E2F6CA',
      contrastText: '#000000',
    },
    statusCompleted: {
      main: '#ABF5D1',
      contrastText: '#000000',
    },
    statusParticipation: {
      main: '#BFD7FF',
      contrastText: '#000000',
    },
    complexityOne: {
      main: '#FBEAB6',
      contrastText: '#000000',
    },
    complexityTwo: {
      main: '#F6D15B',
      contrastText: '#000000',
    },
    complexityThree: {
      main: '#F3BC2F',
      contrastText: '#000000',
    },
    complexityFour: {
      main: '#F19E26',
      contrastText: '#000000',
    },
    complexityFive: {
      main: '#ED721F',
      contrastText: '#000000',
    },
    typeReactive: {
      main: '#FFBDAD',
      contrastText: '#000000',
    },
    typeProactive: {
      main: '#B3F5FF',
      contrastText: '#000000',
    },
    tonePositive: {
      main: '#5FBA7D',
      contrastText: '#000000',
    },
    toneNegative: {
      main: '#F15B50',
      contrastText: '#000000',
    },
    toneNeutral: {
      main: '#707070',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '1rem',
        },
        outlined: ({ ownerState, theme }: any) => {
          if (ownerState.color === 'whiteScale') {
            return {
              color: theme.palette.whiteScale.contrastText,
              borderColor: theme.palette.whiteScale.main,
              '&:hover': {
                borderColor: theme.palette.whiteScale.main,
                backgroundColor: '#0000000a',
              },
            };
          }

          const allowedComplexityPalettes = [
            'complexityOne',
            'complexityTwo',
            'complexityThree',
            'complexityFour',
            'complexityFive',
          ];
          if (allowedComplexityPalettes.includes(ownerState.color)) {
            return {
              color: theme.palette[ownerState.color].contrastText,
              borderColor: theme.palette[ownerState.color].main,
            };
          }

          return {};
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          ...(ownerState.color === 'whiteScale' && {
            color: '#707070',
          }),
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: ({ ownerState, theme }: any) => {
          const allowedPalettes = ['statusInProgress', 'statusCompleted', 'statusParticipation', 'statusCancelled'];
          return {
            ...(allowedPalettes.includes(ownerState.color) && {
              color: theme.palette[ownerState.color].contrastText,
              borderColor: theme.palette[ownerState.color].main,
            }),
          };
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { size: 'smallCustom' },
          style: {
            '& .MuiInputBase-root': {
              height: '32px',
              fontSize: '12px',
            },
          },
        },
      ],
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <MainApp />
          </QueryClientProvider>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
