import { useDispatch } from 'react-redux';
import { useFilters, useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import { useLocation, useParams } from 'react-router-dom';
import { getCurrMonth } from '../../shared/functions/dateProcessor';
import React, { useContext, useEffect, useState } from 'react';
import { useAtom } from 'jotai/index';
import { needRefetchFilteredDataAtom, needRefetchKeywordSearchedDataAtom } from '../../shared/atoms/filterAtoms';
import { SocketContext } from '../../shared/contexts/SocketContext';
import ServiceLogEventHandler from '../../shared/eventHandler/ServiceLogEventHandler';
import { socketEvents, visibleEngagementLogCols } from '../../shared/constants/constants';
import isFilterEmpty from '../../shared/functions/isFilterEmpty';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { Box } from '@mui/material';
import TrackerTable from '../TrackerTable/TrackerTable';
import Spinner from 'react-bootstrap/Spinner';
import { DropdownTable, Engagement, FlatEngagement, SnackbarObject, UserMeta } from '../../shared/types';
import makeStyles from '@mui/styles/makeStyles';
import EngagementLogEventHandler from '../../shared/eventHandler/EngagementLogEventHandler';
import dayjs from '../../utils/dayjs';
import MonthSelector from '../TrackerTable/MonthSelector';
import TrackerTableFuzzySearch from '../TrackerTable/TrackerTableFuzzySearch';
import TrackerTableFilter from '../TrackerTable/TrackerTableFilter';
import AddNewTableEntryButton from '../TrackerTable/AddNewTableEntryButton';
import DownloadCSVButton from '../TrackerTable/DownloadCSVButton';

interface EngagementLogPageProps {
  dropdownTable: DropdownTable;
  user: UserMeta;
}

const useStyles = makeStyles({
  tableContainer: {
    width: 'calc(100% - 60px)',
    margin: '0 auto',
    marginBottom: 30,
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  filterWrapper: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
});

export const EngagementLogPage = (props: Readonly<EngagementLogPageProps>) => {
  const { dropdownTable, user } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const filtersDispatch = useFiltersDispatch();
  const { state } = useLocation();
  let month;
  if (sessionStorage.getItem('currMonth')) {
    month = sessionStorage.getItem('currMonth') as string;
  } else {
    month = getCurrMonth();
  }

  const [data, setData] = useState<FlatEngagement[]>([]);
  const [currMonth, setMonth] = useState(month);
  const existingFilters = useFilters();
  const [, setNeedRefetchFilteredData] = useAtom(needRefetchFilteredDataAtom);
  const [, setNeedRefetchKeywordSearchedData] = useAtom(needRefetchKeywordSearchedDataAtom);

  const socket = useContext(SocketContext);
  const serviceLogEventHandler = ServiceLogEventHandler(socket);
  const engagementLogEventHandler = EngagementLogEventHandler(socket);

  const engagementParams = useParams();

  const shouldDisableMonthSwitches = () => {
    const hasFilters = Object.keys(existingFilters).length > 0;
    const isQuickSearching = state?.queryString && state?.category;
    return hasFilters || isQuickSearching;
  };

  // Filter engagement based on url
  useEffect(() => {
    if (engagementParams.engagementId) {
      // @ts-ignore
      filtersDispatch({
        type: 'setFilters',
        payload: {
          id: engagementParams.engagementId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementParams]);

  useEffect(() => {
    socket.on(socketEvents.EVENT_MONTHLY_ENGAGEMENTS, (data: FlatEngagement[]) => {
      if (isFilterEmpty(existingFilters) && !state?.queryString) {
        setData(data);
      } else {
        setNeedRefetchFilteredData(true);
      }
    });

    socket.on(
      socketEvents.EVENT_ENGAGEMENT_ADDED,
      ({ monthFilter, snackbarObject }: { monthFilter: string; snackbarObject: SnackbarObject }) => {
        let month = sessionStorage.getItem('currMonth');
        if (!isFilterEmpty(existingFilters)) {
          setNeedRefetchFilteredData(true);
        } else if (state?.queryString) {
          setNeedRefetchKeywordSearchedData(true);
        } else if (monthFilter === month) {
          socket.emit(socketEvents.EVENT_REQ_MONTHLY_ENGAGEMENTS, {
            month: month,
            timezoneOffset: dayjs.tz.guess(),
          });
        }
        if (snackbarObject) {
          dispatch(openSnackbar(snackbarObject));
        }
      }
    );

    socket.on(socketEvents.EVENT_ENGAGEMENT_DELETED, ({ monthFilter }: { monthFilter: string }) => {
      let month = sessionStorage.getItem('currMonth');
      if (!isFilterEmpty(existingFilters)) {
        setNeedRefetchFilteredData(true);
      } else if (state?.queryString) {
        setNeedRefetchKeywordSearchedData(true);
      } else if (monthFilter === month) {
        socket.emit(socketEvents.EVENT_REQ_MONTHLY_ENGAGEMENTS, {
          month: month,
          timezoneOffset: dayjs.tz.guess(),
        });
      }
    });

    socket.on(socketEvents.EVENT_CONFIRM_DELETE, (doc: Engagement) => {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: 'engagement deleted!',
          callbackData: doc,
          eventType: 'deleteEngagement',
        })
      );
    });

    socket.on(socketEvents.EVENT_ERROR, (error: string) => {
      alert(error);
    });

    // cleanup function to remove all event listeners when the value of dependencies change, avoid redundant listeners
    return () => {
      socket.off(socketEvents.EVENT_MONTHLY_ENGAGEMENTS);
      socket.off(socketEvents.EVENT_ENGAGEMENT_ADDED);
      socket.off(socketEvents.EVENT_ENGAGEMENT_DELETED);
      socket.off(socketEvents.EVENT_CONFIRM_DELETE);
      socket.off(socketEvents.EVENT_ERROR);
    };
  }, [socket, dispatch, existingFilters, setNeedRefetchFilteredData, setNeedRefetchKeywordSearchedData, state]);

  useEffect(() => {
    if (typeof currMonth === 'string') {
      sessionStorage.setItem('currMonth', currMonth);

      if (!state) {
        if (isFilterEmpty(existingFilters)) {
          socket.emit(socketEvents.EVENT_REQ_MONTHLY_ENGAGEMENTS, {
            month: currMonth,
            timezoneOffset: dayjs.tz.guess(),
          });
        }
      }
    }
  }, [currMonth, existingFilters, socket, state]);

  return (
    <Box className={classes.tableContainer}>
      <Box>
        <MonthSelector disabled={shouldDisableMonthSwitches()} currMonth={currMonth} onMonthChange={setMonth} />
      </Box>
      <Box className={classes.filterContainer}>
        <Box className={classes.filterWrapper}>
          <TrackerTableFuzzySearch />
          <TrackerTableFilter
            setDataCallback={setData}
            eventHandler={engagementLogEventHandler}
            currMonth={currMonth}
          />
        </Box>
        <Box className={classes.filterWrapper}>
          <AddNewTableEntryButton
            dropdownTable={dropdownTable}
            eventHandler={engagementLogEventHandler}
            currMonth={currMonth}
          />
          <DownloadCSVButton
            rows={data}
            dropdowns={dropdownTable}
            flattenerCallback={engagementLogEventHandler.flattenForExport}
            filename={engagementLogEventHandler.type}
            eventHandlerType={engagementLogEventHandler.type}
          />
        </Box>
      </Box>
      {data && dropdownTable ? (
        <>
          <TrackerTable
            rows={data}
            dropdownTable={dropdownTable}
            visibleCols={visibleEngagementLogCols}
            eventHandler={engagementLogEventHandler}
            linkedEventHandler={serviceLogEventHandler}
            user={user}
          />
        </>
      ) : (
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      )}
    </Box>
  );
};
