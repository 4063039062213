import React from 'react';
import { Dialog, Typography, Alert, AlertTitle } from '@mui/material';
import {
  OrgChangeWarning,
  UnitChangeWarning,
  BoldChangeWarning,
  OrganizationChangeWarning
} from '../../shared/constants/constants';

const AlertChangeOrgUnit = (props) => {
  const { open, setOpen, category } = props;
  const getTitle = () => (category === 'Experts' ? 'Changing the Unit' : category === 'Community Members' ? 'Changing the Organization' : 'Changing the Outlet');
  const getWarning = () => (category === 'Experts' ? UnitChangeWarning : category === 'Community Members' ? OrganizationChangeWarning : OrgChangeWarning);
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Alert severity="warning" onClose={() => setOpen(false)}>
        <AlertTitle>{getTitle()}</AlertTitle>
        <Typography variant="body2" paragraph={true}>
          {getWarning()} — <strong>{BoldChangeWarning}</strong>
        </Typography>
      </Alert>
    </Dialog>
  );
};

export default AlertChangeOrgUnit;
