import { SvgIconProps, SvgIcon } from '@mui/material';

export default function EditPencilIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 13 13" {...props}>
      <path
        d="M10.3359 1.88281H10.3125C10.1016 1.67188 9.75 1.67188 9.53906 1.88281L8.83594 2.5625L9.9375 3.66406L10.6172 2.96094C10.8281 2.75 10.8281 2.39844 10.6172 2.16406L10.3359 1.88281ZM4.89844 6.52344C4.82812 6.57031 4.78125 6.66406 4.75781 6.75781L4.35938 8.11719L5.74219 7.74219C5.83594 7.71875 5.90625 7.67188 5.97656 7.60156L9.14062 4.4375L8.0625 3.35938L4.89844 6.52344ZM8.74219 1.08594C9.39844 0.429688 10.4531 0.429688 11.1094 1.08594L11.4141 1.39062C12.0703 2.04688 12.0703 3.10156 11.4141 3.75781L6.77344 8.39844C6.58594 8.58594 6.32812 8.72656 6.04688 8.82031L3.70312 9.47656C3.51562 9.54688 3.30469 9.5 3.16406 9.33594C3 9.19531 2.95312 8.98438 3 8.79688L3.67969 6.45312C3.75 6.17188 3.91406 5.91406 4.10156 5.72656L8.74219 1.08594ZM2.0625 2H4.6875C4.99219 2 5.25 2.25781 5.25 2.5625C5.25 2.89062 4.99219 3.125 4.6875 3.125H2.0625C1.52344 3.125 1.125 3.54688 1.125 4.0625V10.4375C1.125 10.9766 1.52344 11.375 2.0625 11.375H8.4375C8.95312 11.375 9.375 10.9766 9.375 10.4375V7.8125C9.375 7.50781 9.60938 7.25 9.9375 7.25C10.2422 7.25 10.5 7.50781 10.5 7.8125V10.4375C10.5 11.5859 9.5625 12.5 8.4375 12.5H2.0625C0.914062 12.5 0 11.5859 0 10.4375V4.0625C0 2.9375 0.914062 2 2.0625 2Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
