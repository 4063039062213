import { TextField, Tooltip, Typography, Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ComboBox,
  FreeSolo,
  MultiSelect,
  ComboBoxCreatableExpert,
  ComboBoxCreatableOutlet,
  FileTableWithDragAndDrop,
  SingleSelectLinkedService,
  UrlTableWithDialog,
  ComboBoxCreatableDepartment,
  ComboBoxCreatableCommunityMember,
  ComboBoxCreatableJournalist,
} from '../Cells/CellTypes';
import {
  DisplayKeys,
  EngagementKeys,
  IssueAndServiceSharedValues,
  ServiceKeys,
} from '../../shared/constants/IssueAndServiceKeys';
import { useSelector } from 'react-redux';
import { diversityOptions, TrackerDataType } from '../../shared/constants/constants';
import UrlDialog from '../ServiceLog/UrlDialog';
import { useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/AddBox';
import AddLinkIcon from '@mui/icons-material/AddLink';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import DatePickerInputField from '../../shared/inputs/DatePickerInputField';
import StatusInputSelection from '../../shared/inputs/StatusInputSelection';
import ComplexityInputSelection from '../../shared/inputs/ComplexityInputSelection';
import ToneInputSelection from '../../shared/inputs/ToneInputSelection';

const useStyles = makeStyles(({ palette }) => ({
  mediumCell: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
  },
  smallCell: {
    display: 'flex',
    flexDirection: 'column',
    width: '23.5%',
  },
  bigCell: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  datePicker: {
    height: '56px',
    width: '100%',
    borderColor: `${palette.gray}`,
  },
  datePickerInput: {
    height: '56px',
    width: '100%',
  },
  commsCell: {
    flex: '1 0 0',
    minWidth: '100%',
    display: 'block',
    fontWeight: 400,
  },
  dateLabel: {
    flex: '0 1 200',
    maxWidth: '38%',
    minWidth: 190,
    marginBottom: '15px',
  },
  label: {
    flex: '1 0 88%',
    maxWidth: '100%',
    marginBottom: '15px',
  },
  labelError: {
    color: `${palette.error.main}`,
  },
  topicLabel: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  unitLeadLabel: {
    flex: '0 0 100',
    maxWidth: '38%',
    minWidth: 190,
  },
  unitLeadInput: {
    width: '100%',
  },
}));

function QueryAdderCell(props) {
  const {
    col,
    fields,
    dropdownTable,
    callback,
    className,
    required,
    error,
    eventHandler,
    files,
    handleDrag,
    handleDrop,
    isDragActive,
  } = props;
  const classes = useStyles();
  let options = [];
  const currentUser = useSelector((state) => state.user.userInfo);
  const users = dropdownTable['lead'];
  const journalists = dropdownTable['journalist'];
  const communityMembers = dropdownTable['communityMember'];
  const experts = dropdownTable['expert'];
  const topics = dropdownTable['topic'];
  const orgAgents = dropdownTable['orgAgents'];
  const organizationAgents = dropdownTable['organizationAgents'];
  const units = dropdownTable['units'];
  const preSelectUser = [
    {
      _id: currentUser.id,
      name: currentUser.name,
    },
  ];
  const [isUrlFormOpen, setIsUrlFormOpen] = useState(false);
  const file = useRef(null);

  const FileUploadButton = ({ onChange, colName }) => {
    const handleFileSubmit = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.files.length) {
        onChange([...files, ...e.target.files], colName);
      }
    };
    return (
      <>
        <input type="file" multiple id="file" ref={file} onChange={handleFileSubmit} style={{ display: 'none' }} />
        <Tooltip title="add file(s)">
          <label htmlFor="file" data-cy="modal-file-upload-button">
            <IconButton color="primary" onClick={() => file.current.click()} size="large">
              <AddIcon />
            </IconButton>
          </label>
        </Tooltip>
      </>
    );
  };

  const UrlUploadButton = ({ setFormOpen }) => {
    return (
      <>
        <Tooltip title="add link to web-hosted file">
          <IconButton color="primary" onClick={() => setFormOpen(true)} size="large" data-cy="modal-url-upload-button">
            <AddLinkIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const handleSubmit = (callback, dialogValue, handleClose, colName) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    callback([...files, { ...dialogValue, type: 'url' }], colName);

    handleClose();
  };

  const renderInputLabel = (className, labelText, required, error) => {
    return (
      <label htmlFor={labelText} className={`${className} ${error ? classes.labelError : ''}`}>
        {`${labelText} ${required ? '*' : ''}`}
      </label>
    );
  };

  let cell = (
    <label className={classes.label} htmlFor={DisplayKeys[col] || col}>
      <TextField
        align="left"
        className={`${className}`}
        InputLabelProps={{
          className: `${col === 'service' ? classes.topicLabel : ''}`,
        }}
        defaultValue={fields[col]}
        value={fields[col] ? fields[col] : ''}
        label={DisplayKeys[col] || col}
        onChange={(e) => {
          callback(e.target.value, col);
        }}
        required={required}
        error={error}
        variant="standard"
        data-cy={`${col}-input-QueryAdderCell`}
      />
    </label>
  );

  //TODO change to use CellSelector after backend key adjustments are removed.
  switch (col) {
    case 'date':
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.dateLabel, DisplayKeys[col] || col, required, error)}
          <DatePickerInputField
            classes={{ textInputFieldContainerClass: classes.datePicker, textInputFieldClass: classes.datePickerInput }}
            selected={fields[col] || new Date()}
            onChange={(date) => {
              callback(date, col);
            }}
            required={required}
            data-cy="datePicker-QueryAdderCell"
          />
        </Box>
      );
      break;
    case 'service':
      cell = (
        <Box className={classes.bigCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <TextField
            align="left"
            className={`${className}`}
            InputLabelProps={{
              className: `${col === 'service' ? classes.topicLabel : ''}`,
            }}
            defaultValue={fields[col]}
            value={fields[col] ? fields[col] : ''}
            onChange={(e) => {
              callback(e.target.value, col);
            }}
            required={required}
            error={error}
            variant="outlined"
            data-cy="service-input-QueryAdderCell"
          />
        </Box>
      );
      break;
    case 'topic':
      cell = (
        <Box className={classes.bigCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <FreeSolo
            list={topics}
            callback={callback}
            col={col}
            className={`${className} ${classes.topicLabel}`}
            required={required}
            value={fields[col]}
            error={error}
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'tone':
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <ToneInputSelection
            selectedTones={fields[col] ? [fields[col]] : []}
            onSelect={(selectedTones) => callback(selectedTones.length > 0 ? selectedTones[0] : undefined, col)}
          />
        </Box>
      );
      break;
    case 'lead':
      if (!fields[col]) {
        fields[col] = preSelectUser;
      }
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <MultiSelect
            options={users}
            callback={callback}
            val={fields[col]}
            col={col}
            className={className}
            required={required}
            error={error}
            data-cy="lead-select-QueryAdderCell"
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case IssueAndServiceSharedValues.leadExpert:
    case 'Expert Required':
    case 'unitLead':
    case 'expert':
      options = fields[col];
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <ComboBoxCreatableExpert
            units={units}
            options={experts.filter((expert) => !expert.archived)}
            callback={callback}
            val={fields[col]}
            col={col}
            setVal={(v) => (fields[col] = v)}
            className={className}
            required={required}
            error={error}
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'teamMember':
      if (!fields[col]) {
        fields[col] = preSelectUser;
      }

      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <MultiSelect
            options={users}
            callback={callback}
            val={fields[col]}
            col={col}
            className={className}
            required={required}
            error={error}
            data-cy="teamMember-select-QueryAdderCell"
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'outlet':
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <ComboBoxCreatableOutlet
            options={dropdownTable['outlet'].filter((outlet) => !outlet.archived)}
            callback={callback}
            val={fields[col]}
            col={col}
            setVal={(v) => (fields[col] = v)}
            className={className}
            required={required}
            error={error}
            data-cy="outlet-select-QueryAdderCell"
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'organization':
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <ComboBoxCreatableOutlet
            options={dropdownTable['organization'].filter((organization) => !organization.archived)}
            callback={callback}
            val={fields[col]}
            col={col}
            setVal={(v) => (fields[col] = v)}
            className={className}
            required={required}
            error={error}
            data-cy="organization-select-QueryAdderCell"
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'journalist':
      options = fields['outlet']
        ? orgAgents[fields['outlet']]?.filter((journalist) => !journalist.archived)
        : journalists.filter((journalist) => !journalist.archived);

      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <ComboBoxCreatableJournalist
            orgs={dropdownTable['outlet']}
            options={options}
            callback={callback}
            val={fields[col]}
            col={col}
            className={className}
            required={required}
            error={error}
            selectedOrg={fields['outlet']}
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'communityMember':
      options = fields['organization']
        ? organizationAgents[fields['organization']]?.filter((communityMember) => !communityMember.archived)
        : communityMembers.filter((communityMember) => !communityMember.archived);

      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <ComboBoxCreatableCommunityMember
            orgs={dropdownTable['organization']}
            options={options}
            callback={callback}
            val={fields[col]}
            col={col}
            className={className}
            required={required}
            error={error}
            selectedOrg={fields['organization']}
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'dept':
      // const depts = dropdownTable['dept'];
      // alert(JSON.stringify(depts));
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <ComboBoxCreatableDepartment
            options={dropdownTable['dept'].filter((dept) => !dept.archived)}
            callback={callback}
            val={fields[col]}
            col={col}
            className={className}
            required={required}
            error={error}
            data-cy="dept-select-QueryAdderCell"
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'campaign': {
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <MultiSelect
            options={dropdownTable['campaigns'].filter((campaign) => !campaign.archived)}
            callback={callback}
            val={fields[col]}
            col={col}
            groupBy={(option) => option.campaigns}
            required={required}
            error={error}
            data-cy="campaign-select-QueryAdderCell"
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    }
    case 'units': {
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <MultiSelect
            options={dropdownTable['units'].filter((unit) => !unit.archived).map((unit) => unit.name)}
            callback={callback}
            val={fields[col]}
            col={col}
            required={required}
            error={error}
            groupBy={(option) => option.units}
            data-cy="units-select-QueryAdderCell"
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    }
    case 'type':
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <MultiSelect
            options={dropdownTable['type']
              .filter((type) => !type.archived)
              .sort((a, b) => -b?.type?.localeCompare(a?.type) || -b?.firstLetter?.localeCompare(a?.firstLetter))}
            callback={callback}
            val={fields[col]}
            col={col}
            groupBy={(option) => option.type}
            required={required}
            error={error}
            data-cy="type-select-QueryAdderCell"
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'linkedService':
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <SingleSelectLinkedService
            options={dropdownTable['service']}
            callback={callback}
            val={fields[col]?.length > 0 ? fields[col][0] : ''}
            col={col}
            required={required}
            error={error}
            data-cy="linkedService-select-QueryAdderCell"
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'complexity':
      cell = (
        <Box className={classes.smallCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <ComplexityInputSelection
            selectedComplexities={fields[col] ? [fields[col]] : []}
            onSelect={(selectedComplexities) =>
              callback(selectedComplexities.length > 0 ? selectedComplexities[0] : undefined, col)
            }
          />
        </Box>
      );
      break;
    case 'diversity':
      cell = (
        <Box className={classes.smallCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <ComboBox
            options={diversityOptions}
            callback={callback}
            val={fields[col]}
            col={col}
            required={required}
            error={error}
            data-cy="diversity-comboBox-QueryAdderCell"
            isConstant
            textFieldVariant="outlined"
            displayLabel={false}
          />
        </Box>
      );
      break;
    case 'linkedInteraction':
      cell = <></>;
      break;
    case 'file':
      cell =
        eventHandler.type === TrackerDataType.SERVICE || eventHandler.type === TrackerDataType.ENGAGEMENT ? (
          <Box style={{ minHeight: '5vh' }} className={classes.commsCell}>
            <UrlDialog
              colName={col}
              type={eventHandler.type}
              isOpen={isUrlFormOpen}
              setIsOpen={setIsUrlFormOpen}
              callback={callback}
              handleSubmit={handleSubmit}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography>
                {eventHandler.type === TrackerDataType.SERVICE ? ServiceKeys.file : EngagementKeys.file}
              </Typography>
              <FileUploadButton file={file} onChange={callback} colName={col} />
              <UrlUploadButton setFormOpen={setIsUrlFormOpen} />
            </Box>
            <FileTableWithDragAndDrop
              col={col}
              files={files}
              onDelete={callback}
              handleDrag={handleDrag}
              handleDrop={handleDrop}
              isDragActive={isDragActive}
            />
          </Box>
        ) : (
          <UrlTableWithDialog col={col} files={files} onChange={callback} />
        );
      break;
    case 'actions':
    case 'response':
    case 'keyMessaging':
      cell = (
        <Box className={classes.bigCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <RichTextEditor
            defaultValue={''}
            value={fields[col]}
            onChange={(value) => {
              callback(value, col);
            }}
            required={required}
            error={error}
            dataCy={`${col}-input-QueryAdderCell`}
            readOnly={false}
          />
        </Box>
      );
      break;
    case 'status':
      cell = (
        <Box className={classes.mediumCell}>
          {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
          <StatusInputSelection
            selectedStatus={fields[col] ? [fields[col]] : []}
            onSelect={(selectedStatus) => {
              callback(selectedStatus.length > 0 ? selectedStatus[0] : undefined, col);
            }}
          />
        </Box>
      );
      break;
    default:
      if (col !== 'service' && dropdownTable.hasOwnProperty(col)) {
        cell = (
          <Box className={classes.mediumCell}>
            {renderInputLabel(classes.label, DisplayKeys[col] || col, required, error)}
            <ComboBox
              options={dropdownTable[col]}
              callback={callback}
              val={fields[col]}
              col={col}
              className={className}
              required={required}
              error={error}
              data-cy={`${col}-select-QueryAdderCell`}
              textFieldVariant="outlined"
              displayLabel={false}
            />
          </Box>
        );
      }
      break;
  }

  return <>{cell}</>;
}

export { QueryAdderCell };
