import { SvgIconProps, SvgIcon } from '@mui/material';

export default function DuplicateIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 12 13" {...props}>
      <path
        d="M10.5 9.5H6C5.15625 9.5 4.5 8.84375 4.5 8V2C4.5 1.17969 5.15625 0.5 6 0.5H9.28125C9.5625 0.5 9.86719 0.640625 10.0781 0.851562L11.6484 2.42188C11.8594 2.63281 12 2.9375 12 3.21875V8C12 8.84375 11.3203 9.5 10.5 9.5ZM1.5 3.5H3.75V4.625H1.5C1.28906 4.625 1.125 4.8125 1.125 5V11C1.125 11.2109 1.28906 11.375 1.5 11.375H6C6.1875 11.375 6.375 11.2109 6.375 11V10.25H7.5V11C7.5 11.8438 6.82031 12.5 6 12.5H1.5C0.65625 12.5 0 11.8438 0 11V5C0 4.17969 0.65625 3.5 1.5 3.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
