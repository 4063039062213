import { IconButton, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import Fuse from 'fuse.js';
import { useEffect, useState, useCallback } from 'react';

function fuzzySearch(rows, query, category) {
  let fuseOptionsKeys;
  if (category === 'Journalists' || category === 'Experts' || category === 'Organizations') {
    fuseOptionsKeys = ['name', 'Org.name', 'unitName', 'notes', 'Organization.name'];
  } else {
    fuseOptionsKeys = ['name'];
  }
  const options = {
    threshold: 0.2,
    ignoreLocation: true,
    keys: fuseOptionsKeys,
  };
  const fuse = new Fuse(rows, options);
  const results = fuse.search(query).map((result) => result.item);
  return results;
}

const useStyles = makeStyles((theme) => ({
  contactFuzzySearchTextField: { width: '300px' },
  searchIcon: { backgroundColor: '#DEDEDE', borderRadius: '4px' },
}));

const ContactFuzzySearch = (props) => {
  const classes = useStyles();
  const { contacts, setSearchResults, category } = props;
  const [textFieldValue, setTextFieldValue] = useState('');

  const handleSearch = useCallback(
    (keywords) => {
      if (keywords !== '') {
        const results = fuzzySearch(contacts, keywords, category);
        const sortedResults = [...results].sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
        setSearchResults(sortedResults);
      } else {
        const sortedContacts = [...contacts].sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
        setSearchResults(sortedContacts);
      }
    },
    [contacts, category, setSearchResults]
  );

  const handleChange = (e) => {
    setTextFieldValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(textFieldValue);
    }
  };

  const handleSearchButtonClick = (e) => {
    handleSearch(textFieldValue);
  };

  //reset search if category changed
  useEffect(() => {
    setTextFieldValue('');
  }, [category]);

  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder={
        category === 'Journalists'
          ? 'Name/Outlet/Notes'
          : category === 'Experts'
          ? 'Name/Unit/Notes'
          : category === 'Community Members'
          ? 'Name/Organization/Notes'
          : category === 'Organizations'
          ? 'Search Organizations...'
          : 'Search Outlets...' // default placeholder
      }
      value={textFieldValue}
      onChange={handleChange}
      data-cy="fuzzySearch-input-ContactFuzzySearch"
      className={classes.contactFuzzySearchTextField}
      onKeyDown={handleKeyPress}
      InputProps={{
        style: { paddingRight: 0 },
        endAdornment: (
          <IconButton onClick={handleSearchButtonClick} className={classes.searchIcon}>
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default ContactFuzzySearch;
