import { useState } from 'react';
import { complexityOptions, ServiceComplexityColorMap } from '../constants/constants';
import { Box, Button } from '@mui/material';

interface ComplexityInputSelectionProps {
  selectedComplexities?: number[];
  onSelect?: (selectedComplexities: number[]) => void;
  multiSelect?: boolean;
}

export default function ComplexityInputSelection(props: ComplexityInputSelectionProps) {
  const { selectedComplexities, onSelect, multiSelect } = props;

  const [localSelectedComplexities, setLocalSelectedComplexities] = useState(selectedComplexities ?? []);

  const handleClick = (complexity: number) => {
    const isSelected = localSelectedComplexities.some((selectedComplexity) => selectedComplexity === complexity);
    let selectedList: number[] = [];
    if (isSelected) {
      selectedList = localSelectedComplexities.filter((selectedComplexity) => selectedComplexity !== complexity);
    } else if (multiSelect) {
      selectedList = [...localSelectedComplexities, complexity];
    } else {
      selectedList = [complexity];
    }
    setLocalSelectedComplexities(selectedList);

    if (onSelect) {
      onSelect(selectedList);
    }
  };

  const getButtonColor = (complexity: number) => {
    const isSelected = localSelectedComplexities.some((selectedComplexity) => selectedComplexity === complexity);
    if (isSelected) {
      return ServiceComplexityColorMap[complexity];
    } else {
      return 'whiteScale';
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '16px' }}>
      {complexityOptions.map((complexity, index) => (
        <Button
          key={`${complexity.name}-${index}`}
          sx={{ minWidth: '32px', width: '32px', height: '32px' }}
          variant="contained"
          color={getButtonColor(complexity._id)}
          onClick={() => handleClick(complexity._id)}
          data-cy={`complexity-${complexity._id}`}
        >
          {complexity._id}
        </Button>
      ))}
    </Box>
  );
}
