import { SvgIconProps, SvgIcon, useTheme } from '@mui/material';

export default function SmartPhoneIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 18 16" sx={{ color: theme.palette.primaryDefault.main }} {...props}>
      <path d="M5.5 1.5C5.21875 1.5 5 1.75 5 2V14C5 14.2812 5.21875 14.5 5.5 14.5H12.5C12.75 14.5 13 14.2812 13 14V2C13 1.75 12.75 1.5 12.5 1.5H5.5ZM3.5 2C3.5 0.90625 4.375 0 5.5 0H12.5C13.5938 0 14.5 0.90625 14.5 2V14C14.5 15.125 13.5938 16 12.5 16H5.5C4.375 16 3.5 15.125 3.5 14V2ZM8 12.5H10C10.25 12.5 10.5 12.75 10.5 13C10.5 13.2812 10.25 13.5 10 13.5H8C7.71875 13.5 7.5 13.2812 7.5 13C7.5 12.75 7.71875 12.5 8 12.5Z" />
    </SvgIcon>
  );
}
