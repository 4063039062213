import { useCallback, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import { Button, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

// For Journalists Export Preview
const JournalistsColDefs = [
  { field: 'name', flex: 1, sort: 'asc' },
  { field: 'email', flex: 1.5 },
  { field: 'phone', flex: 1 },
  { field: 'Org.name', flex: 1, headerName: 'Outlet' },
  { field: 'cell', flex: 1 },
  { field: 'notes', flex: 1 },
  { field: 'archived', flex: 0.5 },
];

// For Experts Export Preview
const ExpertsColDefs = [
  { field: 'name', flex: 1, sort: 'asc' },
  { field: 'email', flex: 1.5 },
  { field: 'phone', flex: 1 },
  { field: 'unitName', flex: 1, headerName: 'Unit' },
  { field: 'language', flex: 1 },
  { field: 'gender', flex: 1 },
  { field: 'notes', flex: 1 },
  { field: 'archived', flex: 0.5 },
];

// For Outlets Export Preview (Journalists grouped by outlet)
const JournalistsByOutletsColDefs = [
  { field: 'Org.name', flex: 1, headerName: 'Outlet', sort: 'asc' },
  { field: 'name', flex: 1 },
  { field: 'email', flex: 1.5 },
  { field: 'phone', flex: 1 },
  { field: 'cell', flex: 1 },
  { field: 'notes', flex: 1 },
  { field: 'archived', flex: 0.5 },
];

const CommunityMembersColDefs = [
  { field: 'name', flex: 1, sort: 'asc' },
  { field: 'email', flex: 1.5 },
  { field: 'phone', flex: 1 },
  { field: 'Organization.name', flex: 1, headerName: 'Organization' },
  { field: 'cell', flex: 1 },
  { field: 'notes', flex: 1 },
  { field: 'archived', flex: 0.5 },
];

const CommunityMembersByOrganizationsColDefs = [
  { field: 'Organization.name', flex: 1, headerName: 'Organization', sort: 'asc' },
  { field: 'name', flex: 1 },
  { field: 'email', flex: 1.5 },
  { field: 'phone', flex: 1 },
  { field: 'cell', flex: 1 },
  { field: 'notes', flex: 1 },
  { field: 'archived', flex: 0.5 },
];

const featureDescription = `This is a preview of the contacts that will be exported.
Click the button below to download the CSV file.
You can drag the column headers to resize and reorder the columns.
`;

const CotactsExportPreview = ({ category, previewData, autoOpen = false, onPreviewClose }) => {
  const [openPreview, setOpenPreview] = useState(autoOpen);
  const gridRef = useRef();
  const todaysDate = new Date().toLocaleString();

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv({
      fileName: `${category} Export, ${todaysDate}`,
    });
  }, [category, todaysDate]);

  // Based on the category, set the column definitions.
  const categoryMap = new Map([
    ['Journalists', JournalistsColDefs],
    ['Experts', ExpertsColDefs],
    ['Outlets', JournalistsByOutletsColDefs],
    ['Community Members', CommunityMembersColDefs],
    ['Organizations', CommunityMembersByOrganizationsColDefs],
  ]);

  const ColDefs = categoryMap.get(category) || JournalistsByOutletsColDefs;

  const handleClose = () => {
    setOpenPreview(false);
    onPreviewClose && onPreviewClose();
  };
  return (
    <>
      <Tooltip title="Preview and export the current list of contacts">
        <Button
          variant="contained"
          onClick={() => {
            setOpenPreview(true);
          }}
          data-cy="exportContact-button-ContactsExportPreview"
        >
          <DownloadOutlinedIcon />
          Export Contacts
        </Button>
      </Tooltip>
      <Dialog open={openPreview} fullWidth={true} maxWidth="xl" onClose={handleClose}>
        <DialogTitle>{category} Export Preview</DialogTitle>
        <DialogContent>
          <DialogContentText>{featureDescription}</DialogContentText>
          <div
            className="ag-theme-quartz-dark" // applying the Data Grid theme
            style={{ height: '60vh', margin: 'auto' }} // the Data Grid will fill the size of the parent container
          >
            <AgGridReact
              ref={gridRef}
              rowData={previewData}
              columnDefs={ColDefs}
              pagination={true}
              paginationPageSize={20}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onBtnExport} startIcon={<DownloadIcon />}>
            Download
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CotactsExportPreview;
