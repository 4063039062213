import { API } from '../../shared/constants/constants';
import { AIScrapeResult, EmailQueueEntry } from './Interfaces';
import { IssueAndServiceSharedValues, IssueKeys } from '../../shared/constants/IssueAndServiceKeys';
import { postNewDepartmentEnum, postNewUnitEnum } from '../Admin/adminPageRequest';

export const getEmails = async () => {
  try {
    const res = await API.get('/parsed-email', {});
    return res.data;
  } catch (err: any) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when getting emails');
  }
};

export const deleteEmail = async (email: EmailQueueEntry) => {
  try {
    return await API.delete(`/parsed-email/${email._id}`);
  } catch (err: any) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when processing email');
  }
};

export const saveFixedEmail = async (email: EmailQueueEntry, userFixedEmail: AIScrapeResult) => {
  try {
    const data = {
      userFixedResult: userFixedEmail,
      aiScrapeResult: email.aiScrapeResult,
      email: email.text,
      destination: email.destination,
    };
    return await API.post('/fixed-email', data);
  } catch (err: any) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when processing email');
  }
};

export async function addContact(column: string, aiScrapeResult: AIScrapeResult, dropdownTable: any) {
  try {
    switch (column) {
      case IssueKeys.outlet: {
        const newOutlet = {
          name: aiScrapeResult.Outlet?.name ?? aiScrapeResult.Outlet,
        };
        await API.post('/org', newOutlet);
        break;
      }
      case IssueKeys.journalist: {
        const foundOrgId = dropdownTable['outlet'].find(
          (value: any) => value?.name === (aiScrapeResult.Outlet?.name ?? aiScrapeResult.Outlet)
        )?._id;
        if (foundOrgId || !aiScrapeResult.Outlet) {
          const newJournalist = {
            name: aiScrapeResult.Journalist?.name ?? aiScrapeResult.Journalist,
            Org: foundOrgId,
            phone: aiScrapeResult['Journalist Phone'] ?? '',
            cell: '',
            notes: '',
            email: aiScrapeResult['Journalist Email'] ?? '',
          };
          await API.post('/journalist', newJournalist);
        }
        break;
      }
      case IssueAndServiceSharedValues.leadExpert: {
        if (!aiScrapeResult['Lead/Expert']) {
          break;
        }
        await Promise.all(
          aiScrapeResult['Lead/Expert']?.map(async (expert) => {
            const expertName = expert.name ?? expert;
            if (dropdownTable['expert'].find((value: any) => value.name === expertName)) {
              return Promise.resolve();
            }
            const newExpert = {
              name: expertName,
              phone: '',
              email: '',
              unit: '',
              language: '',
              gender: '',
            };
            await API.post('/expert', newExpert);
            return Promise.resolve();
          })
        );
        break;
      }
      case IssueAndServiceSharedValues.unit: {
        const newUnit = {
          name: aiScrapeResult.Unit?.name ?? aiScrapeResult.Unit,
        };
        await postNewUnitEnum(newUnit);
        break;
      }
      case IssueAndServiceSharedValues.department: {
        const newDept = {
          name: aiScrapeResult.Department?.name ?? aiScrapeResult.Department,
        };
        await postNewDepartmentEnum(newDept);
        break;
      }
      default:
        break;
    }
  } catch (error: any) {
    throw new Error(error.response?.data?.message ?? `Error adding data for ${column}}`);
  }
}

export async function getForwardingAddress() {
  try {
    const res = await API.get('/parsed-email-address');
    return res.data.address;
  } catch (err: any) {
    return '';
  }
}
