import { API } from '../../shared/constants/constants';

export const getJournalists = async () => {
  try {
    const res = await API.get(`/journalists`);
    return res.data;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const getExperts = async () => {
  try {
    const res = await API.get(`/experts`);
    return res.data;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const getUnits = async () => {
  try {
    const res = await API.get(`table/units`);
    return res.data;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

// search for related issues of a contact
export const getRelatedIssues = async (jID) => {
  // URL encod the contactId
  // const encodedID = encodeURIComponent(jID);
  try {
    const res = await API.get(`/issues/filter?journalist=["${jID}"]`);
    return res.data;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const getRelatedIssuesByContactPersonnelID = async (category, jID) => {
  let URL;
  category === 'Journalists' ? (URL = `/issues/journalist/${jID}`) : (URL = `/issues/expert/${jID}`);
  try {
    const res = await API.get(URL);
    // const res = await API.get(`/issues/filter?journalist=["611cb664befb95d5a4a5c4b4"]`);
    return res.data;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const getPersonnelsByOrg = async (outletID) => {
  const URL = `/journalists?Org=${outletID}`;
  const res = await API.get(URL);
  res.data?.data?.sort((dataA, dataB) => {
    if (dataA.name?.toLowerCase() < dataB.name?.toLowerCase()) {
      return -1;
    }
    if (dataA.name?.toLowerCase() > dataB.name?.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return res.data;
};

//get all Organizations
export const getOrgs = async () => {
  try {
    const res = await API.get(`/orgs`);
    return res.data;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const getOrgsByCaseInsensitiveName = async (orgName) => {
  const res = await API.get(`/orgs?name=${orgName}&caseInsensitiveName=true`);
  return res.data;
};

export const getDeptsByCaseInsensitiveName = async (deptName) => {
  const res = await API.get(`/depts?name=${deptName}&caseInsensitiveName=true`);
  return res.data;
};

export const getJournalistsByCaseInsensitiveNameAndOrg = async (journalistName, org) => {
  const res = await API.get(`/journalists?name=${journalistName}&Org=${org}&caseInsensitiveName=true`);
  return res.data;
};

export const getExpertsByCaseInsensitiveNameAndUnit = async (expertName, unit) => {
  const res = await API.get(`/experts?name=${expertName}&unit=${unit}&caseInsensitiveName=true`);
  return res.data;
};

export const mergeOrgs = async (toBeMergedOrgs, targetOrg, targetOrgJournalists) => {
  try {
    const res = await API.put(`/journalists/mergeOrgs`, {
      toBeMergedOrgs,
      targetOrg,
      targetOrgJournalists,
    });
    return res;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const mergeJournalists = async (mergingJournalists, targetJournalist) => {
  try {
    const res = await API.put(`/issues/mergeJournalists`, {
      mergingJournalists: mergingJournalists,
      targetJournalist: targetJournalist,
    });
    return res;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const mergeExperts = async (mergingExperts, targetExpert) => {
  try {
    const resIssues = await API.put(`/issues/mergeExperts`, {
      mergingExperts: mergingExperts,
      targetExpert: targetExpert,
    });
    const resServices = await API.put(`/services/mergeExperts`, {
      mergingExperts: mergingExperts,
      targetExpert: targetExpert,
    });
    const resEngagements = await API.put(`/engagements/mergeExperts`, {
      mergingExperts: mergingExperts,
      targetExpert: targetExpert,
    });
    if (resIssues.status === 200 && resServices.status === 200 && (resEngagements.status === 200 || resEngagements.status === 403)) {
      return { status: 200, message: 'Experts merged successfully' };
    }
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const archiveJournalist = async (journalistId) => {
  try {
    const res = await API.put(`/journalist/${journalistId}/archive`);
    return res;
  } catch (error) {
    console.error(`failed to archive journalist: ${journalistId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};

export const unarchiveJournalist = async (journalistId) => {
  try {
    const res = await API.put(`/journalist/${journalistId}/unarchive`);
    return res;
  } catch (error) {
    console.error(`failed to unarchive journalist: ${journalistId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};

export const archiveExpert = async (expertId) => {
  try {
    const res = await API.put(`/expert/${expertId}/archive`);
    return res;
  } catch (error) {
    console.error(`failed to archive expert: ${expertId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};

export const unarchiveExpert = async (expertId) => {
  try {
    const res = await API.put(`/expert/${expertId}/unarchive`);
    return res;
  } catch (error) {
    console.error(`failed to unarchive expert: ${expertId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};

export const archiveOrg = async (orgId) => {
  try {
    const res = await API.put(`/org/${orgId}/archive`);
    return res;
  } catch (error) {
    console.error(`failed to archive organization: ${orgId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};

export const unarchiveOrg = async (orgId) => {
  try {
    const res = await API.put(`/org/${orgId}/unarchive`);
    return res;
  } catch (error) {
    console.error(`failed to unarchive organization: ${orgId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};

export const getCommunityMembers = async () => {
  try {
    const res = await API.get(`/community-members`);
    return res.data;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const getOrganizations = async () => {
  try {
    const res = await API.get('/organizations');
    return res.data;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const getPersonnelByOrganization = async (outletID) => {
  const URL = `/community-members?Organization=${outletID}`;
  const res = await API.get(URL);
  res.data?.data?.sort((dataA, dataB) => {
    if (dataA.name?.toLowerCase() < dataB.name?.toLowerCase()) {
      return -1;
    }
    if (dataA.name?.toLowerCase() > dataB.name?.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return res.data;
};

export const getOrganizationsByCaseInsensitiveName = async (organizationName) => {
  const res = await API.get(`/organizations?name=${organizationName}&caseInsensitiveName=true`);
  return res.data;
};

export const getCommunityMembersByCaseInsensitiveNameAndOrganization = async (communityMemberName, organization) => {
  const res = await API.get(`/community-members?name=${communityMemberName}&Organization=${organization}&caseInsensitiveName=true`);
  return res.data;
};

export const mergeOrganizations = async (toBeMergedOrganizations, targetOrganization, targetOrganizationCommunityMembers) => {
  try {
    const res = await API.put(`/community-members/mergeOrganizations`, {
      toBeMergedOrganizations,
      targetOrganization,
      targetOrganizationCommunityMembers,
    });
    return res;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const mergeCommunityMembers = async (mergingCommunityMembers, targetCommunityMember) => {
  try {
    const res = await API.put(`/engagements/mergeCommunityMembers`, {
      mergingCommunityMembers,
      targetCommunityMember,
    });
    return res;
  } catch (error) {
    return { error: 'Failed request', message: error.message };
  }
};

export const archiveCommunityMember = async (communityMemberId) => {
  try {
    const res = await API.put(`/community-member/${communityMemberId}/archive`);
    return res;
  } catch (error) {
    console.error(`failed to archive community member: ${communityMemberId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};

export const unarchiveCommunityMember = async (communityMemberId) => {
  try {
    const res = await API.put(`/community-member/${communityMemberId}/unarchive`);
    return res;
  } catch (error) {
    console.error(`failed to unarchive journalist: ${communityMemberId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};

export const archiveOrganization = async (organizationId) => {
  try {
    const res = await API.put(`/organization/${organizationId}/archive`);
    return res;
  } catch (error) {
    console.error(`failed to archive organization: ${organizationId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};

export const unarchiveOrganization = async (organizationId) => {
  try {
    const res = await API.put(`/organization/${organizationId}/unarchive`);
    return res;
  } catch (error) {
    console.error(`failed to unarchive organization: ${organizationId}`, error);
    return { error: 'Failed request', message: error.message };
  }
};
