import { Autocomplete, createFilterOptions, useTheme } from '@mui/material';
import {
  RadioGroup,
  Radio,
  Tooltip,
  Typography,
  Zoom,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  FormLabel,
  FormControl,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { API, TrackerDataType, getTabColor } from '../../shared/constants/constants';
import {
  DisplayKeys,
  EngagementKeys,
  IssueAndServiceSharedValues,
  IssueKeys,
  ServiceKeys,
} from '../../shared/constants/IssueAndServiceKeys';
import {
  AiOutlineMail,
  AiOutlineFileExcel,
  AiOutlineFileWord,
  AiOutlineFilePdf,
  AiOutlineFile,
  AiOutlineLink,
} from 'react-icons/ai';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { FileProgressContext } from '../TrackerTable/TrackerCollapsedRow';
import { CircularProgressWithLabel } from '../../shared/Spinner/Spinner';
import { useLocation } from 'react-router-dom';
import { tooltipDescriptions, tooltipDirections } from '../../shared/constants/tooltipDescriptions';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import CombinedContactPersonnelModal from '../Contacts/Modal/CombinedContactPersonnelModal';
import {
  getOrgsByCaseInsensitiveName,
  getDeptsByCaseInsensitiveName,
  getOrganizationsByCaseInsensitiveName,
} from '../Contacts/contactsRequest';

const filter = createFilterOptions();

const useStyles = makeStyles(({ palette }) => ({
  cellInput: {
    minWidth: 190,
    width: '100%',
  },
  groupLabel: {
    background: ({ tabColor }) => `${tabColor} !important`,
    color: `${palette.primary.contrastText} !important`,
    fontWeight: '700 !important',
  },
  dialog: {
    minHeight: '15vh',
    overflow: 'visible !important',
  },
  dragAndDrop: {
    width: '100%',
    borderWidth: '2px',
    borderRadius: '1rem',
    borderStyle: 'dashed',
    borderColor: '#cbd5e1',
    minHeight: '100px',
  },
  containerDrag: {
    borderStyle: 'dashed',
    borderColor: 'grey',
    borderWidth: '4px',
    backgroundColor: '#cbd5e1',
    width: '100%',
    borderRadius: '1rem',
    minHeight: '100px',
  },
  noDragAndDrop: {
    width: '100%',
    borderWidth: '2px',
    borderRadius: '1rem',
    borderColor: '#cbd5e1',
    minHeight: '100px',
  },
}));

function ComboBoxObjInput(props) {
  const { options, col, callback, val, required, error } = props;

  const { pathname } = useLocation();

  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });

  const selected = options.find((option) => option._id === val);
  return (
    <Autocomplete
      disablePortal
      clearOnEscape
      openOnFocus
      autoSelect
      autoHighlight
      id={col}
      options={options}
      value={selected}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      renderInput={getRenderInput({ col, classes, error, required, delay: 500 })}
      onChange={(e, value) => {
        callback(value, col);
      }}
    />
  );
}

function getRenderInput({
  col,
  classes,
  error,
  required,
  delay,
  dataCy,
  textFieldVariant = 'standard',
  displayLabel = true,
  disableTooltip = false,
}) {
  return (params) => (
    <Tooltip
      title={disableTooltip ? null : <Typography variant={'body2'}>{tooltipDescriptions[col]}</Typography>}
      TransitionComponent={Zoom}
      enterDelay={delay}
      placement={'top'}
      disableHoverListener
      disable
    >
      <TextField
        {...params}
        label={displayLabel ? DisplayKeys[col] || col : ''}
        className={classes.cellInput}
        error={error}
        required={required}
        variant={textFieldVariant}
        data-cy={dataCy}
      />
    </Tooltip>
  );
}

function ComboBox(props) {
  const { options, col, callback, val, className, required, error, isConstant, textFieldVariant, displayLabel } = props;
  const { pathname } = useLocation();

  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });
  return (
    <Autocomplete
      disablePortal
      clearOnEscape
      openOnFocus
      autoHighlight
      autoSelect
      id={col}
      options={options}
      value={options.find((option) => (isConstant ? option._id === val : option.name === val)) || ''}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      renderInput={getRenderInput({
        col,
        classes,
        error,
        required,
        delay: 200,
        dataCy: undefined,
        textFieldVariant,
        displayLabel,
      })}
      onChange={(e, value) => {
        callback(value, col);
      }}
      className={className}
    />
  );
}

function ComboBoxCreatableJournalist(props) {
  const { initialSettings, textFieldVariant, displayLabel } = props;

  const [dialogValue, setDialogValue] = React.useState({
    ...initialSettings,
    name: '',
    phone: '',
    cell: '',
    notes: '',
    email: '',
    Org: '',
    _id: '',
  });

  return (
    <ComboBoxCreatableJournalistOrCommunityMember
      {...props}
      dialogValue={dialogValue}
      setDialogValue={setDialogValue}
      textFieldVariant={textFieldVariant}
      displayLabel={displayLabel}
    />
  );
}

function ComboBoxCreatableCommunityMember(props) {
  const { initialSettings, textFieldVariant, displayLabel } = props;

  const [dialogValue, setDialogValue] = React.useState({
    ...initialSettings,
    name: '',
    phone: '',
    cell: '',
    notes: '',
    email: '',
    Organization: '',
    _id: '',
  });

  return (
    <ComboBoxCreatableJournalistOrCommunityMember
      {...props}
      dialogValue={dialogValue}
      setDialogValue={setDialogValue}
      textFieldVariant={textFieldVariant}
      displayLabel={displayLabel}
    />
  );
}

function ComboBoxCreatableJournalistOrCommunityMember(props) {
  const {
    orgs,
    options,
    col,
    initialSettings,
    callback,
    val,
    required,
    error,
    selectedOrg,
    dialogValue,
    setDialogValue,
    textFieldVariant,
    displayLabel,
  } = props;
  const [open, toggleOpen] = React.useState(false);

  const boxOptions = React.useMemo(() => {
    return options ? options : [];
  }, [options]);

  const name = boxOptions.find((option) => option._id === val);
  const [value, setValue] = React.useState(name ? name : null);

  const { pathname } = useLocation();

  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });

  React.useEffect(() => {
    if (!val) return setValue(null);
    const newName = boxOptions.find((option) => option._id === val._id || option._id === val);
    setValue(newName ? newName : null);
  }, [val, boxOptions]);

  return (
    <React.Fragment>
      <Autocomplete
        disablePortal
        clearOnEscape
        openOnFocus
        handleHomeEndKeys
        autoHighlight
        id={col}
        options={boxOptions}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        value={value || null}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        renderInput={getRenderInput({
          col,
          classes,
          error,
          required,
          delay: 500,
          dataCy: 'journalist-select-ComboBoxCreatableJournalist',
          textFieldVariant,
          displayLabel,
        })}
        onChange={(e, value) => {
          if (typeof value === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: value,
                _id: '',
              });
            });
          } else if (value?.inputValue) {
            toggleOpen(true);
            setDialogValue({
              ...initialSettings,
              name: value.inputValue,
              _id: '',
            });
          } else {
            setValue(value);
            callback(value, col);
          }
        }}
        className={''}
      />
      <CombinedContactPersonnelModal
        openAddContact={open}
        setOpenAddContact={toggleOpen}
        orgs={orgs}
        category={col === IssueKeys.journalist || col === 'journalist' ? 'Journalists' : 'Community Members'}
        selectedOrg={selectedOrg}
        isInContactPage={false}
        col={col}
        dialogValue={dialogValue}
        setDialogValue={setDialogValue}
        value={value}
        setValue={setValue}
        callback={callback}
      />
    </React.Fragment>
  );
}

function ComboBoxCreatableExpert(props) {
  const {
    units,
    options,
    col,
    initialSettings,
    callback,
    val,
    setVal,
    required,
    error,
    textFieldVariant,
    displayLabel,
  } = props;
  const [open, toggleOpen] = React.useState(false);

  const boxOptions = options ? options : [];

  // const name = boxOptions.find((option) => option._id === val);
  const value = val ?? [];
  const setValue = setVal;

  const [dialogValue, setDialogValue] = React.useState({
    ...initialSettings,
    name: '',
    phone: '',
    email: '',
    unit: '',
    language: '',
    gender: '',
    _id: '',
  });
  const { pathname } = useLocation();

  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });

  return (
    <React.Fragment>
      <Autocomplete
        selectOnFocus
        clearOnBlur
        multiple
        disablePortal
        clearOnEscape
        openOnFocus
        handleHomeEndKeys
        autoHighlight
        id={col}
        options={boxOptions}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        isOptionEqualToValue={(option, value) => {
          return option._id === value._id;
        }}
        value={value ? value : []}
        getOptionLabel={(option) => option?.name}
        renderInput={getRenderInput({
          col,
          classes,
          error,
          required,
          delay: 500,
          dataCy: 'lead_expert-select-ComboBoxCreatableExpert',
          textFieldVariant,
          displayLabel,
        })}
        onChange={(e, value) => {
          if (typeof value === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: value,
                _id: '',
              });
            });
          } else if (value.length > 0 && value.at(-1).inputValue) {
            toggleOpen(true);
            setDialogValue({
              ...initialSettings,
              name: value.at(-1).inputValue,
              _id: '',
            });
          } else {
            setValue(value);
            callback(value, col);
          }
        }}
        className={''}
      />
      <CombinedContactPersonnelModal
        openAddContact={open}
        setOpenAddContact={toggleOpen}
        units={units}
        category={'Experts'}
        isInContactPage={false}
        col={col}
        dialogValue={dialogValue}
        setDialogValue={setDialogValue}
        value={value}
        setValue={setValue}
        callback={callback}
      />
    </React.Fragment>
  );
}

function ComboBoxCreatableOutlet(props) {
  const { options, col, callback, val, required, error, textFieldVariant, displayLabel, disableTooltip } = props;
  const [open, toggleOpen] = React.useState(false);

  const name = options.find((option) => option._id === val);
  const [value, setValue] = React.useState(name ? name : null);

  const defaultDialogValue = {
    name: '',
    _id: '',
  };
  const [dialogValue, setDialogValue] = React.useState(defaultDialogValue);
  const { pathname } = useLocation();

  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });

  const countOrgDuplicates = async (orgName) => {
    try {
      const response =
        col === IssueKeys.outlet || col === 'outlet'
          ? await getOrgsByCaseInsensitiveName(orgName)
          : await getOrganizationsByCaseInsensitiveName(orgName);
      return response.data.length;
    } catch (error) {
      alert(`get failed - ${error}`);
      return 0;
    }
  };

  const orgString = col === IssueKeys.outlet || col === 'outlet' ? IssueKeys.outlet : EngagementKeys.organization;

  const dispatch = useDispatch();

  const handleClose = () => {
    setDialogValue(defaultDialogValue);
    toggleOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const submitNewOutletRequestPath = col === IssueKeys.outlet || col === 'outlet' ? '/org' : '/organization';
    const numOfDuplicatesOfOrg = await countOrgDuplicates(dialogValue.name);

    if (numOfDuplicatesOfOrg > 0) {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: `${numOfDuplicatesOfOrg} ${orgString.toLowerCase()}(s) with the same name already exists. Please check if you are trying to add a ${orgString} that already exists.`,
        })
      );
    } else {
      try {
        const response = await API.post(`${submitNewOutletRequestPath}`, dialogValue);

        if (response.status !== 201) {
          alert(response.statusText);
        } else {
          dispatch(
            openSnackbar({
              severity: 'success',
              message: `${orgString}: ${dialogValue.name} created!`,
            })
          );
        }

        const newObj = { _id: response.data.id, name: dialogValue.name };

        setDialogValue(newObj);
        setValue(newObj);
        callback(newObj, col);

        handleClose();
      } catch (err) {
        alert(`post failed - ${err}`);
      }
    }
  };

  React.useEffect(() => {
    const newName = options.find((option) => option._id === val);
    setValue(newName);
  }, [val, options]);

  return (
    <React.Fragment>
      <Autocomplete
        disablePortal
        clearOnEscape
        openOnFocus
        handleHomeEndKeys
        autoHighlight
        id={col}
        options={options}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Typography variant={'body1'} sx={{ textAlign: 'left' }}>
              {option.name}
            </Typography>
          </li>
        )}
        value={value || null}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        renderInput={getRenderInput({
          col,
          classes,
          error,
          required,
          delay: 500,
          dataCy: undefined,
          textFieldVariant,
          displayLabel,
          disableTooltip,
        })}
        onChange={(e, value) => {
          if (typeof value === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: value,
                _id: '',
              });
            });
          } else if (value && value.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: value.inputValue,
              _id: '',
            });
          } else {
            setValue(value);
            callback(value, col);
          }
        }}
        className={''}
      />
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>New {orgString}</DialogTitle>
          <DialogContent className={classes.dialog}>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="Name"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

function ComboBoxCreatableDepartment(props) {
  const { options, col, callback, val, required, error, textFieldVariant, displayLabel } = props;
  const [open, toggleOpen] = React.useState(false);

  let tempVal = [];
  if (val && Array.isArray(val)) {
    val.forEach((c) => {
      const name = options.find((option) => option.name === c);
      if (name) tempVal.push(name);
    });
  } else {
    const name = options.find((option) => option.name === val);
    if (name) tempVal.push(name);
  }

  const defaultDialogValue = {
    name: '',
    _id: '',
  };
  const [value, setValue] = React.useState(tempVal ?? []);
  const [dialogValue, setDialogValue] = React.useState(defaultDialogValue);
  const { pathname } = useLocation();

  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });

  const countDeptDuplicates = async (departmentName) => {
    try {
      const response = await getDeptsByCaseInsensitiveName(departmentName);
      return response.data.length;
    } catch (error) {
      alert(`get failed - ${error}`);
      return 0;
    }
  };

  const departmentString = IssueAndServiceSharedValues.department;

  const dispatch = useDispatch();

  const handleClose = () => {
    setDialogValue(defaultDialogValue);
    toggleOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const submitNewDepartmentRequestPath = '/dept';
    const numOfDuplicatesOfDept = await countDeptDuplicates(dialogValue.name);

    if (numOfDuplicatesOfDept > 0) {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: `${numOfDuplicatesOfDept} ${departmentString.toLowerCase()}(s) with the same name already exists. Please check if you are trying to add a ${departmentString} that already exists.`,
        })
      );
    } else {
      try {
        const response = await API.post(`${submitNewDepartmentRequestPath}`, dialogValue);

        if (response.status !== 201) {
          alert(response.statusText);
        } else {
          dispatch(
            openSnackbar({
              severity: 'success',
              message: `${departmentString}: ${dialogValue.name} created!`,
            })
          );
        }

        const newObj = { _id: response.data.id, name: dialogValue.name };

        setDialogValue(newObj);
        setValue([...value, newObj]);
        callback([...value, newObj], col);

        handleClose();
      } catch (err) {
        alert(`post failed - ${err}`);
      }
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        disablePortal
        clearOnEscape
        openOnFocus
        handleHomeEndKeys
        autoHighlight
        multiple
        id={col}
        options={options}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        isOptionEqualToValue={(option, value) => {
          return option?.name === value || option === value || option?.name === value?.name;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option._id}>
            <Typography variant={'body1'} sx={{ textAlign: 'left' }}>
              {option.name}
            </Typography>
          </li>
        )}
        value={value || []}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        renderInput={getRenderInput({
          col,
          classes,
          error,
          required,
          delay: 500,
          dataCy: undefined,
          textFieldVariant,
          displayLabel,
        })}
        onChange={(e, value) => {
          if (typeof value === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: value,
                _id: '',
              });
            });
          } else if (value.length > 0 && value.at(-1).inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: value.at(-1).inputValue,
              _id: '',
            });
          } else {
            setValue(value);
            callback(value, col);
          }
        }}
        className={''}
      />
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <form>
          <DialogTitle>New {departmentString}</DialogTitle>
          <DialogContent className={classes.dialog}>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="Name"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} data-cy="modal-add-button-ComboBoxCreatableDepartment">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

function SingleSelectLinkedService(props) {
  const { options, col, callback, val, className, required, error, textFieldVariant, displayLabel, ...otherProps } =
    props;
  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });
  return (
    <Autocomplete
      autoHighlight
      clearOnEscape
      id={col}
      options={options}
      value={val ?? ''}
      getOptionLabel={(option) => (option.name ? option.name : option)}
      renderInput={getRenderInput({
        col,
        classes,
        error,
        required,
        delay: 200,
        dataCy: undefined,
        textFieldVariant,
        displayLabel,
      })}
      onChange={(e, value) => {
        callback([value], col);
      }}
      classes={{ groupLabel: classes.groupLabel }}
      {...otherProps}
    />
  );
}

function MultiSelect(props) {
  const { options, col, callback, val, className, required, error, textFieldVariant, displayLabel, ...otherProps } =
    props;
  const nonObjects = ['campaigns', IssueAndServiceSharedValues.unit, 'units', IssueAndServiceSharedValues.campaign];
  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });
  return (
    <Autocomplete
      multiple
      autoHighlight
      clearOnEscape
      id={col}
      options={options}
      value={val ? val : []}
      getOptionLabel={(option) => (option.name ? option.name : option)}
      isOptionEqualToValue={(option, value) => {
        return nonObjects.includes(col) ? option === value : option._id === value._id;
      }}
      renderInput={getRenderInput({
        col,
        classes,
        error,
        required,
        delay: 200,
        dataCy: undefined,
        textFieldVariant,
        displayLabel,
      })}
      onChange={(e, value) => {
        callback(value, col);
      }}
      classes={{ groupLabel: classes.groupLabel }}
      {...otherProps}
    />
  );
}

function UploadCell(props) {
  const dispatch = useDispatch();
  const { id, cellName, files, callback, type, handleDrag, handleDrop, isDragActive } = props;
  const fileProgress = React.useContext(FileProgressContext);

  const onDelete = (junkOne, junkTwo, file) => {
    let baseUrl;
    switch (type) {
      case TrackerDataType.SERVICE:
        baseUrl = 'service';
        break;
      case TrackerDataType.MEDIA_INTERACTION:
        baseUrl = 'issues';
        break;
      case TrackerDataType.ENGAGEMENT:
        baseUrl = 'engagement';
        break;
      default:
        baseUrl = 'service';
        break;
    }

    let url = `${baseUrl}/${id}/${file._id}/${encodeURIComponent(file.name)}`;

    url = encodeURI(url);

    API.delete(url)
      .then((response) => {
        if (response.status === 204) {
          dispatch(openSnackbar({ severity: 'warning', message: `${cellName} entry deleted!` }));

          const i = files.findIndex((deletedFile) => {
            return deletedFile._id === file._id;
          });
          if (i > -1) {
            files.splice(i, 1);
            callback(files, cellName);
          }
        } else {
          dispatch(openSnackbar({ severity: 'alert', message: response.statusText }));
        }
      })
      .catch((e) => alert(e));
  };

  if (fileProgress < 100) {
    return <CircularProgressWithLabel value={fileProgress} />;
  } else {
    return (
      <FileTableWithDragAndDrop
        col={cellName}
        key={id}
        files={files}
        onDelete={onDelete}
        handleDrag={handleDrag}
        handleDrop={handleDrop}
        isDragActive={isDragActive}
      />
    );
  }
}

function RadioButtonGroupBox(props) {
  const { options, col, callback, val, required, error } = props;
  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });
  return (
    <FormControl error={error}>
      <FormLabel>{DisplayKeys[col] || col}</FormLabel>
      <Tooltip
        title={<Typography variant={'body2'}>{tooltipDescriptions[col]}</Typography>}
        TransitionComponent={Zoom}
        enterDelay={200}
        placement={tooltipDirections[col]}
      >
        <RadioGroup
          label={DisplayKeys[col] || col}
          className={classes.cellInput}
          required={required}
          defaultValue={val}
          onChange={(e) => {
            // window.alert("Value: "+e.target.value+"\nColumn: "+col);
            callback(e.target.value, col);
          }}
        >
          {options.map((option) => (
            <FormControlLabel key={option._id} value={option._id} control={<Radio />} label={option.name} />
          ))}
        </RadioGroup>
      </Tooltip>
    </FormControl>
  );
}

export default function FreeSolo(props) {
  const { callback, col, list, value, className, required, error, textFieldVariant, displayLabel = true } = props;

  return (
    <Autocomplete
      value={value ? value : ''}
      autoComplete
      fullWidth={true}
      id={col}
      freeSolo
      onChange={(e, data) => {
        callback(data, col);
      }}
      onInputChange={(e, data) => {
        callback(data, col);
      }}
      options={list.map((option) => option)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={displayLabel ? DisplayKeys[col] || col : ''}
          required={required}
          error={error}
          InputLabelProps={{ className }}
          variant={textFieldVariant ?? 'standard'}
          data-cy="topic-input-FreeSolo"
        />
      )}
    />
  );
}

function SingleSelect(props) {
  const { callBack, label, value, items } = props;
  //items shoud be an array of following objects:
  // {
  //   title: String,   The title which is displayed in dropdown
  //   value: any       The value which is passed into callBack when selected
  // }
  return (
    <TextField value={value} label={label} onChange={callBack} select style={{ width: '100%' }}>
      {items
        .filter((item) => item !== null)
        .map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
    </TextField>
  );
}

function FileTableWithDragAndDrop({ col, files, onDelete, handleDrag, handleDrop, isDragActive }) {
  const typeMap = {
    other: <AiOutlineFile />,
    pdf: <AiOutlineFilePdf />,
    'application/pdf': <AiOutlineFilePdf />,
    excel: <AiOutlineFileExcel />,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': <AiOutlineFileExcel />,
    word: <AiOutlineFileWord />,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <AiOutlineFileWord />,
    email: <AiOutlineMail />,
    'message/rfc822': <AiOutlineMail />,
    url: <AiOutlineLink />,
  };
  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });

  const className = isDragActive ? classes.containerDrag : handleDrag ? classes.dragAndDrop : classes.noDragAndDrop;

  return (
    <>
      {(files ?? []).length > 0 ? (
        <table
          className={className}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          data-cy={'drag-and-drop-area-populated'}
        >
          <tbody>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </tbody>
          <tbody>
            {files.map((f, i) => {
              return (
                <tr key={i}>
                  <td>{typeMap[f.type] || typeMap['other']}</td>
                  <td>
                    <a href={f.path} target="_blank" rel="noreferrer">
                      {f.name}
                    </a>
                  </td>
                  <td>{f.description}</td>
                  {onDelete && (
                    <td>
                      <Tooltip title={<Typography>delete {f.name}</Typography>}>
                        <IconButton
                          onClick={() =>
                            onDelete(
                              files.filter((file, index) => {
                                return index !== i;
                              }),
                              col,
                              f
                            )
                          }
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div
          className={className}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          data-cy={'drag-and-drop-area-empty'}
        >
          {col === ServiceKeys.file || col === EngagementKeys.file || col === 'file'
            ? 'Drag and drop files here to upload'
            : ''}
        </div>
      )}
    </>
  );
}

function UrlTableWithDialog({ col, files, onChange }) {
  const defaultDialogValue = {
    type: 'url',
    path: '',
    name: '',
    description: '',
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState(defaultDialogValue);

  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColor(pathname, theme) });

  const handleClose = () => {
    setDialogValue(defaultDialogValue);
    setOpenDialog(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    onChange([...files, dialogValue], col);

    handleClose();
  };

  return (
    <>
      {(files ?? []).length > 0 && (
        <table className={classes.dragAndDrop}>
          <tbody>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </tbody>
          <tbody>
            {files.map((f, i) => {
              return (
                <tr key={i}>
                  <td>
                    <AiOutlineLink />
                  </td>
                  <td>
                    <a href={f.path} target="_blank" rel="noreferrer">
                      {f.name}
                    </a>
                  </td>
                  <td>{f.description}</td>
                  <td>
                    <Tooltip title={<Typography>delete {f.name}</Typography>}>
                      <IconButton
                        onClick={() =>
                          onChange(
                            files.filter((file, index) => {
                              return index !== i;
                            }),
                            col
                          )
                        }
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <Button
        color="primary"
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        Add a Story Link
      </Button>

      <Dialog open={openDialog} onClose={handleClose}>
        <form>
          <DialogTitle>Add a Story Link</DialogTitle>
          <DialogContent>
            <div>
              <TextField
                required
                autoFocus
                margin="dense"
                id="url"
                value={dialogValue.url}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    path: event.target.value,
                  })
                }
                label="URL to Story"
                type="text"
                variant="standard"
              />
            </div>
            <div>
              <TextField
                required
                autoFocus
                margin="dense"
                id="name"
                value={dialogValue.name}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    name: event.target.value,
                  })
                }
                label="Story name"
                type="text"
                variant="standard"
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="description"
                value={dialogValue.description}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    description: event.target.value,
                  })
                }
                label="Description"
                type="text"
                variant="standard"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export {
  ComboBox,
  ComboBoxCreatableJournalist,
  ComboBoxCreatableCommunityMember,
  ComboBoxCreatableExpert,
  ComboBoxCreatableOutlet,
  ComboBoxCreatableDepartment,
  ComboBoxObjInput,
  SingleSelectLinkedService,
  MultiSelect,
  FreeSolo,
  UploadCell,
  RadioButtonGroupBox,
  SingleSelect,
  FileTableWithDragAndDrop,
  UrlTableWithDialog,
};
