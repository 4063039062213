import { Box, ListItem, IconButton, Collapse, Divider } from '@mui/material';
import dayjs from '../../utils/dayjs';
import CollaborationGroupInvitationsListItemStatusChip from './CollaborationGroupInvitationsListItemStatusChip';
import { CollaborationGroupInvitationsListItemProps } from './Types/componentProps';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';

const useStyles = makeStyles({
  invitationListItem: {
    minHeight: '81px',
  },
  listItemWrapper: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '8px',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  listItemContainer: {
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem',
    width: '100%',
  },
  listItemContentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  listItemActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  invitationMessageContainer: {
    padding: '10px',
    display: 'flex',
    width: '100%',
  },
});

const CollaborationGroupInvitationsListItem: React.FC<CollaborationGroupInvitationsListItemProps> = ({
  isInbox,
  invitation,
  itemActions,
}) => {
  const classes = useStyles();

  const [invitationMessageExpanded, setInvitationMessageExpanded] = useState(false);

  const now = dayjs();
  const invitationExpireAt = dayjs(invitation.expireAt);

  const isNotAcceptedStatus = invitation.status === 'pending' || invitation.status === 'rejected';
  const isNotExpired = now.isBefore(invitationExpireAt);

  let invitationContent = (
    <div>
      Sent to: {invitation.receiver.organizationName} ({invitation.receiver.organizationCode})
    </div>
  );
  if (isInbox) {
    invitationContent = (
      <div>
        Invitation From: {invitation.sender.organizationName} ({invitation.sender.organizationCode})
      </div>
    );
  }

  return (
    <ListItem
      className={classes.invitationListItem}
      data-cy="collaboration-group-details-invitations-list-invitations-list-item"
    >
      <Box className={classes.listItemWrapper}>
        <Box className={classes.listItemContainer}>
          <Box className={classes.listItemContentContainer}>
            {invitation.message && invitation.status === 'pending' && (
              <IconButton
                data-cy="collaboration-group-details-invitations-list-invitations-optional-message-toggle-btn"
                aria-label="expand invitation messages"
                size="small"
                onClick={() => setInvitationMessageExpanded(!invitationMessageExpanded)}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            )}
            <Box>
              {invitationContent}
              {isNotExpired && isNotAcceptedStatus && (
                <div>Expires at: {invitationExpireAt.format('MMMM Do YYYY, h:mm:ss a')}</div>
              )}
            </Box>
            <CollaborationGroupInvitationsListItemStatusChip status={invitation.status} isExpired={!isNotExpired} />
          </Box>
          <Box className={classes.listItemActionsContainer}>{itemActions}</Box>
        </Box>
        {invitation.message && invitation.status === 'pending' && (
          <Collapse in={invitationMessageExpanded}>
            <Divider />
            <Box
              className={classes.invitationMessageContainer}
              data-cy="collaboration-group-details-invitations-list-invitations-optional-message-text"
            >
              {invitation.message}
            </Box>
          </Collapse>
        )}
      </Box>
    </ListItem>
  );
};

export default CollaborationGroupInvitationsListItem;
