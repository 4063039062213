import { SvgIconProps, SvgIcon, useTheme } from '@mui/material';

export default function PhoneIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 18 18" sx={{ color: theme.palette.primaryDefault.main }} {...props}>
      <path d="M12.7188 9.625L16.2188 11.125C16.7812 11.3438 17.0938 11.9375 16.9688 12.5312L16.2188 16.0312C16.0938 16.5938 15.5625 17.0312 15 17.0312C14.7812 17.0312 14.5938 17 14.4062 17C14.0938 17 13.7812 16.9688 13.5 16.9375C6.46875 16.1875 1 10.25 1 3C1 2.4375 1.40625 1.90625 1.96875 1.78125L5.46875 1.03125C6.0625 0.90625 6.65625 1.21875 6.875 1.78125L8.375 5.28125C8.59375 5.78125 8.46875 6.375 8.03125 6.71875L6.75 7.78125C7.59375 9.21875 8.78125 10.4062 10.2188 11.25L11.2812 9.96875C11.625 9.53125 12.2188 9.40625 12.7188 9.625ZM14.7812 15.5L15.4375 12.4062L12.3125 11.0625L11.4062 12.1875C10.9375 12.75 10.125 12.9062 9.46875 12.5312C7.8125 11.5625 6.4375 10.1875 5.46875 8.53125C5.09375 7.875 5.25 7.0625 5.8125 6.59375L6.9375 5.6875L5.59375 2.5625L2.5 3.21875C2.59375 9.96875 8.03125 15.4062 14.7812 15.5Z" />
    </SvgIcon>
  );
}
