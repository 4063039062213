import { TableHead, TableRow, TableCell, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import { getTabColorHeaderMain } from '../../shared/constants/constants';
import {
  EngagementKeys,
  IssueAndServiceSharedValues,
  IssueKeys,
  ServiceKeys,
} from '../../shared/constants/IssueAndServiceKeys';

const useStyles = makeStyles(({ palette, tabColor }) => ({
  tableHeaderCell: {
    textAlign: 'left',
    padding: '10px 6px 10px 10px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 700,
    width: 'auto',
    backgroundColor: ({ tabColor }) => tabColor,
    position: 'relative',
  },
  tableIconCell: {
    width: '10px',
    borderLeft: 'none',
    borderRight: 'none',
    padding: 0,
  },
  wide1Col: {
    width: '20%',
  },
  wide2Col: {
    width: '40%',
  },
  teamMemberCol: {
    width: '15%',
  },
  leadExpertCol: {
    width: '10%',
  },
  unitCol: {
    width: '10%',
  },
  orgOutletDeptCol: {
    width: '10%',
  },
  centerAlignedHeaderCell: {
    textAlign: 'center',
  },
}));

// TODO: Add unit test to make sure they are correct subarrays from constants.js
// Also tests to make sure the same column isn't in Media and Service at some time
const wide1ColsMap = {
  MediaInteraction: [IssueKeys.campaign],
  ServiceLog: [ServiceKeys.campaign],
  EngagementLog: [EngagementKeys.campaign],
};

const wide2ColsMap = {
  MediaInteraction: [IssueKeys.topic],
  ServiceLog: [ServiceKeys.service],
  EngagementLog: [EngagementKeys.topic],
};

export default function TrackerTableHeaderRow({ columns, type, tabColor }) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles({ tabColor: tabColor ?? getTabColorHeaderMain(pathname, theme) });
  const centerAlignedColumns = ['Status', 'Complexity', 'Tone'];

  const getTableRowCellClass = (col) => {
    let cellClasses = [classes.tableHeaderCell];

    if (wide1ColsMap[type].includes(col)) {
      cellClasses.push(classes.wide1Col);
    } else if (wide2ColsMap[type].includes(col)) {
      cellClasses.push(classes.wide2Col);
    } else if (col === IssueAndServiceSharedValues.teamMembers) {
      cellClasses.push(classes.teamMemberCol);
    } else if (col === IssueAndServiceSharedValues.leadExpert) {
      cellClasses.push(classes.leadExpertCol);
    } else if (col === IssueAndServiceSharedValues.unit) {
      cellClasses.push(classes.unitCol);
    } else if (col === IssueKeys.outlet || col === EngagementKeys.organization || col === IssueAndServiceSharedValues.department) {
      cellClasses.push(classes.orgOutletDeptCol);
    }

    if (centerAlignedColumns.includes(col)) {
      cellClasses.push(classes.centerAlignedHeaderCell);
    }

    return cellClasses.join(' ');
  };

  return (
    <TableHead sx={{ height: '60px' }}>
      <TableRow>
        <TableCell className={`${classes.tableHeaderCell} ${classes.tableIconCell}`}></TableCell>
        <TableCell className={`${classes.tableHeaderCell} ${classes.tableIconCell}`}></TableCell>
        {columns.map((col) => (
          <TableCell key={col} className={getTableRowCellClass(col)}>
            {col}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
