import TrackerFilterPopoverGrid from './TrackerFilterPopoverGrid';
import { TrackerFilterStatus } from './types';
import StatusInputSelection from '../../shared/inputs/StatusInputSelection';

interface TrackerFilterStatusSelectionProps {
  selectedStatus: TrackerFilterStatus[];
  onSelect: (selectedStatus: TrackerFilterStatus[]) => void;
}

export default function TrackerFilterStatusSelection(props: TrackerFilterStatusSelectionProps) {
  const { selectedStatus, onSelect } = props;

  return (
    <TrackerFilterPopoverGrid>
      STATUS
      <StatusInputSelection
        isFilterModal
        selectedStatus={selectedStatus.map((statusObj) => statusObj.name)}
        onSelect={(statusNames) =>
          onSelect(
            statusNames.map((statusName) => {
              return { name: statusName };
            })
          )
        }
        multiSelect
      />
    </TrackerFilterPopoverGrid>
  );
}
