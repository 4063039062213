import { IssueKeys } from '../../../shared/constants/IssueAndServiceKeys';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FaceIcon from '@mui/icons-material/Face';
import SchoolIcon from '@mui/icons-material/School';
import StyleIcon from '@mui/icons-material/Style';
import BuildIcon from '@mui/icons-material/Build';
import FortIcon from '@mui/icons-material/Fort';
import { DropdownTableContextValueKey } from '../../../shared/contexts/DropdownTableContext';
import { ReactElement } from 'react';
import { CollaborationGroupDetailsSharedResourceFilterCriteriaKey } from '../Types/apiData';

const SharedResourceSourceColKey = 'Source';

export const visibleShareIssueCols = [
  SharedResourceSourceColKey,
  IssueKeys.date,
  IssueKeys.topic,
  IssueKeys.type,
  IssueKeys.units,
  IssueKeys.expert,
  IssueKeys.outlet,
  IssueKeys.journalist,
  IssueKeys.campaign,
  IssueKeys.tone,
  IssueKeys.lead,
  IssueKeys.status,
];

export const visibleShareIssueDetailedWideCols = [IssueKeys.keyMessaging, IssueKeys.response];

export const visibleShareIssueDetailedCols = [IssueKeys.dept, IssueKeys.contactMethod];

interface SharedInteractionsAutoCompleteFilter {
  filterType: DropdownTableContextValueKey;
  filterName: string;
  helperText?: string;
  icon: ReactElement;
}

export const sharedInteractionsAutoCompleteFilters: SharedInteractionsAutoCompleteFilter[] = [
  {
    filterType: 'outlet',
    filterName: 'Outlet',
    icon: <ApartmentIcon />,
  },
  {
    filterType: 'journalist',
    filterName: 'Journalist',
    helperText: "Type a journalist's name",
    icon: <AssignmentIndIcon />,
  },
  {
    filterType: 'expert',
    filterName: 'Lead/Expert',
    icon: <FaceIcon />,
  },
  {
    filterType: 'units',
    filterName: 'Unit',
    icon: <SchoolIcon />,
  },
  {
    filterType: 'type',
    filterName: 'Type',
    icon: <StyleIcon />,
  },
  {
    filterType: 'status',
    filterName: 'Status',
    icon: <BuildIcon />,
  },
  {
    filterType: 'campaigns',
    filterName: 'Campaigns',
    icon: <FortIcon />,
  },
  {
    filterType: 'lead',
    filterName: 'Team Members',
    icon: <FaceIcon />,
  },
];

type CollaborationGroupSharedResourcesFiltersListItemsSharedResourceFilterCriteriaKeyToExclude = 'dateFilter' | 'topic';
type CollaborationGroupSharedResourcesFiltersListItemsFilterDisplayFilterCriteriaKey = Exclude<
  CollaborationGroupDetailsSharedResourceFilterCriteriaKey,
  CollaborationGroupSharedResourcesFiltersListItemsSharedResourceFilterCriteriaKeyToExclude
>;

type CollaborationGroupSharedResourcesFiltersListItemsDropdownTableContextValueKeyToExtract =
  | 'outlet'
  | 'journalist'
  | 'expert'
  | 'units'
  | 'type'
  | 'status'
  | 'campaigns'
  | 'lead';
type CollaborationGroupSharedResourcesFiltersListItemsDropdownTableContextFilterCriteriaKey = Extract<
  DropdownTableContextValueKey,
  CollaborationGroupSharedResourcesFiltersListItemsDropdownTableContextValueKeyToExtract
>;

interface CollaborationGroupSharedResourcesFiltersListItemsFilterDisplayEntry {
  title: string;
  filterCriteriaKey: CollaborationGroupSharedResourcesFiltersListItemsFilterDisplayFilterCriteriaKey;
  dropdownTableContextFilterCriteriaKey: CollaborationGroupSharedResourcesFiltersListItemsDropdownTableContextFilterCriteriaKey;
}

const collaborationGroupSharedResourcesFiltersListItemsFilterDisplayEntriesElements = [
  ['Outlets', 'outlets', 'outlet'],
  ['Journalists', 'journalists', 'journalist'],
  ['Lead/Experts', 'experts', 'expert'],
  ['Units', 'units', 'units'],
  ['Types', 'types', 'type'],
  ['Status', 'status', 'status'],
  ['Campaigns', 'campaigns', 'campaigns'],
  ['Team Members', 'teamMembers', 'lead'],
];

export const collaborationGroupSharedResourcesFiltersListItemsFilterDisplayEntries: CollaborationGroupSharedResourcesFiltersListItemsFilterDisplayEntry[] =
  collaborationGroupSharedResourcesFiltersListItemsFilterDisplayEntriesElements.map(
    ([title, filterCriteriaKey, dropdownTableContextFilterCriteriaKey]) => ({
      title,
      filterCriteriaKey:
        filterCriteriaKey as CollaborationGroupSharedResourcesFiltersListItemsFilterDisplayFilterCriteriaKey,
      dropdownTableContextFilterCriteriaKey:
        dropdownTableContextFilterCriteriaKey as CollaborationGroupSharedResourcesFiltersListItemsDropdownTableContextFilterCriteriaKey,
    })
  );

export const shareContactType = {
  journalist: 'journalist',
  outlet: 'outlet',
};

export const visibleSharedJournalistCols = [
  'Source',
  'Journalist',
  'Outlet',
  'Email',
  'Phone',
  'Archived',
  'Notes',
  'Cell',
];

export type SharedContactCellCol = (typeof visibleSharedJournalistCols)[number];

export const sharedInteractionsSearchCategories = [
  { label: 'Topic', value: 'topic' },
  { label: 'Source', value: 'source' },
  { label: 'Key Messaging', value: 'keyMessaging' },
  { label: 'Background/Response', value: 'response' },
];

export const sharedJournalistsSearchCategories = [
  { label: 'Outlet', value: 'outlet' },
  { label: 'Source', value: 'source' },
  { label: 'Journalist', value: 'name' },
  { label: 'Notes', value: 'notes' },
];
