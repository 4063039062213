import React, { useContext, useState } from 'react';
import { AIScrapeResult, EmailQueueEntry } from './Interfaces';
import { Paper, Table, TableBody, TableContainer, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EmailEntryRow from './EmailEntryRow';
import {
  API,
  AutoTrackerInteractionHeaderCols,
  AutoTrackerServiceHeaderCols,
  getBackgroundColor,
  RequiredAutoTrackerIssueKeys,
  RequiredAutoTrackerServiceKeys,
  TrackerDataType,
} from '../../shared/constants/constants';
import { SocketContext } from '../../shared/contexts/SocketContext';
import MediaInteractionEventHandler from '../../shared/eventHandler/MediaInteractionEventHandler';
import QueryAdder from '../QueryAdder/QueryAdder';
import { addContact, deleteEmail, getEmails, saveFixedEmail } from './emailScrapingRequests';
import {
  processAttachmentsFromAutotracker,
  processFromAIScrapeForAutoComplete,
  processFromResultForAIScrape,
} from '../../shared/functions/autoCompleteProcessing';
import { IssueKeys, ServiceKeys } from '../../shared/constants/IssueAndServiceKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AutoTrackerTableHeader from './AutoTrackerTableHeader';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import ServiceLogEventHandler from '../../shared/eventHandler/ServiceLogEventHandler';
import { validateEntry, validatePotentialEntry } from './helpers';
import dayjs from '../../utils/dayjs';
import { sendNotification } from '../../shared/requests/notifications';
import ForwardingAddressInstructions from './ForwardingAddressInstructions';
import { DropdownTable } from '../../shared/types';
import QueryAdderDrawer from '../QueryAdder/QueryAdderDrawer';

interface EmailScrapingPageInterface {
  dropdownTable: DropdownTable;
}

const useStyles = makeStyles(() => ({
  tableContainer: {
    borderRadius: '10px',
    width: '90%',
    border: `2px solid #B1B9C9`,
    margin: '0 auto',
    maxHeight: '75vh',
    marginTop: '10px',
  },
  spinnerContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const interactionsEmail = 'interaction';
const servicesEmail = 'service';

interface Attachment {
  type: 'autotrackerAttachment';
  path: string;
  description?: string;
  name: string;
}

//TODO finish support for services (comms material)
function AutoTrackerPage(props: Readonly<EmailScrapingPageInterface>) {
  const { dropdownTable } = props;
  const socket = useContext(SocketContext);
  const mediaInteractionEventHandler = MediaInteractionEventHandler(socket);
  const serviceEventHandler = ServiceLogEventHandler(socket);
  const [selectedView, setSelectedView] = useState(mediaInteractionEventHandler.type);
  const theme = useTheme();
  const classes = useStyles();
  const [openForm, setOpenForm] = useState(false);
  const [cells, setCells] = useState({});
  const [files, setFiles] = useState<Attachment[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<EmailQueueEntry | null>(null);
  const currentUser = useSelector((state: any) => state.user.userInfo);
  const dispatch = useDispatch();

  const emailsQuery = useQuery({
    queryKey: ['emails', 'emailScrapingPage'],
    queryFn: () => getEmails(),
    refetchInterval: 5 * 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const handleTabClick = (view: TrackerDataType) => {
    setSelectedView(view);
  };
  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };
  const tabButtonStyle = {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    cursor: 'pointer',
    margin: '0 5px',
    transition: 'background-color 0.3s ease',
  };
  const activeTabButtonStyle = {
    backgroundColor: getBackgroundColor(selectedView, theme),
    color: '#fff',
  };

  const queryClient = useQueryClient();
  const handleDelete = useMutation({
    mutationFn: (email: EmailQueueEntry) => deleteEmail(email),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['emails', 'emailScrapingPage'] });
      if (!selectedEmail) {
        dispatch(openSnackbar({ severity: 'success', message: `Entry deleted successfully` }));
      } else {
        setSelectedEmail(null);
      }
    },
    onError: () => {
      dispatch(openSnackbar({ severity: 'error', message: `There was an error deleting the AutoTracker entry` }));
    },
  });

  const querySubmitCallback = async (
    fields: any,
    email?: EmailQueueEntry,
    updatedDropdownTable: DropdownTable = dropdownTable,
    postID?: string,
    attachmentsToSubmit?: Attachment[]
  ) => {
    if (attachmentsToSubmit && attachmentsToSubmit.length > 0 && postID && email) {
      await API.post(`/service/${postID}/autotrackerAttachment`, {
        parsedEmailId: email._id,
        fileObjects: attachmentsToSubmit.map((a) => {
          return { parsedEmailId: email?._id, name: a.name };
        }),
      });
    }

    const emailToDelete = email || selectedEmail;
    if (emailToDelete) {
      try {
        let result: AIScrapeResult;
        if (selectedView === TrackerDataType.MEDIA_INTERACTION) {
          result = {
            Contact: '',
            Date: '',
            Department: undefined,
            Journalist: undefined,
            'Journalist Email': '',
            'Journalist Phone': '',
            Outlet: undefined,
            'Lead/Expert': undefined,
            Status: '',
            Topic: '',
            Campaign: undefined,
            Type: [],
            Unit: undefined,
            'Key Messaging': '',
            'Background/Response': '',
          };
        } else {
          result = {
            Date: '',
            Department: undefined,
            'Lead/Expert': undefined,
            Status: '',
            Service: '',
            Type: [],
            Unit: undefined,
            Campaign: undefined,
            Complexity: undefined,
            'Key Messaging': '',
            'Background/Response': '',
          };
        }
        const parsedFixedResult = processFromResultForAIScrape(result, fields, updatedDropdownTable);
        await saveFixedEmail(emailToDelete, parsedFixedResult);
        dispatch(openSnackbar({ severity: 'success', message: `Entry added successfully` }));
        handleDelete.mutate(emailToDelete);
      } catch (error: any) {
        dispatch(openSnackbar({ severity: 'error', message: error.message }));
      }
    }
  };

  if (emailsQuery.isLoading) {
    return <div className={classes.spinnerContainer}>Loading...</div>;
  } else if (emailsQuery.isError) {
    return (
      <div className={classes.spinnerContainer}>
        <Typography variant="h3">Error loading emails</Typography>
        <Typography variant="h5">Please refresh the page</Typography>
      </div>
    );
  }

  async function handleManualAddClick(email: EmailQueueEntry) {
    if (email.aiScrapeResult) {
      setCells(
        processFromAIScrapeForAutoComplete(
          email.aiScrapeResult,
          email.text,
          selectedView === TrackerDataType.MEDIA_INTERACTION ? IssueKeys : ServiceKeys,
          dropdownTable,
          [...new Set([currentUser.id, email.sender])]
        )
      );
    } else {
      setCells({});
    }
    if (email.attachments && selectedView === TrackerDataType.SERVICE) {
      setFiles(processAttachmentsFromAutotracker(email.attachments));
    } else {
      setFiles([]);
    }

    setSelectedEmail(email);
    setOpenForm(true);
  }

  async function handleAutomaticAddClick(email: EmailQueueEntry) {
    const requiredColumns =
      selectedView === TrackerDataType.MEDIA_INTERACTION
        ? RequiredAutoTrackerIssueKeys
        : RequiredAutoTrackerServiceKeys;
    const columnsToBeAdded = validatePotentialEntry(email, dropdownTable, requiredColumns);
    let updatedDropdownTable = dropdownTable;
    if (columnsToBeAdded?.length > 0 && email.aiScrapeResult) {
      dispatch(
        openSnackbar({
          severity: 'info',
          message: `Adding missing fields, please wait.`,
        })
      );
      try {
        for (const column of columnsToBeAdded) {
          const contactRequests = [];
          if (column !== IssueKeys.journalist) {
            contactRequests.push(addContact(column, email.aiScrapeResult, updatedDropdownTable));
          }
          await Promise.all(contactRequests);
        }
        updatedDropdownTable = (await API.get('dropdown-table'))?.data;
        if (columnsToBeAdded.includes(IssueKeys.journalist)) {
          await addContact(IssueKeys.journalist, email.aiScrapeResult, updatedDropdownTable);
          updatedDropdownTable = (await API.get('dropdown-table'))?.data;
        }
        await queryClient.invalidateQueries({ queryKey: ['dropdown-table'] });
      } catch (error) {
        dispatch(
          openSnackbar({
            severity: 'error',
            message: `There was a problem adding missing fields.`,
          })
        );
      }
    }
    const isValidEntry = validateEntry(email, updatedDropdownTable, requiredColumns);
    if (isValidEntry) {
      const fields = processFromAIScrapeForAutoComplete(
        email.aiScrapeResult,
        email.text,
        selectedView === TrackerDataType.MEDIA_INTERACTION ? IssueKeys : ServiceKeys,
        updatedDropdownTable,
        [...new Set([currentUser.id, email.sender])]
      );
      fields['year'] = dayjs.utc(new Date(fields.date)).format('YYYY');
      fields['month'] = dayjs.utc(new Date(fields.date)).format('MMM');

      delete fields.outlet;
      try {
        if (selectedView === TrackerDataType.MEDIA_INTERACTION) {
          await mediaInteractionEventHandler.createNewItem(fields, null, ({ postId }: { postId: any }) => {
            sendNotification(
              fields.lead
                ?.map((teamMember: any) => teamMember._id)
                .filter((teamMemberId: any) => teamMemberId !== currentUser.id) ?? [],
              postId,
              fields.topic,
              selectedView
            );
            setSelectedEmail(email);
            querySubmitCallback(fields, email, updatedDropdownTable);
          });
        } else {
          let currentFiles: Attachment[] | undefined = [];
          if (email.attachments) {
            currentFiles = processAttachmentsFromAutotracker(email.attachments);
          }
          await serviceEventHandler.createNewItem(fields, [], ({ postId }: { postId: any }) => {
            sendNotification(
              fields.teamMember
                ?.map((teamMember: any) => teamMember._id)
                .filter((teamMemberId: any) => teamMemberId !== currentUser.id) ?? [],
              postId,
              fields.service,
              selectedView
            );
            setSelectedEmail(email);
            querySubmitCallback(fields, email, updatedDropdownTable, postId, currentFiles);
          });
        }
      } catch (e: any) {
        console.log(e.message);
      }
    } else {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: `Entry needs to be added manually, some required fields are missing`,
        })
      );
    }
  }
  return (
    <div style={{ width: '100%' }}>
      {dropdownTable && emailsQuery.isSuccess ? (
        <div>
          <ForwardingAddressInstructions selectedView={selectedView} />
          <div style={buttonStyle} className="tabs">
            <button
              style={
                selectedView === TrackerDataType.MEDIA_INTERACTION
                  ? { ...tabButtonStyle, ...activeTabButtonStyle }
                  : tabButtonStyle
              }
              onClick={() => handleTabClick(TrackerDataType.MEDIA_INTERACTION)}
            >
              Media Interactions
            </button>
            <button
              style={
                selectedView === TrackerDataType.SERVICE
                  ? { ...tabButtonStyle, ...activeTabButtonStyle }
                  : tabButtonStyle
              }
              onClick={() => handleTabClick(TrackerDataType.SERVICE)}
            >
              Services
            </button>
          </div>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <AutoTrackerTableHeader
                tabColor={getBackgroundColor(selectedView, theme)}
                columns={
                  selectedView === TrackerDataType.MEDIA_INTERACTION
                    ? AutoTrackerInteractionHeaderCols
                    : AutoTrackerServiceHeaderCols
                }
              />
              <TableBody>
                {emailsQuery.data.parsedEmails
                  .filter((entry: EmailQueueEntry) =>
                    entry.destination.includes(
                      selectedView === TrackerDataType.MEDIA_INTERACTION ? interactionsEmail : servicesEmail
                    )
                  )
                  .map((entry: EmailQueueEntry) => {
                    return (
                      <EmailEntryRow
                        row={entry}
                        key={entry._id}
                        dropdownTable={dropdownTable}
                        handleManualAddClick={handleManualAddClick}
                        handleAutomaticAddClick={handleAutomaticAddClick}
                        handleDelete={handleDelete}
                        columns={
                          selectedView === TrackerDataType.MEDIA_INTERACTION
                            ? AutoTrackerInteractionHeaderCols
                            : AutoTrackerServiceHeaderCols
                        }
                        eventHandler={
                          selectedView === TrackerDataType.MEDIA_INTERACTION
                            ? mediaInteractionEventHandler
                            : serviceEventHandler
                        }
                        tabColor={getBackgroundColor(selectedView, theme)}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <QueryAdderDrawer openForm={openForm} setOpenForm={setOpenForm}>
            <QueryAdder
              dropdownTable={dropdownTable}
              setOpenForm={setOpenForm}
              eventHandler={
                selectedView === TrackerDataType.MEDIA_INTERACTION ? mediaInteractionEventHandler : serviceEventHandler
              }
              title={
                selectedView === TrackerDataType.MEDIA_INTERACTION
                  ? mediaInteractionEventHandler.newItemModalTitle
                  : serviceEventHandler.newItemModalTitle
              }
              cells={cells}
              forAutoTracker={true}
              email={selectedEmail}
              initialFiles={files}
              emailScrapeCallback={querySubmitCallback}
            />
          </QueryAdderDrawer>
        </div>
      ) : (
        <div className={classes.spinnerContainer}>Loading...</div>
      )}
    </div>
  );
}

export default AutoTrackerPage;
