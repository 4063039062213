import { useState } from 'react';
import TrackerFilterDateSelection from './TrackerFilterDateSelection';

interface FilterModalDateRangeInputsProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
}

const FilterModalDateRangeInputs = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}: FilterModalDateRangeInputsProps) => {
  const [start, setStart] = useState<Date | null>(startDate);
  const [end, setEnd] = useState<Date | null>(endDate);

  const onStartDateSelect = (date: Date | null) => {
    setStart(date);
    if (date) {
      onStartDateChange(date);
    }
  };
  const onEndDateSelect = (date: Date | null) => {
    setEnd(date);
    if (date) {
      onEndDateChange(date);
    }
  };

  return (
    <TrackerFilterDateSelection
      label="DATE"
      startDate={start}
      endDate={end}
      onStartDateSelection={onStartDateSelect}
      onEndDateSelection={onEndDateSelect}
    />
  );
};

export default FilterModalDateRangeInputs;
