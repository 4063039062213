import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Tooltip,
  IconButton,
  LinearProgress,
  Alert,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TableViewIcon from '@mui/icons-material/TableView';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import ContactsPageEditOutletModal from './Modal/ContactsPageEditOutletModal';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { useArchiveContact } from './Hooks/useArchiveContact';
import { useJournalistsByOutlet } from './Hooks/useJournalistsByOutlet';
import { useCommunityMembersByOrganization } from './Hooks/useCommunityMembersByOrganization';

const useStyles = makeStyles((theme) => ({
  orgProfileAvatar: {
    width: '130px',
    height: '130px',
    marginRight: '10px',
  },
  orgTableHead: {
    backgroundColor: '#DEDEDE',
  },
}));

const OrgProfile = (props) => {
  const classes = useStyles();
  const { selectedOrg, setRefreshContacts, category } = props;

  const {
    data: journalistList,
    status: statusJournalists,
    isLoading: isLoadingJournalists,
  } = useJournalistsByOutlet(selectedOrg?._id ?? '');
  const {
    data: membersList,
    status: statusMembers,
    isLoading: isLoadingMembers,
  } = useCommunityMembersByOrganization(selectedOrg?._id ?? '');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const visibleJournalists = useMemo(
    () => [...(journalistList?.data ?? [])].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, journalistList]
  );
  const visibleMembers = useMemo(
    () => [...(membersList?.data ?? [])].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, membersList]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openEditOutlet, setOpenEditOutlet] = useState(false);
  const navigate = useNavigate();
  const dispatch = useFiltersDispatch();

  const archiveContactMutation = useArchiveContact({
    contactId: selectedOrg?._id,
    category: category,
    isArchiving: false,
    setRefetchContacts: setRefreshContacts,
  });

  const mediaInteractionsFilterBasedOnSelectedOutlet = {
    outlet: [
      {
        firstLetter: selectedOrg?.name?.split(' ')[0][0] ?? '',
        name: selectedOrg?.name ?? '',
        _id: selectedOrg?._id ?? '',
      },
    ],
  };

  const engagementLogFilterBasedOnSelectedOrganization = {
    organization: [
      {
        firstLetter: selectedOrg?.name?.split(' ')[0][0] ?? '',
        name: selectedOrg?.name ?? '',
        _id: selectedOrg?._id ?? '',
      },
    ],
  };

  const handleUnarchiveButtonClick = async () => {
    await archiveContactMutation.mutateAsync();
  };

  let editOutletButton = (
    <Button
      onClick={() => {
        setOpenEditOutlet(true);
      }}
      data-cy="editContact-button-OutletProfile"
    >
      <EditOutlinedIcon />
    </Button>
  );

  if (!selectedOrg) {
    return (
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          overflow: 'auto',
          minWidth: '100%',
        }}
      ></List>
    );
  }

  if (selectedOrg.archived) {
    editOutletButton = (
      <Tooltip>
        <IconButton size="small" onClick={() => handleUnarchiveButtonClick()} data-cy="unarchive-button-OutletProfile">
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
    );
  }

  if (category === 'Outlets' && statusJournalists === 'error') {
    return <Alert severity="error">Error fetching journalists of {selectedOrg.name}</Alert>;
  }

  if (category === 'Organizations' && statusMembers === 'error') {
    return <Alert severity="error">Error fetching community members of {selectedOrg.name}</Alert>;
  }

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          overflow: 'auto',
          minWidth: '100%',
        }}
      >
        <ListItem key={'name'}>
          <Stack direction="row" alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.orgProfileAvatar}>
                <Typography variant="h1">{selectedOrg.name.split(' ')[0][0]}</Typography>
              </Avatar>
            </ListItemAvatar>

            <Stack direction="row" alignItems="flex-start">
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                {selectedOrg.name}
              </Typography>
              {editOutletButton}
            </Stack>
          </Stack>
        </ListItem>

        {(isLoadingJournalists || isLoadingMembers) && <LinearProgress />}
        <ListItem
          key={'journalists'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: '100%',
          }}
        >
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {category === 'Outlets' ? 'Journalists' : 'Community Members'}
          </Typography>

          <Button
            variant="contained"
            disabled={isLoadingJournalists || isLoadingMembers}
            onClick={() => {
              dispatch({
                type: 'setFilters',
                payload:
                  category === 'Outlets'
                    ? mediaInteractionsFilterBasedOnSelectedOutlet
                    : engagementLogFilterBasedOnSelectedOrganization,
              });
              navigate(
                category === 'Outlets' ? RouteInformation.mediaInteraction.path : RouteInformation.engagementLog.path
              );
            }}
          >
            <TableViewIcon />
            View in Media Interaction
          </Button>
        </ListItem>

        <ListItem>
          {category === 'Outlets' && statusJournalists === 'success' && (
            <Stack sx={{ width: '100%' }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.orgTableHead}>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Email</TableCell>
                      <TableCell align="right">Phone</TableCell>
                      <TableCell align="right">Cell</TableCell>
                      <TableCell align="right">Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleJournalists.map((personnel) => (
                      <TableRow key={personnel._id} sx={{ height: '55px' }}>
                        <TableCell component="th" scope="row">
                          <Link
                            to={RouteInformation.mediaInteraction.path}
                            style={{ textDecoration: 'none' }}
                            onClick={() => {
                              const mediaInteractionsFilterBasedOnPersonnel = {
                                journalist: [
                                  {
                                    firstLetter: personnel.name.split(' ')[0][0],
                                    name: personnel.name,
                                    _id: personnel._id,
                                  },
                                ],
                              };

                              // Set filters with selected contact and the personnel under selected contact
                              dispatch({
                                type: 'setFilters',
                                payload: {
                                  ...mediaInteractionsFilterBasedOnSelectedOutlet,
                                  ...mediaInteractionsFilterBasedOnPersonnel,
                                },
                              });
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                display: 'inline',
                              }}
                              style={{ textDecoration: 'underline' }}
                            >
                              {`${personnel.name} ${personnel.archived ? '(Archived)' : ''}`}
                            </Typography>
                          </Link>
                        </TableCell>
                        <TableCell align="right">{personnel.email}</TableCell>
                        <TableCell align="right">{personnel.phone}</TableCell>
                        <TableCell align="right">{personnel.cell}</TableCell>
                        <TableCell align="right">{personnel.notes}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={journalistList?.data?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          )}
          {category !== 'Outlets' && statusMembers === 'success' && (
            <Stack sx={{ width: '100%' }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.orgTableHead}>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Email</TableCell>
                      <TableCell align="right">Phone</TableCell>
                      <TableCell align="right">Cell</TableCell>
                      <TableCell align="right">Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleMembers.map((personnel) => (
                      <TableRow key={personnel._id} sx={{ height: '55px' }}>
                        <TableCell component="th" scope="row">
                          <Link
                            to={RouteInformation.mediaInteraction.path}
                            style={{ textDecoration: 'none' }}
                            onClick={() => {
                              const engagementLogFilterBasedOnPersonnel = {
                                communityMembers: [
                                  {
                                    firstLetter: personnel.name.split(' ')[0][0],
                                    name: personnel.name,
                                    _id: personnel._id,
                                  },
                                ],
                              };

                              // Set filters with selected contact and the personnel under selected contact
                              dispatch({
                                type: 'setFilters',
                                payload: {
                                  ...engagementLogFilterBasedOnSelectedOrganization,
                                  ...engagementLogFilterBasedOnPersonnel,
                                },
                              });
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                display: 'inline',
                              }}
                              style={{ textDecoration: 'underline' }}
                            >
                              {`${personnel.name} ${personnel.archived ? '(Archived)' : ''}`}
                            </Typography>
                          </Link>
                        </TableCell>
                        <TableCell align="right">{personnel.email}</TableCell>
                        <TableCell align="right">{personnel.phone}</TableCell>
                        <TableCell align="right">{personnel.cell}</TableCell>
                        <TableCell align="right">{personnel.notes}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={membersList?.data?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          )}
        </ListItem>
      </List>
      <ContactsPageEditOutletModal
        openModal={openEditOutlet}
        setOpenModal={setOpenEditOutlet}
        setRefreshContacts={setRefreshContacts}
        selectedOrg={selectedOrg}
        category={category}
      />
    </>
  );
};

export default OrgProfile;
