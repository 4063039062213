import makeStyles from '@mui/styles/makeStyles';
import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Button } from '@mui/material';
import { API } from '../../../shared/constants/constants';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';
import { useState } from 'react';
import {getOrganizationsByCaseInsensitiveName, getOrgsByCaseInsensitiveName} from '../contactsRequest';

const ContactsPageAddOutletModal = (props) => {
  const { openModal, setOpenModal, setRefreshContacts, category } = props;
  const [textFieldValue, setTextFieldValue] = useState('');

  const dispatch = useDispatch();
  const useStyles = makeStyles(() => ({
    dialog: {
      minHeight: '15vh',
      overflow: 'visible !important',
    },
  }));
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };

  const countDuplicates = async (orgName) => {
    try {
      const response = category === 'Outlets' ? await getOrgsByCaseInsensitiveName(orgName) : await getOrganizationsByCaseInsensitiveName(orgName);
      return response.data.length;
    } catch (error) {
      alert(`get failed - ${error}`);
      return 0;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const duplicates = await countDuplicates(textFieldValue);

    if (duplicates > 0) {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: `${duplicates} ${category.toLowerCase().slice(0, -1)}(s) with the same name already exists. Please check if you are trying to add an ${category.toLowerCase().slice(0, -1)} that already exists.`,
        })
      );
      return;
    } else {
      try {
        const response = await API.post(category === 'Outlets' ? `/org` : `/organization`, { name: textFieldValue });

        if (response.status !== 201) {
          alert(response.statusText);
        } else {
          dispatch(
            openSnackbar({
              severity: 'success',
              message: `${category.slice(0, -1)}: ${textFieldValue} created!`,
            })
          );
        }

        setRefreshContacts(true);
        handleClose();
        event.stopPropagation();
      } catch (err) {
        alert(`post failed - ${err}`);
      }
    }
  };

  return (
    <Dialog open={openModal} onClose={handleClose} className={classes.dialog}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New {category.slice(0, -1)}</DialogTitle>
        <DialogContent className={classes.dialog}>
          <TextField
            required
            autoFocus
            value={textFieldValue}
            onChange={(event) => {
              setTextFieldValue(event.target.value);
            }}
            margin="dense"
            id="name"
            label="Name"
            type="text"
            variant="standard"
            data-cy="name-input-ContactsPageAddOutletModal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} data-cy="cancel-button-ContactsPageAddOutletModal">Cancel</Button>
          <Button type="submit" data-cy="add-button-ContactsPageAddOutletModal">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContactsPageAddOutletModal;
