import { SvgIconProps, SvgIcon } from '@mui/material';

export default function CardsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 19 15" {...props}>
      <path
        d="M2.39453 5.15234C2.3125 5.20703 2.25781 5.34375 2.33984 5.42578L6.90625 13.3555C6.96094 13.4375 7.07031 13.4922 7.17969 13.4102L12.457 10.375C12.5664 10.3203 12.5938 10.1836 12.5391 10.1016L7.97266 2.17188C7.91797 2.08984 7.78125 2.03516 7.67188 2.08984L2.39453 5.15234ZM1.19141 6.08203C0.78125 5.37109 1.02734 4.44141 1.73828 4.00391L7.01562 0.96875C7.75391 0.558594 8.68359 0.804688 9.09375 1.51562L13.6602 9.44531C14.0977 10.1562 13.8516 11.0859 13.1133 11.5234L7.83594 14.5586C7.09766 14.9688 6.16797 14.7227 5.75781 14.0117L1.19141 6.08203ZM13.5508 12.2617C14.6992 11.6055 15.082 10.1562 14.4258 9.00781L10.6797 2.52734C10.7344 2.5 10.7891 2.5 10.8438 2.5H16.9688C17.7891 2.5 18.5 3.21094 18.5 4.03125V13.2188C18.5 14.0664 17.7891 14.75 16.9688 14.75H10.8438C10.4609 14.75 10.1055 14.6406 9.85938 14.3945L13.5508 12.2617Z"
        fill="#707070"
      />
    </SvgIcon>
  );
}
