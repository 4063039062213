import {
  IconButton,
  Tooltip,
  Zoom,
  Chip,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
} from '@mui/material';
import PhoneIcon from '../TrackerFilter/Icons/PhoneIcon';
import SmartphoneIcon from '../TrackerFilter/Icons/SmartPhoneIcon';
import EmailIcon from '../TrackerFilter/Icons/EmailIcon';
import CommentIcon from '../TrackerFilter/Icons/CommentIcon';
import PositiveIcon from '../TrackerFilter/Icons/PositiveIcon';
import NeutralIcon from '../TrackerFilter/Icons/NeutralIcon';
import NegativeIcon from '../TrackerFilter/Icons/NegativeIcon';
import AttachmentIcon from '../TrackerFilter/Icons/AttachmentIcon';
import LinkedServiceIcon from '../TrackerFilter/Icons/LinkedServiceIcon';
import { UploadCell } from './CellTypes';
import ErrorBoundary from '../ErrorBoundary';
import { useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import { Link } from 'react-router-dom';
import { complexityOptions, TrackerDataType, mediaToneOptions } from '../../shared/constants/constants';
import {
  IssueAndServiceSharedValues,
  IssueKeys,
  ServiceKeys,
  inProgressKeys,
  EngagementKeys,
  IssueAndEngagementSharedValues,
} from '../../shared/constants/IssueAndServiceKeys';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { useDialog } from '../../shared/contexts/DialogContext';
import CloseIcon from '@mui/icons-material/Close';
import truncate from 'html-truncate';
import dayjs from '../../utils/dayjs';
import { styled } from '@mui/material/styles';

// Styled tooltip component for "Click to Copy" tooltips
const WhiteBackgroundTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px 10px',
    gap: '8px',
    boxShadow: '0px 0px 4px rgba(126, 126, 126, 0.25)',
    borderRadius: '5px',
  },
  [`& .MuiTooltip-arrow`]: {
    color: 'white'
  }
}));

const TooltipTitle = styled(Typography)({
  fontWeight: 400,
  fontSize: '12px',
  color: '#A3A3A3',
});

const TooltipContent = styled(Typography)({
  fontSize: '14px',
  color: 'black'
});

const ClickToCopyTooltip = ({ content, icon }) => {
  const displayContent = content || 'N/A';
  
  return (
    <WhiteBackgroundTooltip
      title={
        <Box>
          <TooltipTitle>Click to copy</TooltipTitle>
          <TooltipContent>{displayContent}</TooltipContent>
        </Box>
      }
      arrow
    >
      <IconButton 
        style={{ padding: 4 }}
        onClick={() => navigator.clipboard.writeText(displayContent)}
        size="large"
        // Visual indication when no content available
        sx={!content ? { opacity: 0.5 } : {}}
      >
        {icon}
      </IconButton>
    </WhiteBackgroundTooltip>
  );
};


const renderContactInfo = (obj, styles) => {
  const iconSize = { fontSize: '20px' };
  return (
    <Box>
      <Typography variant="body2" sx={styles}>
        {obj.name}
      </Typography>
      <Box display="inline-flex">
        <ClickToCopyTooltip content={obj.phone} icon={<PhoneIcon style={iconSize} />} />
        <ClickToCopyTooltip content={obj.cell} icon={<SmartphoneIcon style={iconSize} />} />
        <ClickToCopyTooltip content={obj.email} icon={<EmailIcon style={iconSize} />} />
        <ClickToCopyTooltip content={obj.notes} icon={<CommentIcon style={iconSize} />} />
      </Box>
    </Box>
  );
};

const CellViewerTextDialog = ({ payload, open, onClose }) => {
  let titleColor = 'primary';
  if (payload.type === 'mediaInteraction') {
    titleColor = 'mediaInteraction.main';
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{payload.titleName}</Typography>
            <Typography color={titleColor}>{payload.title}</Typography>
          </Box>
          <Button
            sx={{
              backgroundColor: '#AAAAAA',
              '&:hover': {
                backgroundColor: '#AAAAB0',
              },
            }}
            variant="contained"
            onClick={() => onClose()}
            endIcon={<CloseIcon />}
          >
            Close
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        {payload.cellName}
        <Paper variant="outlined" sx={{ padding: '1rem', marginTop: '1rem' }}>
          <RichTextEditor
            defaultValue={''}
            value={payload.content}
            readOnly={true}
            quillStyle={{ resize: 'vertical' }}
          />
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

// Helper function to create tooltip with icon
const createTooltipWithIcon = (title, icon, mr = 0.5) => (
  <Tooltip
    title={<Typography variant={'body2'}>{title}</Typography>}
    TransitionComponent={Zoom}
    enterDelay={200}
  >
    <Box component="span" sx={{ mr }}>
      {icon}
    </Box>
  </Tooltip>
);

// Helper function to create a topic box with proper styling
const createTopicBox = (content, color, fileKey, linkedServiceKey, cells, fileTooltipText, linkedServiceTooltipText) => (
  <Box sx={{ paddingLeft: '6px' }}>
    <Typography variant="body2" sx={{ textAlign: 'left', color }}>
      {content}
    </Typography>
    {(fileKey || linkedServiceKey) && (
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
        {fileKey && cells[fileKey] && cells[fileKey].length > 0 ? 
          createTooltipWithIcon(fileTooltipText, <AttachmentIcon />) : null}
        {linkedServiceKey && cells[linkedServiceKey] && cells[linkedServiceKey].length > 0 ? 
          createTooltipWithIcon(linkedServiceTooltipText, <LinkedServiceIcon />, 0) : null}
      </Box>
    )}
  </Box>
);

export default function CellViewer({
  index,
  cells,
  dropdownTable,
  callback,
  openForm,
  id,
  classes,
  type,
  handleDrag,
  handleDrop,
  isDragActive,
  isSharedResource = false,
}) {
  let cellData;
  const findExpertName = (id) => {
    return dropdownTable['expert']?.find?.((expert) => expert['_id'] === id)?.name ?? id;
  };

  const dispatch = useFiltersDispatch();
  const { openDialog } = useDialog();

  const leftAlignedStyle = {
    textAlign: 'left',
    paddingLeft: '6px',
  };

  switch (index) {
    case IssueAndServiceSharedValues.leadExpert:
      cellData = (
        <Typography variant="body2" sx={leftAlignedStyle}>
          {cells[index]?.map((item) => (item.name ? item.name : findExpertName(item)))?.join(', ') ?? cells[index]}
        </Typography>
      );
      break;
    case IssueAndServiceSharedValues.teamMembers:
      cellData = (
        <Typography variant="body2" sx={leftAlignedStyle}>
          {cells[index]
            ?.map?.((c) => {
              if (c?.name) {
                return c?.name;
              }
              const user = dropdownTable['lead']?.find((lead) => lead['_id'] === c);
              return user ? user.name : null;
            })
            .join(', \n') ?? dropdownTable['lead']?.find?.((lead) => lead['_id'] === cells[index])?.name}
        </Typography>
      );
      break;
    case IssueAndServiceSharedValues.date:
      cellData = (
        <Typography variant="body2" sx={leftAlignedStyle}>
          {dayjs(cells[index]).format('M/D/YYYY')}
        </Typography>
      );
      break;
    case IssueKeys.journalist: {
      let obj;
      if (isSharedResource) {
        obj = cells[index];
      } else {
        obj = dropdownTable['journalist']?.find((journalist) => journalist['_id'] === cells[index]);
      }
      cellData = obj ? renderContactInfo(obj, leftAlignedStyle) : null;
      break;
    }
    case EngagementKeys.communityMember: {
      let obj;
      if (isSharedResource) {
        obj = cells[index];
      } else {
        obj = dropdownTable['communityMember']?.find((communityMember) => communityMember['_id'] === cells[index]);
      }
      cellData = obj ? renderContactInfo(obj, leftAlignedStyle) : null;
      break;
    }
    case IssueKeys.outlet:
      if (isSharedResource) {
        const obj = cells[index];
        cellData = obj ? (
          <Typography variant="body2" sx={leftAlignedStyle}>
            {obj.name}
          </Typography>
        ) : null;
        break;
      }
    // eslint-disable-next-line no-fallthrough
    case inProgressKeys.outlet:
      cellData = (
        <Typography variant="body2" sx={leftAlignedStyle}>
          {dropdownTable['outlet']?.find((org) => org['_id'] === cells[index])?.name ?? null}
        </Typography>
      );
      break;
    case EngagementKeys.organization:
      if (isSharedResource) {
        const obj = cells[index];
        cellData = obj ? (
          <Typography variant="body2" sx={leftAlignedStyle}>
            {obj.name}
          </Typography>
        ) : null;
        break;
      }
      cellData = (
        <Typography variant="body2" sx={leftAlignedStyle}>
          {dropdownTable['organization']?.find((organization) => organization['_id'] === cells[index])?.name ?? null}
        </Typography>
      );
      break;
    case IssueAndServiceSharedValues.type: {
      const typeColorMap = {
        'Red': '#FFBDAD',
        'Green': '#B3F5FF',
        'None': undefined,
      };

      cellData = (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'flex-start', paddingLeft: '6px' }}>
          {cells[index].map((c, i) => {
            const chipColor = typeColorMap[c.backgroundColor];

            return (
              <Chip
                key={`${c.name}-${i}`}
                label={<Box sx={{ marginTop: '5px', marginBottom: '5px' }}>{c.name}</Box>}
                variant="filled"
                sx={{
                  backgroundColor: chipColor,
                  borderRadius: '8px',
                  height: '32px',
                  fontSize: '10px',
                }}
              />
            );
          })}
        </Box>
      );
      break;
    }
    case IssueKeys.linkedService:
      cellData = (
        <Box className={classes.stackBox}>
          {id && (
            <Link
              to={RouteInformation.serviceLog.path}
              onClick={() => {
                dispatch({
                  type: 'setFilters',
                  payload: {
                    linkedInteraction: {
                      _id: id,
                    },
                  },
                });
              }}
            >
              View Linked Service
            </Link>
          )}
          <Box className={classes.stackBox}>
            {cells[index].map((c) => (
              <Typography>{c.name}</Typography>
            ))}
          </Box>
          {cells[index].length === 0 && (
            <Button variant="contained" className={classes.addLinkedButton} onClick={() => openForm()}>
              Create a new Linked Service
            </Button>
          )}
        </Box>
      );
      break;
    case IssueAndServiceSharedValues.campaign: {
      let sanitizedData = Array.isArray(cells[index]) ? cells[index] : [cells[index]];

      if (sanitizedData.length === 0) {
        cellData = <Typography variant="body2" sx={leftAlignedStyle}></Typography>;
      } else {
        cellData = (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'flex-start', paddingLeft: '6px' }}>
            {sanitizedData.map((campaign, i) => (
              <Box
                key={`${campaign?.name || campaign}-${i}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '4px',
                  overflow: 'hidden',
                  width: 'fit-content',
                }}
              >
                <Box
                  sx={{
                    width: '7px',
                    height: '30px',
                    bgcolor: campaign?.backgroundColor || '#9E9E9E',
                    mr: 0.8,
                    borderRadius: '3px 3px 3px 3px',
                    flexShrink: 0,
                  }}
                />
                <Typography sx={{ textAlign: 'left', fontSize: '14px', fontWeight: 400 }}>
                  {campaign?.name || campaign}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      }

      break;
    }
    case IssueAndServiceSharedValues.department: {
      let display = '';

      if (cells[index] instanceof Array && cells[index].length > 0) {
        display = cells[index]?.map?.((c) => `${c}`)?.join(', \n');
      } else if (typeof cells[index] === 'string') {
        display = cells[index];
      }

      cellData = <Typography variant="body2" sx={leftAlignedStyle}>{display}</Typography>;
      break;
    }
    case IssueAndServiceSharedValues.unit: {
      let display = '';

      if (cells[index] instanceof Array && cells[index].length > 0) {
        display = cells[index]?.map?.((c) => `${c}`)?.join(', \n');
      } else if (typeof cells[index] === 'string') {
        display = cells[index];
      }

      cellData = (
        <Typography variant="body2" sx={leftAlignedStyle}>
          {display}
        </Typography>
      );
      break;
    }
    case ServiceKeys.linkedInteraction:
      cellData =
        cells[index]?.interactions?.length > 0 ? (
          <Box className={classes.stackBox}>
            <Link
              to={RouteInformation.mediaInteraction.path}
              onClick={() => {
                dispatch({
                  type: 'setFilters',
                  payload: {
                    linkedService: [
                      {
                        _id: cells[index]._id,
                        name: cells[index].name,
                        firstLetter: cells[index].name.charAt(0),
                      },
                    ],
                  },
                });
              }}
            >
              View Linked Interactions
            </Link>
            {cells[index].interactions.map((c) => (
              <Typography key={c.outlet}>
                {c.outlet}(Reach {c.reach})
              </Typography>
            ))}

            <Button variant="contained" className={classes.addLinkedButton} onClick={() => openForm()}>
              Add an interaction
            </Button>
          </Box>
        ) : (
          <Box className={classes.stackBox}>
            No Media Interaction yet
            <Button variant="contained" className={classes.addLinkedButton} onClick={() => openForm()}>
              Add an interaction
            </Button>
          </Box>
        );

      break;
    case IssueKeys.topic:
      cellData = createTopicBox(
        cells[index],
        'black',
        IssueKeys.file,
        IssueAndEngagementSharedValues.linkedService,
        cells,
        'Interaction has attached links',
        'Interaction has been linked to a Service'
      );
      break;
    case EngagementKeys.topic:
      cellData = createTopicBox(
        cells[index],
        'black',
        EngagementKeys.file,
        IssueAndEngagementSharedValues.linkedService,
        cells,
        'Engagement has attached links or documents',
        'Engagement has been linked to a Service'
      );
      break;
    case ServiceKeys.service:
      cellData = createTopicBox(
        cells[index],
        'black',
        ServiceKeys.file,
        null,
        cells,
        'Service has attached links or documents',
        null
      );
      break;
    case IssueAndServiceSharedValues.backgroundResponse:
    case IssueAndServiceSharedValues.keyMessaging: {
      const addonKey = index === IssueAndServiceSharedValues.backgroundResponse ? 'response' : 'keyMessaging';

      let data = cells[index] ? cells[index] : '';
      if (
        (type === TrackerDataType.MEDIA_INTERACTION || type === TrackerDataType.ENGAGEMENT) &&
        cells[IssueAndEngagementSharedValues.fieldAddons]?.[addonKey]
      ) {
        data = `${data !== '' ? data + '\n' : ''}---- Copied from Linked Service ---- \n${
          cells[IssueAndEngagementSharedValues.fieldAddons][addonKey]
        }`;
      }

      let displayComponent = <RichTextEditor defaultValue={''} value={data} readOnly={true} />;
      const TRUNCATE_LIMIT = 350;

      if (data && data.length > TRUNCATE_LIMIT) {
        const displayData = truncate(data, TRUNCATE_LIMIT);
        const dialogType = cells['Service'] ? 'service' : 'mediaInteraction';
        const dialogTitleName = dialogType === 'service' ? 'Service' : 'Topic';
        const dialogTitle = dialogType === 'service' ? cells['Service'] : cells['Topic'];

        displayComponent = (
          <>
            <RichTextEditor
              defaultValue={''}
              value={displayData}
              readOnly={true}
              quillStyle={{
                minHeight: 130,
                maxHeight: 130,
                width: '100%',
                overflow: 'hidden',
              }}
            />
            <Button
              variant="outlined"
              sx={{ marginTop: '1rem', width: '100%' }}
              onClick={() =>
                openDialog(CellViewerTextDialog, {
                  type: dialogType,
                  titleName: dialogTitleName,
                  title: dialogTitle,
                  cellName: index.toUpperCase(),
                  content: data,
                })
              }
            >
              Read More
            </Button>
          </>
        );
      }

      cellData = (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          {displayComponent}
        </div>
      );
      break;
    }
    case IssueKeys.file:
      cellData = <UploadCell id={id} cellName={index} files={cells[index]} callback={callback} type={type} />;
      break;
    case ServiceKeys.file:
    case EngagementKeys.file:
      cellData = (
        <UploadCell
          id={id}
          cellName={index}
          files={cells[index]}
          callback={callback}
          type={type}
          handleDrag={handleDrag}
          handleDrop={handleDrop}
          isDragActive={isDragActive}
        />
      );
      break;
    case ServiceKeys.complexity:
      if (cells[index]) {
        const complexityOption = complexityOptions.find((option) => option._id === cells[index]);
        cellData = complexityOption ? (
          <Chip
            label={complexityOption._id}
            variant="filled"
            sx={{
              '& .MuiChip-label': {
                padding: 0,
              },
            }}
          />
        ) : (
          cells[index]
        );
      } else {
        cellData = '';
      }
      break;
    case IssueKeys.tone: {
      const toneIcons = {
        [mediaToneOptions[0]._id]: <PositiveIcon />,
        [mediaToneOptions[1]._id]: <NeutralIcon />,
        [mediaToneOptions[2]._id]: <NegativeIcon />,
      };

      cellData = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {toneIcons[cells[index]] || ''}
        </Box>
      );
      break;
    }
    case ServiceKeys.diversity:
      cellData = (
        <Typography variant="body2" style={{ minWidth: '150px' }}>
          {cells[index]}
        </Typography>
      );
      break;
    case IssueAndServiceSharedValues.status:
      cellData = <Chip label={cells[index] === "Participation Ribbon" ? "Participation" : cells[index]} variant="filled" />;
      break;
    default:
      cellData = cells[index] ?? '';
      break;
  }
  return <ErrorBoundary>{cellData}</ErrorBoundary>;
}
