import { useEffect, useState } from 'react';
import {transitionTime, socketEvents, TrackerDataType, API} from '../constants/constants';
import { EngagementKeys } from '../constants/IssueAndServiceKeys';
import { htmlToText } from 'html-to-text';
import {Socket} from "socket.io-client";
import {DropdownTable, Engagement, EngagementQueryAdder, FlatEngagement, File as TrackerFile} from '../types';
import {openSnackbar} from "../../components/AppSnackbar/snackbarSlice";
import axios, {AxiosError} from "axios";
import {useDispatch} from "react-redux";
import dayjs from "../../utils/dayjs";

const EngagementLogEventHandler = (socket: Socket) => {
  const dispatch = useDispatch();

  return {
    type: TrackerDataType.ENGAGEMENT,
    newItemModalTitle: 'Add a New Engagement',
    createNewItem: async (fields: Engagement, files: File[], successCallback: (arg0: { postId: string }) => void) => {
      try {
        let response = await API.post(`/engagements`, [fields]);
        if (response.status !== 201) {
          dispatch(
            openSnackbar({
              severity: 'alert',
              message: response.statusText,
            })
          );
          return response;
        }

        let f = new FormData();
        const id = response.data.id[0] || null;
        const headers = { 'content-type': 'multipart/form-data' };

        files
          .filter((file) => file.type !== 'url')
          .forEach((file) => {
            f.append('file', file);
          });

        if (id) {
          response = await API.post(`/engagement/${id}/upload`, f, {
            headers: headers,
          });

          if (response.status !== 201) {
            dispatch(
              openSnackbar({
                severity: 'warning',
                message: `File upload failed for newly created engagement`,
              })
            );
          }

          await Promise.all(
            files
              .filter((file) => file.type === 'url')
              .map((file) => {
                return API.post(`/engagement/${id}/url`, file).then((response) => {
                  if (response.status !== 201) {
                    openSnackbar({
                      severity: 'warning',
                      message: `Url upload failed for newly created engagement`,
                    });
                  }
                });
              })
          );

          if (successCallback) {
            successCallback({ postId: id });
          }
        }

        return response;
      } catch (error: any) {
        if (axios instanceof AxiosError && error.response.status === 400) {
          dispatch(
            openSnackbar({
              severity: 'error',
              message: error.response.data.message,
            })
          );
        }
      }
    },
    // use as a React Hook
    useListenOnCreation: (rowId: string) => {
      const [isNewlyAdded, setIsNewlyAdded] = useState(false);

      useEffect(() => {
        let timeout: NodeJS.Timeout;
        const listener = ({ engagement }: { engagement: Engagement }) => {
          if (engagement._id === rowId) {
            setIsNewlyAdded(true);
            timeout = setTimeout(() => {
              setIsNewlyAdded(false);
            }, transitionTime);
          }
        };
        socket.on(socketEvents.EVENT_ENGAGEMENT_ADDED, listener);

        return () => {
          socket.off(socketEvents.EVENT_ENGAGEMENT_ADDED, listener);
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }, [rowId]);

      return { isNewlyAdded, setIsNewlyAdded };
    },
    editItem: (val: EngagementQueryAdder[keyof EngagementQueryAdder], index: string) => {
      let newVal;
      if (!val) {
        newVal = null;
      } else {
        switch (index) {
          case EngagementKeys.type:
          {
            let array: Engagement['type'] = [];
            (val as EngagementQueryAdder['type']).forEach((obj) => array.push(obj));
            newVal = array;
          }
            break;
          case EngagementKeys.linkedService:
            newVal = (val as EngagementQueryAdder['linkedService']).filter((item) => item !== null);
            break;
          case EngagementKeys.lead:
          {
            let array: Engagement['lead'] = [];
            (val as EngagementQueryAdder['lead']).forEach((obj) => array.push(obj));
            newVal = array;
          }
            break;
          case EngagementKeys.organization:
            newVal = (val as EngagementQueryAdder['organization'])?._id;
            //TODO cancel community member when organization changed
            break;
          case EngagementKeys.communityMember:
            newVal = (val as EngagementQueryAdder['communityMember'])?._id;
            break;
          case EngagementKeys.topic:
          case EngagementKeys.response:
          case EngagementKeys.keyMessaging:
          case EngagementKeys.date:
            newVal = val;
            break;
          case EngagementKeys.expert:
            newVal = val;
            break;
          case EngagementKeys.units:
          case EngagementKeys.campaign:
            let arr: Engagement['campaign'] = [];
            (val as EngagementQueryAdder['campaign']).forEach((obj) => arr.push(obj));
            newVal = arr;
            break;
          case EngagementKeys.file:
            newVal = val;
            break;
          case EngagementKeys.dept:
            newVal = (val as EngagementQueryAdder['dept']).map((c) => c.name);
            break;
          default:
            newVal = (val as EngagementQueryAdder['communityMember'])?.name;
            break;
        }
      }
      return newVal;
    },
    updateItem: async (updatedEngagement: Engagement, successCallback: (arg0: { postId: string }) => void) => {
      socket.emit(socketEvents.EVENT_REQ_UPDATE_ENGAGEMENT, { data: updatedEngagement }, successCallback);
    },
    deleteItem: async (id: string) => {
      socket.emit(socketEvents.EVENT_REQ_DELETE_ENGAGEMENT, id);
    },
    undoDelete: async (data: Engagement) => {
      try {
        socket.emit(socketEvents.EVENT_NEW_ENGAGEMENT, { data: data, undo: true });
      } catch (e: any) {
        alert(e.message);
      }
    },
    monthlyView: async (currMonth: string) => {
      socket.emit(socketEvents.EVENT_REQ_MONTHLY_ENGAGEMENTS, {
        month: currMonth,
        timezoneOffset: dayjs.tz.guess(),
      });
    },
    flattenForExport: (data: FlatEngagement[], dropdownTable: DropdownTable) => {
      return data.map((row: FlatEngagement) => {
        try {
          let flatRow = JSON.parse(JSON.stringify(row.cells));

          flatRow[EngagementKeys.type] = flatRow[EngagementKeys.type].map((c: FlatEngagement['cells'][EngagementKeys.type][0]) => `${c.type}:${c.name}`).join(', \n');

          let obj;
          flatRow[EngagementKeys.expert] = flatRow[EngagementKeys.expert]
            .map((le: FlatEngagement['cells'][EngagementKeys.expert][0]) => dropdownTable['expert'].find((dropdownle) => dropdownle._id === le._id)?.name ?? '')
            .join(', \n');

          flatRow[EngagementKeys.lead] = flatRow[EngagementKeys.lead]
            .map((leadId: FlatEngagement['cells'][EngagementKeys.lead][0]) => dropdownTable['lead'].find((lead) => lead._id === leadId)?.name ?? null)
            .filter((leadName: string) => leadName !== null)
            .join(', ');

          flatRow[EngagementKeys.linkedService] = flatRow[EngagementKeys.linkedService].map((c: FlatEngagement['cells'][EngagementKeys.linkedService][0]) => c.name);

          obj = dropdownTable['communityMember'].find((communityMember) => communityMember['_id'] === flatRow[EngagementKeys.communityMember]);
          flatRow[EngagementKeys.communityMember] = obj ? obj.name : null;
          flatRow['Community Member Email'] = obj ? obj.email : null;
          flatRow['Community Member Phone'] = obj ? obj.phone : null;

          obj = dropdownTable['organization'].find((org) => org['_id'] === flatRow[EngagementKeys.organization]);
          flatRow[EngagementKeys.organization] = obj ? obj.name : null;

          flatRow[EngagementKeys.file] = flatRow[EngagementKeys.file].map((file: TrackerFile) => {
            if (file.type === 'url') {
              return file.path;
            } else {
              return file.name;
            }
          });
          flatRow[EngagementKeys.campaign] = flatRow[EngagementKeys.campaign].map((campaign: FlatEngagement['cells'][EngagementKeys.campaign][0]) => `${campaign.name}`).join(', \n');

          let messaging = flatRow[EngagementKeys.keyMessaging] ? flatRow[EngagementKeys.keyMessaging] : '';
          messaging += flatRow['Field Addons']?.keyMessaging ? flatRow['Field Addons'].keyMessaging : '';
          messaging = htmlToText(messaging, { wordwrap: null });
          messaging = messaging.replace(/"/g, '""');
          flatRow[EngagementKeys.keyMessaging] = messaging;

          let response = flatRow[EngagementKeys.response] ? flatRow[EngagementKeys.response] : '';
          response += flatRow['Field Addons']?.response ? flatRow['Field Addons'].response : '';
          response = htmlToText(response, { wordwrap: null });
          response = response.replace(/"/g, '""');
          flatRow[EngagementKeys.response] = response;

          delete flatRow['Field Addons'];

          return flatRow;
        } catch {
          return {
            [EngagementKeys.date]: '',
            [EngagementKeys.contactMethod]: '',
            [EngagementKeys.topic]: 'error flattening data for export!',
            [EngagementKeys.type]: '',
            [EngagementKeys.units]: '',
            [EngagementKeys.expert]: '',
            [EngagementKeys.organization]: '',
            [EngagementKeys.communityMember]: '',
            'Community Member Email': '',
            'Community Member Phone': '',
            [EngagementKeys.campaign]: '',
            [EngagementKeys.lead]: '',
            [EngagementKeys.response]: '',
            [EngagementKeys.file]: '',
            [EngagementKeys.status]: '',
            [EngagementKeys.dept]: '',
            [EngagementKeys.keyMessaging]: '',
            [EngagementKeys.linkedService]: '',
          };
        }
      });
    },
  };
};

export default EngagementLogEventHandler;
