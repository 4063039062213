import { useState } from 'react';
import { TrackerDataType } from '../../shared/constants/constants';
import './ClientAnalytics.css';
import dayjs from '../../utils/dayjs';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchModal from './SearchModal';
import AnalyticsTable from './AnalyticsTable/AnalyticsTable';
import { chartTypeEnum } from './constants';
import * as chartAPIRequests from '../../shared/requests/teamAnalyticsChartRequests';
import { Spinner } from 'react-bootstrap';
import { IssueKeys, ServiceKeys, inProgressKeys } from '../../shared/constants/IssueAndServiceKeys';
import { useDropdownTable } from '../../shared/hooks/useDropdownTable';

const useStyles = makeStyles(() => ({
  filterBar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: '3vh',
    justifyContent: 'center',
  },
}));

//hook to use to prevent multiple rerenders when setting multiple states.
///https://stackoverflow.com/questions/59163378/react-hooks-skip-re-render-on-multiple-consecutive-setstate-calls

function CustomAnalytics() {
  const classes = useStyles();

  const { data } = useDropdownTable();
  const dropdownTable = data ?? 'loading';
  const interactionsColumns = [IssueKeys.date, IssueKeys.topic, IssueKeys.units, IssueKeys.outlet];
  const servicesColumns = [ServiceKeys.date, ServiceKeys.service, IssueKeys.units, ServiceKeys.type];
  const inProgressColumns = [
    inProgressKeys.topic,
    inProgressKeys.outlet,
    inProgressKeys.teamMember,
    inProgressKeys.timeStamp,
  ];
  //The default 90 day filter time frame.
  const now = dayjs().endOf('day').toDate();
  const past = dayjs().startOf('month').subtract(2, 'month').toDate();
  const defaultQuery = `startDate=${past.toISOString()}&endDate=${now.toISOString()}`;

  //using this state setter, the network requests for all chart data will be called with the new query state.
  const [query, setQuery] = useState(defaultQuery);

  //This function gets set to the setOpen state stter in SearchModal on first render, this way we can open/close modal without
  //having to rerender the charts.
  let showModal = () => {};

  //pass in network request for the corresponding backend call
  //This network request will be called in the corresponding useEffect hook of the table/chart component
  //This way we don't have to manage the state for each chart in this component, but rather in subcomponents.
  const returnChartsArrayData = () => {
    return [
      {
        title: 'Media Interactions by Type',
        type: chartTypeEnum.ChartJSStackedBar,
        networkRequest: chartAPIRequests.getInteractionsByType,
      },
      {
        title: 'Media Interactions by Team Member',
        type: chartTypeEnum.ChartJSStackedBar, //line
        networkRequest: chartAPIRequests.getInteractionsByMember,
      },
      {
        title: 'Services by Team Member',
        type: chartTypeEnum.ChartJSStackedBar,
        networkRequest: chartAPIRequests.getServicesByMember,
      },
      {
        title: 'Proactive vs Reactive Media Interactions',
        plotly: 1,
        type: chartTypeEnum.line,
        networkRequest: chartAPIRequests.getPvrData,
      },
      {
        title: 'Campaign Distribution',
        plotly: 0,
        type: chartTypeEnum.ChartJSDoughnut, //pie
        networkRequest: chartAPIRequests.getCampaignsChartData,
      },
      {
        title: 'Top Media Issue Topics',
        type: chartTypeEnum.ChartJSBar,
        networkRequest: chartAPIRequests.getTopMediaTopics,
        enlargedViewRequest: chartAPIRequests.getMoreMediaTopics,
      },
      {
        title: 'Top Media Interactions by Unit',
        type: chartTypeEnum.ChartJSDoughnut,
        networkRequest: chartAPIRequests.getInteractionsByUnit,
      },
      {
        title: 'Top Proactive Media Topics',
        type: chartTypeEnum.ChartJSBar,
        networkRequest: chartAPIRequests.getTopProactiveTopics,
        enlargedViewRequest: chartAPIRequests.getMoreProactiveTopics,
      },
      {
        title: 'Complexity of Services',
        plotly: 1,
        type: chartTypeEnum.PlotlyMonthLine,
        networkRequest: chartAPIRequests.getComplexityChartData,
      },
      {
        title: 'Media Interactions Compared to Past Years',
        plotly: 1,
        type: chartTypeEnum.numberBox,
        subTitle: 'Media Interactions in time period',
        networkRequest: chartAPIRequests.getApplesData,
      },
      {
        title: 'Top Service Areas (Unit)',
        type: chartTypeEnum.topHits,
        networkRequest: chartAPIRequests.getTopServiceHits,
      },
      {
        title: 'Top Media Areas (Unit)',
        type: chartTypeEnum.topHits,
        networkRequest: chartAPIRequests.getTopMediaHits,
      },
      {
        title: 'Services by Type',
        type: chartTypeEnum.ChartJSStackedBar,
        networkRequest: chartAPIRequests.getServicesByType,
      },
      {
        title: 'Top Complex Services',
        type: chartTypeEnum.ChartJSBar,
        label: '# of Total Complexity for Service',
        networkRequest: chartAPIRequests.getTopComplexServices,
        enlargedViewRequest: chartAPIRequests.getMoreComplexServices,
      },
      {
        title: 'Top 20 Media Outlets',
        plotly: 0,
        type: chartTypeEnum.ChartJSDoughnut, //pie
        networkRequest: chartAPIRequests.getOutletsChartData,
      },
      {
        title: 'Top Proactive Media Topics',
        type: chartTypeEnum.wordCloud,
        networkRequest: chartAPIRequests.get50ProactiveTopics,
      },
      {
        title: 'Top Media Issue Topics',
        type: chartTypeEnum.wordCloud,
        networkRequest: chartAPIRequests.get50MediaTopics,
      },
      {
        title: 'Top Experts',
        type: chartTypeEnum.wordCloud,
        networkRequest: chartAPIRequests.getExperts,
      },
      {
        title: 'Top Services by Unit',
        type: chartTypeEnum.ChartJSDoughnut,
        networkRequest: chartAPIRequests.getServicesByUnit,
      },
    ];
  };
  const returnTablesDataArray = () => {
    return [
      {
        columns: interactionsColumns,
        dropdownTable: dropdownTable,
        title: 'All Media Interactions',
        type: TrackerDataType.MEDIA_INTERACTION,
        networkRequest: chartAPIRequests.getInteractionsTable,
      },
      {
        columns: servicesColumns,
        dropdownTable: dropdownTable,
        title: 'All Media Services',
        type: TrackerDataType.SERVICE,
        networkRequest: chartAPIRequests.getServicesTable,
      },
      {
        columns: inProgressColumns,
        dropdownTable: dropdownTable,
        type: TrackerDataType.MEDIA_INTERACTION,
        title: 'Media Interactions in Progress',
        networkRequest: chartAPIRequests.getInProgressInteraction,
      },
    ];
  };

  return (
    <div className="analytics-wrapper">
      <div className={classes.filterBar}>
        <div className="filterButton">
          <Button variant="contained" onClick={() => showModal()}>
            {' '}
            <Typography>
              <FilterListIcon />
              Filters
            </Typography>
          </Button>
        </div>
        <div className={classes.filters}>
          <SearchModal setModalOpen={(f) => (showModal = f)} dropdownTable={dropdownTable} setQuery={setQuery} />
        </div>
      </div>
      {dropdownTable !== 'loading' ? (
        <AnalyticsTable
          chartDataArray={returnChartsArrayData()}
          tableDataArray={returnTablesDataArray()}
          query={query}
        ></AnalyticsTable>
      ) : (
        <Spinner animation="border"></Spinner>
      )}
    </div>
  );
}

export default CustomAnalytics;
