import { Select, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { AddOnTypes } from '../../shared/constants/TenantFields';

const ContactCategorySelect = (props) => {
  const { category, handleCategoryChange } = props;
  const user = useSelector((state) => state.user);
  const userTenantHasEngagementAddOn = user.addonControls[AddOnTypes.engagementLog];

  const engagementLogContacts = [{ value: 'Community Members' }, { value: 'Organizations' }];

  return (
    <>
      <Select
        sx={{ marginRight: '10px' }}
        labelId="contact-category"
        id="contact-category-select"
        value={category}
        label="Category"
        onChange={handleCategoryChange}
        size="small"
        data-cy="category-select-ContactCategorySelect"
      >
        <MenuItem value={'Journalists'}>Journalists</MenuItem>
        <MenuItem value={'Experts'}>Experts</MenuItem>
        <MenuItem value={'Outlets'}>Outlets</MenuItem>
        {engagementLogContacts.map((contactType) => {
          // MenuItem won't work unless it is a direct child of Select so we can't use the wrapper here
          // (or at least I haven't figured out how yet)
          if (userTenantHasEngagementAddOn) {
            return <MenuItem value={contactType.value}>{contactType.value}</MenuItem>;
          } else {
            return null;
          }
        })}
      </Select>
    </>
  );
};

export default ContactCategorySelect;
