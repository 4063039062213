import { socketEvents } from '../../shared/constants/constants';
import dayjs from '../../utils/dayjs';

export const emitMonthlyServices = (socket, currMonth) => {
  return new Promise((resolve, reject) => {
    let timeoutId;
    socket.emit(socketEvents.EVENT_MONTHLY_SERVICES, {
      month: currMonth,
      timezoneOffset: dayjs.tz.guess(),
    });
    socket.on(socketEvents.EVENT_MONTHLY_SERVICES, (data) => {
      clearTimeout(timeoutId);
      resolve(data);
      socket.off(socketEvents.EVENT_MONTHLY_SERVICES);
    });
  });
};

export const emitMonthlyIssues = (socket, currMonth) => {
  return new Promise((resolve, reject) => {
    let timeoutId;
    socket.emit(socketEvents.EVENT_REQ_MONTHLY_ISSUES, {
      month: currMonth,
      timezoneOffset: dayjs.tz.guess(),
    });

    socket.on(socketEvents.EVENT_MONTHLY_ISSUES, (data) => {
      clearTimeout(timeoutId);
      resolve(data);
      socket.off(socketEvents.EVENT_MONTHLY_ISSUES);
    });
  });
};
