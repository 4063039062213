import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface TrackerFilterPopoverGridProps {
  children?: [React.ReactNode, React.ReactNode];
}

const FILTER_LABEL_XS = 4;
const FILTER_XS = 14;

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
});

const TrackerFilterPopoverGrid: React.FC<TrackerFilterPopoverGridProps> = ({ children }) => {
  const classes = useStyles();

  const errorComponent = <Typography color="error">Error: this component requires 2 children</Typography>;

  return (
    <>
      <Grid xs={FILTER_LABEL_XS} className={classes.title}>
        <Typography fontSize="12px">{children ? children[0] : errorComponent}</Typography>
      </Grid>
      <Grid xs={FILTER_XS}>{children ? children[1] : errorComponent}</Grid>
    </>
  );
};

export default TrackerFilterPopoverGrid;
