import { IconButton, InputAdornment, TextField } from '@mui/material';
import DatePicker from 'react-datepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';

interface DatePickerInputFieldProps {
  classes?: {
    textInputFieldContainerClass: string;
    textInputFieldClass: string;
    endEndAdornmentIconClass: string;
  };
  selected?: Date | null;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  onChange: (date: Date | null) => void;
  required?: boolean;
  'data-cy'?: string;
  onClear?: () => void;
}
export default function DatePickerInputField(props: DatePickerInputFieldProps) {
  const { classes, required, onChange, selected, onClear } = props;
  const dataCy = props['data-cy'];

  let customInputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <CalendarTodayIcon sx={{ fontSize: '20px' }} />
      </InputAdornment>
    ),
    endAdornment: <></>,
    className: classes?.textInputFieldClass,
  };

  if (onClear && selected) {
    if (classes?.endEndAdornmentIconClass) {
      customInputProps = {
        ...customInputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton className={classes.endEndAdornmentIconClass} onClick={() => onClear()}>
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        ),
      };
    }

    customInputProps = {
      ...customInputProps,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton size="small" onClick={() => onClear()}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      ),
    };
  }

  const CustomInput = React.forwardRef<HTMLInputElement, any>(({ value, onClick }, ref) => (
    <TextField
      className={classes?.textInputFieldContainerClass}
      ref={ref}
      value={value}
      onClick={onClick}
      variant="outlined"
      size="small"
      InputProps={customInputProps}
    />
  ));

  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      required={required}
      customInput={<CustomInput />}
      data-cy={dataCy}
    />
  );
}
