import { useQuery } from '@tanstack/react-query';
import { getPersonnelByOrganization } from '../contactsRequest';

export const getCommunityMembersByOrganizationOptions = (orgID: string) => {
  return {
    queryKey: ['community-members', { orgID }],
    queryFn: () => getPersonnelByOrganization(orgID),
  };
};

export const useCommunityMembersByOrganization = (orgID: string) => {
  return useQuery(getCommunityMembersByOrganizationOptions(orgID));
};
