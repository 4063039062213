import List from '@mui/material/List';
import { LinearProgress, ListItem, Typography } from '@mui/material';
import ContactsVirtualList from './ContactsVirtualList';

// generate a random color for each contact
function stringToColor(string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: '70px',
      height: '70px',
      marginRight: '10px',
    },
    children: <Typography variant="h3">{name[0]}</Typography>,
  };
}

const OrgList = (props) => {
  const { handleContactClick, isLoadingList, organizations, selectedOrganization, style } = props;

  // Props from MergeOutletsModal
  const { isMerging, orgsToBeMerged, setOrgsToBeMerged } = props;

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '0',
        margin: '0',
        maxHeight: '100%',
        overflow: 'auto',
        width: '35rem',
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          overflow: 'auto',
          padding: 0,
          border: '1px solid #DEDEDE',
          ...style,
        }}
      >
        {isLoadingList ? <LinearProgress /> : null}
        <ContactsVirtualList
          isOutletContact={true}
          contactsSize={organizations ? organizations.length : 0}
          searchResultItems={organizations}
          isMerging={isMerging}
          setMergingContacts={setOrgsToBeMerged}
          mergingContacts={orgsToBeMerged}
          selectedContact={selectedOrganization}
          handleContactClick={handleContactClick}
          itemSize={120}
          getListItemAvatarProps={(name) => stringAvatar(name)}
        />
      </ListItem>
    </List>
  );
};

export default OrgList;
