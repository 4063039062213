import { TableHead, TableCell, TableRow, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getTabColorHeaderMain } from '../../shared/constants/constants';
import { SharedContactsTableHeaderProps } from './Types/componentProps';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles<DefaultThemeWithTabColor>(({ palette, tabColor }) => ({
  tableHeaderCell: {
    textAlign: 'center',
    color: 'white',
    fontSize: '16px',
    width: '8%',
    fontWeight: 700,
    backgroundColor: palette.primary.main,
  },
}));

const SharedContactsTableHeader = (props: SharedContactsTableHeaderProps) => {
  const { columns } = props;

  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColorHeaderMain(pathname, theme) });

  return (
    <TableHead>
      <TableRow>
        {columns.map((col) => (
          <TableCell key={`shared-contacts-table-header-cell-${col}`} className={classes.tableHeaderCell}>
            {col}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SharedContactsTableHeader;
