export const cellColors = {
  healthThemeStyle: {
    background: '#e6c56c',
  },
  equityStyle: {
    background: '#d2a9f0',
  },
  sustainabilityStyle: {
    background: '#5cb5a6',
  },

  statusCompleted: {
    background: '#e1efd9',
  },
  statusParticipation: {
    background: '#8fd1eb',
  },
  statusProgress: {
    background: '#FFDFA0',
  },
  statusCancelled: {
    background: 'lightgrey',
  },
  reconciliationStyle: {
    background: '#59b7e3',
  },

  teachingAndLearningStyle: {
    background: '#f7c87c',
  },
  drivenYes: {
    background: '#36d67e',
  },
  typeStyle: {
    background: '#f8dee7',
  },
  complexity1: {
    background: '#fff5eb',
  },
  complexity2: {
    background: '#fee6ce',
  },
  complexity3: {
    background: '#fdd0a2',
  },
  complexity4: {
    background: '#fdae6b',
  },
  complexity5: {
    background: '#f7954f',
  },
  issue: {
    background: '#ff9797',
  },
  support: {
    background: '#e1efd9',
  },
};

// Complexity color palette
const COMPLEXITY_COLORS = {
	LEVEL_1: '#FBEAB6',
	LEVEL_2: '#F6D15B',
	LEVEL_3: '#F3BC2F',
	LEVEL_4: '#F19E26',
	LEVEL_5: '#ED721F'
};
// Type color palette
export const typeColors = {
  'Reactive': '#FFBDAD',
  'Proactive': '#B3F5FF'
};

// Base style for all complexity chips
const baseComplexityStyle = {
	height: "100%",
	padding: "8px",
	"& .MuiChip-root": {
		color: "#000000",
		borderRadius: "4px",
		padding: "0",
		width: "32px",
		height: "32px",
		fontWeight: "500",
	}
};

export const cellColorsNew = {
	healthThemeStyle: {},
	equityStyle: {},
	sustainabilityStyle: {},

	statusCompleted: {
		"& .MuiChip-root": {
			backgroundColor: "#ABF5D1",
			color: "#000000",
			borderRadius: "16px",
			padding: "0 3px",
			width: "100px",
			height: "32px",
			fontSize: "12px"
		}
	},
	statusParticipation: {
		"& .MuiChip-root": {
			backgroundColor: "#BFD7FF",
			color: "#000000",
			borderRadius: "16px",
			padding: "0 3px",
			width: "100px",
			height: "32px",
			fontSize: "12px"
		}
	},
	statusProgress: {
		"& .MuiChip-root": {
			backgroundColor: "#E2F6CA",
			color: "#000000",
			borderRadius: "16px",
			padding: "0 3px",
			width: "100px",
			height: "32px",
			fontSize: "12px"
		}
	},
	statusCancelled: {
		"& .MuiChip-root": {
			backgroundColor: "#C0B6F2",
			color: "#000000",
			borderRadius: "16px",
			padding: "0 3px",
			width: "100px",
			height: "32px",
			fontSize: "12px"
		}
	},

	complexity1: {
		...baseComplexityStyle,
		"& .MuiChip-root": {
			...baseComplexityStyle["& .MuiChip-root"],
			backgroundColor: `${COMPLEXITY_COLORS.LEVEL_1}33`,
			border: `2px solid ${COMPLEXITY_COLORS.LEVEL_1}`
		}
	},
	complexity2: {
		...baseComplexityStyle,
		"& .MuiChip-root": {
			...baseComplexityStyle["& .MuiChip-root"],
			backgroundColor: `${COMPLEXITY_COLORS.LEVEL_2}33`,
			border: `2px solid ${COMPLEXITY_COLORS.LEVEL_2}`
		}
	},
	complexity3: {
		...baseComplexityStyle,
		"& .MuiChip-root": {
			...baseComplexityStyle["& .MuiChip-root"],
			backgroundColor: `${COMPLEXITY_COLORS.LEVEL_3}33`,
			border: `2px solid ${COMPLEXITY_COLORS.LEVEL_3}`
		}
	},
	complexity4: {
		...baseComplexityStyle,
		"& .MuiChip-root": {
			...baseComplexityStyle["& .MuiChip-root"],
			backgroundColor: `${COMPLEXITY_COLORS.LEVEL_4}33`,
			border: `2px solid ${COMPLEXITY_COLORS.LEVEL_4}`
		}
	},
	complexity5: {
		...baseComplexityStyle,
		"& .MuiChip-root": {
			...baseComplexityStyle["& .MuiChip-root"],
			backgroundColor: `${COMPLEXITY_COLORS.LEVEL_5}33`,
			border: `2px solid ${COMPLEXITY_COLORS.LEVEL_5}`
		}
	},

	reconciliationStyle: {},
	teachingAndLearningStyle: {},
	drivenYes: {},

	commonCell: {
		padding: "6px",
		textAlign: "left",
	},
};