import {
  DropdownTableAutocompleteOption,
  DropdownTableAutocompleteOptionCampaign,
  DropdownTableAutocompleteOptionCommunityMember,
  DropdownTableAutocompleteOptionDepartment,
  DropdownTableAutocompleteOptionExpert,
  DropdownTableAutocompleteOptionJournalist,
  DropdownTableAutocompleteOptionLinkedService,
  DropdownTableAutocompleteOptionOrganization,
  DropdownTableAutocompleteOptionOutlet,
  DropdownTableAutocompleteOptionTeamMember,
  DropdownTableAutocompleteOptionType,
  DropdownTableAutocompleteOptionUnit,
  DropdownTableContextValue,
  DropdownTableContextValueKey,
} from '../../shared/contexts/DropdownTableContext';
import dayjs from '../../utils/dayjs';

type TrackerFilterTextQueryKey = 'service' | 'topic' | 'id';

export const getTrackerFilterTextInputQuery = (key: TrackerFilterTextQueryKey, text: string) => {
  if (!text) {
    return '';
  }
  return `${key}=${encodeURIComponent(text)}`;
};

const getStringListFilterQuery = (query: string, strList: string[]) => {
  if (strList.length === 0) {
    return '';
  }
  const statusNameListStr = strList.map((str) => `"${str}"`).join(', ');
  return `${query}=[${statusNameListStr}]`;
};

export const getTrackerFilterStatusFilterQuery = (statusNameList: string[]) => {
  return getStringListFilterQuery('status', statusNameList);
};

export const getTrackerFilterToneFilterQuery = (tonesList: string[]) => {
  return getStringListFilterQuery('tone', tonesList);
};

type TrackerFilterDateQueryKey = 'startDate' | 'endDate';

export const getTrackerFilterDateInputFilterQuery = (key: TrackerFilterDateQueryKey, date: Date | null) => {
  if (!date) {
    return '';
  }

  let outputDate = dayjs(date);
  if (key === 'endDate') {
    outputDate = outputDate.add(1, 'day');
  }

  return `${key}=${outputDate.toISOString()}`;
};

type TrackerFilterAutocompleteQueryKey =
  | 'lead/expert'
  | 'campaigns'
  | 'type'
  | 'team members'
  | 'units'
  | 'outlet'
  | 'journalist'
  | 'linkedservice'
  | 'department'
  | 'organization'
  | 'communityMember';

export const getTrackerFilterAutocompleteFilterQuery = (
  key: TrackerFilterAutocompleteQueryKey,
  idOrNameList: string[]
) => {
  if (idOrNameList.length === 0) {
    return '';
  }

  const idOrNameListStr = idOrNameList.map((item) => `"${item}"`).join(', ');
  return `${key}=[${idOrNameListStr}]`;
};

export const getTrackerFilterComplexityFilter = (complexities: number[]) => {
  if (complexities.length === 0) {
    return '';
  }
  return `complexity=[${complexities.map((item) => item).join(', ')}]`;
};

export const getTrackerFilterQueryString = (filterQueries: string[]) => {
  if (filterQueries.length === 0) {
    return '';
  }

  return filterQueries.filter((item) => item !== '').join('&');
};

export const getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc = (
  dropdownTableContext: DropdownTableContextValue,
  filterType: DropdownTableContextValueKey
): DropdownTableAutocompleteOption[] => {
  if (!dropdownTableContext) {
    return [];
  }

  switch (filterType) {
    case 'expert': {
      return dropdownTableContext.expert
        .filter((expert) => !expert.archived)
        .map((expert) => ({
          ...expert,
          firstLetter: expert.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    case 'campaigns': {
      return dropdownTableContext.campaigns
        .filter((campaign) => !campaign.archived)
        .map((campaign) => ({
          ...campaign,
          firstLetter: campaign.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    case 'type': {
      return dropdownTableContext.type
        .filter((type) => !type.archived)
        .map((type) => ({
          ...type,
          firstLetter: type.name.charAt(0),
        }))
        .sort((a, b) => -b?.type?.localeCompare(a?.type) || -b?.firstLetter?.localeCompare(a?.firstLetter));
    }
    case 'lead': {
      return dropdownTableContext.lead
        .map((teamMember) => ({
          ...teamMember,
          firstLetter: teamMember.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    case 'units': {
      return dropdownTableContext.units
        .map((unit) => ({
          ...unit,
          firstLetter: unit.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    case 'outlet': {
      return dropdownTableContext.outlet
        .map((outlet) => ({
          ...outlet,
          firstLetter: outlet.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    case 'journalist': {
      return dropdownTableContext.journalist
        .map((journalist) => ({
          ...journalist,
          firstLetter: journalist.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    case 'organization': {
      return dropdownTableContext.organization
        .map((organization) => ({
          ...organization,
          firstLetter: organization.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    case 'communityMember': {
      return dropdownTableContext.communityMember
        .map((communityMember) => ({
          ...communityMember,
          firstLetter: communityMember.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    case 'service': {
      return dropdownTableContext.service
        .map((service) => ({
          ...service,
          firstLetter: service.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    case 'dept': {
      return dropdownTableContext.dept
        .map((dept) => ({
          ...dept,
          firstLetter: dept.name.charAt(0),
        }))
        .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    }
    default: {
      // add additional logic if filter type not covered
      console.log(`${filterType} - this type of filter is not supported, generate autocomplete options failed`);
      return [];
    }
  }
};

export const getTrackerFilterAutoCompleteOptionsGroupByFunc = (filterType: DropdownTableContextValueKey) => {
  switch (filterType) {
    case 'expert': {
      return (option: DropdownTableAutocompleteOptionExpert) => option.firstLetter;
    }
    case 'campaigns': {
      return (option: DropdownTableAutocompleteOptionCampaign) => option.firstLetter;
    }
    case 'type': {
      return (option: DropdownTableAutocompleteOptionType) => option.type;
    }
    case 'lead': {
      return (option: DropdownTableAutocompleteOptionTeamMember) => option.firstLetter;
    }
    case 'units': {
      return (option: DropdownTableAutocompleteOptionUnit) => option.firstLetter;
    }
    case 'outlet': {
      return (option: DropdownTableAutocompleteOptionOutlet) => option.firstLetter;
    }
    case 'journalist': {
      return (option: DropdownTableAutocompleteOptionJournalist) => option.firstLetter;
    }
    case 'organization': {
      return (option: DropdownTableAutocompleteOptionOrganization) => option.firstLetter;
    }
    case 'communityMember': {
      return (option: DropdownTableAutocompleteOptionCommunityMember) => option.firstLetter;
    }
    case 'service': {
      return (option: DropdownTableAutocompleteOptionLinkedService) => option.firstLetter;
    }
    case 'dept': {
      return (option: DropdownTableAutocompleteOptionDepartment) => option.firstLetter;
    }
    default: {
      // add additional logic if filter type not covered
      console.log(`${filterType} - this type of filter is not supported, generate groupBy function failed`);
      return (option: any) => option;
    }
  }
};
