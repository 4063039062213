import { useState, useEffect, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
  Badge,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { UserMenuItems } from '../Profile/UserMenuItems';
import logout from '../Authorization/Logout';
import { getNewUserNotificationSocketEvent } from '../../shared/constants/constants';
import CustomAvatar from '../Profile/CustomAvatar';
import SiteLogo from '../../shared/images/ColoredBackGroundLogo.svg';
import { useSelector, useDispatch } from 'react-redux';
import * as UserActions from '../Authorization/userSlice';
import getFirstPartOfPath from '../../utils/getFirstPartOfPath';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { SocketContext } from '../../shared/contexts/SocketContext';
import { getNotificationsForCurrentUser } from '../../shared/requests/notifications';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  header: {
    height: '50px',
  },
  toolbar: {
    minHeight: '50px',
  },
  logoBox: {
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 0,
  },
  logoImg: {
    maxWidth: '124px',
    maxHeight: '37.98px',
  },
  sidebarBtn: {
    '&:focus': {
      backgroundColor: 'inherit',
    },
    zIndex: 2,
  },
  leftItems: {
    display: 'flex',
    alignItems: 'center',
  },
  rightItems: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  notificationBtn: {
    width: '16px',
    height: '16px',
  },
  notificationBtnIcon: {
    '&:hover': { color: 'grey' },
    color: 'black',
  },
  userProfileWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  menuButton: {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopMenu: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileMenu: {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

function Header(props) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { sidebarOpen, onSetSidebarOpen } = props;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const [notificationsCount, setNotificationsCount] = useState(0);
  const socket = useContext(SocketContext);

  const queryClient = useQueryClient();
  const { data: notifications } = useQuery({
    queryKey: ['notifications', 'header'],
    queryFn: () => getNotificationsForCurrentUser(),
  });

  useEffect(() => {
    if (notifications) {
      setNotificationsCount(notifications.filter((notification) => notification.isRead === false).length ?? 0);
    }
  }, [notifications]);

  useEffect(() => {
    const currentUserNotificationsSocketEvent = getNewUserNotificationSocketEvent(user.userInfo.id);
    socket.on(currentUserNotificationsSocketEvent, () => {
      queryClient.invalidateQueries({ queryKey: ['notifications', 'header'] });
    });

    return () => {
      socket.off(currentUserNotificationsSocketEvent);
    };
  }, [socket, user, queryClient]);

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getTab = () => {
    switch (getFirstPartOfPath(pathname)) {
      case RouteInformation.mediaInteraction.path:
        return 'Media Interactions';
      case RouteInformation.teamAnalytics.path:
        return 'Team Analytics';
      case RouteInformation.customAnalytics.path:
        return 'Custom Analytics';
      case RouteInformation.serviceLog.path:
        return 'Service Log';
      case RouteInformation.engagementLog.path:
        return 'Engagement Log';
      case RouteInformation.admin.path:
        return 'Admin';
      case RouteInformation.profile.path:
        return 'User Profile';
      case RouteInformation.tenants.path:
        return 'Broadsight Admin';
      case RouteInformation.contacts.path:
        return 'Contacts';
      case RouteInformation.calendar.path:
        return 'Calendar';
      case RouteInformation.report.path:
        return 'Report Generation';
      case RouteInformation.autoTracker.path:
        return 'AutoTracker';
      case RouteInformation.notifications.path:
        return 'Notifications';
      case RouteInformation.collaboration.path:
        return 'Collaborate';
      case RouteInformation.broadcastPopup.path:
        return 'Announcement';
      default:
        return 'Page Name Placeholder';
    }
  };

  const clearUserInfo = () => {
    localStorage.clear();
    dispatch(UserActions.userLogout());
  };

  return (
    <AppBar color="default" position="fixed" elevation={0} className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            onSetSidebarOpen(!sidebarOpen);
          }}
          edge="start"
          className={classes.sidebarBtn}
        >
          <MenuIcon />
        </IconButton>
        {!isMobile ? (
          <Typography variant="h6" noWrap>
            {getTab()}
          </Typography>
        ) : (
          <Typography></Typography>
        )}

        <div className={classes.logoBox}>
          <img src={SiteLogo} alt="logo" className={classes.logoImg} />
        </div>

        <div className={classes.rightItems}>
          <IconButton
            onClick={() => {
              if (pathname === RouteInformation.notifications.path) {
                navigate(0); // refresh page
              } else {
                navigate(RouteInformation.notifications.path, { replace: true });
              }
            }}
            className={classes.notificationBtn}
          >
            <Tooltip title="Notifications">
              <Badge badgeContent={notificationsCount} color="error">
                <NotificationsOutlinedIcon fontSize="inherit" className={classes.notificationBtnIcon} />
              </Badge>
            </Tooltip>
          </IconButton>

          <div className={classes.userProfileWrapper}>
            <Typography variant="h6">{user.userInfo.name}</Typography>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} data-cy="user-menu-button">
                <CustomAvatar user={user.userInfo} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {UserMenuItems.map((item) => (
            <MenuItem key={item.title} component={Link} to={item.link} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{item.title}</Typography>
            </MenuItem>
          ))}
          <MenuItem
            key="logoutItem"
            onClick={() => {
              handleCloseUserMenu();
              logout(user.userInfo.username, clearUserInfo);
            }}
            data-cy="logout-item"
          >
            <Typography textAlign="center">Log Out</Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
