import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ContactsIcon from '@mui/icons-material/Contacts';
import HomeIcon from '@mui/icons-material/Home';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CalendarMonthIcon from '@mui/icons-material/CalendarToday';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import InboxIcon from '@mui/icons-material/Inbox';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

/**
 * For addon page sidebar items, must include `addonControl` property.
 *
 * `addonControl` = 'collaboration' | 'engagement' | 'trackerAI' (update if more addons)
 */
export const SidebarItems = [
  {
    title: 'Service Log',
    icon: <AssessmentIcon />,
    link: RouteInformation.serviceLog.path,
    accessibility: RouteInformation.serviceLog.privilegeRequirement,
  },
  {
    title: 'Media Interactions',
    icon: <HomeIcon />,
    link: RouteInformation.mediaInteraction.path,
    accessibility: RouteInformation.mediaInteraction.privilegeRequirement,
  },
  {
    title: 'Engagement Log',
    icon: <RecordVoiceOverIcon />,
    link: RouteInformation.engagementLog.path,
    accessibility: RouteInformation.engagementLog.privilegeRequirement,
    addonControl: 'engagement'
  },
  {
    title: 'Calendar',
    icon: <CalendarMonthIcon />,
    link: RouteInformation.calendar.path,
    accessibility: RouteInformation.calendar.privilegeRequirement,
  },
  {
    title: 'Contacts',
    icon: <ContactsIcon />,
    link: RouteInformation.contacts.path,
    accessibility: RouteInformation.contacts.privilegeRequirement,
  },
  {
    title: 'Team Analytics',
    icon: <DashboardIcon />,
    link: RouteInformation.teamAnalytics.path,
    accessibility: RouteInformation.teamAnalytics.privilegeRequirement,
  },
  {
    title: 'Custom Analytics',
    icon: <AnalyticsIcon />,
    link: RouteInformation.customAnalytics.path,
    accessibility: RouteInformation.customAnalytics.privilegeRequirement,
  },
  {
    title: 'Report Generation',
    icon: <EmailIcon />,
    link: RouteInformation.report.path,
    accessibility: RouteInformation.report.privilegeRequirement,
  },
  {
    title: 'Collaborate',
    icon: <GroupsIcon />,
    link: RouteInformation.collaboration.path,
    accessibility: RouteInformation.collaboration.privilegeRequirement,
    addonControl: 'collaboration',
  },
  {
    title: 'AutoTracker',
    icon: <InboxIcon />,
    link: RouteInformation.autoTracker.path,
    accessibility: RouteInformation.autoTracker.privilegeRequirement,
    addonControl: 'trackerAI',
  },
  {
    title: 'Admin',
    icon: <AdminPanelSettingsIcon />,
    link: RouteInformation.admin.path,
    accessibility: RouteInformation.admin.privilegeRequirement,
  },
  {
    title: 'Broadsight Admin',
    icon: <SupervisorAccountIcon />,
    link: RouteInformation.tenants.path,
    accessibility: RouteInformation.tenants.privilegeRequirement,
  },
  {
    title: 'Feedback',
    icon: <FeedbackIcon />,
    link: RouteInformation.feedback.path,
    accessibility: RouteInformation.feedback.privilegeRequirement,
  },
];
