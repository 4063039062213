import { Badge, Button, Tooltip } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useEffect, useState } from 'react';
import { useFilters, useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import TrackerFilterPopover from '../TrackerFilter/TrackerFilterPopover';
import { API, TrackerDataType } from '../../shared/constants/constants';
import { useLocation } from 'react-router-dom';
import { useAtom } from 'jotai/index';
import { needRefetchKeywordSearchedDataAtom } from '../../shared/atoms/filterAtoms';
import makeStyles from '@mui/styles/makeStyles';

interface TrackerTableFilterProps {
  setDataCallback: (data: any) => void;
  eventHandler: any;
  currMonth: string;
}

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
  },
});

export default function TrackerTableFilter(props: TrackerTableFilterProps) {
  const classes = useStyles();
  const { state } = useLocation();
  const [needRefetchKeywordSearchedData, setNeedRefetchKeywordSearchedData] = useAtom(
    needRefetchKeywordSearchedDataAtom
  );

  const { setDataCallback, eventHandler, currMonth } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [inProgress, setInProgress] = useState(false);

  let componentColor: 'mediaInteraction' | 'primary' | 'primaryLighter';
  switch (eventHandler.type) {
    case TrackerDataType.MEDIA_INTERACTION:
      componentColor = 'mediaInteraction';
      break;
    case TrackerDataType.ENGAGEMENT:
      componentColor = 'primaryLighter';
      break;
    default:
      componentColor = 'primary';
      break;
  }

  const filters = useFilters();
  const dispatch = useFiltersDispatch();

  async function globalSearch(state: any, delayInMilliseconds: number) {
    if (state && Object.keys(state).length > 0) {
      setDataCallback(null);
      let domain: string;
      switch (eventHandler.type) {
        case TrackerDataType.MEDIA_INTERACTION:
          domain = 'issues';
          break;
        case TrackerDataType.ENGAGEMENT:
          domain = 'engagements';
          break;
        default:
          domain = 'services';
          break;
      }

      // There is a race condition that happens when you reload the table (by updating/deleting/adding issues/services) while
      // keyword filters are on. You can add a delay in that case to minimize occurrences of that race condition.
      // Note: if delay is 0, we remove setTimeout immediately because setTimeout(()=>{}, 0) still has delay.
      if (delayInMilliseconds > 0) {
        setTimeout(async () => {
          await API.get(`/${domain}/fuzzySearch?queryString=${state.queryString}&category=${state.category}`)
            .then((res) => {
              const filteredIssues = res.data.data || null;

              setDataCallback(filteredIssues);
            })
            .catch((err) => {
              alert(err);
              setDataCallback(null);
            });
        }, delayInMilliseconds);
      } else {
        await API.get(`/${domain}/fuzzySearch?queryString=${state.queryString}&category=${state.category}`)
          .then((res) => {
            const filteredIssues = res.data.data || null;

            setDataCallback(filteredIssues);
          })
          .catch((err) => {
            alert(err);
            setDataCallback(null);
          });
      }
    }
  }

  const getFilterButtonIconColor = () => {
    const filterKeysCount = Object.keys(filters).length;
    if (filterKeysCount > 0) {
      return componentColor;
    } else {
      return 'whiteScale';
    }
  };

  const handleFiltersButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFiltersPopoverClose = () => {
    setAnchorEl(null);
  };

  const shouldDisplayClearAllButton = () => {
    return Object.keys(filters).length > 0;
  };

  const handleClearAllFilters = () => {
    setDataCallback(null);
    dispatch({ type: 'setFilters', payload: {} });
  };

  useEffect(() => {
    if (state?.queryString) {
      globalSearch(state, 0);
    }
    //ignoring globalSearch in dep array
    //eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (state?.queryString && needRefetchKeywordSearchedData) {
      globalSearch(state, 1500);
    }
    setNeedRefetchKeywordSearchedData(false);
    //ignoring globalSearch, setNeedRefetchKeywordSearchedData in dep array
    //eslint-disable-next-line
  }, [state, needRefetchKeywordSearchedData]);

  return (
    <>
      <Badge className={classes.fullHeight} badgeContent={Object.keys(filters).length} color="error">
        <Button
          color="whiteScale"
          variant="outlined"
          onClick={handleFiltersButtonClick}
          startIcon={<TuneIcon color={getFilterButtonIconColor()} />}
          data-cy="tracker-filter-btn"
        >
          Filters
        </Button>
      </Badge>
      <Tooltip title={'My Entries In Progress'} placement="top" enterDelay={500}>
        <Button
          className={classes.fullHeight}
          color="whiteScale"
          variant="outlined"
          onClick={() => setInProgress(true)}
          startIcon={<TaskAltIcon color={getFilterButtonIconColor()} />}
          data-cy="tracker-table-filter-in-progress-btn"
        >
          In Progress
        </Button>
      </Tooltip>
      {shouldDisplayClearAllButton() && (
        <Button
          className={classes.fullHeight}
          variant="text"
          color={componentColor}
          onClick={() => handleClearAllFilters()}
          data-cy="tracker-filter-clear-all-btn"
        >
          Clear All Filters
        </Button>
      )}
      <TrackerFilterPopover
        anchorEl={anchorEl}
        onClose={handleFiltersPopoverClose}
        setDataCallback={setDataCallback}
        eventHandler={eventHandler}
        currMonth={currMonth}
        inProgress={inProgress}
        inProgressCallback={setInProgress}
      />
    </>
  );
}
