import { SharedInteractionsTableProps } from './Types/componentProps';
import { Paper, TableContainer, TableBody, useTheme } from '@mui/material';
import TrackerTableHeaderRow from '../TrackerTable/TrackerTableHeaderRow';
import Table from '@mui/material/Table';
import { visibleShareIssueCols } from './Utils/constants';
import { getBackgroundColor, TrackerDataType } from '../../shared/constants/constants';
import makeStyles from '@mui/styles/makeStyles';
import { cellColors } from '../../shared/constants/commonCellColors';
import TablePagination from '@mui/material/TablePagination';
import SharedInteractionsDataRow from './SharedInteractionsDataRow';
import React, { useEffect, useState } from 'react';
import { SharedCollaborationGroupInteractionsDataIssue } from './Types/apiData';
import Spinner from 'react-bootstrap/Spinner';

const { MEDIA_INTERACTION } = TrackerDataType;
const useStyles = makeStyles(({ palette }) => ({
  tableContainer: {
    borderRadius: '10px',
    width: '100%',
    border: `2px solid #B1B9C9`,
    margin: '0 auto',
    maxHeight: '75vh',
    marginTop: '10px',
  },
  table: {
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    maxWidth: 210,
  },
  ...cellColors,
  input: {
    width: 130,
    height: 40,
  },
  cell: {
    maxWidth: 150,
  },
  label: {
    minWidth: 150,
  },
  dataCell: {
    padding: '6px',
    textAlign: 'center',
    height: '109px',
  },
  pagination: {
    marginRight: '2%',
  },
}));

const SharedInteractionsTable = (props: SharedInteractionsTableProps) => {
  const { issues, totalCount, page, pageSize, onPageChange, onPageSizeChange } = props;
  const theme = useTheme();
  const classes = useStyles();

  const [paginatedRows, setPaginatedRows] = useState<SharedCollaborationGroupInteractionsDataIssue[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const timeout = setTimeout(() => {
      const startIndex = page * pageSize;
      const endIndex = startIndex + pageSize;
      setPaginatedRows(issues.slice(startIndex, endIndex));
      setIsLoading(false);
    }, 150);

    return () => clearTimeout(timeout);
  }, [issues, page, pageSize]);

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader className={classes.table}>
          <TrackerTableHeaderRow
            columns={visibleShareIssueCols}
            type={MEDIA_INTERACTION}
            tabColor={getBackgroundColor(MEDIA_INTERACTION, theme)}
          />
          <TableBody>
            {paginatedRows.map((issue, index) => (
              <SharedInteractionsDataRow key={issue.id} issue={issue} rowIndex={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 150]}
        component="div"
        count={totalCount}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={(_event, page) => onPageChange(page)}
        onRowsPerPageChange={(event) => onPageSizeChange(parseInt(event.target.value, 10))}
        className={classes.pagination}
      />
    </>
  );
};

export default SharedInteractionsTable;
