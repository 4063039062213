import { ListItem, Typography, Checkbox, Stack, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import MergeIcon from '@mui/icons-material/Merge';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { useArchiveItem } from './Hooks/useArchiveItem';
import { cellColors } from '../../shared/constants/commonCellColors';
import isTargetMergingItem from '../../shared/functions/isSelectedContact';
import { IssueAndServiceSharedValues } from '../../shared/constants/IssueAndServiceKeys';

const EnumListTypeItem = ({
  targetMergingItem,
  enumItem,
  isMerging,
  selectedItems,
  handleCheckboxChange,
  setIsEditing,
  setSelectedEnum,
  setEnumModalOpen,
  setIsMerging,
  isArchivedItem,
  typesImportantForAnalytics,
  whatIsBeingMergedForTypes,
  setWhatIsBeingMergedForTypes,
}) => {
  const archiveItemMutation = useArchiveItem(enumItem, IssueAndServiceSharedValues.type);

  const handleArchiveClick = async () => {
    await archiveItemMutation.mutateAsync();
  };

  // References the choices made in client\src\shared\functions\cellStyling.jsx
  const typeBackgroundColorOptionsToCellColor = {
    Green: cellColors.support.background,
    Red: cellColors.issue.background,
  };

  const shouldBeDisabled = isTargetMergingItem(targetMergingItem, enumItem);

  return (
    <ListItem
      divider={true}
      sx={{
        alignContent: 'space-between',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: typeBackgroundColorOptionsToCellColor[enumItem.backgroundColor],
      }}
      disabled={shouldBeDisabled}
    >
      <Stack direction={'column'}>
        {typesImportantForAnalytics.includes(enumItem.name) ? (
          <Tooltip
            title="This type is important for analytics. Some editing restrictions are in place for this type so that it is irremovable."
            placement="bottom-start"
          >
            <Typography sx={{ color: 'primaryDarker.main' }}>{enumItem.name}</Typography>
          </Tooltip>
        ) : (
          <Typography>{enumItem.name}</Typography>
        )}
        <Typography sx={{ opacity: 0.6 }}>{enumItem.proactivity ?? ''}</Typography>
      </Stack>

      {isMerging ? (
        whatIsBeingMergedForTypes === 'Types' && (
          <Checkbox
            disabled={typesImportantForAnalytics.includes(enumItem.name) || shouldBeDisabled}
            checked={selectedItems.has(enumItem)}
            onChange={(event) => handleCheckboxChange(event, enumItem)}
          />
        )
      ) : (
        <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center', marginLeft: '5%' }}>
          <Tooltip title="Edit">
            <IconButton
              size="small"
              onClick={() => {
                setIsEditing(true);
                setSelectedEnum(enumItem);
                setEnumModalOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Merge Types">
            <IconButton
              size="small"
              onClick={() => {
                setSelectedEnum(enumItem);
                setWhatIsBeingMergedForTypes('Types');
                setIsMerging(true);
              }}
            >
              <MergeIcon />
            </IconButton>
          </Tooltip>

          {isArchivedItem ? (
            <Tooltip title="Unarchive">
              <IconButton size="small" onClick={handleArchiveClick}>
                <UnarchiveIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Archive">
              <IconButton size="small" onClick={handleArchiveClick}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      )}
    </ListItem>
  );
};

export default EnumListTypeItem;
