import { Box, Card, CardContent, Collapse, Grid, TableCell, TableRow } from '@mui/material';
import {
  visibleShareIssueCols,
  visibleShareIssueDetailedCols,
  visibleShareIssueDetailedWideCols,
} from './Utils/constants';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { paletteColorsHeaderMain, TrackerDataType } from '../../shared/constants/constants';
import CellViewer from '../Cells/CellViewer';
import { SharedInteractionsCollapsedDataRowProps } from './Types/componentProps';
import { IssueKeys } from '../../shared/constants/IssueAndServiceKeys';

const useStyles = (tabColor: string) => {
  return makeStyles(({ palette }) => ({
    hiddenCellCard: {
      flex: 1,
      alignSelf: 'flex-start',
      margin: '0',
      textAlign: 'center',
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
    },
    cardContent: {
      padding: '0 !important',
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none',
    },
    cardName: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: palette.whiteScaleDarker.main,
      padding: 8,
      color: palette.whiteScaleDarker.contrastText,
      fontWeight: 400,
    },
    cardData: {
      padding: 5,
      wordWrap: 'break-word',
    },
    wideColContainer: {
      flex: 2,
      display: 'flex',
      width: '50%',
      flexBasis: '15vw',
      flexDirection: 'row',
    },
    fixedWideColContainer: {
      flex: 2,
      display: 'flex',
      width: '75%',
      flexDirection: 'row',
    },
    colContainer: {
      flex: 1,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      width: '50%',
      flexDirection: 'row',
    },
    collapseWrapperInner: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      width: '100%',
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)',
    },
  }))();
};

const SharedInteractionsCollapsedDataRow = (props: SharedInteractionsCollapsedDataRowProps) => {
  const { collapsed, issueCells } = props;

  const classes = useStyles(paletteColorsHeaderMain[0]);

  const renderDetailedViewCards = (col: IssueKeys) => {
    let xs = 2;
    if (visibleShareIssueDetailedWideCols.includes(col)) {
      xs = 4;
    }
    return (
      <Grid item xs={xs}>
        <Card className={classes.hiddenCellCard}>
          <CardContent className={classes.cardContent}>
            <Box className={classes.cardName}>{col}</Box>
            <Box sx={{ padding: 6, minHeight: '5vh' }}>
              <CellViewer
                type={TrackerDataType.MEDIA_INTERACTION}
                cells={issueCells}
                index={col}
                callback={() => {}}
                dropdownTable={null}
                openForm={() => {}}
                classes={classes}
                id={null}
                handleDrag={() => {}}
                handleDrop={() => {}}
                isDragActive={() => {}}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  if (!collapsed) {
    return null;
  }

  return (
    <TableRow sx={{ display: collapsed ? 'table-row' : 'none', padding: '4px' }}>
      <TableCell
        colSpan={visibleShareIssueCols.length + 2}
        sx={{ padding: '10px', paddingLeft: '20px', paddingRight: '15px' }}
      >
        <Collapse in={collapsed} timeout={250} classes={{ wrapperInner: classes.collapseWrapperInner }}>
          <Grid container spacing={0}>
            {visibleShareIssueDetailedCols.map(renderDetailedViewCards)}
            {visibleShareIssueDetailedWideCols.map(renderDetailedViewCards)}
          </Grid>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default SharedInteractionsCollapsedDataRow;
