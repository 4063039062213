import { useEffect, useState } from 'react';
import { transitionTime, socketEvents, TrackerDataType } from '../constants/constants';
import { IssueKeys } from '../constants/IssueAndServiceKeys';
import { htmlToText } from 'html-to-text';
import dayjs from '../../utils/dayjs';

const MediaInteractionEventHandler = (socket) => {
  return {
    type: TrackerDataType.MEDIA_INTERACTION,
    newItemModalTitle: 'Add a New Media Interaction',
    createNewItem: async (fields, files, successCallback) => {
      socket.emit(socketEvents.EVENT_NEW_ISSUE, { data: fields, undo: false, files: files }, successCallback);
    },
    // use as a React Hook
    useListenOnCreation: (rowId) => {
      const [isNewlyAdded, setIsNewlyAdded] = useState(false);

      useEffect(() => {
        let timeout;
        const listener = ({ issue }) => {
          if (issue._id === rowId) {
            setIsNewlyAdded(true);
            timeout = setTimeout(() => {
              setIsNewlyAdded(false);
            }, transitionTime);
          }
        };
        socket.on(socketEvents.EVENT_ISSUE_ADDED, listener);

        return () => {
          socket.off(socketEvents.EVENT_ISSUE_ADDED, listener);
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }, [rowId]);

      return { isNewlyAdded, setIsNewlyAdded };
    },
    editItem: (val, index) => {
      let newVal;
      if (!val) {
        newVal = null;
      } else {
        switch (index) {
          case IssueKeys.type:
            {
              let array = [];
              val.forEach((obj) => array.push(obj));
              newVal = array;
            }
            break;
          case IssueKeys.linkedService:
            newVal = val.filter((item) => item !== null);
            break;
          case IssueKeys.lead:
            {
              let array = [];
              val.forEach((obj) => array.push(obj));
              newVal = array;
            }
            break;
          case IssueKeys.outlet:
            newVal = val._id;
            //TODO cancel journalist when outlet changed
            break;
          case IssueKeys.journalist:
            newVal = val._id;
            break;
          case IssueKeys.topic:
          case IssueKeys.response:
          case IssueKeys.keyMessaging:
          case IssueKeys.date:
            newVal = val;
            break;
          case IssueKeys.expert:
            newVal = val;
            break;
          case IssueKeys.units:
          case IssueKeys.campaign:
            let arr = [];
            val.forEach((obj) => arr.push(obj));
            newVal = arr;
            break;
          case IssueKeys.file:
            newVal = val;
            break;
          case IssueKeys.dept:
            newVal = val.map((c) => c.name);
            break;
          default:
            newVal = val.name;
            break;
        }
      }
      return newVal;
    },
    updateItem: async (updatedIssue, successCallback) => {
      socket.emit(socketEvents.EVENT_REQ_UPDATE_ISSUE, { data: updatedIssue }, successCallback);
    },
    deleteItem: async (id) => {
      socket.emit(socketEvents.EVENT_REQ_DELETE_ISSUE, id);
    },
    undoDelete: async (data) => {
      try {
        socket.emit(socketEvents.EVENT_NEW_ISSUE, { data: data, undo: true });
      } catch (e) {
        alert(e.message);
      }
    },
    monthlyView: async (currMonth) => {
      socket.emit(socketEvents.EVENT_REQ_MONTHLY_ISSUES, {
        month: currMonth,
        timezoneOffset: dayjs.tz.guess(),
      });
    },
    flattenForExport: (data, dropdownTable) => {
      return data.map((row) => {
        try {
          let flatRow = JSON.parse(JSON.stringify(row.cells));

          flatRow[IssueKeys.type] = flatRow[IssueKeys.type].map((c) => `${c.type}:${c.name}`).join(', \n');

          let obj;
          flatRow[IssueKeys.expert] = flatRow[IssueKeys.expert]
            .map((le) => dropdownTable['expert'].find((dropdownle) => dropdownle._id === le._id)?.name ?? '')
            .join(', \n');

          flatRow[IssueKeys.lead] = flatRow[IssueKeys.lead]
            .map((leadId) => dropdownTable['lead'].find((lead) => lead._id === leadId)?.name ?? null)
            .filter((leadName) => leadName !== null)
            .join(', ');

          flatRow[IssueKeys.linkedService] = flatRow[IssueKeys.linkedService].map((c) => c.name);

          obj = dropdownTable['journalist'].find((journalist) => journalist['_id'] === flatRow[IssueKeys.journalist]);
          flatRow[IssueKeys.journalist] = obj ? obj.name : null;
          flatRow['Journalist Email'] = obj ? obj.email : null;
          flatRow['Journalist Phone'] = obj ? obj.phone : null;

          obj = dropdownTable['outlet'].find((org) => org['_id'] === flatRow[IssueKeys.outlet]);
          flatRow[IssueKeys.outlet] = obj ? obj.name : null;

          flatRow[IssueKeys.file] = flatRow[IssueKeys.file].map((file) => {
            if (file.type === 'url') {
              return file.path;
            } else {
              return file.name;
            }
          });
          flatRow[IssueKeys.campaign] = flatRow[IssueKeys.campaign].map((campaign) => `${campaign.name}`).join(', \n');

          let messaging = flatRow[IssueKeys.keyMessaging] ? flatRow[IssueKeys.keyMessaging] : '';
          messaging += flatRow['Field Addons']?.keyMessaging ? flatRow['Field Addons'].keyMessaging : '';
          messaging = htmlToText(messaging, { wordwrap: null });
          messaging = messaging.replace(/"/g, '""');
          flatRow[IssueKeys.keyMessaging] = messaging;

          let response = flatRow[IssueKeys.response] ? flatRow[IssueKeys.response] : '';
          response += flatRow['Field Addons']?.response ? flatRow['Field Addons'].response : '';
          response = htmlToText(response, { wordwrap: null });
          response = response.replace(/"/g, '""');
          flatRow[IssueKeys.response] = response;

          delete flatRow['Field Addons'];

          return flatRow;
        } catch {
          return {
            [IssueKeys.date]: '',
            [IssueKeys.contactMethod]: '',
            [IssueKeys.topic]: 'error flattening data for export!',
            [IssueKeys.type]: '',
            [IssueKeys.units]: '',
            [IssueKeys.expert]: '',
            [IssueKeys.outlet]: '',
            [IssueKeys.journalist]: '',
            'Journalist Email': '',
            'Journalist Phone': '',
            [IssueKeys.campaign]: '',
            [IssueKeys.lead]: '',
            [IssueKeys.response]: '',
            [IssueKeys.file]: '',
            [IssueKeys.status]: '',
            [IssueKeys.dept]: '',
            [IssueKeys.keyMessaging]: '',
            [IssueKeys.linkedService]: '',
          };
        }
      });
    },
  };
};

export default MediaInteractionEventHandler;
