import makeStyles from '@mui/styles/makeStyles';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { SidebarItems } from './SidebarItems';
import { useLocation } from 'react-router-dom';
import { getTabColorHeaderMain } from '../../shared/constants/constants';
import { useSelector } from 'react-redux';
import { useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import React from 'react';
import { getSidebarItemAddonControlIfExists } from '../../utils/RouteAddonControlUtils';

const useStyles = makeStyles(({ palette }) => {
  return {
    sidebarItems: {
      height: '100%',
      padding: 0,
      width: '100%',
    },
    sidebarItem: {
      height: '60px',
      paddingLeft: '3px',
      width: '100%',
      listStyle: 'none',
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      // color: '#000000',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: ({ tabColor }) => tabColor,
        // color: palette.primary.contrastText,
        color: palette.primary.contrastText,
      },
      '&.selected': {
        backgroundColor: ({ tabColor }) => tabColor,
        color: palette.primary.contrastText,
      },
      '& #icon': {
        flex: '30%',
        '& > svg': {
          display: 'block',
          margin: 'auto',
        },
      },
      '& #title': {
        flex: '70%',
      },
    },
    drawer: {
      top: 50,
      width: 250,
    },
  };
});

function Sidebar(props) {
  const { pathname } = useLocation();

  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColorHeaderMain(pathname, theme) });
  const { open, setOpen } = props;

  const user = useSelector((state) => state.user.userInfo);
  const userAddonControl = useSelector((state) => state.user.addonControls);
  const dispatch = useFiltersDispatch();

  const items = SidebarItems.map((val, key) => {
    if (val.accessibility <= user.privilege && getSidebarItemAddonControlIfExists(val, userAddonControl)) {
      if (val.title === 'Feedback') {
        return (
          <React.Fragment key={key}>
            <ListItemButton
              component="a"
              href={val.link} // Set the href to the desired link
              onClick={() => {
                dispatch({ type: 'setFilters', payload: {} });
                setOpen(false);
              }}
              className={`${classes.sidebarItem} ${window.location.pathname === val.link ? 'selected' : ''}`}
            >
              <ListItemIcon style={{ paddingLeft: '10px' }}>{val.icon}</ListItemIcon>
              <ListItemText primary={val.title} />
            </ListItemButton>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={key}>
            <ListItemButton
              component={Link}
              to={val.link}
              onClick={() => {
                dispatch({ type: 'setFilters', payload: {} });
                setOpen(false);
              }}
              selected={window.location.pathname === val.link}
              className={`${classes.sidebarItem} ${window.location.pathname === val.link ? 'selected' : ''}`}
            >
              <ListItemIcon style={{ paddingLeft: '10px' }}>{val.icon}</ListItemIcon>
              <ListItemText primary={val.title} />
            </ListItemButton>
          </React.Fragment>
        );
      }
    } else {
      return <React.Fragment key={key}></React.Fragment>;
    }
  });

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.drawer }}
      SlideProps={{ timeout: 200 }}
      sx={{
        height: '100%',
        padding: 0,
        width: '100%',
      }}
    >
      <List>{items}</List>
    </Drawer>
  );
}

export default Sidebar;
