import { Box, Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import { mediaToneOptions, Tone, ToneColorMap } from '../constants/constants';
import { ToneColors } from '../types';
import PositiveIcon from '../../components/TrackerFilter/Icons/PositiveIcon';
import NeutralIcon from '../../components/TrackerFilter/Icons/NeutralIcon';
import NegativeIcon from '../../components/TrackerFilter/Icons/NegativeIcon';

interface ToneInputSelectionProps {
  selectedTones?: Tone[];
  onSelect?: (selectedTones: Tone[]) => void;
  multiSelect?: boolean;
}

export default function ToneInputSelection(props: ToneInputSelectionProps) {
  const { selectedTones, onSelect, multiSelect } = props;
  const [localSelectedTones, setLocalSelectedTones] = useState<Tone[]>([]);
  const [chipOutlineStyle, setChipOutlineStyle] = useState<Record<Tone, any>>({
    [Tone.POSITIVE]: {},
    [Tone.NEGATIVE]: {},
    [Tone.NEUTRAL]: {},
  });
  const [chipColor, setChipColor] = useState<Record<Tone, ToneColors | 'default'>>({
    [Tone.POSITIVE]: 'default',
    [Tone.NEGATIVE]: 'default',
    [Tone.NEUTRAL]: 'default',
  });

  useEffect(() => {
    setLocalSelectedTones(selectedTones ?? []);
  }, [selectedTones]);

  useEffect(() => {
    setChipOutlineStyle({
      [Tone.POSITIVE]: getChipOutlineStyle(Tone.POSITIVE),
      [Tone.NEGATIVE]: getChipOutlineStyle(Tone.NEGATIVE),
      [Tone.NEUTRAL]: getChipOutlineStyle(Tone.NEUTRAL),
    });
    setChipColor({
      [Tone.POSITIVE]: getChipColor(Tone.POSITIVE),
      [Tone.NEGATIVE]: getChipColor(Tone.NEGATIVE),
      [Tone.NEUTRAL]: getChipColor(Tone.NEUTRAL),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSelectedTones]);

  const getChipOutlineStyle = (toneName: Tone) => {
    const isSelected = localSelectedTones.some((selectedTone) => selectedTone === toneName);

    if (isSelected) {
      return { borderWidth: '4px' };
    } else {
      return {};
    }
  };

  const getChipColor = (toneName: Tone) => {
    const isSelected = localSelectedTones.some((selectedTone) => selectedTone === toneName);

    if (isSelected) {
      return ToneColorMap[toneName];
    } else {
      return 'default';
    }
  };

  const getChipIcon = (toneName: Tone) => {
    if (toneName === Tone.POSITIVE) {
      return <PositiveIcon />;
    } else if (toneName === Tone.NEGATIVE) {
      return <NegativeIcon />;
    } else if (toneName === Tone.NEUTRAL) {
      return <NeutralIcon />;
    }
  };

  const handleChipClick = (toneName: Tone) => {
    const isSelected = localSelectedTones.some((selectedTone) => selectedTone === toneName);

    let selectedChips: Tone[] = [];
    if (isSelected) {
      selectedChips = localSelectedTones.filter((selectedChip) => selectedChip !== toneName);
    } else if (multiSelect) {
      selectedChips = [...localSelectedTones, toneName];
    } else {
      selectedChips = [toneName];
    }

    setLocalSelectedTones(selectedChips);

    if (onSelect) {
      onSelect(selectedChips);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '20px' }}>
      {mediaToneOptions.map((toneObj, index) => (
        <Chip
          sx={{
            ...chipOutlineStyle[toneObj.name],
            '& .MuiChip-label': { color: 'rgba(0, 0, 0, 0.87)' },
          }}
          color={chipColor[toneObj.name]}
          variant="outlined"
          key={`${toneObj.name}-${index}`}
          label={toneObj.name}
          onClick={() => handleChipClick(toneObj.name)}
          icon={getChipIcon(toneObj.name)}
          data-cy={`tone-${toneObj._id}`}
        />
      ))}
    </Box>
  );
}
