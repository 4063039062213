import { Box, TextField } from '@mui/material';
import TrackerFilterPopoverGrid from './TrackerFilterPopoverGrid';
import makeStyles from '@mui/styles/makeStyles';

interface TrackerFilterTextFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '32px',
  },
});

export default function TrackerFilterTextField(props: TrackerFilterTextFieldProps) {
  const classes = useStyles();
  const { label, value, onChange } = props;

  return (
    <TrackerFilterPopoverGrid>
      {label}
      <Box className={classes.container}>
        <TextField
          fullWidth
          size="smallCustom"
          variant="outlined"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          data-cy={`tracker-filter-text-field-${label}`}
        />
      </Box>
    </TrackerFilterPopoverGrid>
  );
}
