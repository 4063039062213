import { SvgIconProps, SvgIcon, useTheme } from '@mui/material';

export default function EmailIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 18 16" sx={{ color: theme.palette.primaryDefault.main }} {...props}>
      <path d="M3 3.5C2.71875 3.5 2.5 3.75 2.5 4V4.71875L7.875 9.125C8.53125 9.65625 9.4375 9.65625 10.0938 9.125L15.5 4.71875V4C15.5 3.75 15.25 3.5 15 3.5H3ZM2.5 6.65625V12C2.5 12.2812 2.71875 12.5 3 12.5H15C15.25 12.5 15.5 12.2812 15.5 12V6.65625L11.0625 10.2812C9.84375 11.2812 8.125 11.2812 6.9375 10.2812L2.5 6.65625ZM1 4C1 2.90625 1.875 2 3 2H15C16.0938 2 17 2.90625 17 4V12C17 13.125 16.0938 14 15 14H3C1.875 14 1 13.125 1 12V4Z" />
    </SvgIcon>
  );
}
