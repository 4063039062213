import dayjs from 'dayjs';

export function getCurrMonth() {
  return dayjs().format('YYYY-M');
}

export function getPrevMonth(currMonth) {
  return dayjs(currMonth, 'YYYY-M').subtract(1, 'month').format('YYYY-M');
}

export function getNextMonth(currMonth) {
  return dayjs(currMonth, 'YYYY-M').add(1, 'month').format('YYYY-M');
}

export function monthYearToReadableString(currMonth) {
  return dayjs(currMonth, 'YYYY-M').format('MMMM YYYY');
}

export function getMMMMYYYYFromString(str) {
  let monthDayjs = dayjs(str);
  if (!monthDayjs.isValid()) {
    monthDayjs = dayjs();
  }

  return monthDayjs.format('MMMM YYYY');
}

export function getYYYYMFromString(str, offset) {
  let monthDayjs = dayjs(str);
  if (!monthDayjs.isValid()) {
    monthDayjs = dayjs();
  }

  return monthDayjs.add(offset, 'months').format('YYYY-M');
}
