import { API } from '../../../shared/constants/constants';
import {
  CreateCollaborationGroupAPIData,
  CreateOrUpdateCollaborationGroupReqData,
  CreateOrUpdateCollaborationGroupData,
  GetCollaborationGroupDetailsAPIData,
  ListCollaborationGroupAPIData,
  UpdateCollaborationGroupReqData,
  PromoteGroupOwnerReq,
  RemoveGroupCollaboratorReq,
  UpsertCollaborationGroupSharedResourcesFilterReq,
  RemoveCollaborationGroupSharedResourcesFilterReq,
  SharedResourceType,
  SharedCollaborationGroupInteractionsData,
  UpdateCollaborationGroupSharedContactsRequestParams,
  ListCollaborationGroupSharedContactsAPIData,
  ListSharedInteractionsQuickSearchParams,
} from '../Types/apiData';

export const listCollaborationGroups = async () => {
  const res = await API.get<ListCollaborationGroupAPIData[]>('/collaboration/groups');
  return res.data;
};

export const getCollaborationGroupDetails = async (groupId: string) => {
  const res = await API.get<GetCollaborationGroupDetailsAPIData>(`/collaboration/group/details/${groupId}`);
  return res.data;
};

export const createCollaborationGroup = async (data: CreateOrUpdateCollaborationGroupReqData) => {
  const res = await API.post<CreateCollaborationGroupAPIData>('/collaboration/group', data);
  return res.data;
};

export const updateCollaborationGroup = async ({ groupId, data }: UpdateCollaborationGroupReqData) => {
  const res = await API.put<CreateOrUpdateCollaborationGroupData>(`/collaboration/group/${groupId}`, data);
  return res.data;
};

export const disbandCollaborationGroup = async (groupId: string) => {
  const res = await API.delete(`/collaboration/group/${groupId}`);
  return res.data;
};

export const quitCollaborationGroup = async (groupId: string) => {
  const res = await API.put(`/collaboration/group/${groupId}/quit`);
  return res.data;
};

export const promoteCollaborationGroupOwner = async ({ groupId, data }: PromoteGroupOwnerReq) => {
  const res = await API.put(`/collaboration/group/${groupId}/promote-owners`, data);
  return res.data;
};

export const removeCollaborationGroupCollaborator = async ({ groupId, data }: RemoveGroupCollaboratorReq) => {
  const res = await API.put(`/collaboration/group/${groupId}/remove-members`, data);
  return res.data;
};

export const upsertCollaborationGroupSharedResourcesFilter = async ({
  groupId,
  data,
}: UpsertCollaborationGroupSharedResourcesFilterReq) => {
  const res = await API.put(`/collaboration/group/${groupId}/shared-resource/filter`, data);
  return res.data;
};

export const removeCollaborationGroupSharedResourcesFilter = async ({
  groupId,
  resourceType,
}: RemoveCollaborationGroupSharedResourcesFilterReq) => {
  const res = await API.delete(`/collaboration/group/${groupId}/shared-resource/filter/${resourceType}`);
  return res.data;
};

const getListCollaborationGroupSharedResourcesQuickSearchParams = (
  quickSearchParams: ListSharedInteractionsQuickSearchParams
) => {
  const keys = Object.keys(quickSearchParams);
  for (const key of keys) {
    const paramKey = key as keyof ListSharedInteractionsQuickSearchParams;
    if (quickSearchParams[paramKey]) {
      return `&${key}=${quickSearchParams[paramKey]}`;
    }
  }
  return '';
};

export const listCollaborationGroupSharedResources = async (
  groupId: string,
  resourceType: SharedResourceType,
  quickSearchParams: ListSharedInteractionsQuickSearchParams
) => {
  const quickSearchQuery = getListCollaborationGroupSharedResourcesQuickSearchParams(quickSearchParams);
  const res = await API.get<SharedCollaborationGroupInteractionsData>(
    `/collaboration/group/${groupId}/shared-resource/list/${resourceType}?${quickSearchQuery}`
  );
  return res.data;
};

export const updateCollaborationGroupSharedContacts = async ({
  groupId,
  contactType,
  toggle,
}: UpdateCollaborationGroupSharedContactsRequestParams) => {
  const res = await API.put(`/collaboration/group/${groupId}/shared-contacts/${contactType}?toggle=${toggle}`);
  return res.data;
};

export const listCollaborationGroupSharedContacts = async (
  groupId: string,
  contactType: string,
  category: string,
  query: string,
  page: number,
  pageNumber: number
) => {
  const res = await API.get<ListCollaborationGroupSharedContactsAPIData>(
    `/collaboration/group/${groupId}/shared-contacts/${contactType}?category=${category}&query=${query}&page=${page}&pageNumber=${pageNumber}`
  );
  return res.data;
};
