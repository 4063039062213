import { SvgIconProps, SvgIcon, useTheme } from '@mui/material';

export default function CommentIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 18 16" sx={{ color: theme.palette.primaryDefault.main }} {...props}>
      <path d="M6.25 12.0312C7.0625 12.3438 8 12.5 9 12.5C12.875 12.5 15.5 10 15.5 7.5C15.5 5.03125 12.875 2.5 9 2.5C5.09375 2.5 2.5 5.03125 2.5 7.5C2.5 8.5 2.875 9.46875 3.59375 10.3125C3.875 10.5938 4 11 3.96875 11.4062C3.9375 11.9688 3.78125 12.5 3.625 12.9375C4.15625 12.7188 4.59375 12.4375 4.84375 12.25C5.25 11.9375 5.78125 11.875 6.25 12.0312ZM1.8125 13.25C2.125 12.75 2.40625 12.0625 2.46875 11.2812C1.53125 10.2188 1 8.9375 1 7.5C1 3.9375 4.5625 1 9 1C13.4062 1 17 3.9375 17 7.5C17 11.0938 13.4062 14 9 14C7.84375 14 6.75 13.8125 5.75 13.4688C5.375 13.7188 4.75 14.0938 4.03125 14.4062C3.5625 14.625 3.03125 14.8125 2.46875 14.9062C2.46875 14.9062 2.4375 14.9375 2.40625 14.9375C2.28125 14.9688 2.125 14.9688 2 15H1.96875C1.8125 15 1.65625 15.0312 1.5 15.0312C1.28125 15.0312 1.09375 14.9062 1.03125 14.7188C0.9375 14.5312 1 14.3125 1.125 14.1562C1.25 14.0312 1.375 13.875 1.5 13.75C1.53125 13.6562 1.59375 13.5938 1.625 13.5312L1.65625 13.5C1.71875 13.4375 1.75 13.3438 1.8125 13.25ZM5.75 5.5H12.25C12.6562 5.5 13 5.84375 13 6.25C13 6.6875 12.6562 7 12.25 7H5.75C5.3125 7 5 6.6875 5 6.25C5 5.84375 5.3125 5.5 5.75 5.5ZM5.75 8.5H9.25C9.65625 8.5 10 8.84375 10 9.25C10 9.6875 9.65625 10 9.25 10H5.75C5.3125 10 5 9.6875 5 9.25C5 8.84375 5.3125 8.5 5.75 8.5Z" />
    </SvgIcon>
  );
}
