import { useEffect } from 'react';
import { refreshToken, refreshTokenHandler } from '../Authorization/refreshToken';
import { useDispatch, useSelector } from 'react-redux';
import * as UserActions from '../Authorization/userSlice';
import jwt_decode from 'jwt-decode';
import { Spinner } from 'react-bootstrap';
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import refreshCloudfrontCookies from '../../shared/functions/refreshCloudfrontCookies';
import { tokenRefreshTime, cloudfrontRefreshTime, userPrivilegeMap } from '../../shared/constants/constants';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { getUserMetaDataNonPIUserSettings } from '../../shared/functions/userFunctions';
import { validateTermsOfServiceAgreement } from '../Authorization/validateTermsOfServiceAgreement';
import { getAddonPageAccessControl } from '../../shared/requests/addonPageAccessControlRequests';
import { getUserBroadcastPopup } from '../../shared/requests/broadcastPopupRequests';

export default function Landing({ socketAuthenticated }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const loc = useLocation();

  useEffect(() => {
    const refreshTokenAsync = async () => {
      try {
        dispatch(UserActions.setIsFetching());
        const validated = await refreshToken();
        refreshCloudfrontCookies();

        if (validated) {
          //update user info in redux if successful api call
          const id_token = jwt_decode(sessionStorage.getItem('id_token'));
          const userInfo = id_token.mainUserAttributes;

          const { nonPIUserSettings } = await getUserMetaDataNonPIUserSettings(userInfo.sub);

          //update user data from id token
          const termsOfServiceAgreed = userInfo['custom:termsOfServiceAgreed'] || null;
          const userTenantId = userInfo['custom:tenantId'] || '';

          const addonPageAccessControls = await getAddonPageAccessControl();
          await validateTermsOfServiceAgreement(termsOfServiceAgreed, dispatch);
          const userBroadcastPopup = await getUserBroadcastPopup();

          dispatch(
            UserActions.setUserInfo({
              username: userInfo.username,
              email: userInfo.email,
              id: userInfo.sub,
              privilege: userPrivilegeMap[userInfo['cognito:groups'][0]] || 0,
              name: userInfo.name,
              avatar: userInfo.picture,
              nonPIUserSettings: nonPIUserSettings,
              termsOfServiceAgreed: termsOfServiceAgreed,
              tenantId: userTenantId,
              phone: userInfo.phone_number || 'Not Configured',
            })
          );
          dispatch(UserActions.setIsAuthenticated());
          dispatch(UserActions.setUserAddonPageAccessControls({ ...addonPageAccessControls }));
          dispatch(UserActions.setUserBroadcastPopup({ ...userBroadcastPopup }));

          console.log('authentication successful');
        }
      } catch (error) {
        console.error(error);
        window.location.replace(process.env.REACT_APP_COGNITO_URL);
      }
    };

    // Save url before authentication in case user ends up needing to login first
    const locFromPath = loc?.state?.from?.pathname ?? '';
    const locQueryParams = loc?.state?.from?.search ?? '';
    const locFullPath = locFromPath + locQueryParams;

    if (locFromPath) {
      localStorage.setItem('redirectUrlAfterLogin', locFullPath);
    }

    refreshTokenAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (socketAuthenticated && user.isAuthenticated) {
      localStorage.removeItem('redirectUrlAfterLogin');

      setTimeout(refreshTokenHandler, tokenRefreshTime); //start refresh token timer
      setTimeout(refreshCloudfrontCookies, cloudfrontRefreshTime);

      const locFromPath = loc?.state?.from?.pathname ?? '';
      const locQueryParams = loc?.state?.from?.search ?? '';
      const locFullPath = locFromPath + locQueryParams;

      if (locFromPath) {
        navigate(locFullPath);
      } else {
        navigate(RouteInformation.serviceLog.path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketAuthenticated, user.isAuthenticated, navigate]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner animation="border"></Spinner>
      <Typography>Please wait, logging you in to Broadsight Tracker</Typography>
    </div>
  );
}
