import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button, Typography } from '@mui/material';

export type DownloadCSVButtonUIColor = 'mediaInteraction' | 'primary' | 'primaryLighter';

interface DownloadCSVButtonUIProps {
  onClick: () => void;
  color: DownloadCSVButtonUIColor;
}

export default function DownloadCSVButtonUI(props: DownloadCSVButtonUIProps) {
  const { onClick, color } = props;

  return (
    <Button
      sx={{ height: '100%' }}
      variant="contained"
      color={color}
      startIcon={<SaveAltIcon />}
      onClick={() => onClick()}
    >
      <Typography>Download CSV</Typography>
    </Button>
  );
}
