import { SetupSharedMediaInteractionFilterDialogProps } from './Types/componentProps';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUpsertCollaborationGroupSharedResourcesFilterMutation } from './Hooks/useCollaborationGroup';
import { useQueryClient } from '@tanstack/react-query';
import FilterModalDateRangeInputs from '../TrackerFilter/FilterModalDateRangeInputs';
import { useContext, useMemo, useState } from 'react';
import {
  DropdownTableAutocompleteOptionCampaign,
  DropdownTableAutocompleteOptionExpert,
  DropdownTableAutocompleteOptionJournalist,
  DropdownTableAutocompleteOptionOutlet,
  DropdownTableAutocompleteOptionTeamMember,
  DropdownTableAutocompleteOptionType,
  DropdownTableAutocompleteOptionUnit,
  DropdownTableContext,
} from '../../shared/contexts/DropdownTableContext';
import dayjs from '../../utils/dayjs';
import { ServiceOrIssueStatus, TrackerDataType } from '../../shared/constants/constants';
import Grid from '@mui/material/Unstable_Grid2';
import TrackerFilterTextField from '../TrackerFilter/TrackerFilterTextField';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import TrackerFilterAutoCompleteSelection from '../TrackerFilter/TrackerFilterAutoCompleteSelection';
import { TrackerFilterAutocompleteGroupByFunc, TrackerFilterStatus } from '../TrackerFilter/types';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import makeStyles from '@mui/styles/makeStyles';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CardsIcon from '../TrackerFilter/Icons/CardsIcon';
import TrackerFilterStatusSelection from '../TrackerFilter/TrackerFilterStatusSelection';
import LaurelWreathIcon from '../TrackerFilter/Icons/LaurelWreathIcon';
import UsersIcon from '../TrackerFilter/Icons/UsersIcon';
import {
  getTrackerFilterAutoCompleteOptionsGroupByFunc,
  getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc,
} from '../TrackerFilter/trackerFilterHelpers';

interface DateFilterCriteria {
  timezone: string;
  start: Date;
  end: Date;
}

const useStyles = makeStyles({
  autoCompleteIcon: {
    fontSize: '20px',
  },
});

const SetupSharedMediaInteractionFilterDialog = ({
  open,
  onClose,
  groupId,
  sharedInteractionFilter,
}: SetupSharedMediaInteractionFilterDialogProps) => {
  const classes = useStyles();

  const queryClient = useQueryClient();
  const sharedMediaInteractionUpsertMutation = useUpsertCollaborationGroupSharedResourcesFilterMutation({
    onSuccessCallback: () => {
      queryClient.invalidateQueries({
        queryKey: ['collaboration/group-details'],
      });
      onClose();
    },
  });

  const dropdownTableContext = useContext(DropdownTableContext);

  const [topicFilterCriteria, setTopicFilterCriteria] = useState<string>(() => {
    if (!sharedInteractionFilter) {
      return '';
    }

    return sharedInteractionFilter.criteria.topic ? sharedInteractionFilter.criteria.topic : '';
  });

  const [dateFilterCriteria, setDateFilterCriteria] = useState<DateFilterCriteria>(() => {
    if (!sharedInteractionFilter) {
      const startOfLastMonth = dayjs().subtract(1, 'month').startOf('month').toDate();
      const endOfYesterday = dayjs().add(5, 'year').endOf('day').toDate();
      return {
        timezone: dayjs.tz.guess(),
        start: startOfLastMonth,
        end: endOfYesterday,
      };
    }

    const { timezone, start, end } = sharedInteractionFilter.criteria.dateFilter;
    return {
      timezone,
      start: dayjs(start).tz(timezone).toDate(),
      end: dayjs(end).tz(timezone).toDate(),
    };
  });

  const onDateFilterCriteriaStartChange = (date: Date) => {
    setDateFilterCriteria({
      ...dateFilterCriteria,
      start: dayjs(date).tz(dateFilterCriteria.timezone).startOf('day').toDate(),
    });
  };

  const onDateFilterCriteriaEndChange = (date: Date) => {
    setDateFilterCriteria({
      ...dateFilterCriteria,
      end: dayjs(date).tz(dateFilterCriteria.timezone).endOf('day').toDate(),
    });
  };

  const outletsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'outlet'),
    [dropdownTableContext]
  );
  const outletsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('outlet');
  const [outletFilter, setOutletFilter] = useState<DropdownTableAutocompleteOptionOutlet[]>(() => {
    if (!sharedInteractionFilter) {
      return [];
    }
    const outletIds = sharedInteractionFilter.criteria.outlets;
    if (!outletIds || outletIds.length === 0) {
      return [];
    }
    return outletsFilterOptions.filter((outlet): outlet is DropdownTableAutocompleteOptionOutlet =>
      outletIds.includes(outlet._id)
    );
  });

  const journalistsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'journalist'),
    [dropdownTableContext]
  );
  const journalistsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('journalist');
  const [journalistFilter, setJournalistFilter] = useState<DropdownTableAutocompleteOptionJournalist[]>(() => {
    if (!sharedInteractionFilter) {
      return [];
    }
    const journalistsIds = sharedInteractionFilter.criteria.journalists;
    if (!journalistsIds || journalistsIds.length === 0) {
      return [];
    }
    return journalistsFilterOptions.filter((journalist): journalist is DropdownTableAutocompleteOptionJournalist =>
      journalistsIds.includes(journalist._id)
    );
  });

  const expertsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'expert'),
    [dropdownTableContext]
  );
  const expertsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('expert');
  const [expertsFilter, setExpertsFilter] = useState<DropdownTableAutocompleteOptionExpert[]>(() => {
    if (!sharedInteractionFilter) {
      return [];
    }
    const expertsIds = sharedInteractionFilter.criteria.experts;
    if (!expertsIds || expertsIds.length === 0) {
      return [];
    }
    return expertsFilterOptions.filter((expert): expert is DropdownTableAutocompleteOptionExpert =>
      expertsIds.includes(expert._id)
    );
  });

  const unitsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'units'),
    [dropdownTableContext]
  );
  const unitsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('units');
  const [unitFilter, setUnitFilter] = useState<DropdownTableAutocompleteOptionUnit[]>(() => {
    if (!sharedInteractionFilter) {
      return [];
    }
    const unitsNames = sharedInteractionFilter.criteria.units;
    if (!unitsNames || unitsNames.length === 0) {
      return [];
    }
    return unitsFilterOptions.filter((unit): unit is DropdownTableAutocompleteOptionUnit =>
      unitsNames.includes(unit.name)
    );
  });

  const typesFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'type'),
    [dropdownTableContext]
  );
  const typesFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('type');
  const [typeFilter, setTypeFilter] = useState<DropdownTableAutocompleteOptionType[]>(() => {
    if (!sharedInteractionFilter) {
      return [];
    }
    const typesIds = sharedInteractionFilter.criteria.types;
    if (!typesIds || typesIds.length === 0) {
      return [];
    }
    return typesFilterOptions.filter((type): type is DropdownTableAutocompleteOptionType =>
      typesIds.includes(type._id)
    );
  });

  const campaignsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'campaigns'),
    [dropdownTableContext]
  );
  const campaignsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('campaigns');
  const [campaignFilter, setCampaignFilter] = useState<DropdownTableAutocompleteOptionCampaign[]>(() => {
    if (!sharedInteractionFilter) {
      return [];
    }
    const campaignsIds = sharedInteractionFilter.criteria.campaigns;
    if (!campaignsIds || campaignsIds.length === 0) {
      return [];
    }
    return campaignsFilterOptions.filter((campaign): campaign is DropdownTableAutocompleteOptionCampaign =>
      campaignsIds.includes(campaign._id)
    );
  });

  const teamMembersFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'lead'),
    [dropdownTableContext]
  );
  const teamMembersFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('lead');
  const [teamMemberFilter, setTeamMemberFilter] = useState<DropdownTableAutocompleteOptionTeamMember[]>(() => {
    if (!sharedInteractionFilter) {
      return [];
    }
    const teamMembersIds = sharedInteractionFilter.criteria.teamMembers;
    if (!teamMembersIds || teamMembersIds.length === 0) {
      return [];
    }
    return teamMembersFilterOptions.filter((teamMember): teamMember is DropdownTableAutocompleteOptionTeamMember =>
      teamMembersIds.includes(teamMember._id)
    );
  });

  const [statusFilter, setStatusFilter] = useState<TrackerFilterStatus[]>(() => {
    if (!sharedInteractionFilter) {
      return [];
    }
    const savedStatus = sharedInteractionFilter.criteria.status;
    if (!savedStatus || savedStatus.length === 0) {
      return [];
    }
    return savedStatus.map((statusName) => ({ name: statusName as ServiceOrIssueStatus }));
  });

  const onSave = () => {
    const outletIds = outletFilter.map((outlet) => outlet._id);
    const journalistIds = journalistFilter.map((journalist) => journalist._id);
    const expertsIds = expertsFilter.map((expert) => expert._id);
    const unitsNames = unitFilter.map((unit) => unit.name);
    const typesIds = typeFilter.map((type) => type._id);
    const statusNames = statusFilter.map((status) => status.name);
    const campaignsIds = campaignFilter.map((campaign) => campaign._id);
    const teamMembersIds = teamMemberFilter.map((teamMember) => teamMember._id);

    sharedMediaInteractionUpsertMutation.mutateAsync({
      groupId: groupId,
      data: {
        resourceType: 'issue',
        resourceFields: ['all_issue_fields'],
        filterCriteria: {
          dateFilter: {
            timezone: dateFilterCriteria.timezone,
            start: dayjs(dateFilterCriteria.start).tz(dateFilterCriteria.timezone).toISOString(),
            end: dayjs(dateFilterCriteria.end).tz(dateFilterCriteria.timezone).toISOString(),
          },
          topic: topicFilterCriteria ? topicFilterCriteria : undefined,
          outlets: outletIds.length === 0 ? undefined : outletIds,
          journalists: journalistIds.length === 0 ? undefined : journalistIds,
          experts: expertsIds.length === 0 ? undefined : expertsIds,
          units: unitsNames.length === 0 ? undefined : unitsNames,
          types: typesIds.length === 0 ? undefined : typesIds,
          status: statusNames.length === 0 ? undefined : statusNames,
          campaigns: campaignsIds.length === 0 ? undefined : campaignsIds,
          teamMembers: teamMembersIds.length === 0 ? undefined : teamMembersIds,
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      data-cy="collaboration-group-details-interactions-filter-setup-filter-dialog"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>Set up shared Media Interactions Filter</DialogTitle>
      <DialogContent data-cy="collaboration-group-details-interactions-filter-setup-filter-dialog-content">
        <Grid container spacing="10px" columns={18}>
          <TrackerFilterTextField
            label="TOPIC"
            value={topicFilterCriteria}
            onChange={(value: string) => setTopicFilterCriteria(value)}
          />
          <TrackerFilterStatusSelection selectedStatus={statusFilter} onSelect={(e) => setStatusFilter(e)} />
          <FilterModalDateRangeInputs
            startDate={dateFilterCriteria.start}
            endDate={dateFilterCriteria.end}
            onStartDateChange={onDateFilterCriteriaStartChange}
            onEndDateChange={onDateFilterCriteriaEndChange}
          />
          <TrackerFilterAutoCompleteSelection
            label="UNIT"
            icon={<CategoryOutlinedIcon className={classes.autoCompleteIcon} />}
            options={unitsFilterOptions}
            groupBy={unitsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={unitFilter}
            onChange={(values) => {
              setUnitFilter(values as DropdownTableAutocompleteOptionUnit[]);
            }}
            type={TrackerDataType.MEDIA_INTERACTION}
          />
          <TrackerFilterAutoCompleteSelection
            label="OUTLET"
            icon={<ForumOutlinedIcon className={classes.autoCompleteIcon} />}
            options={outletsFilterOptions}
            groupBy={outletsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={outletFilter}
            onChange={(values) => {
              setOutletFilter(values as DropdownTableAutocompleteOptionOutlet[]);
            }}
            type={TrackerDataType.MEDIA_INTERACTION}
          />
          <TrackerFilterAutoCompleteSelection
            label="JOURNALIST"
            icon={<AssignmentIndOutlinedIcon className={classes.autoCompleteIcon} />}
            options={journalistsFilterOptions}
            groupBy={journalistsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={journalistFilter}
            onChange={(values) => {
              setJournalistFilter(values as DropdownTableAutocompleteOptionJournalist[]);
            }}
            type={TrackerDataType.MEDIA_INTERACTION}
          />
          <TrackerFilterAutoCompleteSelection
            label="LEAD EXPERT"
            options={expertsFilterOptions}
            groupBy={expertsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            icon={<PersonOutlinedIcon className={classes.autoCompleteIcon} />}
            values={expertsFilter}
            onChange={(values) => {
              setExpertsFilter(values as DropdownTableAutocompleteOptionExpert[]);
            }}
            type={TrackerDataType.MEDIA_INTERACTION}
          />
          <TrackerFilterAutoCompleteSelection
            label="CAMPAIGN"
            icon={<LaurelWreathIcon className={classes.autoCompleteIcon} />}
            options={campaignsFilterOptions}
            groupBy={campaignsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={campaignFilter}
            onChange={(values) => setCampaignFilter(values as DropdownTableAutocompleteOptionCampaign[])}
            type={TrackerDataType.MEDIA_INTERACTION}
          />
          <TrackerFilterAutoCompleteSelection
            label="TYPE"
            icon={<CardsIcon className={classes.autoCompleteIcon} />}
            options={typesFilterOptions}
            groupBy={typesFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={typeFilter}
            onChange={(values) => setTypeFilter(values as DropdownTableAutocompleteOptionType[])}
            type={TrackerDataType.MEDIA_INTERACTION}
          />
          <TrackerFilterAutoCompleteSelection
            label="TEAM MEMBERS"
            icon={<UsersIcon className={classes.autoCompleteIcon} />}
            options={teamMembersFilterOptions}
            groupBy={teamMembersFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={teamMemberFilter}
            onChange={(values) => setTeamMemberFilter(values as DropdownTableAutocompleteOptionTeamMember[])}
            type={TrackerDataType.MEDIA_INTERACTION}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => onClose()}
          data-cy="collaboration-group-details-interactions-filter-setup-filter-dialog-cancel-btn"
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={sharedMediaInteractionUpsertMutation.isPending}
          onClick={() => onSave()}
          data-cy="collaboration-group-details-interactions-filter-setup-filter-dialog-save-btn"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SetupSharedMediaInteractionFilterDialog;
