import React, { useEffect, useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TrackerTableDataRow from './TrackerTableDataRow';
import Paper from '@mui/material/Paper';
import { cellColors } from '../../shared/constants/commonCellColors';
import TrackerTableHeaderRow from './TrackerTableHeaderRow';
import TablePagination from '@mui/material/TablePagination';
import './Override.css';
import { Box } from '@mui/material';
import { useFilters } from '../TrackerFilter/Context/FiltersContext';
import { getFlatEngagementById, getFlatIssueById, getFlatServiceById } from './trackerTableRequest';
import { HttpStatusCode } from 'axios';
import { TrackerDataType } from '../../shared/constants/constants';

const useStyles = makeStyles(({ palette }) => ({
  table: {
    minWidth: 0,
    margin: 0,
  },
  tableContainer: {
    borderRadius: '10px',
    width: '100%',
    border: `2px solid #B1B9C9`,
    margin: '0 auto',
    maxHeight: '75vh',
    marginTop: '10px',
  },
  ...cellColors,
  pagination: {
    marginRight: '2%',
  },
  exportButton: {
    position: 'sticky',
  },
  progress: {
    display: 'flex',
    width: '95%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    marginTop: '0.4rem',
  },
  noDataText: {
    marginTop: '1em',
  },
}));

export default function TrackerTable(props) {
  const { rows, dropdownTable, visibleCols, eventHandler, linkedEventHandler, user } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const ref = useRef(null);
  const [searchedRows, setSearchedRows] = useState(rows);
  const filters = useFilters();

  // Relevant when rows are filtered by issue/service id since we want to differentiate
  // whether user cant see a post due to post not existing or whether user has no permission
  const [noDataMessage, setNoDataMessage] = useState('');

  const handleChangePage = (event, newPage) => {
    ref.current.scrollTop = 0;
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSearchedRows(rows);
  }, [rows]);

  useEffect(() => {
    const checkIfNoDataIsCausedByUserPermissions = async () => {
      const IdFromFilter =
        filters.linkedInteraction?._id || (filters.linkedService && filters.linkedService[0]._id) || filters.id;

      if (IdFromFilter && rows.length === 0) {
        try {
          // We expect the get requests to throw an error
          if (eventHandler.type === TrackerDataType.MEDIA_INTERACTION) {
            await getFlatIssueById(IdFromFilter);
          } else if (eventHandler.type === TrackerDataType.ENGAGEMENT) {
            await getFlatEngagementById(IdFromFilter);
          } else {
            await getFlatServiceById(IdFromFilter);
          }
        } catch (err) {
          if (err.response.status === HttpStatusCode.Forbidden) {
            const typeString =
              eventHandler.type === TrackerDataType.MEDIA_INTERACTION
                ? 'Media Interaction'
                : eventHandler.type === TrackerDataType.ENGAGEMENT
                ? 'Engagement'
                : 'Service';
            setNoDataMessage(
              `You do not have permission to view this ${typeString}, please contact your company's administrator`
            );
          } else if (err.response.status === HttpStatusCode.NotFound) {
            setNoDataMessage('No data found');
          } else {
            console.error(err);
            setNoDataMessage('Internal Server Error');
          }
        }
      } else {
        setNoDataMessage('No data found');
      }
    };

    checkIfNoDataIsCausedByUserPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return rows.length ? (
    <Box>
      <TableContainer ref={ref} component={Paper} className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TrackerTableHeaderRow columns={visibleCols} type={eventHandler.type} />
          <TableBody>
            {searchedRows.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage).map((row, index) => {
              return (
                <TrackerTableDataRow
                  key={row.id}
                  row={row}
                  visibleCols={visibleCols}
                  dropdownTable={dropdownTable}
                  isStripedRow={index % 2}
                  eventHandler={eventHandler}
                  linkedEventHandler={linkedEventHandler}
                  user={user}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
        component="div"
        count={searchedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.pagination}
        data-cy={'TrackerTable-Pagination'}
      />
    </Box>
  ) : (
    <h2 className={classes.noDataText}>{noDataMessage}</h2>
  );
}
