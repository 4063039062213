import React, { createContext, useState, useContext, ReactNode, useMemo } from 'react';

interface DialogState {
  Component: React.FC<any> | null;
  payload: any;
  isOpen: boolean;
}

interface DialogContextValue {
  openDialog: (Component: React.FC<any>, payload?: any) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextValue | undefined>(undefined);

export const DialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [dialogState, setDialogState] = useState<DialogState>({
    Component: null,
    payload: null,
    isOpen: false,
  });

  const openDialog = (Component: React.FC<any>, payload: any = null) => {
    setDialogState({ Component, payload, isOpen: true });
  };

  const closeDialog = () => {
    setDialogState((prev) => ({ ...prev, isOpen: false }));
  };

  const { Component, payload, isOpen } = dialogState;

  const contextValue = useMemo(
    () => ({
      openDialog,
      closeDialog,
    }),
    [] // Dependencies are empty because `openDialog` and `closeDialog` functions are stable
  );

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
      {Component && <Component payload={payload} open={isOpen} onClose={closeDialog} />}
    </DialogContext.Provider>
  );
};

/**
 * Provide headless use of custom MUI Dialog similar to MUI Toolpad
 * ```
 *  const { openDialog } = useDialog()
 *  openDialog(CustomDialog, CustomDialogPropsPayload)
 * ```
 */
export const useDialog = (): DialogContextValue => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
