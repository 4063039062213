import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getForwardingAddress } from './emailScrapingRequests';
import { TrackerDataType } from '../../shared/constants/constants';

interface ForwardingAddressInterface {
  selectedView: TrackerDataType;
}

const ForwardingAddressInstructions = (props: Readonly<ForwardingAddressInterface>) => {
  const { selectedView } = props;
  const [address, setAddress] = useState('');

  useEffect(() => {
    getForwardingAddress().then((response) => {
      setAddress(response);
    });
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Typography variant="body1">
        Please forward your emails to{' '}
        {selectedView === TrackerDataType.SERVICE ? address.replace('interaction', 'service') : address}
      </Typography>
    </Box>
  );
};

export default ForwardingAddressInstructions;
