import { Box, Button, Stack, Typography } from '@mui/material';
import ContactPersonnelProfile from './ContactPersonnelProfile';
import ContactPersonnelList from './ContactPersonnelList';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { getCommunityMembers, getExperts, getJournalists, getOrganizations, getOrgs } from './contactsRequest';
import ContactCategorySelect from './ContactCategorySelect';
import OrgList from './OrgList';
import OrgProfile from './OrgProfile';
import { useUnits } from './Hooks/useUnits';
import ContactFuzzySearch from './ContactFuzzySearch';
import CotactsExportPreview from './ContactsExportPreview';
import AddIcon from '@mui/icons-material/Add';
import CombinedContactPersonnelModal from './Modal/CombinedContactPersonnelModal';
import ContactsPageAddOutletModal from './Modal/ContactsPageAddOutletModal';
import { useJournalists } from './Hooks/useJournalists';
import { useCommunityMembers } from './Hooks/useCommunityMembers';

const useStyles = makeStyles((theme) => ({
  contactPageContainer: {
    margin: '0',
    padding: '0',
    width: '100vw',
    height: '100vh',
  },
  contactHeader: {
    width: '150px',
    marginLeft: '0',
    marginBottom: '0',
  },
  contactOperations: {
    marginTop: '20px',
    marginLeft: '0',
    marginBottom: '0',
  },
  outerContainer: {
    width: '100%',
    height: '100%',
    paddingTop: '0rem',
    marginLeft: '0',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  contactsListContainer: {
    height: '80vh',
    borderRadius: '1rem',
    marginLeft: '0',
  },
  contactsProfileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '80vh',
    width: '60vw',
    background: '#e8eef5',
    borderTopRightRadius: '1rem',
    borderBottomRightRadius: '1rem',
  },
  spinnerContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const ContactsPage = () => {
  const classes = useStyles();
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [refreshOrgs, setRefreshOrgs] = useState(false);
  const [refreshContacts, setRefreshContacts] = useState(false);
  const [query, setQuery] = useState('');
  const [orgs, setOrgs] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [openAddContact, setOpenAddContact] = useState(false);
  const [category, setCategory] = useState('Journalists');
  const isContactPersonnel = category === 'Journalists' || category === 'Experts' || category === 'Community Members';

  const { data: units, isLoading: isLoadingUnits, isError } = useUnits();

  function getContactsByOrganizations(organizations, contactPersonnels, orgType) {
    // Create a set of outlet IDs
    const organizationIDs = new Set(organizations.map((organization) => organization?._id));

    if (!Array.isArray(contactPersonnels.data)) {
      console.error('contactPersonnels input is not an array:', contactPersonnels);
      return [];
    }

    // Filter the journalists to only include those who belong to the given outlets
    return contactPersonnels.data?.filter((contactPersonnel) => {
      return organizationIDs.has(contactPersonnel?.[orgType]?._id);
    });
  }

  const handleContactClick = (contact) => {
    setSelectedContact(contact);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  // Used when exporting organization
  const {
    data: journalists,
    isLoading: isLoadingJournalists,
    isSuccess: isSuccessJournalists,
    isError: isErrorJournalists,
  } = useJournalists();
  const {
    data: communityMembers,
    isLoading: isLoadingMembers,
    isSuccess: isSuccessMembers,
    isError: isErrorMembers,
  } = useCommunityMembers();

  useEffect(() => {
    setIsLoadingList(true);
    let getContacts;
    let getOrgss;
    switch (category) {
      case 'Journalists':
        getContacts = getJournalists;
        getOrgss = getOrgs;
        break;
      case 'Experts':
        getContacts = getExperts;
        getOrgss = getOrgs;
        break;
      case 'Outlets':
        getContacts = getOrgs;
        getOrgss = getOrgs;
        break;
      case 'Community Members':
        getContacts = getCommunityMembers;
        getOrgss = getOrganizations;
        break;
      case 'Organizations':
        getContacts = getOrganizations;
        getOrgss = getOrganizations;
        break;
      default:
        console.error('invalid category provided in contacts page');
        break;
    }
    getContacts()
      .then((response) => {
        const sortedResponseData = response.data.sort((a, b) => {
          return a.name.trim().localeCompare(b.name.trim());
        });

        setSearchedContacts(sortedResponseData);
        setContacts(sortedResponseData);

        if (selectedContact) {
          const updatedSelectedContact = sortedResponseData.find((contact) => selectedContact._id === contact?._id);

          if (updatedSelectedContact) {
            setSelectedContact(updatedSelectedContact);
          } else {
            setSelectedContact(sortedResponseData[0]);
          }
        } else {
          setSelectedContact(sortedResponseData[0]);
        }

        setIsLoadingList(false);
      })
      .catch((err) => {
        console.log('Error getting contacts: ', err);
        console.log(err);
      });

    getOrgss()
      .then((response) => {
        setOrgs(response.data);
      })
      .catch((err) => {
        console.log('Error getting organizations: ', err);
        console.log(err);
      });

    setRefreshContacts(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshContacts, category]);

  useEffect(() => {
    let getOrgss;
    switch (category) {
      case 'Journalists':
        getOrgss = getOrgs;
        break;
      case 'Experts':
        getOrgss = getOrgs;
        break;
      case 'Outlets':
        getOrgss = getOrgs;
        break;
      case 'Community Members':
        getOrgss = getOrganizations;
        break;
      case 'Organizations':
        getOrgss = getOrganizations;
        break;
      default:
        console.error('invalid category provided in contacts page');
        break;
    }

    getOrgss()
      .then((response) => {
        setOrgs(response.data);
      })
      .catch((err) => {
        console.log('Error getting organizations: ', err);
        console.log(err);
      });

    setRefreshOrgs(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshOrgs]);

  if (isLoadingUnits || isLoadingList || isLoadingJournalists || isLoadingMembers)
    return <div className={classes.spinnerContainer}>Loading...</div>;

  if (isError) {
    return (
      <div className={classes.spinnerContainer}>
        <Typography variant="h3">Error loading users or units</Typography>
        <Typography variant="h5">Please refresh the page</Typography>
      </div>
    );
  }

  let contactsList = null;
  let contactDetails = null;
  let contactAddModal = null;
  let contactExportPreview = null;
  if (isContactPersonnel) {
    contactsList = (
      <ContactPersonnelList
        data={searchedContacts}
        handleContactClick={handleContactClick}
        category={category}
        isLoadingList={isLoadingList}
        selectedContact={selectedContact}
        units={units}
        style={{
          borderTopLeftRadius: '1rem',
          borderBottomLeftRadius: '1rem',
          borderRightStyle: 'none',
        }}
      />
    );
    contactDetails = (
      <Box className={classes.contactsProfileContainer}>
        <ContactPersonnelProfile
          selectedContact={selectedContact}
          category={category}
          units={units}
          orgs={orgs}
          setRefreshContacts={setRefreshContacts}
          setRefreshOrgs={setRefreshOrgs}
        />
      </Box>
    );
    contactAddModal = (
      <CombinedContactPersonnelModal
        openAddContact={openAddContact}
        setOpenAddContact={setOpenAddContact}
        orgs={orgs}
        units={units}
        setRefreshContacts={setRefreshContacts}
        setRefreshOrgs={setRefreshOrgs}
        category={category}
        isInContactPage={true}
        contacts={searchedContacts}
      />
    );
    contactExportPreview = (
      <CotactsExportPreview category={category} previewData={searchedContacts} onPreviewClose={() => {}} />
    );
  } else {
    contactsList = (
      <OrgList
        selectedOrganization={selectedContact}
        organizations={searchedContacts}
        handleContactClick={handleContactClick}
        isLoadingList={isLoadingList}
        style={{
          borderTopLeftRadius: '1rem',
          borderBottomLeftRadius: '1rem',
          borderRightStyle: 'none',
        }}
      />
    );
    contactDetails = (
      <Box className={classes.contactsProfileContainer}>
        <OrgProfile selectedOrg={selectedContact} setRefreshContacts={setRefreshContacts} category={category} />
      </Box>
    );
    contactAddModal = (
      <ContactsPageAddOutletModal
        openModal={openAddContact}
        setOpenModal={setOpenAddContact}
        setRefreshContacts={setRefreshContacts}
        category={category}
      />
    );
    contactExportPreview = (
      <>
        {isErrorJournalists && isErrorMembers && <Typography>Error fetching contacts</Typography>}
        {isSuccessJournalists && isSuccessMembers && (
          <CotactsExportPreview
            category={category}
            previewData={getContactsByOrganizations(
              searchedContacts,
              category === 'Outlets' ? journalists : communityMembers,
              category === 'Outlets' ? 'Org' : 'Organization'
            )}
            onPreviewClose={() => {}}
          />
        )}
      </>
    );
  }

  return (
    <Stack direction="column" alignItems="center" className={classes.contactPageContainer}>
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between" className={classes.contactOperations}>
          <Box>
            <ContactCategorySelect category={category} handleCategoryChange={handleCategoryChange} />
            <ContactFuzzySearch
              contacts={contacts}
              setSearchResults={setSearchedContacts}
              query={query}
              setQuery={setQuery}
              category={category}
            />
          </Box>

          <Box>
            <Button
              sx={{ marginRight: '10px' }}
              variant="contained"
              onClick={() => {
                setOpenAddContact(true);
              }}
              data-cy="addContact-button-ContactsPage"
            >
              <AddIcon />
              Add {category.slice(0, -1)}
            </Button>
            {contactExportPreview}
          </Box>
        </Stack>
        <Stack direction="row" alignItems="start" className={classes.outerContainer}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.contactsListContainer}
          >
            {contactsList}
          </Stack>
          {contactDetails}
        </Stack>
      </Stack>
      {contactAddModal}
    </Stack>
  );
};

export default ContactsPage;
