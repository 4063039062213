import TrackerFilterPopoverGrid from './TrackerFilterPopoverGrid';
import { Autocomplete, Box, Chip, InputAdornment, TextField, Theme } from '@mui/material';
import React from 'react';
import { DropdownTableAutocompleteOption } from '../../shared/contexts/DropdownTableContext';
import makeStyles from '@mui/styles/makeStyles';
import { TrackerDataType } from '../../shared/constants/constants';

interface TrackerFilterAutoCompleteSelectionProps {
  label: string;
  icon: React.ReactNode;
  options: DropdownTableAutocompleteOption[];
  groupBy: (option: DropdownTableAutocompleteOption) => string;
  values: DropdownTableAutocompleteOption[];
  onChange: (values: DropdownTableAutocompleteOption[]) => void;
  type: TrackerDataType;
}

const themeStyles = (theme: Theme, type: TrackerDataType) => {
  let palette;
  switch (type) {
    case TrackerDataType.MEDIA_INTERACTION:
      palette = theme.palette.mediaInteraction;
      break;
    case TrackerDataType.ENGAGEMENT:
      palette = theme.palette.primaryLighter;
      break;
    default:
      palette = theme.palette.primary;
      break;
  }

  return {
    groupLabel: {
      background: palette.main,
      color: palette.contrastText,
      fontWeight: 700,
      fontSize: '12px',
    },
  };
};

const useStyles = makeStyles((theme) => ({
  container: { display: 'flex', alignItems: 'center', width: '100%' },
  autocomplete: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      height: '17px',
    },
  },
}));

// TBD: virtualization should be used for large amount of options
export default function TrackerFilterAutoCompleteSelection(props: TrackerFilterAutoCompleteSelectionProps) {
  const { label, icon, options, groupBy, values, onChange, type } = props;
  const classes = useStyles({ type });

  return (
    <TrackerFilterPopoverGrid>
      {label}
      <Box className={classes.container}>
        <Autocomplete
          className={classes.autocomplete}
          componentsProps={{
            popper: {
              sx: {
                '& .MuiAutocomplete-groupLabel': (theme) => themeStyles(theme, type).groupLabel,
              },
            },
          }}
          options={options || []}
          groupBy={groupBy}
          multiple
          getOptionLabel={(option) => option.name || ''}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option._id} sx={{ fontSize: '12px' }}>
              {option.name}
            </Box>
          )}
          value={values}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              return (
                <Chip
                  sx={{ 'MuiChip-label': { fontSize: '12px' } }}
                  size="small"
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              );
            })
          }
          onChange={(_, v) => onChange(v)}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  minHeight: '32px',
                  height: 'auto', // Allow to expand
                  paddingTop: 0,
                  paddingBottom: 0,
                  alignItems: 'center',
                },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">{icon}</InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
    </TrackerFilterPopoverGrid>
  );
}
