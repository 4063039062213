import TrackerFilterPopoverGrid from './TrackerFilterPopoverGrid';
import ComplexityInputSelection from '../../shared/inputs/ComplexityInputSelection';
import { TrackerFilterComplexity } from './types';
import { complexityOptionsIdToNameMap } from '../../shared/constants/constants';

interface TrackerFilterComplexitySelectionProps {
  selectedComplexities: TrackerFilterComplexity[];
  onSelect: (selectedComplexities: TrackerFilterComplexity[]) => void;
}

export default function TrackerFilterComplexitySelection(props: TrackerFilterComplexitySelectionProps) {
  const { selectedComplexities, onSelect } = props;

  return (
    <TrackerFilterPopoverGrid>
      COMPLEXITY
      <ComplexityInputSelection
        selectedComplexities={selectedComplexities.map((selectedComplexity) => selectedComplexity._id)}
        onSelect={(selectedComplexityNumbers) =>
          onSelect(
            selectedComplexityNumbers.map((selectedComplexityNumber) => {
              return { _id: selectedComplexityNumber, name: complexityOptionsIdToNameMap[selectedComplexityNumber] };
            })
          )
        }
        multiSelect
      />
    </TrackerFilterPopoverGrid>
  );
}
