import { SharedInteractionsTableWrapperProps } from './Types/componentProps';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { listCollaborationGroupSharedResources } from './Requests/CollaborationGroupRequests';
import Spinner from 'react-bootstrap/Spinner';
import React, { useState } from 'react';
import SharedInteractionsTable from './SharedInteractionsTable';
import SharedResourceGroupSelect from './SharedResourceGroupSelect';
import { Alert, Box, Typography } from '@mui/material';
import SharedInteractionsQuickSearch from './SharedInteractionsQuickSearch';
import ExportCSVButton from './Utils/ExportCSVButton';
import {
  ListSharedInteractionsQuickSearchParams,
  SharedCollaborationGroupInteractionsDataCells,
} from './Types/apiData';
import dayjs from '../../utils/dayjs';
import { TrackerDataType } from '../../shared/constants/constants';

const SharedInteractionsTableWrapper = (props: SharedInteractionsTableWrapperProps) => {
  const { groupId, onSelectedGroupChange, groupsData } = props;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(50);

  const [quickSearchParams, setQuickSearchParams] = useState<ListSharedInteractionsQuickSearchParams>({
    source: undefined,
    topic: undefined,
    keyMessaging: undefined,
    response: undefined,
  });

  const { isFetching: isFetchingSharedIssues, data: sharedIssuesData } = useQuery({
    queryKey: ['collaboration/group/shared/interactions', { groupId, resourceType: 'issue', quickSearchParams }],
    queryFn: () => listCollaborationGroupSharedResources(groupId, 'issue', quickSearchParams),
    enabled: groupId !== '',
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  const handleQuickSearch = (category: string, query: string) => {
    setPage(0);

    switch (category) {
      case 'source': {
        setQuickSearchParams({
          source: query,
          topic: undefined,
          keyMessaging: undefined,
          response: undefined,
        });
        break;
      }
      case 'topic': {
        setQuickSearchParams({
          source: undefined,
          topic: query,
          keyMessaging: undefined,
          response: undefined,
        });
        break;
      }
      case 'keyMessaging': {
        setQuickSearchParams({
          source: undefined,
          topic: undefined,
          keyMessaging: query,
          response: undefined,
        });
        break;
      }
      case 'response': {
        setQuickSearchParams({
          source: undefined,
          topic: undefined,
          keyMessaging: undefined,
          response: query,
        });
        break;
      }
      default: {
        setQuickSearchParams({
          source: undefined,
          topic: undefined,
          keyMessaging: undefined,
          response: undefined,
        });
      }
    }

    queryClient.invalidateQueries({
      queryKey: [
        'collaboration/group/shared/interactions',
        { groupId, resourceType: 'issue', page, pageSize, quickSearchParams },
      ],
    });
  };

  const handleGroupSelectChange = (selectedGroupId: string) => {
    onSelectedGroupChange(selectedGroupId);
    setPage(0);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(0);
  };

  const formatCSVData = (data: SharedCollaborationGroupInteractionsDataCells[]) => {
    return data.map((issueCell) => {
      let department;
      if (Array.isArray(issueCell.Department)) {
        department = issueCell.Department.join(', ');
      } else {
        department = issueCell.Department;
      }

      return {
        Source: issueCell.Source,
        Date: issueCell.Date ? dayjs(issueCell.Date).tz(dayjs.tz.guess()).format('YYYY-MM-DD') : '',
        Topic: issueCell.Topic ? issueCell.Topic : '',
        Type: issueCell.Type.map((typeCell) => `${typeCell.type}:${typeCell.name}`).join(','),
        Unit: issueCell.Unit ? issueCell.Unit.join(',') : '',
        'Lead/Expert': issueCell['Lead/Expert'].map((expertCell) => expertCell.name).join(','),
        Outlet: issueCell.Outlet ? issueCell.Outlet.name : '',
        Journalist: issueCell.Journalist ? issueCell.Journalist.name : '',
        Campaign: issueCell.Campaign.map((campaignCell) => campaignCell.name).join(', '),
        'Team Members': issueCell['Team Members'].map((teamMemberCell) => teamMemberCell.name).join(', '),
        'Background/Response': issueCell['Background/Response'] ? issueCell['Background/Response'] : '',
        Status: issueCell.Status ? issueCell.Status : '',
        Department: department,
        'Key Messaging': issueCell['Key Messaging'] ? issueCell['Key Messaging'] : '',
        Tone: issueCell.Tone,
      };
    });
  };

  let sharedInteractionsComp;
  let exportCSV = null;

  if (isFetchingSharedIssues) {
    sharedInteractionsComp = (
      <div className="spinner-container">
        <Spinner animation="border" />
      </div>
    );
  } else if (groupId === '') {
    sharedInteractionsComp = <div>Select a group to view shared Interactions</div>;
  } else if (!sharedIssuesData) {
    sharedInteractionsComp = <div>failed to fetch shared interactions, try refresh this page</div>;
  } else if (sharedIssuesData.issues.length === 0) {
    sharedInteractionsComp = (
      <div>
        No shared interactions found in this group. Try set up Interactions filter to this group or select another group
      </div>
    );
  } else {
    sharedInteractionsComp = (
      <>
        <Alert variant="outlined" severity="info" sx={{ marginTop: '10px' }}>
          <Typography>
            Only the <strong>150 most recent interactions</strong> are displayed for each collaborator (including
            yourself). To view more specific results, please <strong>refine your search</strong>.
          </Typography>
        </Alert>
        <SharedInteractionsTable
          issues={sharedIssuesData.issues}
          totalCount={sharedIssuesData.totalCount}
          page={page}
          pageSize={pageSize}
          onPageChange={setPage}
          onPageSizeChange={handlePageSizeChange}
        />
      </>
    );

    const groupName = groupsData.find((group) => group._id === groupId)?.name;
    exportCSV = (
      <div>
        <ExportCSVButton
          tooltipTitle="Download CSV of this page's shared interactions"
          data={sharedIssuesData.issues.map((issue) => issue.cells)}
          filename={`${groupName}-shared-interactions`}
          formatCSVCallback={formatCSVData}
          type={TrackerDataType.MEDIA_INTERACTION}
        />
      </div>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', height: '40px', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '14px' }}>
          <SharedResourceGroupSelect
            selectedGroupId={groupId}
            groups={groupsData}
            onGroupSelect={(groupId) => handleGroupSelectChange(groupId)}
          />
          {groupId !== '' && <SharedInteractionsQuickSearch onSearch={handleQuickSearch} />}
        </Box>
        {exportCSV}
      </Box>
      {sharedInteractionsComp}
    </>
  );
};

export default SharedInteractionsTableWrapper;
