import React from 'react';

interface LinkedServiceIconProps {
  className?: string;
}

const LinkedServiceIcon: React.FC<LinkedServiceIconProps> = ({ className }) => {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.1797 0.820312C14.3255 0.820312 15.3021 1.23698 16.1094 2.07031C16.9167 2.8776 17.3203 3.85417 17.3203 5C17.3203 6.14583 16.9167 7.13542 16.1094 7.96875C15.3021 8.77604 14.3255 9.17969 13.1797 9.17969H9.82031V7.57812H13.1797C13.8828 7.57812 14.4818 7.33073 14.9766 6.83594C15.4974 6.3151 15.7578 5.70312 15.7578 5C15.7578 4.29688 15.4974 3.69792 14.9766 3.20312C14.4818 2.68229 13.8828 2.42188 13.1797 2.42188H9.82031V0.820312H13.1797ZM5.67969 5.82031V4.17969H12.3203V5.82031H5.67969ZM2.98438 3.20312C2.48958 3.69792 2.24219 4.29688 2.24219 5C2.24219 5.70312 2.48958 6.3151 2.98438 6.83594C3.50521 7.33073 4.11719 7.57812 4.82031 7.57812H8.17969V9.17969H4.82031C3.67448 9.17969 2.69792 8.77604 1.89062 7.96875C1.08333 7.13542 0.679688 6.14583 0.679688 5C0.679688 3.85417 1.08333 2.8776 1.89062 2.07031C2.69792 1.23698 3.67448 0.820312 4.82031 0.820312H8.17969V2.42188H4.82031C4.11719 2.42188 3.50521 2.68229 2.98438 3.20312Z"
        fill="#707070"
      />
    </svg>
  );
};

export default LinkedServiceIcon;
