import makeStyles from '@mui/styles/makeStyles';
import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Button } from '@mui/material';
import { API } from '../../../shared/constants/constants';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';
import { useEffect, useState } from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import MergeIcon from '@mui/icons-material/Merge';
import MergeOrgsModal from './MergeOrgsModal';
import { getOrgsByCaseInsensitiveName, getPersonnelsByOrg } from '../contactsRequest';
import { countActiveContacts } from '../utils';
import AlertCannotArchiveOrg from '../AlertConnotArchiveOrg';
import { useArchiveContact } from '../Hooks/useArchiveContact';

const ContactsPageEditOutletModal = (props) => {
  const { openModal, setOpenModal, setRefreshContacts, selectedOrg, category } = props;
  const [textFieldValue, setTextFieldValue] = useState('');
  const [openMergeOrg, setOpenMergeOrg] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const dispatch = useDispatch();
  const useStyles = makeStyles(() => ({
    dialog: {
      minHeight: '15vh',
      overflow: 'visible !important',
    },
  }));
  const classes = useStyles();

  const archiveContactMutation = useArchiveContact({
    contactId: selectedOrg?._id,
    category: category,
    isArchiving: true,
    setRefetchContacts: setRefreshContacts,
  });

  useEffect(() => {
    setTextFieldValue(selectedOrg.name);
  }, [selectedOrg]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const countDuplicatesButExcludeSelected = async (outletName) => {
    try {
      const response = await getOrgsByCaseInsensitiveName(outletName);
      response.data = response.data.filter((outlet) => {
        return outlet._id !== selectedOrg._id;
      });
      return response.data.length;
    } catch (error) {
      alert(`get failed - ${error}`);
      return 0;
    }
  };

  const countActiveJournalistsOfSelectedOutlet = async () => {
    try {
      const response = await getPersonnelsByOrg(selectedOrg._id);
      return countActiveContacts(response.data);
    } catch (error) {
      alert(`get failed - ${error}`);
      return 0;
    }
  };

  const handleArchive = async () => {
    const numActiveJournalistsOfOutlet = await countActiveJournalistsOfSelectedOutlet();
    if (numActiveJournalistsOfOutlet === 0) {
      await archiveContactMutation.mutateAsync();
    } else {
      setOpenAlert(true);
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const duplicates = await countDuplicatesButExcludeSelected(textFieldValue);

    if (duplicates > 0) {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: `${duplicates} outlet(s) with the same name already exists. Please check if you are trying to update an outlet to one that already exists.`,
        })
      );
      return;
    } else {
      try {
        const response = await API.put(`/org/${selectedOrg._id}`, { name: textFieldValue });

        if (response.status !== 200) {
          alert(response.statusText);
        } else {
          dispatch(
            openSnackbar({
              severity: 'success',
              message: `Outlet updated!`,
            })
          );
        }

        setRefreshContacts(true);
        handleClose();
        event.stopPropagation();
      } catch (err) {
        alert(`post failed - ${err}`);
      }
    }
  };

  return (
    <Dialog open={openModal} onClose={handleClose} className={classes.dialog}>
      <form onSubmit={handleEdit}>
        <DialogTitle>Edit Outlet</DialogTitle>
        <DialogContent className={classes.dialog}>
          <TextField
            required
            autoFocus
            value={textFieldValue}
            onChange={(event) => {
              setTextFieldValue(event.target.value);
            }}
            margin="dense"
            id="name"
            label="Name"
            type="text"
            variant="standard"
            data-cy="name-input-ContactsPageEditOutletModal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} data-cy="cancel-button-ContactsPageEditOutletModal">
            Cancel
          </Button>
          <Button
            onClick={() => setOpenMergeOrg(true)}
            startIcon={<MergeIcon />}
            data-cy="merge-button-ContactsPageEditOutletModal"
          >
            Merge
          </Button>
          <Button
            onClick={() => handleArchive()}
            startIcon={<ArchiveIcon />}
            data-cy="archive-button-ContactsPageEditOutletModal"
          >
            Archive
          </Button>
          <Button type="submit" startIcon={<SyncAltIcon />} data-cy="update-button-ContactsPageEditOutletModal">
            Update
          </Button>
        </DialogActions>
      </form>

      <MergeOrgsModal
        openMergeOrg={openMergeOrg}
        setOpenMergeOrg={setOpenMergeOrg}
        selectedOrg={selectedOrg}
        setRefreshContacts={setRefreshContacts}
        setOpenEditOrg={setOpenModal}
        category={category}
      />

      <AlertCannotArchiveOrg open={openAlert} setOpen={setOpenAlert} />
    </Dialog>
  );
};

export default ContactsPageEditOutletModal;
