import { useEffect, useState } from 'react';
import socketIo from 'socket.io-client';
import { DOMAIN, socketEvents } from './shared/constants/constants';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LayoutWrapper from './components/Layout/LayoutWrapper';
import App from './App.jsx';
import './MainApp.css';
import CustomAnalytics from './components/Analytics/CustomAnalytics';
import TeamAnalytics from './components/Analytics/TeamAnalytics';
import { ServiceLogPage } from './components/ServiceLog';
import { SocketContext } from './shared/contexts/SocketContext';
import Landing from './components/Landing/Landing';
import LoginLoading from './components/Authorization/LoginLoading';
import ProfilePage from './components/Profile/ProfilePage';
import AdminPage from './components/Admin/AdminPage';
import TenantsPage from './components/TenantsPage/TenantsPage';
import ContactsPage from './components/Contacts/ContactsPage';
import AppSnackbar from './components/AppSnackbar/AppSnackbar';
import ProtectedRoute from './components/Routes/ProtectedRoute';
import { useSelector } from 'react-redux';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; //uncomment to use devtools
import { FiltersProvider } from './components/TrackerFilter/Context/FiltersContext';
import CalendarPage from './components/Calendar/Calendar';
import ReportsPage from './components/ReportGeneration/ReportGeneration';
import { RouteInformation } from './shared/constants/RouteInformation';
import { useDropdownTable } from './shared/hooks/useDropdownTable';
import { useQueryClient } from '@tanstack/react-query';
import AutoTrackerPage from './components/AutoTrackerPage/AutoTrackerPage';
import NotificationsPage from './components/Notifications/NotificationsPage';
import CollaborationPage from './components/Collaboration/CollaborationPage';
import { getRouteAddonControlIfExists } from './utils/RouteAddonControlUtils';
import BroadcastPopupPage from './components/BroadcastPopup/BroadcastPopupPage';
import { DropdownTableContext } from './shared/contexts/DropdownTableContext';
import { EngagementLogPage } from './components/EngagementLog/EngagementLog';
import { DialogProvider } from './shared/contexts/DialogContext';

export default function MainApp() {
  const [socket, setSocket] = useState(null);
  const [isSocketAuthenticated, setIsSocketAuthenticated] = useState(false);
  const user = useSelector((state) => state.user);
  const { data } = useDropdownTable({ enabled: user.isAuthenticated });
  const dropdownTable = data ?? null;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (user.isAuthenticated) {
      setSocket(socketIo(DOMAIN, { transports: ['websocket'] }));
    }
  }, [user.isAuthenticated]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        const accessToken = sessionStorage.getItem('access_token');
        const idToken = sessionStorage.getItem('id_token');
        socket.emit('authenticate', { accessToken: accessToken, idToken: idToken });
      });

      socket.on(socketEvents.AUTHENTICATED, () => {
        setIsSocketAuthenticated(true);
      });

      socket.on(socketEvents.EVENT_DROPDOWN_TABLE, () => {
        queryClient.invalidateQueries(['dropdown-table']);
      });
    }
  }, [socket, queryClient]);

  const stylesheet = document.getElementById('login-stylesheet');
  stylesheet.disabled = true;

  const renderProtectedRoutes = () => {
    const routeComponents = [];
    for (const route in RouteInformation) {
      const hasValidPrivilege = user.userInfo.privilege >= RouteInformation[route].privilegeRequirement;
      const hasValidAddonControl = getRouteAddonControlIfExists(route, user.addonControls);

      if (hasValidPrivilege && hasValidAddonControl) {
        switch (route) {
          case 'serviceLog':
            routeComponents.push(
              <Route
                path={RouteInformation.serviceLog.path}
                element={
                  <ProtectedRoute>
                    <ServiceLogPage dropdownTable={dropdownTable} user={user.userInfo} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'serviceLogIndividualService':
            routeComponents.push(
              <Route
                path={RouteInformation.serviceLogIndividualService.path}
                element={
                  <ProtectedRoute>
                    <ServiceLogPage dropdownTable={dropdownTable} user={user.userInfo} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'mediaInteraction':
            routeComponents.push(
              <Route
                index
                path={RouteInformation.mediaInteraction.path}
                element={
                  <ProtectedRoute>
                    <App dropdownTable={dropdownTable} user={user.userInfo} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'mediaInteractionIndividualIssue':
            routeComponents.push(
              <Route
                path={RouteInformation.mediaInteractionIndividualIssue.path}
                element={
                  <ProtectedRoute>
                    <App dropdownTable={dropdownTable} user={user.userInfo} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'engagementLog':
            routeComponents.push(
              <Route
                path={RouteInformation.engagementLog.path}
                element={
                  <ProtectedRoute>
                    <EngagementLogPage dropdownTable={dropdownTable} user={user.userInfo} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'engagementLogIndividualEngagement':
            routeComponents.push(
              <Route
                path={RouteInformation.engagementLogIndividualEngagement.path}
                element={
                  <ProtectedRoute>
                    <EngagementLogPage dropdownTable={dropdownTable} user={user.userInfo} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'calendar':
            routeComponents.push(
              <Route
                path={RouteInformation.calendar.path}
                element={
                  <ProtectedRoute>
                    <CalendarPage dropdownTable={dropdownTable} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'contacts':
            routeComponents.push(
              <Route
                path={RouteInformation.contacts.path}
                element={
                  <ProtectedRoute>
                    <ContactsPage dropdownTable={dropdownTable} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'teamAnalytics':
            routeComponents.push(
              <Route
                path={RouteInformation.teamAnalytics.path}
                element={
                  <ProtectedRoute>
                    <TeamAnalytics />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'customAnalytics':
            routeComponents.push(
              <Route
                path={RouteInformation.customAnalytics.path}
                element={
                  <ProtectedRoute>
                    <CustomAnalytics />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'report':
            routeComponents.push(
              <Route
                path={RouteInformation.report.path}
                element={
                  <ProtectedRoute>
                    <ReportsPage dropdownTable={dropdownTable} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'autoTracker':
            routeComponents.push(
              <Route
                path={RouteInformation.autoTracker.path}
                element={
                  <ProtectedRoute>
                    <AutoTrackerPage dropdownTable={dropdownTable} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'admin':
            routeComponents.push(
              <Route
                path={RouteInformation.admin.path}
                element={
                  <ProtectedRoute>
                    <AdminPage editingUserPrivilege={user.userInfo.privilege} dropdownTable={dropdownTable}></AdminPage>
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'tenants':
            routeComponents.push(
              <Route
                path={RouteInformation.tenants.path}
                element={
                  <ProtectedRoute>
                    <TenantsPage user={user.userInfo} editingUserPrivilege={user.userInfo.privilege} />
                  </ProtectedRoute>
                }
              ></Route>
            );
            break;
          case 'profile':
            routeComponents.push(
              <Route
                path={RouteInformation.profile.path}
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'notifications':
            routeComponents.push(
              <Route
                path={RouteInformation.notifications.path}
                element={
                  <ProtectedRoute>
                    <NotificationsPage user={user.userInfo} dropdownTable={dropdownTable} />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'collaboration':
            routeComponents.push(
              <Route
                path={RouteInformation.collaboration.path}
                element={
                  <ProtectedRoute>
                    <CollaborationPage />
                  </ProtectedRoute>
                }
              />
            );
            break;
          case 'broadcastPopup':
            routeComponents.push(
              <Route
                path={RouteInformation.broadcastPopup.path}
                element={
                  <ProtectedRoute>
                    <BroadcastPopupPage />
                  </ProtectedRoute>
                }
              />
            );
            break;
          default:
            break;
        }
      }
    }

    return routeComponents;
  };

  return (
    <div className="mainApp">
      <SocketContext.Provider value={socket}>
        <DropdownTableContext.Provider value={dropdownTable}>
          <FiltersProvider>
            <DialogProvider>
              <AppSnackbar />
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Landing socketAuthenticated={isSocketAuthenticated} />} />
                  <Route path="login" element={<LoginLoading socketAuthenticated={isSocketAuthenticated} />} />
                  <Route
                    element={
                      <ProtectedRoute>
                        <LayoutWrapper />
                      </ProtectedRoute>
                    }
                  >
                    {renderProtectedRoutes()}

                    <Route
                      path="*"
                      element={
                        <ProtectedRoute>
                          <Navigate to={RouteInformation.serviceLog.path} replace />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            </DialogProvider>
          </FiltersProvider>
        </DropdownTableContext.Provider>
      </SocketContext.Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </div>
  );
}
