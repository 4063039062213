export enum AddOnTypes {
  collaboration = 'collaborationActivated',
  engagementLog = 'engagementLogActivated',
  trackerAI = 'trackerAIActivated',
}

export const TenantAddonControlFields = [
  { field: AddOnTypes.collaboration, description: 'Collaboration Addon Control' },
  { field: AddOnTypes.engagementLog, description: 'Engagement Log Addon Control' },
  { field: AddOnTypes.trackerAI, description: 'AutoTracker Addon Control' },
];
