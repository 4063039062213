import { SvgIconProps, SvgIcon } from '@mui/material';

export default function UsersIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18 15" {...props}>
      <path
        d="M3.9375 5.125C3.14453 5.125 2.43359 4.71484 2.02344 4.03125C1.64062 3.375 1.64062 2.52734 2.02344 1.84375C2.43359 1.1875 3.14453 0.75 3.9375 0.75C4.70312 0.75 5.41406 1.1875 5.82422 1.84375C6.20703 2.52734 6.20703 3.375 5.82422 4.03125C5.41406 4.71484 4.70312 5.125 3.9375 5.125ZM14 5.125C13.207 5.125 12.4961 4.71484 12.0859 4.03125C11.7031 3.375 11.7031 2.52734 12.0859 1.84375C12.4961 1.1875 13.207 0.75 14 0.75C14.7656 0.75 15.4766 1.1875 15.8867 1.84375C16.2695 2.52734 16.2695 3.375 15.8867 4.03125C15.4766 4.71484 14.7656 5.125 14 5.125ZM0 8.92578C0 7.3125 1.28516 6 2.89844 6H4.07422C4.51172 6 4.92188 6.10938 5.30469 6.27344C5.25 6.46484 5.25 6.68359 5.25 6.875C5.25 7.94141 5.6875 8.87109 6.42578 9.5H6.39844H0.574219C0.246094 9.5 0 9.25391 0 8.92578ZM11.0742 9.5H11.0469C11.7852 8.87109 12.25 7.94141 12.25 6.875C12.25 6.68359 12.2227 6.46484 12.1953 6.27344C12.5508 6.10938 12.9609 6 13.3984 6H14.5742C16.1875 6 17.5 7.3125 17.5 8.92578C17.5 9.25391 17.2266 9.5 16.8984 9.5H11.0742ZM8.75 5.5625C8.25781 5.5625 7.84766 5.83594 7.60156 6.21875C7.35547 6.62891 7.35547 7.14844 7.60156 7.53125C7.84766 7.94141 8.25781 8.1875 8.75 8.1875C9.21484 8.1875 9.625 7.94141 9.87109 7.53125C10.1172 7.14844 10.1172 6.62891 9.87109 6.21875C9.625 5.83594 9.21484 5.5625 8.75 5.5625ZM8.75 9.5C7.79297 9.5 6.94531 9.00781 6.45312 8.1875C5.98828 7.39453 5.98828 6.38281 6.45312 5.5625C6.94531 4.76953 7.79297 4.25 8.75 4.25C9.67969 4.25 10.5273 4.76953 11.0195 5.5625C11.4844 6.38281 11.4844 7.39453 11.0195 8.1875C10.5273 9.00781 9.67969 9.5 8.75 9.5ZM7.13672 11.6875C6.04297 11.6875 5.14062 12.4531 4.86719 13.4375H12.6055C12.332 12.4531 11.4297 11.6875 10.3359 11.6875H7.13672ZM7.13672 10.375H10.3359C12.3594 10.375 14 12.0156 14 14.0391C14 14.4492 13.6719 14.75 13.2617 14.75H4.21094C3.80078 14.75 3.5 14.4219 3.5 14.0391C3.5 12.0156 5.11328 10.375 7.13672 10.375Z"
        fill="#707070"
      />
    </SvgIcon>
  );
}
