import { useQuery } from '@tanstack/react-query';
import { getPersonnelsByOrg } from '../contactsRequest'

export const getJournalistsByOutletOptions = (outletId) => {
  return {
    queryKey: ['journalists', { outletId }],
    queryFn: () => getPersonnelsByOrg(outletId),
  };
};

export const useJournalistsByOutlet = (outletId) => {
  return useQuery(getJournalistsByOutletOptions(outletId));
};
