import TrackerFilterPopoverGrid from './TrackerFilterPopoverGrid';
import ToneInputSelection from '../../shared/inputs/ToneInputSelection';
import { Tone } from '../../shared/constants/constants';

interface TrackerFilterToneSelectionProps {
  selectedTones: Tone[];
  onSelect: (tones: Tone[]) => void;
}

export default function TrackerFilterToneSelection(props: TrackerFilterToneSelectionProps) {
  const { selectedTones, onSelect } = props;

  return (
    <TrackerFilterPopoverGrid>
      TONE
      <ToneInputSelection selectedTones={selectedTones} onSelect={onSelect} multiSelect />
    </TrackerFilterPopoverGrid>
  );
}
