import { Drawer } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    borderRadius: '25px',
    width: '60vw',
    minWidth: '1200px',
    padding: '20px 40px',
  },
});

function QueryAdderDrawer({ children, openForm, setOpenForm }) {
  const classes = useStyles();
  return (
    <div>
      <Drawer
        open={openForm}
        onClose={() => setOpenForm(false)}
        classes={{ paper: classes.content }}
        disableEnforceFocus
        anchor="right"
      >
        {children}
      </Drawer>
    </div>
  );
}

export default QueryAdderDrawer;
