import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';

// Extend Day.js with required plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(weekOfYear);

// custom methods declaration
declare module 'dayjs' {
  /**
   * Gives the array of month shorts ['Jan', 'Feb', ..., 'Dec']
   * @returns {string[]} month shorts array
   */
  export function monthsShort(): string[];
}

// custom methods implementation
dayjs.monthsShort = (): string[] => {
  return Array.from({ length: 12 }, (_, i) => {
    return dayjs().month(i).format('MMM');
  });
};

export default dayjs;
