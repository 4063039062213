import { useLocation, useNavigationType } from 'react-router-dom';
import { useFilters, useFiltersDispatch } from './Context/FiltersContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Popover } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import TrackerFilterTextField from './TrackerFilterTextField';
import TrackerFilterStatusSelection from './TrackerFilterStatusSelection';
import TrackerFilterDateSelection from './TrackerFilterDateSelection';
import TrackerFilterAutoCompleteSelection from './TrackerFilterAutoCompleteSelection';
import TrackerFilterComplexitySelection from './TrackerFilterComplexitySelection';
import LaurelWreathIcon from './Icons/LaurelWreathIcon';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import CardsIcon from './Icons/CardsIcon';
import UsersIcon from './Icons/UsersIcon';
import { API, ServiceOrIssueStatus, Tone, TrackerDataType } from '../../shared/constants/constants';
import {
  DropdownTableAutocompleteOptionCampaign,
  DropdownTableAutocompleteOptionDepartment,
  DropdownTableAutocompleteOptionCommunityMember,
  DropdownTableAutocompleteOptionExpert,
  DropdownTableAutocompleteOptionJournalist,
  DropdownTableAutocompleteOptionLinkedService,
  DropdownTableAutocompleteOptionOrganization,
  DropdownTableAutocompleteOptionOutlet,
  DropdownTableAutocompleteOptionTeamMember,
  DropdownTableAutocompleteOptionType,
  DropdownTableAutocompleteOptionUnit,
  DropdownTableContext,
} from '../../shared/contexts/DropdownTableContext';
import { TrackerFilterAutocompleteGroupByFunc, TrackerFilterComplexity, TrackerFilterStatus } from './types';
import {
  getTrackerFilterAutocompleteFilterQuery,
  getTrackerFilterAutoCompleteOptionsGroupByFunc,
  getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc,
  getTrackerFilterComplexityFilter,
  getTrackerFilterDateInputFilterQuery,
  getTrackerFilterQueryString,
  getTrackerFilterStatusFilterQuery,
  getTrackerFilterTextInputQuery,
  getTrackerFilterToneFilterQuery,
} from './trackerFilterHelpers';
import { useAtom } from 'jotai/index';
import { needRefetchFilteredDataAtom } from '../../shared/atoms/filterAtoms';
import { SocketContext } from '../../shared/contexts/SocketContext';
import TrackerFilterPopoverGrid from './TrackerFilterPopoverGrid';
import makeStyles from '@mui/styles/makeStyles';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import { useSelector } from 'react-redux';
import { StoreState, UserState } from '../../redux-types';
import TrackerFilterToneSelection from './TrackerFilterToneSelection';

interface TrackerFilterPopoverProps {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  setDataCallback: (data: any) => void;
  eventHandler: any;
  currMonth: string;
  inProgress: boolean;
  inProgressCallback: (inProgress: boolean) => void;
}

type FiltersMapKey = 'MediaInteraction' | 'ServiceLog' | 'ManagerAnalytics' | 'EngagementLog';

interface FilterLinkedInteraction {
  _id: string;
}

interface FiltersType {
  id?: string;
  topic?: string;
  service?: string;
  status?: TrackerFilterStatus[];
  type?: any[];
  units?: any[];
  complexity?: any[];
  'Team Members'?: any[];
  'Lead/Expert'?: any[];
  Campaigns?: any[];
  'Start Date'?: Date;
  'End Date'?: Date;
  outlet?: any[];
  journalist?: any[];
  organization?: any[];
  communityMember?: any[];
  linkedService?: any[];
  linkedInteraction?: FilterLinkedInteraction[];
  department?: any[];
  tone?: Tone[];
}

type FiltersTypeKey = keyof FiltersType;

const updateOrDeleteFilters = (
  filters: FiltersType,
  filterKey: FiltersTypeKey,
  value: any,
  condition: (value: any) => boolean
) => {
  if (condition(value)) {
    filters[filterKey] = value;
  } else {
    delete filters[filterKey];
  }
};

const useStyles = makeStyles({
  popoverBody: {
    width: '572px',
    padding: '27px 20px',
  },
  popoverActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  autoCompleteIcon: {
    fontSize: '20px',
  },
});

const FILTER_INPUTS_GRID_COL_NUM = 18;
export default function TrackerFilterPopover(props: TrackerFilterPopoverProps) {
  const classes = useStyles();
  const { anchorEl, onClose, setDataCallback, eventHandler, inProgress, inProgressCallback } = props;
  const open = Boolean(anchorEl);

  const { state, pathname } = useLocation();

  let initialState = state;
  if (state?.queryString) {
    initialState = {};
  }

  const filters = useFilters();
  const dispatch = useFiltersDispatch();

  const eventHandlerType: FiltersMapKey = eventHandler.type;
  const socket = useContext(SocketContext);

  const navigationType = useNavigationType();
  const currentUser = useSelector<StoreState, UserState>((state) => state.user);

  const [needRefetchFilteredData, setNeedRefetchFilteredData] = useAtom(needRefetchFilteredDataAtom);

  // common filters
  const [idFilter, setIdFilter] = useState<string>(filters.id || '');
  const [statusFilter, setStatusFilter] = useState<TrackerFilterStatus[]>(filters.status || []);
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(filters['Start Date'] || null);
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(filters['End Date'] || null);
  const [expertsFilter, setExpertsFilter] = useState<DropdownTableAutocompleteOptionExpert[]>(filters.expert || []);
  const [complexityFilter, setComplexityFilter] = useState<TrackerFilterComplexity[]>(filters.complexity || []);
  const [campaignFilter, setCampaignFilter] = useState<DropdownTableAutocompleteOptionCampaign[]>(
    filters.campaigns || []
  );
  const [typeFilter, setTypeFilter] = useState<DropdownTableAutocompleteOptionType[]>(filters.type || []);
  const [teamMemberFilter, setTeamMemberFilter] = useState<DropdownTableAutocompleteOptionTeamMember[]>(
    filters.teamMember || []
  );

  // service exclusive filters
  const [serviceFilter, setServiceFilter] = useState<string>(filters.service || '');
  const [unitFilter, setUnitFilter] = useState<DropdownTableAutocompleteOptionUnit[]>(filters.units || []);
  const [linkedInteractionFilter, setLinkedInteractionFilter] = useState<FilterLinkedInteraction | undefined>(
    filters.linkedInteraction || undefined
  );
  const [departmentsFilter, setDepartmentsFilter] = useState<DropdownTableAutocompleteOptionDepartment[]>([]);

  // interaction exclusive filters
  // TBD - require api modification - tone filter
  const [topicFilter, setTopicFilter] = useState<string>(filters.topic || '');
  const [outletFilter, setOutletFilter] = useState<DropdownTableAutocompleteOptionOutlet[]>(filters.outlet || []);
  const [journalistFilter, setJournalistFilter] = useState<DropdownTableAutocompleteOptionJournalist[]>(
    filters.journalist || []
  );
  const [linkedServiceFilter, setLinkedServiceFilter] = useState<DropdownTableAutocompleteOptionLinkedService[]>(
    filters.linkedService || []
  );
  const [toneFilter, setToneFilter] = useState<Tone[]>(filters.tone || []);

  // engagement exclusive filters
  const [communityMemberFilter, setCommunityMemberFilter] = useState<DropdownTableAutocompleteOptionCommunityMember[]>(
    filters.communityMember || []
  );
  const [organizationFilter, setOrganizationFilter] = useState<DropdownTableAutocompleteOptionOrganization[]>(
    filters.organization || []
  );
  // TODO linked services for engagements

  window.history.replaceState(null, pathname);

  const dropdownTableContext = useContext(DropdownTableContext);

  const expertsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'expert'),
    [dropdownTableContext]
  );
  const expertsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('expert');

  const campaignsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'campaigns'),
    [dropdownTableContext]
  );
  const campaignsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('campaigns');

  const typesFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'type'),
    [dropdownTableContext]
  );
  const typesFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('type');

  const teamMembersFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'lead'),
    [dropdownTableContext]
  );
  const teamMembersFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('lead');

  const unitsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'units'),
    [dropdownTableContext]
  );
  const unitsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('units');

  const outletsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'outlet'),
    [dropdownTableContext]
  );
  const outletsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('outlet');

  const journalistsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'journalist'),
    [dropdownTableContext]
  );
  const journalistsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('journalist');

  const organizationsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'organization'),
    [dropdownTableContext]
  );
  const organizationsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('organization');

  const communityMembersFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'communityMember'),
    [dropdownTableContext]
  );
  const communityMembersFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('communityMember');

  const linkedServicesFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'service'),
    [dropdownTableContext]
  );
  const linkedServicesFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('service');

  const departmentsFilterOptions = useMemo(
    () => getTrackerFilterAutoCompleteOptionsUseMemoFactoryFunc(dropdownTableContext, 'dept'),
    [dropdownTableContext]
  );
  const departmentsFilterGroupBy = getTrackerFilterAutoCompleteOptionsGroupByFunc('dept');

  const onSearchButtonClick = async () => {
    setDataCallback(null);

    const serviceQuery = getTrackerFilterTextInputQuery('service', serviceFilter);
    const statusQuery = getTrackerFilterStatusFilterQuery(statusFilter.map((s) => s.name));
    const startDateQuery = getTrackerFilterDateInputFilterQuery('startDate', startDateFilter);
    const endDateQuery = getTrackerFilterDateInputFilterQuery('endDate', endDateFilter);
    const expertsQuery = getTrackerFilterAutocompleteFilterQuery(
      'lead/expert',
      expertsFilter.map((e) => e._id)
    );
    const complexityQuery = getTrackerFilterComplexityFilter(complexityFilter.map((c) => c._id));
    const campaignQuery = getTrackerFilterAutocompleteFilterQuery(
      'campaigns',
      campaignFilter.map((c) => c._id)
    );
    const typeQuery = getTrackerFilterAutocompleteFilterQuery(
      'type',
      typeFilter.map((t) => t._id)
    );
    const teamMembersQuery = getTrackerFilterAutocompleteFilterQuery(
      'team members',
      teamMemberFilter.map((t) => t._id)
    );
    const unitsQuery = getTrackerFilterAutocompleteFilterQuery(
      'units',
      unitFilter.map((u) => u.name)
    );
    const outletsQuery = getTrackerFilterAutocompleteFilterQuery(
      'outlet',
      outletFilter.map((o) => o._id)
    );
    const journalistsQuery = getTrackerFilterAutocompleteFilterQuery(
      'journalist',
      journalistFilter.map((j) => j._id)
    );
    const organizationsQuery = getTrackerFilterAutocompleteFilterQuery(
      'organization',
      organizationFilter.map((o) => o._id)
    );
    const communityMembersQuery = getTrackerFilterAutocompleteFilterQuery(
      'communityMember',
      communityMemberFilter.map((j) => j._id)
    );
    const linkedServiceQuery = getTrackerFilterAutocompleteFilterQuery(
      'linkedservice',
      linkedServiceFilter.map((ls) => ls._id)
    );
    const linkedInteractionQuery = linkedInteractionFilter ? `linkedinteraction=${linkedInteractionFilter._id}` : '';
    const departmentQuery = getTrackerFilterAutocompleteFilterQuery(
      'department',
      departmentsFilter.map((d) => d.name)
    );
    const topicQuery = getTrackerFilterTextInputQuery('topic', topicFilter);
    const idQuery = getTrackerFilterTextInputQuery('id', idFilter);
    const toneQuery = getTrackerFilterToneFilterQuery(toneFilter);

    const queryParamsStr = getTrackerFilterQueryString([
      serviceQuery,
      statusQuery,
      startDateQuery,
      endDateQuery,
      expertsQuery,
      complexityQuery,
      campaignQuery,
      typeQuery,
      teamMembersQuery,
      unitsQuery,
      outletsQuery,
      journalistsQuery,
      organizationsQuery,
      communityMembersQuery,
      linkedServiceQuery,
      linkedInteractionQuery,
      departmentQuery,
      topicQuery,
      idQuery,
      toneQuery,
    ]);

    if (queryParamsStr) {
      let url;
      switch (eventHandler.type) {
        case TrackerDataType.MEDIA_INTERACTION:
          url = `issues/filter?${queryParamsStr}`;
          break;
        case TrackerDataType.ENGAGEMENT:
          url = `engagements/filter?${queryParamsStr}`;
          break;
        default:
          url = `services/filter?${queryParamsStr}`;
          break;
      }

      API.get(url)
        .then((res) => {
          const filteredData = res.data.data || null;
          setDataCallback(filteredData);
        })
        .catch((err) => {
          alert(err);
          setDataCallback([]);
        });
    }

    let updatingFilters: FiltersType = {};
    updateOrDeleteFilters(updatingFilters, 'id', idFilter, (idFilter) => idFilter !== '');
    updateOrDeleteFilters(updatingFilters, 'service', serviceFilter, (serviceFilter) => serviceFilter);
    updateOrDeleteFilters(updatingFilters, 'topic', topicFilter, (topicFilter) => topicFilter);
    updateOrDeleteFilters(updatingFilters, 'status', statusFilter, (statusFilter) => statusFilter.length > 0);
    updateOrDeleteFilters(updatingFilters, 'Start Date', startDateFilter, (startDateFilter) => startDateFilter);
    updateOrDeleteFilters(updatingFilters, 'End Date', endDateFilter, (endDateFilter) => endDateFilter);
    updateOrDeleteFilters(updatingFilters, 'Lead/Expert', expertsFilter, (expertsFilter) => expertsFilter.length > 0);
    updateOrDeleteFilters(
      updatingFilters,
      'complexity',
      complexityFilter,
      (complexityFilter) => complexityFilter.length > 0
    );
    updateOrDeleteFilters(updatingFilters, 'Campaigns', campaignFilter, (campaignFilter) => campaignFilter.length > 0);
    updateOrDeleteFilters(updatingFilters, 'type', typeFilter, (typeFilter) => typeFilter.length > 0);
    updateOrDeleteFilters(
      updatingFilters,
      'Team Members',
      teamMemberFilter,
      (teamMemberFilter) => teamMemberFilter.length > 0
    );
    updateOrDeleteFilters(updatingFilters, 'units', unitFilter, (unitFilter) => unitFilter.length > 0);
    updateOrDeleteFilters(updatingFilters, 'outlet', outletFilter, (outletFilter) => outletFilter.length > 0);
    updateOrDeleteFilters(
      updatingFilters,
      'journalist',
      journalistFilter,
      (journalistFilter) => journalistFilter.length > 0
    );
    updateOrDeleteFilters(
      updatingFilters,
      'organization',
      organizationFilter,
      (organizationFilter) => organizationFilter.length > 0
    );
    updateOrDeleteFilters(
      updatingFilters,
      'communityMember',
      communityMemberFilter,
      (communityMemberFilter) => communityMemberFilter.length > 0
    );
    updateOrDeleteFilters(
      updatingFilters,
      'linkedService',
      linkedServiceFilter,
      (linkedServiceFilter) => linkedServiceFilter.length > 0
    );
    updateOrDeleteFilters(
      updatingFilters,
      'linkedInteraction',
      linkedInteractionFilter,
      (linkedInteractionFilter) => linkedInteractionFilter
    );
    updateOrDeleteFilters(
      updatingFilters,
      'department',
      departmentsFilter,
      (departmentFilter) => departmentFilter.length > 0
    );
    updateOrDeleteFilters(updatingFilters, 'tone', toneFilter, (toneFilter) => toneFilter.length > 0);

    dispatch({
      type: 'setFilters',
      payload: {
        ...updatingFilters,
      },
    });

    onClose();
  };

  const getColor = () => {
    if (eventHandlerType === TrackerDataType.MEDIA_INTERACTION) {
      return 'mediaInteraction';
    } else if (eventHandlerType === TrackerDataType.ENGAGEMENT) {
      return 'primaryLighter';
    }
    return 'primary';
  };

  useEffect(() => {
    if (initialState) dispatch({ type: 'setFilters', payload: initialState });
    //keep dep array empty to avoid render loops
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function emitData() {
      if (navigationType === 'POP') {
        // Filter context is unfortunately not updated instantly. So we need to do onSearch using empty object while waiting for
        // filter context to reset.
        dispatch({ type: 'setFilters', payload: {} });
      }

      if (filters) {
        await onSearchButtonClick();
      }
    }
    emitData();
    //ignoring filters, navigationType, onSearch in dep array
    //eslint-disable-next-line
  }, [socket, dispatch]);

  useEffect(() => {
    if (needRefetchFilteredData) {
      onSearchButtonClick().then(() => {
        setNeedRefetchFilteredData(false);
      });
    }
    //ignoring filters, onSearch, setNeedRefetchFilteredData in dep array
    //eslint-disable-next-line
  }, [needRefetchFilteredData]);

  useEffect(() => {
    setIdFilter(filters.id || '');
    setServiceFilter(filters.service || '');
    setUnitFilter(filters.units || []);
    setLinkedInteractionFilter(filters.linkedInteraction || undefined);
    setDepartmentsFilter(filters.department || []);

    setTopicFilter(filters.topic || '');
    setOutletFilter(filters.outlet || []);
    setJournalistFilter(filters.journalist || []);
    setLinkedServiceFilter(filters.linkedService || []);

    setOrganizationFilter(filters.organization || []);
    setCommunityMemberFilter(filters.communityMember || []);

    setStatusFilter(filters.status || []);
    setStartDateFilter(filters['Start Date'] || null);
    setEndDateFilter(filters['End Date'] || null);
    setExpertsFilter(filters['Lead/Expert'] || []);
    setComplexityFilter(filters.complexity || []);
    setCampaignFilter(filters.Campaigns || []);
    setTypeFilter(filters.type || []);
    setTeamMemberFilter(filters['Team Members'] || []);
    setToneFilter(filters.tone || []);
  }, [filters]);

  useEffect(() => {
    if (inProgress && dropdownTableContext) {
      setStatusFilter([{ name: ServiceOrIssueStatus.IN_PROGRESS }]);
      const teamMember = dropdownTableContext.lead.find((lead) => currentUser.userInfo.id === lead._id);
      setTeamMemberFilter(teamMember ? [{ ...teamMember, firstLetter: teamMember.name.charAt(0) }] : []);
      inProgressCallback(false);
      setNeedRefetchFilteredData(true);
    }
  }, [inProgress, currentUser, dropdownTableContext, inProgressCallback, setNeedRefetchFilteredData]);

  const serviceOrTopicTextField =
    eventHandlerType === TrackerDataType.SERVICE ? (
      <TrackerFilterTextField
        label="SERVICE"
        value={serviceFilter}
        onChange={(service: string) => setServiceFilter(service)}
      />
    ) : (
      <TrackerFilterTextField
        label="MEDIA REQUEST"
        value={topicFilter}
        onChange={(topic: string) => setTopicFilter(topic)}
      />
    );

  if (eventHandler.type === TrackerDataType.SERVICE && linkedInteractionFilter) {
    return (
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => onClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: '8px' }}
      >
        <Box sx={{ flexGrow: 1, width: '662px', padding: '21px' }}>
          <Grid container spacing={2}>
            <TrackerFilterPopoverGrid>
              <>LINKED INTERACTION</>
              <>Currently in Linked Interaction view. Clear all filters to return monthly view</>
            </TrackerFilterPopoverGrid>
          </Grid>
        </Box>
      </Popover>
    );
  } else if (idFilter !== '') {
    return (
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => onClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: '8px' }}
      >
        <Box sx={{ flexGrow: 1, width: '662px', padding: '21px' }}>
          <Grid container spacing={2}>
            <TrackerFilterPopoverGrid>
              <>ID VIEW</>
              <>Currently in ID view. Clear all filters to return monthly view</>
            </TrackerFilterPopoverGrid>
          </Grid>
        </Box>
      </Popover>
    );
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{ vertical: -8, horizontal: 'left' }}
      slotProps={{
        paper: {
          sx: {
            maxHeight: '75vh', // Ensures it doesn't exceed viewport height, minimum 720px height
            overflowY: 'auto',
          },
        },
      }}
      data-cy="tracker-filter-popover"
    >
      <Box className={classes.popoverBody}>
        <Grid container spacing="10px" columns={FILTER_INPUTS_GRID_COL_NUM}>
          {serviceOrTopicTextField}
          <TrackerFilterStatusSelection selectedStatus={statusFilter} onSelect={(e) => setStatusFilter(e)} />
          <TrackerFilterDateSelection
            label="DATE"
            startDate={startDateFilter}
            endDate={endDateFilter}
            onStartDateSelection={(date) => setStartDateFilter(date)}
            onEndDateSelection={(date) => setEndDateFilter(date)}
          />
          <TrackerFilterAutoCompleteSelection
            label="UNIT"
            icon={<CategoryOutlinedIcon className={classes.autoCompleteIcon} />}
            options={unitsFilterOptions}
            groupBy={unitsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={unitFilter}
            onChange={(values) => {
              setUnitFilter(values as DropdownTableAutocompleteOptionUnit[]);
            }}
            type={eventHandler.type}
          />
          {eventHandler.type === TrackerDataType.MEDIA_INTERACTION && (
            <>
              <TrackerFilterAutoCompleteSelection
                label="OUTLET"
                icon={<ForumOutlinedIcon className={classes.autoCompleteIcon} />}
                options={outletsFilterOptions}
                groupBy={outletsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
                values={outletFilter}
                onChange={(values) => {
                  setOutletFilter(values as DropdownTableAutocompleteOptionOutlet[]);
                }}
                type={eventHandler.type}
              />
              <TrackerFilterAutoCompleteSelection
                label="JOURNALIST"
                icon={<AssignmentIndOutlinedIcon className={classes.autoCompleteIcon} />}
                options={journalistsFilterOptions}
                groupBy={journalistsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
                values={journalistFilter}
                onChange={(values) => {
                  setJournalistFilter(values as DropdownTableAutocompleteOptionJournalist[]);
                }}
                type={eventHandler.type}
              />
              <TrackerFilterToneSelection
                selectedTones={toneFilter}
                onSelect={(selectedTones) => {
                  setToneFilter(selectedTones);
                }}
              />
            </>
          )}
          {eventHandler.type === TrackerDataType.ENGAGEMENT && (
            <>
              <TrackerFilterAutoCompleteSelection
                label="ORGANIZATION"
                icon={<ForumOutlinedIcon className={classes.autoCompleteIcon} />}
                options={organizationsFilterOptions}
                groupBy={organizationsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
                values={organizationFilter}
                onChange={(values) => {
                  setOrganizationFilter(values as DropdownTableAutocompleteOptionOrganization[]);
                }}
                type={eventHandler.type}
              />
              <TrackerFilterAutoCompleteSelection
                label="COMMUNITY MEMBER"
                icon={<AssignmentIndOutlinedIcon className={classes.autoCompleteIcon} />}
                options={communityMembersFilterOptions}
                groupBy={communityMembersFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
                values={communityMemberFilter}
                onChange={(values) => {
                  setCommunityMemberFilter(values as DropdownTableAutocompleteOptionCommunityMember[]);
                }}
                type={eventHandler.type}
              />
            </>
          )}
          <TrackerFilterAutoCompleteSelection
            label="LEAD EXPERT"
            options={expertsFilterOptions}
            groupBy={expertsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            icon={<PersonOutlinedIcon className={classes.autoCompleteIcon} />}
            values={expertsFilter}
            onChange={(values) => {
              setExpertsFilter(values as DropdownTableAutocompleteOptionExpert[]);
            }}
            type={eventHandler.type}
          />
          {eventHandler.type === TrackerDataType.SERVICE && (
            <>
              <TrackerFilterAutoCompleteSelection
                label="DEPARTMENT"
                options={departmentsFilterOptions}
                groupBy={departmentsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
                icon={<CorporateFareOutlinedIcon className={classes.autoCompleteIcon} />}
                values={departmentsFilter}
                onChange={(values) => {
                  setDepartmentsFilter(values as DropdownTableAutocompleteOptionDepartment[]);
                }}
                type={eventHandler.type}
              />
              <TrackerFilterComplexitySelection
                selectedComplexities={complexityFilter}
                onSelect={(selectedComplexities) => setComplexityFilter(selectedComplexities)}
              />
            </>
          )}
          <TrackerFilterAutoCompleteSelection
            label="CAMPAIGN"
            icon={<LaurelWreathIcon className={classes.autoCompleteIcon} />}
            options={campaignsFilterOptions}
            groupBy={campaignsFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={campaignFilter}
            onChange={(values) => setCampaignFilter(values as DropdownTableAutocompleteOptionCampaign[])}
            type={eventHandler.type}
          />
          <TrackerFilterAutoCompleteSelection
            label="TYPE"
            icon={<CardsIcon className={classes.autoCompleteIcon} />}
            options={typesFilterOptions}
            groupBy={typesFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={typeFilter}
            onChange={(values) => setTypeFilter(values as DropdownTableAutocompleteOptionType[])}
            type={eventHandler.type}
          />
          {eventHandler.type === TrackerDataType.MEDIA_INTERACTION && (
            <TrackerFilterAutoCompleteSelection
              label="LINKED SERVICE"
              icon={<StorageOutlinedIcon className={classes.autoCompleteIcon} />}
              options={linkedServicesFilterOptions}
              groupBy={linkedServicesFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
              values={linkedServiceFilter}
              onChange={(values) => setLinkedServiceFilter(values as DropdownTableAutocompleteOptionLinkedService[])}
              type={eventHandler.type}
            />
          )}
          <TrackerFilterAutoCompleteSelection
            label="TEAM MEMBERS"
            icon={<UsersIcon className={classes.autoCompleteIcon} />}
            options={teamMembersFilterOptions}
            groupBy={teamMembersFilterGroupBy as TrackerFilterAutocompleteGroupByFunc}
            values={teamMemberFilter}
            onChange={(values) => setTeamMemberFilter(values as DropdownTableAutocompleteOptionTeamMember[])}
            type={eventHandler.type}
          />
          <Grid xs={FILTER_INPUTS_GRID_COL_NUM}>
            <Box className={classes.popoverActions}>
              <Button
                sx={{ fontSize: '12px' }}
                onClick={() => onClose()}
                variant="outlined"
                color={getColor()}
                data-cy="tracker-filter-cancel-btn"
              >
                Cancel
              </Button>
              <Button
                sx={{ fontSize: '12px' }}
                variant="contained"
                color={getColor()}
                onClick={() => onSearchButtonClick()}
                data-cy="tracker-filter-save-btn"
              >
                SEARCH
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
}
