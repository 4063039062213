import { Box, Collapse, Card, CardContent, TableRow, TableCell, useTheme, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CellSelector from '../Cells/CellSelector';
import CellViewer from '../Cells/CellViewer';
import QueryAdder from '../QueryAdder/QueryAdder';
import { useState, createContext } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddBox';
import ToolTip from '@mui/material/Tooltip';
import { useRef } from 'react';
import { API, getTabColorHeaderMain, TrackerDataType } from '../../shared/constants/constants';
import { useLocation } from 'react-router-dom';
import { processCellsForAutoComplete } from '../../shared/functions/autoCompleteProcessing';
import AddLinkIcon from '@mui/icons-material/AddLink';
import UrlDialog from '../ServiceLog/UrlDialog';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { EngagementKeys, IssueKeys, ServiceKeys } from '../../shared/constants/IssueAndServiceKeys';
import QueryAdderDrawer from '../QueryAdder/QueryAdderDrawer';

const useStyles = makeStyles(({ palette }) => ({
  hiddenCellCard: {
    flex: 1,
    alignSelf: 'flex-start',
    margin: '0',
    textAlign: 'center',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
  },
  cardContent: {
    padding: '0 !important',
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
  },
  cardName: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: palette.whiteScaleDarker.main,
    padding: 8,
    color: palette.whiteScaleDarker.contrastText,
    fontWeight: 400,
  },
  cardData: {
    padding: 5,
    wordWrap: 'break-word',
  },
  wideColContainer: {
    flex: 2,
    display: 'flex',
    width: '50%',
    flexBasis: '15vw',
    flexDirection: 'row',
  },
  fixedWideColContainer: {
    flex: 2,
    display: 'flex',
    width: '75%',
    flexDirection: 'row',
  },
  colContainer: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '50%',
    flexDirection: 'row',
  },
  collapseWrapperInner: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)',
  },
  dataCell: {
    padding: '6px',
    textAlign: 'center',
    border: `1px solid ${palette.secondary.dark}`,
  },
  input: {
    width: '100%',
    maxHeight: '40vh',
    overflowY: 'scroll',
    overflowWrap: 'break-word',
    textAlign: 'left',
    '&> div': {
      padding: 8,
    },
  },
  textArea: {
    height: '13.2vh',
    width: '100%',
    backgroundColor: 'white',
  },
  stackBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addLinkedButton: {
    '&.MuiButton-contained': {
      background: ({ tabColor }) => tabColor,
    },
    '&.MuiButton-contained:hover': {
      background: ({ tabColor }) => tabColor,
    },
    marginTop: '5vh',
    width: '60%',
  },
  boxButton: {
    color: palette.primary.contrastText,
  },
}));

const wideColsMap = {
  MediaInteraction: [IssueKeys.keyMessaging, IssueKeys.response, IssueKeys.linkedService],
  ServiceLog: [ServiceKeys.actions, ServiceKeys.linkedInteraction],
  EngagementLog: [EngagementKeys.keyMessaging, EngagementKeys.response, EngagementKeys.linkedService],
};

const fixedWideColsMap = {
  MediaInteraction: [IssueKeys.file],
  ServiceLog: [ServiceKeys.file],
  EngagementLog: [EngagementKeys.file],
};

const otherColsMap = {
  MediaInteraction: [IssueKeys.contactMethod, IssueKeys.dept],
  ServiceLog: [ServiceKeys.diversity, ServiceKeys.keyMessaging],
  EngagementLog: [EngagementKeys.contactMethod, EngagementKeys.dept],
};

const FileUploadButton = ({ file, onChange }) => {
  return (
    <>
      <input type="file" multiple id="file" ref={file} onChange={onChange} style={{ display: 'none' }} />
      <ToolTip title="add file(s)">
        <label htmlFor="file" data-cy="file-upload-button">
          <IconButton onClick={() => file.current.click()} size="large">
            <AddIcon style={{ color: 'white' }} />
          </IconButton>
        </label>
      </ToolTip>
    </>
  );
};

const UrlUploadButton = ({ setFormOpen }) => {
  return (
    <>
      <ToolTip title="add link to web-hosted file">
        <IconButton onClick={() => setFormOpen(true)} size="large" data-cy="url-upload-button">
          <AddLinkIcon style={{ color: 'white' }} />
        </IconButton>
      </ToolTip>
    </>
  );
};

export const FileProgressContext = createContext();

const TrackerCollapsedRow = ({
  cells,
  open,
  colSpan,
  dropdownTable,
  isEditMode,
  onEdit,
  type,
  linkedEventHandler,
  id,
}) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const classes = useStyles({ tabColor: getTabColorHeaderMain(pathname, theme) });
  const dispatch = useDispatch();

  const file = useRef(null);
  const [fileProgress, setFileProgress] = useState();

  const [isUrlFormOpen, setIsUrlFormOpen] = useState(false);

  const [isDragActive, setDragActive] = useState(false);

  const onChange = (colName) => (e) => {
    if (e.target.files.length) {
      const headers = { 'content-type': 'multipart/form-data' };
      let files = new FormData();

      Object.values(e.target.files).forEach((file) => {
        files.append('file', file);
      });

      setFileProgress(0);

      API.post(`/${type === TrackerDataType.SERVICE ? 'service' : 'engagement'}/${id}/upload`, files, {
        headers: headers,
        onUploadProgress: (data) => {
          setFileProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
        .then((response) => {
          if (response.status !== 201) {
            alert(response.statusText);
          } else {
            dispatch(openSnackbar({ severity: 'success', message: `${colName} entry added!` }));
          }

          const filesData = response.data.files || null;
          const cellData = cells[colName].concat(filesData);

          onEdit(cellData, colName);
          return response;
        })
        .catch((err) => {
          alert(`post failed - ${err}`);
        });
    }
  };

  const handleDrag = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (colName) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.files && e.dataTransfer.files[0] && isDragActive) {
      const headers = { 'content-type': 'multipart/form-data' };
      let files = new FormData();

      Object.values(e.dataTransfer.files).forEach((file) => {
        files.append('file', file);
      });

      setFileProgress(0);

      API.post(`/${type === TrackerDataType.ENGAGEMENT ? 'engagement' : 'service'}/${id}/upload`, files, {
        headers: headers,
        onUploadProgress: (data) => {
          setFileProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
        .then((response) => {
          if (response.status !== 201) {
            alert(response.statusText);
          } else {
            dispatch(openSnackbar({ severity: 'success', message: `${colName} entry added!` }));
          }

          const filesData = response.data.files || null;
          const cellData = cells[colName].concat(filesData);

          onEdit(cellData, colName);
          return response;
        })
        .catch((err) => {
          alert(`post failed - ${err}`);
        });
    }
    setDragActive(false);
  };

  const handleSubmit = (callback, dialogValue, handleClose, colName, id, dispatch) => (event) => {
    event.preventDefault();

    let pathPrefix;

    switch (type) {
      case TrackerDataType.SERVICE:
        pathPrefix = 'service';
        break;
      case TrackerDataType.MEDIA_INTERACTION:
        pathPrefix = 'issues';
        break;
      case TrackerDataType.ENGAGEMENT:
        pathPrefix = 'engagement';
        break;
      default:
        pathPrefix = 'service';
        break;
    }

    API.post(`/${pathPrefix}/${id}/url`, dialogValue)
      .then((response) => {
        if (response.status !== 201) {
          alert(response.statusText);
        } else {
          dispatch(openSnackbar({ severity: 'success', message: `${colName} entry added!` }));
        }
        return response;
      })
      .then((data) => {
        callback(data, colName);
      })
      .catch((e) => {
        console.log('post failed - ', e);
      });

    handleClose();
  };

  const handleCommsChange = (response, colName) => {
    const filesData = response.data.updatedService?.file || response.data.updatedIssue?.file || null;

    onEdit(filesData, colName);
  };

  const [openForm, setOpenForm] = useState(false);

  const calculateGridXS = (colName) => {
    const isKeyMessaging =
      colName === IssueKeys.keyMessaging || colName === ServiceKeys.actions || colName === EngagementKeys.keyMessaging;
    const isResponse = colName === IssueKeys.response || colName === EngagementKeys.response;
    const isLinkedCol = colName === IssueKeys.linkedService || colName === ServiceKeys.linkedInteraction;
    const isFileCol = colName === IssueKeys.file || colName === ServiceKeys.file || colName === EngagementKeys.file;

    // grid xs must add up to 12
    switch (type) {
      case 'MediaInteraction': {
        if (isKeyMessaging || isResponse) {
          return 3;
        } else if (isLinkedCol || isFileCol) {
          return 2;
        } else {
          return 1;
        }
      }
      case 'ServiceLog': {
        if (isKeyMessaging || isResponse) {
          return 3;
        } else if (isLinkedCol || isFileCol) {
          return 2;
        } else {
          return 2;
        }
      }
      case 'EngagementLog': {
        if (isKeyMessaging || isResponse) {
          return 3;
        } else if (isLinkedCol || isFileCol) {
          return 2;
        } else {
          return 1;
        }
      }
      default: {
        throw new Error('unknown type for table detailed rows');
      }
    }
  };

  const renderHiddenCell = (colName, xs) => {
    return (
      <Grid item xs={xs}>
        <Card key={colName} className={classes.hiddenCellCard}>
          <CardContent className={classes.cardContent}>
            <Box className={classes.cardName}>
              {colName}{' '}
              {colName === ServiceKeys.file || colName === IssueKeys.file || colName === EngagementKeys.file ? (
                <>
                  <UrlDialog
                    colName={colName}
                    type={type}
                    id={id}
                    isOpen={isUrlFormOpen}
                    setIsOpen={setIsUrlFormOpen}
                    callback={handleCommsChange}
                    handleSubmit={handleSubmit}
                  />
                  {type === TrackerDataType.SERVICE || type === TrackerDataType.ENGAGEMENT ? (
                    <FileUploadButton file={file} onChange={onChange(colName)} />
                  ) : (
                    <></>
                  )}
                  <UrlUploadButton setFormOpen={setIsUrlFormOpen} />
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box style={{ padding: 6, minHeight: '5vh' }}>
              <FileProgressContext.Provider value={fileProgress}>
                {isEditMode ? (
                  <CellSelector
                    cells={cells}
                    callBack={onEdit}
                    col={colName}
                    dropdownTable={dropdownTable}
                    classes={classes}
                    id={id}
                    type={type}
                    handleDrag={handleDrag}
                    handleDrop={handleDrop(colName)}
                    isDragActive={isDragActive}
                  />
                ) : (
                  <CellViewer
                    type={type}
                    cells={cells}
                    index={colName}
                    callback={onEdit}
                    dropdownTable={dropdownTable}
                    openForm={() => setOpenForm(true)}
                    classes={classes}
                    id={id}
                    handleDrag={handleDrag}
                    handleDrop={handleDrop(colName)}
                    isDragActive={isDragActive}
                  />
                )}
              </FileProgressContext.Provider>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <TableRow style={{ display: open ? 'table-row' : 'none', padding: '4px' }}>
        <TableCell colSpan={colSpan} style={{ padding: '10px', paddingLeft: '20px', paddingRight: '15px' }}>
          <Collapse in={open} timeout={250} classes={{ wrapperInner: classes.collapseWrapperInner }}>
            <Grid container spacing={0}>
              {otherColsMap[type].map((col) => renderHiddenCell(col, calculateGridXS(col)))}
              {wideColsMap[type].map((col) => renderHiddenCell(col, calculateGridXS(col)))}
              {fixedWideColsMap[type].map((col) => renderHiddenCell(col, calculateGridXS(col)))}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
      {linkedEventHandler && (
        <QueryAdderDrawer openForm={openForm} setOpenForm={setOpenForm}>
          <QueryAdder
            title={linkedEventHandler?.newItemModalTitle}
            dropdownTable={dropdownTable}
            setOpenForm={setOpenForm}
            eventHandler={linkedEventHandler}
            cells={processCellsForAutoComplete(cells, dropdownTable, id)}
            issueId={id}
            linkedType={type}
          />
        </QueryAdderDrawer>
      )}
    </>
  );
};

export default TrackerCollapsedRow;
