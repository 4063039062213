import { SvgIconProps, SvgIcon } from '@mui/material';

export default function CopyUrlIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 9 13" {...props}>
      <path
        d="M6.5625 2H7.5C8.32031 2 9 2.67969 9 3.5V11C9 11.8438 8.32031 12.5 7.5 12.5H1.5C0.65625 12.5 0 11.8438 0 11V3.5C0 2.67969 0.65625 2 1.5 2H2.4375H2.64844C2.83594 1.15625 3.58594 0.5 4.5 0.5C5.39062 0.5 6.16406 1.15625 6.32812 2H6.5625ZM1.5 3.125C1.28906 3.125 1.125 3.3125 1.125 3.5V11C1.125 11.2109 1.28906 11.375 1.5 11.375H7.5C7.6875 11.375 7.875 11.2109 7.875 11V3.5C7.875 3.3125 7.6875 3.125 7.5 3.125H7.125V3.6875C7.125 4.01562 6.86719 4.25 6.5625 4.25H4.5H2.4375C2.10938 4.25 1.875 4.01562 1.875 3.6875V3.125H1.5ZM4.5 2.9375C4.80469 2.9375 5.0625 2.70312 5.0625 2.375C5.0625 2.07031 4.80469 1.8125 4.5 1.8125C4.17188 1.8125 3.9375 2.07031 3.9375 2.375C3.9375 2.70312 4.17188 2.9375 4.5 2.9375Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
