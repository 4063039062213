import React from 'react';

interface NegativeIconProps {
  className?: string;
}

const NegativeIcon: React.FC<NegativeIconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.375 0.6875C4.8125 0.25 5.375 0.03125 5.96875 0.03125H10C10.5938 0.03125 11.1562 0.25 11.5938 0.6875L15.3125 4.40625C15.75 4.84375 15.9688 5.40625 15.9688 6V10.0312C15.9688 10.625 15.75 11.1875 15.3125 11.625L11.5938 15.3438C11.1562 15.7812 10.5938 16 10 16H5.96875C5.375 16 4.8125 15.7812 4.375 15.3438L0.65625 11.625C0.21875 11.1875 0 10.625 0 10.0312V6C0 5.40625 0.21875 4.84375 0.65625 4.40625L4.375 0.6875ZM8 4C7.5625 4 7.25 4.34375 7.25 4.75V8.25C7.25 8.6875 7.5625 9 8 9C8.40625 9 8.75 8.6875 8.75 8.25V4.75C8.75 4.34375 8.40625 4 8 4ZM9 11C9 10.4688 8.53125 10 8 10C7.4375 10 7 10.4688 7 11C7 11.5625 7.4375 12 8 12C8.53125 12 9 11.5625 9 11Z"
        fill="#F15B50"
      />
    </svg>
  );
};

export default NegativeIcon;
