import { Box, Button, IconButton, MenuItem, Select, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import makeStyles from '@mui/styles/makeStyles';
import { TrackerDataType } from '../../../shared/constants/constants';

interface CollaborationSharedQuickSearchOption {
  label: string;
  value: string;
}

interface CollaborationSharedQuickSearchProps {
  searchCategoryOptions: CollaborationSharedQuickSearchOption[];
  selectedCategory: string;
  onSelectedCategoryChange: (value: string) => void;
  searchText: string;
  onSearchTextChange: (value: string) => void;
  onSearchButtonClick: () => void;
  onClearButtonClick: () => void;
  type?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    border: '1px solid #ccc',
    borderRadius: 4,
    alignItems: 'stretch',
  },
  select: {
    height: '100%',
    border: 'none',
    '& fieldset': { border: 'none' },
    borderRight: '1px solid #ccc',
    borderRadius: '0',
  },
  textfieldInput: {
    height: '100%',
    '& fieldset': { border: 'none' },
    padding: 0,
    width: '400px',
  },
  button: {
    height: '100% !important',
    borderLeft: '1px solid #ccc',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    backgroundColor: '#f5f5f5',
    '&:hover': { backgroundColor: '#e0e0e0' },
  },
}));

const CollaborationSharedQuickSearch = (props: CollaborationSharedQuickSearchProps) => {
  const classes = useStyles();

  const {
    searchCategoryOptions,
    selectedCategory,
    onSelectedCategoryChange,
    searchText,
    onSearchTextChange,
    onSearchButtonClick,
    onClearButtonClick,
    type,
  } = props;

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onSearchButtonClick();
    }
  };

  const getClearButtonColor = () => {
    if (type === TrackerDataType.MEDIA_INTERACTION) {
      return 'mediaInteraction';
    }
    return 'primary';
  };

  return (
    <>
      <Box className={classes.container}>
        <Select
          className={classes.select}
          value={selectedCategory}
          onChange={(e) => onSelectedCategoryChange(e.target.value)}
          data-cy="collaboration-shared-quick-search-category-select"
        >
          {searchCategoryOptions.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              {category.label}
            </MenuItem>
          ))}
        </Select>
        <TextField
          placeholder="Select & search by keywords..."
          value={searchText}
          onChange={(e) => onSearchTextChange(e.target.value)}
          onKeyDown={(e) => handleEnterKeyDown(e)}
          InputProps={{
            className: classes.textfieldInput,
          }}
          autoComplete="off"
          fullWidth
          data-cy="collaboration-shared-quick-search-text-field"
        />
        <IconButton
          size="small"
          className={classes.button}
          disabled={!selectedCategory || !searchText}
          onClick={() => onSearchButtonClick()}
          data-cy="collaboration-shared-quick-search-search-button"
        >
          <SearchIcon />
        </IconButton>
      </Box>
      <Button
        variant="outlined"
        size="medium"
        color={getClearButtonColor()}
        onClick={() => onClearButtonClick()}
        data-cy="collaboration-shared-quick-search-clear-button"
      >
        Clear
      </Button>
    </>
  );
};

export default CollaborationSharedQuickSearch;
