import React, { useState } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { visibleShareIssueCols } from './Utils/constants';
import TrackerTableDataCell from '../TrackerTable/TrackerTableDataCell';
import { TrackerDataType } from '../../shared/constants/constants';
import { getStrippedRow } from './Utils/functions';
import makeStyles from '@mui/styles/makeStyles';
import SharedInteractionsCollapsedDataRow from './SharedInteractionsCollapsedDataRow';
import { SharedInteractionsDataRowProps } from './Types/componentProps';
import { useDropdownTable } from '../../shared/hooks/useDropdownTable';

const { MEDIA_INTERACTION } = TrackerDataType;

const useStyles = makeStyles(({ palette }) => ({
  dataCell: {
    padding: '6px',
    textAlign: 'center',
  },
}));

const SharedInteractionsDataRow = (props: SharedInteractionsDataRowProps) => {
  const { issue, rowIndex } = props;
  const { data } = useDropdownTable({});
  const dropdownTable = data ?? null;

  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <TableRow
        key={issue.id}
        sx={{ backgroundColor: getStrippedRow(rowIndex) }}
        data-cy="collaboration-shared-interactions-table-data-row"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            data-cy="collaboration-shared-interactions-table-data-row-detailed-view-toggle-button"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell></TableCell>
        {visibleShareIssueCols.map((col) => (
          <TrackerTableDataCell
            type={MEDIA_INTERACTION}
            key={col}
            cells={issue.cells}
            index={col}
            isEditMode={false}
            onEdit={() => {}}
            dropdownTable={dropdownTable}
            className={classes.dataCell}
            id={null}
            isSharedResource={true}
          />
        ))}
      </TableRow>
      <SharedInteractionsCollapsedDataRow collapsed={collapsed} issueCells={issue.cells} />
    </>
  );
};

export default SharedInteractionsDataRow;
