import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { listCollaborationGroupSharedContacts } from './Requests/CollaborationGroupRequests';
import Spinner from 'react-bootstrap/Spinner';
import SharedJournalistsTable from './SharedJournalistsTable';
import { SharedJournalistsTableWrapperProps } from './Types/componentProps';
import SharedResourceGroupSelect from './SharedResourceGroupSelect';
import { Box } from '@mui/material';
import SharedJournalistsQuickSearch from './SharedJournalistsQuickSearch';
import ExportCSVButton from './Utils/ExportCSVButton';
import { ListCollaborationGroupSharedContactsAPIDataContacts } from './Types/apiData';

const SharedJournalistsTableWrapper = (props: SharedJournalistsTableWrapperProps) => {
  const { groupId, groupsData, onSelectedGroupChange } = props;

  const [quickSearchCategory, setQuickSearchCategory] = useState<string>('');
  const [quickSearchQuery, setQuickSearchQuery] = useState<string>('');
  const [page, setPage] = useState(0);
  const [pageNumber, setPageNumber] = useState<number>(50);

  const { isFetching, data: sharedJournalistsData } = useQuery({
    queryKey: [
      'collaboration/group/shared/journalists',
      { groupId, category: quickSearchCategory, query: quickSearchQuery, page, pageNumber },
    ],
    queryFn: () =>
      listCollaborationGroupSharedContacts(
        groupId,
        'journalist',
        quickSearchCategory,
        quickSearchQuery,
        page + 1,
        pageNumber
      ),
    enabled: groupId !== '',
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  const handleGroupSelect = (selectedGroupId: string) => {
    onSelectedGroupChange(selectedGroupId);
    setPage(0);
  };

  const handleQuickSearch = (category: string, query: string) => {
    setPage(0);
    setQuickSearchCategory(category);
    setQuickSearchQuery(query);
    queryClient.invalidateQueries({
      queryKey: [
        'collaboration/group/shared/journalists',
        { groupId, category: quickSearchCategory, query: quickSearchQuery, page, pageNumber },
      ],
    });
  };

  const formatCSVData = (data: ListCollaborationGroupSharedContactsAPIDataContacts[]) => {
    return data.map(({ id, ...rest }) => rest);
  };

  let sharedJournalistsTable;
  let exportCSV = null;

  if (groupId === '') {
    sharedJournalistsTable = <div>Select a group to view Shared Journalists</div>;
  } else if (isFetching) {
    sharedJournalistsTable = (
      <div className="spinner-container">
        <Spinner animation="border" />
      </div>
    );
  } else if (!sharedJournalistsData) {
    sharedJournalistsTable = <div>something went wrong, try re-select a group</div>;
  } else {
    sharedJournalistsTable = (
      <SharedJournalistsTable
        contactsData={sharedJournalistsData}
        page={page}
        pageSize={pageNumber}
        onPageChange={setPage}
        onPageSizeChange={setPageNumber}
      />
    );

    const groupName = groupsData.find((group) => group._id === groupId)?.name;
    exportCSV = (
      <div>
        <ExportCSVButton
          tooltipTitle="Download CSV of this Page"
          data={sharedJournalistsData.contacts}
          filename={`${groupName}-shared-journalists`}
          formatCSVCallback={formatCSVData}
        />
      </div>
    );
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', height: '40px', justifyContent: 'space-between', marginBottom: '14px' }}
      >
        <Box sx={{ display: 'flex', gap: '14px' }}>
          <SharedResourceGroupSelect
            selectedGroupId={groupId}
            groups={groupsData ?? []}
            onGroupSelect={(groupId) => handleGroupSelect(groupId)}
          />
          {groupId !== '' && (
            <SharedJournalistsQuickSearch
              disabled={!sharedJournalistsData || isFetching}
              onSearch={handleQuickSearch}
            />
          )}
        </Box>
        {exportCSV}
      </Box>
      {sharedJournalistsTable}
    </>
  );
};

export default SharedJournalistsTableWrapper;
