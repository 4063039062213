import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import QueryAdder from '../QueryAdder/QueryAdder';
import QueryAdderDrawer from '../QueryAdder/QueryAdderDrawer';
import { TrackerDataType } from '../../shared/constants/constants';

interface AddNewTableEntryButtonProps {
  dropdownTable: any;
  eventHandler: any;
  currMonth: string;
}

export default function AddNewTableEntryButton(props: AddNewTableEntryButtonProps) {
  const { dropdownTable, eventHandler, currMonth } = props;
  const [openForm, setOpenForm] = useState<boolean>(false);

  let componentColor: 'mediaInteraction' | 'primary' | 'primaryLighter';
  let buttonName: string;
  switch (eventHandler.type) {
    case TrackerDataType.MEDIA_INTERACTION:
      componentColor = 'mediaInteraction';
      buttonName = 'New interaction';
      break;
    case TrackerDataType.ENGAGEMENT:
      componentColor = 'primaryLighter';
      buttonName = 'New engagement';
      break;
    default:
      componentColor = 'primary';
      buttonName = 'New service';
      break;
  }

  return (
    <>
      <Button
        variant="contained"
        color={componentColor}
        sx={{ height: '100%' }}
        startIcon={<AddIcon />}
        onClick={() => setOpenForm(true)}
        data-cy="addNewService/Interaction-button-TrackerFilter"
      >
        <Typography>{buttonName}</Typography>
      </Button>
      <QueryAdderDrawer openForm={openForm} setOpenForm={setOpenForm}>
        <QueryAdder
          title={eventHandler.newItemModalTitle}
          dropdownTable={dropdownTable}
          currMonth={currMonth}
          setOpenForm={setOpenForm}
          eventHandler={eventHandler}
        />
      </QueryAdderDrawer>
    </>
  );
}
