import React, { useEffect, useState, useContext } from 'react';
import './App.css';
import TrackerTable from './components/TrackerTable/TrackerTable';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getCurrMonth } from './shared/functions/dateProcessor';
import { visibleIssueCols, socketEvents } from './shared/constants/constants';
import MediaInteractionEventHandler from './shared/eventHandler/MediaInteractionEventHandler';
import { useLocation, useParams } from 'react-router-dom';
import ServiceLogEventHandler from './shared/eventHandler/ServiceLogEventHandler';
import { useDispatch } from 'react-redux';
import { openSnackbar } from './components/AppSnackbar/snackbarSlice';
import { SocketContext } from './shared/contexts/SocketContext';
import { useFilters, useFiltersDispatch } from './components/TrackerFilter/Context/FiltersContext';
import { useAtom } from 'jotai';
import { needRefetchFilteredDataAtom, needRefetchKeywordSearchedDataAtom } from './shared/atoms/filterAtoms';
import isFilterEmpty from './shared/functions/isFilterEmpty';
import dayjs from '../src/utils/dayjs';
import MonthSelector from './components/TrackerTable/MonthSelector';
import { Box } from '@mui/material';
import TrackerTableFuzzySearch from './components/TrackerTable/TrackerTableFuzzySearch';
import TrackerTableFilter from './components/TrackerTable/TrackerTableFilter';
import AddNewTableEntryButton from './components/TrackerTable/AddNewTableEntryButton';
import DownloadCSVButton from './components/TrackerTable/DownloadCSVButton';

function App({ dropdownTable, user }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const filtersDispatch = useFiltersDispatch();
  const { state } = useLocation();

  let month;
  if (sessionStorage.getItem('currMonth')) {
    month = sessionStorage.getItem('currMonth');
  } else {
    month = getCurrMonth();
  }

  const [currMonth, setMonth] = useState(month);

  const socket = useContext(SocketContext);
  const mediaInteractionEventHandler = MediaInteractionEventHandler(socket);
  const serviceLogEventHandler = ServiceLogEventHandler(socket);
  const [, setNeedRefetchFilteredData] = useAtom(needRefetchFilteredDataAtom);
  const [, setNeedRefetchKeywordSearchedData] = useAtom(needRefetchKeywordSearchedDataAtom);
  const existingFilters = useFilters();

  const issueParams = useParams();

  const shouldDisableMonthSwitches = () => {
    const hasFilters = Object.keys(existingFilters).length > 0;
    const isQuickSearching = state?.queryString && state?.category;
    return hasFilters || isQuickSearching;
  };

  // Filter service based on url
  useEffect(() => {
    if (issueParams.issueId) {
      filtersDispatch({
        type: 'setFilters',
        payload: {
          id: issueParams.issueId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueParams]);

  useEffect(() => {
    socket.on('disconnect', () => {
      console.log(`socket ${socket.id} disconnected`);
    });

    socket.on(socketEvents.EVENT_SERVICE_ADDED, ({ snackbarObject }) => {
      if (snackbarObject) {
        dispatch(openSnackbar(snackbarObject));
      }
    });

    socket.on(socketEvents.EVENT_ISSUE_ADDED, ({ monthFilter, snackbarObject }) => {
      let month = sessionStorage.getItem('currMonth');
      // if there is an existing filter, then we need to refetch the filtered data
      if (!isFilterEmpty(existingFilters)) {
        setNeedRefetchFilteredData(true);
      } else if (state?.queryString) {
        setNeedRefetchKeywordSearchedData(true);
      } else {
        if (monthFilter === month) {
          socket.emit(socketEvents.EVENT_REQ_MONTHLY_ISSUES, {
            month: month,
            timezoneOffset: dayjs.tz.guess(),
          });
        }
      }
      if (snackbarObject) {
        dispatch(openSnackbar(snackbarObject));
      }
    });

    socket.on(socketEvents.EVENT_ISSUE_DELETED, ({ monthFilter }) => {
      let month = sessionStorage.getItem('currMonth');
      if (!isFilterEmpty(existingFilters)) {
        setNeedRefetchFilteredData(true);
      } else if (state?.queryString) {
        setNeedRefetchKeywordSearchedData(true);
      } else {
        if (monthFilter === month) {
          socket.emit(socketEvents.EVENT_REQ_MONTHLY_ISSUES, {
            month: month,
            timezoneOffset: dayjs.tz.guess(),
          });
        }
      }
    });

    socket.on(socketEvents.EVENT_CONFIRM_DELETE, (doc) => {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: 'issue deleted!',
          callbackData: doc,
          eventType: 'deleteIssue',
        })
      );
    });

    socket.on(socketEvents.EVENT_MONTHLY_ISSUES, (data) => {
      if (isFilterEmpty(existingFilters) && !state?.queryString) {
        setData(data);
      } else {
        setNeedRefetchFilteredData(true);
      }
    });

    socket.on(socketEvents.EVENT_ERROR, (error) => {
      alert(error);
    });

    // cleanup function to remove all event listeners when the value of dependencies change, avoid redundant listeners
    return () => {
      socket.off('disconnect');
      socket.off(socketEvents.EVENT_ISSUE_ADDED);
      socket.off(socketEvents.EVENT_ISSUE_DELETED);
      socket.off(socketEvents.EVENT_CONFIRM_DELETE);
      socket.off(socketEvents.EVENT_MONTHLY_ISSUES);
      socket.off(socketEvents.EVENT_ERROR);
    };
  }, [socket, dispatch, existingFilters, setNeedRefetchFilteredData, setNeedRefetchKeywordSearchedData, state]);

  useEffect(() => {
    sessionStorage.setItem('currMonth', currMonth);

    if (!state) {
      if (isFilterEmpty(existingFilters)) {
        socket.emit(socketEvents.EVENT_REQ_MONTHLY_ISSUES, {
          month: currMonth,
          timezoneOffset: dayjs.tz.guess(),
        });
      }
    }
  }, [currMonth, existingFilters, socket, state]);

  return (
    <div className="App">
      <div className="interaction-container">
        <Box>
          <MonthSelector disabled={shouldDisableMonthSwitches()} currMonth={currMonth} onMonthChange={setMonth} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
            marginBottom: '1rem',
            height: '32px',
          }}
        >
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <TrackerTableFuzzySearch />
            <TrackerTableFilter
              setDataCallback={setData}
              eventHandler={mediaInteractionEventHandler}
              currMonth={currMonth}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <AddNewTableEntryButton
              dropdownTable={dropdownTable}
              eventHandler={mediaInteractionEventHandler}
              currMonth={currMonth}
            />
            <DownloadCSVButton
              rows={data}
              dropdowns={dropdownTable}
              flattenerCallback={mediaInteractionEventHandler.flattenForExport}
              filename={mediaInteractionEventHandler.type}
              eventHandlerType={mediaInteractionEventHandler.type}
            />
          </Box>
        </Box>
        {data && dropdownTable ? (
          <>
            <div className="outerTable-Container">
              <TrackerTable
                rows={data}
                dropdownTable={dropdownTable}
                visibleCols={visibleIssueCols}
                eventHandler={mediaInteractionEventHandler}
                linkedEventHandler={serviceLogEventHandler}
                user={user}
              />
            </div>
          </>
        ) : (
          <div className="spinner-container">
            <Spinner animation="border" />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
