import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import OrgList from '../OrgList';
import { getOrganizations, getOrgs, mergeOrganizations, mergeOrgs } from '../contactsRequest';
import MergeIcon from '@mui/icons-material/MergeType';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';
import ContactFuzzySearch from '../ContactFuzzySearch';

function MergeOrgsModal(props) {
  const { openMergeOrg, setOpenMergeOrg, selectedOrg, setRefreshContacts, setOpenEditOrg, category } = props;
  const [orgs, setOrgs] = useState([]);
  const [orgsToBeMerged, setOrgsToBeMerged] = useState([]);
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenMergeOrg(false);
    setOpenEditOrg(false);
  };

  const handleMerge = async () => {
    const res =
      category === 'Outlets'
        ? await mergeOrgs(orgsToBeMerged, selectedOrg)
        : await mergeOrganizations(orgsToBeMerged, selectedOrg);
    if (res.status === 200) {
      dispatch(openSnackbar({ severity: 'success', message: `${category.slice(0, -1)} Merge Successful!` }));
      handleClose();
      setRefreshContacts(true);
    } else {
      dispatch(openSnackbar({ severity: 'error', message: `${category.slice(0, -1)} Merge Failed!` }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = category === 'Outlets' ? await getOrgs() : await getOrganizations();
      setOrgs(response.data ?? []);
    };

    fetchData();
  }, [category]); // Assuming getOrganizations doesn't need to be in the dependency array

  return (
    <Dialog
      open={openMergeOrg}
      onClose={handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      data-cy="dialog-MergeOutletsModal"
    >
      <DialogTitle>Merge Contact</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {`Please select which ${category.toLowerCase().slice(0, -1)}(s) to merge into:`}
          <strong> {selectedOrg?.name}</strong>
        </Typography>
        <Typography variant="body1">
          Selected to become above:
          <strong>{orgsToBeMerged.map((contact) => contact.name).join()}</strong>
        </Typography>
        <Box
          sx={{
            marginBottom: '10px',
            marginTop: '10px',
          }}
        >
          <ContactFuzzySearch
            contacts={orgs}
            setSearchResults={setOrgs}
            query={query}
            setQuery={setQuery}
            category={category}
          />
        </Box>
        <Box
          sx={{
            height: '50vh',
          }}
        >
          <OrgList
            organizations={orgs}
            isMerging={true}
            selectedOrg={selectedOrg}
            orgsToBeMerged={orgsToBeMerged}
            setOrgsToBeMerged={setOrgsToBeMerged}
            category={category}
          />
        </Box>
        <DialogActions>
          <br></br>
          <Button onClick={handleClose} variant="contained" data-cy="cancel-button-MergeOutletsModal">
            Cancel
          </Button>
          <Button
            onClick={handleMerge}
            startIcon={<MergeIcon />}
            variant="contained"
            data-cy="merge-button-MergeOutletsModal"
          >
            Merge
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default MergeOrgsModal;
