import { pick } from 'lodash';
import { Report, ReportMetadata, Type, ReportQuery } from './Interfaces';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import dayjs from '../../utils/dayjs';

export function createHyperlink(link: string, title: string): string {
  return `<a href="${link}" style="color: blue;">${title}</a>`;
}

export function reportToString(report: Report): string {
  let reportString: string = `Date: ${dateToStr(report.date)}\n`;
  reportString += `Topic: ${report.topic}\n`;
  reportString += `Department/Unit: ${report.department}\n`;
  reportString += `Lead Expert: ${report.expert}\n`;
  reportString += `Background Response: ${report.backgroundResponse}\n`;
  if (typeof report.keyMessaging !== 'undefined') {
    reportString += `Key Messaging: ${report.keyMessaging}\n`;
  }
  reportString += `Relevant Media Coverage:\n`;

  if (report.relevantMediaCoverage !== undefined && report.relevantMediaCoverage.length > 0) {
    report.relevantMediaCoverage.forEach((coverage) => {
      reportString += ` \u2022 Outlet: ${coverage.outlet}, Reach: ${coverage.reach}\n`;
    });
  }
  return reportString;
}

function groupReportsByProactivity(reports: Report[]): { proactive: Report[]; reactive: Report[] } {
  const grouped = {
    proactive: [] as Report[],
    reactive: [] as Report[],
  };

  reports.forEach((report) => {
    if (report.type.proactivity === 'Proactive') {
      grouped.proactive.push(report);
    } else if (report.type.proactivity === 'Reactive') {
      grouped.reactive.push(report);
    }
  });

  return grouped;
}

export function responseToReportObject(res: any, dropdownTable: any): Report {
  let report: Report = {
    date: new Date(res.Date),
    topic: res.Service || res.Topic,
    expert:
      Array.isArray(res['Lead/Expert']) && res['Lead/Expert'].length > 0 ? res['Lead/Expert'][0].name : 'No Experts',
    backgroundResponse: res['Background/Response'],
    type: res['Type'][0],
  };

  if (typeof res['Key Messaging'] !== 'undefined') {
    report.keyMessaging = res['Key Messaging'];
  }

  if (typeof res['Outlet'] !== 'undefined') {
    report.outlet = dropdownTable['outlet'].find((entry: any) => entry._id === res['Outlet'])?.name;
  }

  if (typeof res['Story Link'] !== 'undefined') {
    report.headline = res['Story Link'][0];
  }

  if (typeof res['Journalist'] !== 'undefined') {
    report.journalist = pick(
      dropdownTable['journalist'].find((journalist: any) => journalist._id === res['Journalist']),
      ['name', 'email']
    );
  }

  if (res['Media Interactions']) {
    report.relevantMediaCoverage = res['Media Interactions']?.['interactions'] || [];
  }

  if (res['Unit'] && Array.isArray(res.Unit) && res.Unit.length > 0) {
    report.unit = res.Unit;
  }

  if (res['Department'] && res['Department'].length > 0 && res['Department'][0] !== '') {
    report.department = res.Department;
  }

  return report;
}

export function encodeComplexityQuery(min: number, max: number): string {
  const complexities: number[] = [];
  for (let i = min; i <= max; i++) {
    complexities.push(i);
  }

  const jsonString: string = JSON.stringify(complexities);

  const encodedString: string = encodeURIComponent(jsonString);

  return `complexity=${encodedString}`;
}

export function encodeServiceTypeQuery(serviceTypes: string[]): string {
  if (serviceTypes.length === 0) {
    return '';
  }

  const jsonString: string = JSON.stringify(serviceTypes);
  const encodedString: string = encodeURIComponent(jsonString);

  return `type=${encodedString}`;
}

export function encodeUnitTypeQuery(unitTypes: string[]): string {
  if (unitTypes.length === 0) {
    return '';
  }

  const jsonString: string = JSON.stringify(unitTypes);
  const encodedString: string = encodeURIComponent(jsonString);

  return `units=${encodedString}`;
}

export function encodeTopicQuery(topics: string[]): string {
  if (topics.length === 0) {
    return '';
  }

  const jsonString: string = JSON.stringify(topics);
  const encodedString: string = encodeURIComponent(jsonString);

  return `topics=${encodedString}`;
}

export function encodeReportQuery(query: ReportQuery): string {
  return `startDate=${query.startDate}&endDate=${query.endDate}&${encodeComplexityQuery(
    query.minComplexity,
    query.maxComplexity
  )}&${encodeServiceTypeQuery(query.selectedServiceTypes.map((s) => s._id))}&includeKeyMessaging=${
    query.includeKeyMessaging ? 'true' : 'false'
  }&${encodeUnitTypeQuery(query.selectedUnitTypes.map((u) => u.name))}&${encodeTopicQuery(query.selectedTopics)}`;
}

export function removeApplicableFields(
  reports: Report[],
  includeBackgroundResponse: boolean,
  includeKeyMessaging: boolean
): Report[] {
  return reports.map((report) => {
    const { backgroundResponse, keyMessaging, ...rest } = report;
    return {
      ...rest,
      ...(includeBackgroundResponse && { backgroundResponse }),
      ...(includeKeyMessaging && { keyMessaging }),
    };
  });
}

export const responseToArray = (res: any[], dropdownTable: any): Report[] => {
  return res.map((i: any) => responseToReportObject(i.cells, dropdownTable));
};

export const resToText = (res: any[], dropdownTable: any): string => {
  let result: string = '';

  res.forEach((i: any) => {
    result += `${reportToString(responseToReportObject(i.cells, dropdownTable))}\n`;
  });
  return result;
};
function dateToStr(date: Date) {
  return dayjs(date).format('MMMM D, YYYY');
}

export function renderReportsToHTML(reports: Report[], metadata: ReportMetadata, includeMetadata: boolean): string {
  const groupedReports: { proactive: Report[]; reactive: Report[] } = groupReportsByProactivity(reports);

  const subtitle: string =
    metadata.reportType === 'Service' ? 'Communications Services Report' : 'Media Interactions Report';

  return `
        <!DOCTYPE html>
        <html>
        <head>
            <p class="logo"><b class="logoPart">BROAD</b>SIGHT</p>
            <p class="subtitle">${subtitle} for ${dateToStr(new Date(metadata.startDate))} - ${dateToStr(
    new Date(metadata.endDate)
  )}</p>
            <style>${getReportsStyle()}</style>
        </head>
        <body>
            ${includeMetadata ? renderMetadata(metadata) : ''}
            ${
              metadata.proactivity.proactive && groupedReports.proactive.length > 0
                ? '<b class="proactivity-title">Proactive Communications Activities</b>'
                : ''
            }
            ${groupedReports.proactive.map((report) => renderReport(report, metadata.reportType)).join('')}
            ${
              metadata.proactivity.reactive && groupedReports.reactive.length > 0
                ? '<b class="proactivity-title">Reactive / Issues Management</b>'
                : ''
            }
            ${groupedReports.reactive.map((report) => renderReport(report, metadata.reportType)).join('')}
        </body>
        </html>
    `;
}

function getReportsStyle(): string {
  return `
        body {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            background-color: #f2f2f2;
            color: #444;
            line-height: 1.6;
            margin: 0;
            padding: 0;
        }
        .logo {
            font-size: 40px;
            text-align: left;
            margin-top: 20px;
            margin-bottom: 0px;
            align-items: left;
            margin-left: 4%;

        }
        .logoPart {
            color: #0055B7;
        }
        .subtitle {
            text-align: left;
            margin-top: 10px;
            margin-bottom: 15px;
            align-items: left;
            margin-left: 4%;
            font-size: 20px;
            bold: true;
        }
        .proactivity-title {
            text-align: left;
            margin-top: 10px;
            margin-bottom: 15px;
            align-items: left;
            margin-left: 4%;
            font-size: 30px;
            bold: true;
            color: #0055B7;
        }
        .metadata-card {
            max-width: 45%; 
            margin: 25px auto;
            padding: 20px; 
            border: 2px solid #0055B7;
            border-radius: 10px;
            margin-top: 0px;
        }
        .metadata-header {
            font-size: 24px;
            color: #0055B7;
            font-weight: bold;
            text-align: start;
            margin: 7px;
        }
        .metadata-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 10px;
            font-size: 17px;
        }
        .metadata-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px;
        }
        .report-container {
            max-width: 90%;
            margin: 25px auto;
            padding: 20px;
            border: 2px solid #C0C0C0;
            border-radius: 8px;
        }
        .report-header {
            color: #0055B7;
            font-size: 24px;
            margin-bottom: 20px;
            margin-left: 7px;
            padding-bottom: 10px;
            border-bottom: 1px solid #adadad;
            text-align: start;
        }
        .report-section {
            margin: 15px;
            font-size: 17px;
        }
        .report-list {
            list-style: none inside;
            padding-left: 0;
        }
        .report-list-item {
            background: #f7f7f7;
            margin: 7px;
            padding: 10px;
            border-radius: 4px;
            font-size: 14px;
        }
    `;
}

function renderReport(report: Report, reportType: string): string {
  return `
        <div class="report-container">
            <div class="report-header"><b>${report.topic}</b></div>
            <div class="report-section"><span><b>Date: </b></span> ${dateToStr(new Date(report.date))}</div>
            ${
              report.headline
                ? `<div class="report-section"><span><b>Headline: </b></span> <a href="${report.headline.path}">${report.headline.name}</a> </div>`
                : ''
            }
            ${
              report.journalist
                ? `<div class="report-section"><span><b>Journalist: </b></span> <a href="mailto:${report.journalist.email}">${report.journalist.name}</a> </div>`
                : ''
            }
            ${report.outlet ? `<div class="report-section"><span><b>Outlet: </b></span> ${report.outlet}</div>` : ''}
            ${
              report.unit
                ? `<div class="report-section"><span><b>Unit: </b></span> ${report.unit.join(', ')}</div>`
                : ''
            }
            ${
              report.department
                ? `<div class="report-section"><span><b>Department: </b></span> ${report.department}</div>`
                : ''
            }
            <div class="report-section"><span><b>Lead Expert: </b></span> ${report.expert}</div>
            ${
              report.backgroundResponse
                ? `<div class="report-section"><span><b>Background/Response: </b></span> ${report.backgroundResponse}</div>`
                : ''
            }
            ${
              report.keyMessaging
                ? `<div class="report-section"><span><b>Key Messaging: </b></span> ${report.keyMessaging}</div>`
                : ''
            }
            ${reportType === 'MediaInteraction' ? '' : renderMediaCoverage(report)}
        </div>
    `;
}

function renderMediaCoverage(report: Report): string {
  if (!report.relevantMediaCoverage || report.relevantMediaCoverage.length === 0) {
    return `<div class="report-section" style="font-style: italic;">No relevant media coverage</div>`;
  }

  const coverageItems = report.relevantMediaCoverage
    .map((coverage) =>
      coverage.file && coverage.file.length > 0
        ? `
          <li class="report-list-item">
              ${coverage.outlet}: <a href="${coverage.file?.[0]?.path}">${coverage.file[0].name}</a>
          </li>
      `
        : `<li class="report-list-item">
      ${coverage.outlet}</li>`
    )
    .join('');

  return `
        <div class="report-section">
            <span><b>Relevant Media Coverage:&nbsp;</b></span>
            <ul class="report-list">${coverageItems}</ul>
        </div>
    `;
}

function renderMetadata(metadata: ReportMetadata): string {
  const {
    startDate,
    endDate,
    minComplexity,
    maxComplexity,
    keyMessaging,
    backgroundResponse,
    selectedServiceTypes,
    proactivity,
  } = metadata;
  const dateRange = `${dateToStr(new Date(startDate))} - ${dateToStr(new Date(endDate))}`;
  const complexityRange = `${minComplexity} - ${maxComplexity}`;

  const dateIcon = '&#128197;';
  const complexityIcon = '&#9878;';
  const messagingIcon = keyMessaging ? '&#10004;' : '&#10008;';
  const serviceTypeIcon = '&#128221;';
  const proactiveTypeIcon = '🎯';
  let proactiveMetadata;
  if (proactivity.proactive && proactivity.reactive) {
    proactiveMetadata = 'Proactive and Reactive';
  } else if (proactivity.proactive) {
    proactiveMetadata = 'Proactive only';
  } else if (proactivity.reactive) {
    proactiveMetadata = 'Reactive only';
  } else {
    proactiveMetadata = '';
  }

  return `
    <div class="metadata-card">
    <div class="metadata-header">Report Metadata 📈</div>
    <div class="metadata-section"><span class="metadata-icon">${dateIcon}<b>&nbsp;&nbsp;Date Range:&nbsp;</b>${dateRange}</span></div>
    <div class="metadata-section"><span class="metadata-icon">${complexityIcon}<b>&nbsp;&nbsp;Complexity Range:&nbsp;</b>${complexityRange}</span></div>
    <div class="metadata-section"><span class="metadata-icon">${messagingIcon}<b>&nbsp;&nbsp;Key Messaging Included:&nbsp;</b>${
    keyMessaging ? 'Yes' : 'No'
  }</span></div>
  <div class="metadata-section"><span class="metadata-icon">${messagingIcon}<b>&nbsp;&nbsp;Background Response Included:&nbsp;</b>${
    backgroundResponse ? 'Yes' : 'No'
  }</span></div>
  <div class="metadata-section"><span class="metadata-icon">${proactiveTypeIcon}<b>&nbsp;&nbsp;Proactivity:&nbsp;</b>${proactiveMetadata}</span></div>
    <div class="metadata-section"><span class="metadata-icon">${serviceTypeIcon}<b>&nbsp;&nbsp;Selected Service Types:&nbsp;</b>${selectedServiceTypes
    .map((t) => t.name)
    .join(', ')}</span></div>
    </div>
    `;
}

export function isValidEmail(email: string): boolean {
  const regex = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,}$/;
  return regex.test(email);
}

export function filterTypesByProactivity(
  type: Type,
  proactivityState: { reactive: boolean; proactive: boolean }
): boolean {
  return (
    (type.proactivity === 'Reactive' && proactivityState.reactive) ||
    (type.proactivity === 'Proactive' && proactivityState.proactive)
  );
}

export const sortTypesAlphabetically = <T>(types: T[], typeName: keyof T): T[] => {
  return [...types].sort((a, b) => {
    const nameA = String(a[typeName]).toLowerCase();
    const nameB = String(b[typeName]).toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

export const filterReportProactivity = (
  responseArray: Report[],
  proactivityState: {
    proactive: boolean;
    reactive: boolean;
  }
): Report[] => {
  let reports: Report[] = [];
  const proactiveReports: Report[] = [];
  const reactiveReports: Report[] = [];

  responseArray.forEach((report: Report) => {
    if (report.type.proactivity === 'Reactive') {
      reactiveReports.push(report);
    } else if (report.type.proactivity === 'Proactive') {
      proactiveReports.push(report);
    }
  });

  if (proactivityState.proactive) {
    reports = reports.concat(proactiveReports);
  }
  if (proactivityState.reactive) {
    reports = reports.concat(reactiveReports);
  }

  return reports;
};

export const generateEmailConfig = (
  email: string,
  reportType: string,
  reports: Report[],
  reportMetadata: ReportMetadata,
  includeMetadata: boolean
) => ({
  destAddress: email,
  subject: reportType === 'Service' ? 'Service Report' : 'Media Interaction Report',
  emailBody: renderReportsToHTML(reports, reportMetadata, includeMetadata),
});

export const handleEmailResponse = (emailResponse: any, email: string, dispatch: any) => {
  if (emailResponse.error) {
    dispatch(
      openSnackbar({
        severity: 'error',
        message: 'Email failed to send, the payload is too large! Try narrowing your dates or using more filters!',
      })
    );
    return;
  }

  dispatch(
    openSnackbar({
      severity: 'success',
      message:
        'Email sent! Check your inbox at ' +
        email +
        ' for the report, If you have not received the report, kindly check your spam folder.',
    })
  );
};

export const triggerErrorDispatch = (
  dispatch: any,
  proactivityState: {
    proactive: boolean;
    reactive: boolean;
  },
  email: string
): boolean => {
  if (!proactivityState.proactive && !proactivityState.reactive) {
    dispatch(openSnackbar({ severity: 'error', message: 'Please enter at least one option for proactivity!' }));
    return true;
  }
  if (email === '') {
    dispatch(openSnackbar({ severity: 'error', message: 'Please enter your E-mail first!' }));
    return true;
  } else if (!isValidEmail(email)) {
    dispatch(openSnackbar({ severity: 'error', message: 'Please enter a valid E-mail!' }));
    return true;
  }

  return false;
};
