import { CSVLink } from 'react-csv';
import { useEffect, useRef, useState } from 'react';
import { TrackerDataType } from '../../shared/constants/constants';
import DownloadCSVButtonUI from '../../shared/UIComponents/DownloadCSVButtonUI';

interface DownloadCSVButtonProps {
  rows: any[];
  dropdowns: any;
  flattenerCallback: (data: any, dropdownTable: any) => any[];
  filename: string;
  eventHandlerType: string;
}

export default function DownloadCSVButton(props: DownloadCSVButtonProps) {
  const { rows, dropdowns, flattenerCallback, filename, eventHandlerType } = props;

  const [csvData, setCsvData] = useState<any[]>([]);
  const csvLink = useRef<any>(null);
  const initialLoad = useRef<boolean>(true);

  let componentColor: 'mediaInteraction' | 'primary' | 'primaryLighter';
  switch (eventHandlerType) {
    case TrackerDataType.MEDIA_INTERACTION:
      componentColor = 'mediaInteraction';
      break;
    case TrackerDataType.ENGAGEMENT:
      componentColor = 'primaryLighter';
      break;
    default:
      componentColor = 'primary';
      break;
  }

  useEffect(() => {
    if (initialLoad.current === false) {
      csvLink.current.link.click();
    }

    if (initialLoad.current === true) {
      initialLoad.current = false;
    }
  }, [csvData]);

  return (
    <>
      <DownloadCSVButtonUI
        onClick={() => {
          const formattedRows = flattenerCallback(rows, dropdowns);
          setCsvData(formattedRows);
        }}
        color={componentColor}
      />
      <CSVLink
        filename={Date.now() + '-' + filename + '.csv'}
        className="hidden"
        data={csvData}
        ref={csvLink}
        separator={','}
      />
    </>
  );
}
