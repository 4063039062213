import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Spinner from 'react-bootstrap/Spinner';
import { visibleServiceLogCols, socketEvents } from '../../shared/constants/constants';
import ServiceLogEventHandler from '../../shared/eventHandler/ServiceLogEventHandler';
import MediaInteractionEventHandler from '../../shared/eventHandler/MediaInteractionEventHandler';
import TrackerTable from '../TrackerTable/TrackerTable';
import { getCurrMonth } from '../../shared/functions/dateProcessor';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { SocketContext } from '../../shared/contexts/SocketContext';
import { useFilters, useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import { useAtom } from 'jotai';
import { needRefetchFilteredDataAtom, needRefetchKeywordSearchedDataAtom } from '../../shared/atoms/filterAtoms';
import isFilterEmpty from '../../shared/functions/isFilterEmpty';
import MonthSelector from '../TrackerTable/MonthSelector';
import TrackerTableFilter from '../TrackerTable/TrackerTableFilter';
import TrackerTableFuzzySearch from '../TrackerTable/TrackerTableFuzzySearch';
import AddNewTableEntryButton from '../TrackerTable/AddNewTableEntryButton';
import DownloadCSVButton from '../TrackerTable/DownloadCSVButton';
import dayjs from '../../utils/dayjs';

const useStyles = makeStyles({
  tableContainer: {
    width: 'calc(100% - 60px)',
    margin: '0 auto',
    marginBottom: 30,
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
    marginBottom: '1rem',
    height: '32px',
  },
  filterWrapper: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
});

export const ServiceLogPage = ({ dropdownTable, user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filtersDispatch = useFiltersDispatch();
  const { state } = useLocation();
  let month;
  if (sessionStorage.getItem('currMonth')) {
    month = sessionStorage.getItem('currMonth');
  } else {
    month = getCurrMonth();
  }

  const [data, setData] = useState([]);
  const [currMonth, setMonth] = useState(month);
  const existingFilters = useFilters();
  const [, setNeedRefetchFilteredData] = useAtom(needRefetchFilteredDataAtom);
  const [, setNeedRefetchKeywordSearchedData] = useAtom(needRefetchKeywordSearchedDataAtom);

  const socket = useContext(SocketContext);
  const serviceLogEventHandler = ServiceLogEventHandler(socket);
  const mediaInteractionEventHandler = MediaInteractionEventHandler(socket);

  const serviceParams = useParams();

  const shouldDisableMonthSwitches = () => {
    const hasFilters = Object.keys(existingFilters).length > 0;
    const isQuickSearching = state?.queryString && state?.category;
    return hasFilters || isQuickSearching;
  };

  // Filter service based on url
  useEffect(() => {
    if (serviceParams.serviceId) {
      filtersDispatch({
        type: 'setFilters',
        payload: {
          id: serviceParams.serviceId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceParams]);

  useEffect(() => {
    socket.on(socketEvents.EVENT_MONTHLY_SERVICES, (data) => {
      if (isFilterEmpty(existingFilters) && !state?.queryString) {
        setData(data);
      } else {
        setNeedRefetchFilteredData(true);
      }
    });

    socket.on(socketEvents.EVENT_SERVICE_ADDED, ({ monthFilter, snackbarObject }) => {
      let month = sessionStorage.getItem('currMonth');
      if (!isFilterEmpty(existingFilters)) {
        setNeedRefetchFilteredData(true);
      } else if (state?.queryString) {
        setNeedRefetchKeywordSearchedData(true);
      } else if (monthFilter === month) {
        socket.emit(socketEvents.EVENT_MONTHLY_SERVICES, {
          month: month,
          timezoneOffset: dayjs.tz.guess(),
        });
      }
      if (snackbarObject) {
        dispatch(openSnackbar(snackbarObject));
      }
    });

    socket.on(socketEvents.EVENT_SERVICE_DELETED, ({ monthFilter }) => {
      let month = sessionStorage.getItem('currMonth');
      if (!isFilterEmpty(existingFilters)) {
        setNeedRefetchFilteredData(true);
      } else if (state?.queryString) {
        setNeedRefetchKeywordSearchedData(true);
      } else if (monthFilter === month) {
        socket.emit(socketEvents.EVENT_MONTHLY_SERVICES, {
          month: month,
          timezoneOffset: dayjs.tz.guess(),
        });
      }
    });

    socket.on(socketEvents.EVENT_CONFIRM_DELETE, (doc) => {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: 'service deleted!',
          callbackData: doc,
          eventType: 'deleteService',
        })
      );
    });

    socket.on(socketEvents.EVENT_ERROR, (error) => {
      alert(error);
    });

    // cleanup function to remove all event listeners when the value of dependencies change, avoid redundant listeners
    return () => {
      socket.off(socketEvents.EVENT_MONTHLY_SERVICES);
      socket.off(socketEvents.EVENT_SERVICE_ADDED);
      socket.off(socketEvents.EVENT_SERVICE_DELETED);
      socket.off(socketEvents.EVENT_CONFIRM_DELETE);
      socket.off(socketEvents.EVENT_ERROR);
    };
  }, [socket, dispatch, existingFilters, setNeedRefetchFilteredData, setNeedRefetchKeywordSearchedData, state]);

  useEffect(() => {
    sessionStorage.setItem('currMonth', currMonth);
    if (!state) {
      if (isFilterEmpty(existingFilters)) {
        socket.emit(socketEvents.EVENT_MONTHLY_SERVICES, {
          month: currMonth,
          timezoneOffset: dayjs.tz.guess(),
        });
      }
    }
  }, [currMonth, existingFilters, socket, state]);

  return (
    <Box className={classes.tableContainer}>
      <Box>
        <MonthSelector disabled={shouldDisableMonthSwitches()} currMonth={currMonth} onMonthChange={setMonth} />
      </Box>
      <Box className={classes.filterContainer}>
        <Box className={classes.filterWrapper}>
          <TrackerTableFuzzySearch />
          <TrackerTableFilter setDataCallback={setData} eventHandler={serviceLogEventHandler} currMonth={currMonth} />
        </Box>
        <Box className={classes.filterWrapper}>
          <AddNewTableEntryButton
            dropdownTable={dropdownTable}
            eventHandler={serviceLogEventHandler}
            currMonth={currMonth}
          />
          <DownloadCSVButton
            rows={data}
            dropdowns={dropdownTable}
            flattenerCallback={serviceLogEventHandler.flattenForExport}
            filename={serviceLogEventHandler.type}
            eventHandlerType={serviceLogEventHandler.type}
          />
        </Box>
      </Box>
      {data && dropdownTable ? (
        <>
          <TrackerTable
            rows={data}
            dropdownTable={dropdownTable}
            visibleCols={visibleServiceLogCols}
            eventHandler={serviceLogEventHandler}
            linkedEventHandler={mediaInteractionEventHandler}
            user={user}
          />
        </>
      ) : (
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      )}
    </Box>
  );
};
