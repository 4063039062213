import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import { Card, Box, ListSubheader, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgressWithLabel } from '../../shared/Spinner/Spinner';
import CustomAvatar from '../Profile/CustomAvatar';
import { Avatar } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: 0,
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  passwordChangeItem: {
    color: theme.palette.primaryDarker.main,
  },
}));
function UserListComponent({
  clickCallBack,
  users,
  addUserButton,
  getAvatarLetter,
  getListItemTitle,
  getListItemSubTitle,
  editingUserPrivilege,
  requiredEditingUserPrivilege,
}) {
  const classes = useStyles();
  const [usersList, setUsersList] = useState([]);
  useEffect(() => {
    setUsersList(
      users?.sort((a, b) => {
        // 'username' in for when component is accessed from Admin Page. 'organizationName' for when it is accessed
        // from Broadsight Admin Page
        const userA = (a.username ?? a.organizationName ?? '').toLowerCase();
        const userB = (b.username ?? b.organizationName ?? '').toLowerCase();
        if (userA < userB) return -1;
        if (userA > userB) return 1;
        return 0;
      }) ?? []
    );
  }, [users]);

  // Enabled and disabled users are for Admin Page.
  const enabledUsers = usersList?.filter((user) => user.enabled === 'True') ?? [];
  const disabledUsers = usersList?.filter((user) => user.enabled === 'False') ?? [];

  return (
    <Box className={classes.outerContainer} display="inline-block">
      <br />
      <Card sx={{ maxWidth: 360 }}>
        <List
          sx={{
            overflow: 'scroll',
            overflowX: 'hidden',
            maxHeight: '70vh',
            maxWidth: 360,
            minWidth: 360,
            bgcolor: 'background.paper',
          }}
          disablePadding={true}
          padding={0}
        >
          {editingUserPrivilege >= requiredEditingUserPrivilege && addUserButton()}

          {enabledUsers.length <= 0 &&
            disabledUsers.length <= 0 &&
            usersList.length > 0 &&
            usersList.map((user, index) => {
              user.id = user._id;
              return (
                <ListItem
                  key={index}
                  component="div"
                  button={true}
                  onClick={() => clickCallBack(user)}
                  className={user.status === 1 || user.status === 3 ? classes.passwordChangeItem : ''}
                >
                  <ListItemAvatar>
                    {user.username ? <CustomAvatar user={user} /> : <Avatar>{getAvatarLetter(user)}</Avatar>}
                  </ListItemAvatar>
                  <ListItemText
                    primary={getListItemTitle(user)}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                          className={user.status === 1 || user.status === 3 ? classes.passwordChangeItem : ''}
                        >
                          {getListItemSubTitle(user)}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              );
            })}

          {enabledUsers.length > 0 &&
            enabledUsers.map((user, index) => {
              const isMFAConfigured =
                user.mfaEnabled === 'Enabled' && user.phone !== 'Not Configured' && user.mfaSettings.length > 0;
              user.id = user._id;
              let toolTipTitle;

              if (user.status === 3) {
                toolTipTitle = "User can reset their password with the 'Forgot your password?' option on login";
              } else {
                toolTipTitle = 'User is still in the middle of the password change process';
              }

              return (
                <ListItem key={index} component="div" button={true} onClick={() => clickCallBack(user)}>
                  <ListItemAvatar>
                    {user.username ? <CustomAvatar user={user} /> : <Avatar>{getAvatarLetter(user)}</Avatar>}
                  </ListItemAvatar>
                  <ListItemText
                    className={user.status === 1 || user.status === 3 ? classes.passwordChangeItem : ''}
                    primary={
                      <React.Fragment>
                        <Tooltip title={toolTipTitle} placement="bottom-start" disableHoverListener={user.status === 2}>
                          <Typography>{getListItemTitle(user)}</Typography>
                        </Tooltip>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline', opacity: 0.6 }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {getListItemSubTitle(user)}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  {isMFAConfigured && (
                    <Tooltip title="MFA is configured for this user">
                      <KeyIcon />
                    </Tooltip>
                  )}
                </ListItem>
              );
            })}

          {disabledUsers.length > 0 && (
            <ListSubheader
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
                width: '93%',
                borderRadius: '10rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '1rem 1rem 1rem 1rem',
                padding: '0',
              }}
            >
              Deactivated
            </ListSubheader>
          )}

          {disabledUsers.length > 0 &&
            disabledUsers.map((user, index) => {
              user.id = user._id;
              return (
                <ListItem key={index} component="div" button={true} onClick={() => clickCallBack(user)}>
                  <ListItemAvatar>
                    {user.username ? <CustomAvatar user={user} /> : <Avatar>{getAvatarLetter(user)}</Avatar>}
                  </ListItemAvatar>
                  <ListItemText
                    primary={getListItemTitle(user)}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline', opacity: 0.6 }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {getListItemSubTitle(user)}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              );
            })}

          {!users && <CircularProgressWithLabel></CircularProgressWithLabel>}
        </List>
      </Card>
    </Box>
  );
}

export default UserListComponent;
