import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.css';
import { useEffect, useState, useRef, CSSProperties } from 'react';

interface RichTextEditorProps {
  defaultValue: string;
  value: string;
  required?: boolean;
  error?: boolean;
  label?: string;
  onChange?: (value: string) => void;
  readOnly: boolean;
  quillStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  dataCy?: string;
}

const RichTextEditor = (props: RichTextEditorProps) => {
  // default props
  const {
    defaultValue,
    value,
    required = false,
    error = false,
    label,
    readOnly,
    quillStyle,
    labelStyle,
    dataCy,
  } = props;

  // props necessary only when readOnly = false
  const { onChange } = props;

  const [labelClass, setLabelClass] = useState('');
  const [quillClass, setQuillClass] = useState('');
  const quillRef = useRef(null);

  useEffect(() => {
    let newQuillClass = readOnly
      ? 'rich-text-editor-quill-component uneditable-quill-component'
      : 'rich-text-editor-quill-component editable-quill-component';
    let newLabelClass = 'rich-text-editor-label';

    if (error) {
      newQuillClass += ' error-quill-component';
      newLabelClass += ' error-label';
    }

    setLabelClass(newLabelClass);
    setQuillClass(newQuillClass);
  }, [error, readOnly]);

  useEffect(() => {
    // Set data-cy to editor div in ReactQuill component so that cypress can point to editor
    if (quillRef.current && dataCy) {
      (quillRef.current as ReactQuill).getEditor().root.setAttribute('data-cy', dataCy);
    }
  }, [dataCy]);

  return (
    <div className="rich-text-editor" style={{ width: '100%' }}>
      {label && (
        <label className={labelClass} style={{ ...labelStyle }}>
          {label + (required ? '*' : '')}
        </label>
      )}
      <ReactQuill
        className={quillClass}
        theme={readOnly ? '' : 'snow'}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        preserveWhitespace={true}
        readOnly={readOnly}
        style={{ ...quillStyle }}
        ref={quillRef}
      />
    </div>
  );
};

export default RichTextEditor;
