import { useListCollaborationGroupQuery } from './Hooks/useCollaborationGroup';
import Spinner from 'react-bootstrap/Spinner';
import { Box } from '@mui/material';
import { useState } from 'react';
import SharedInteractionsTableWrapper from './SharedInteractionsTableWrapper';

const SharedMediaInteractionsPage = () => {
  const { isFetching: isFetchingGroups, data: groupsData } = useListCollaborationGroupQuery();

  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  if (isFetchingGroups) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" />
      </div>
    );
  }

  if (!groupsData) {
    return <div>Failed to fetch groups, try refresh this page</div>;
  }

  return (
    <Box>
      <SharedInteractionsTableWrapper
        groupId={selectedGroupId}
        onSelectedGroupChange={setSelectedGroupId}
        groupsData={groupsData}
      />
    </Box>
  );
};

export default SharedMediaInteractionsPage;
