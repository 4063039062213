import makeStyles from '@mui/styles/makeStyles';
import { useState, useEffect } from 'react';
import {
  EngagementKeys,
  IssueAndServiceSharedValues,
  IssueKeys,
  ServiceKeys,
  TextInputKeys,
} from '../../shared/constants/IssueAndServiceKeys';
import { TrackerDataType, transitionTime } from '../../shared/constants/constants';
import { TableCell, TableRow, IconButton } from '@mui/material';
import TrackerTableDataCell from './TrackerTableDataCell';
import TrackerTableEditorCell from './TrackerTableEditorCell';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TrackerCollapsedRow from './TrackerCollapsedRow';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { sendNotification } from '../../shared/requests/notifications';
import {duplicateEngagementLog, duplicateMediaInteraction, duplicateServiceLog} from './trackerTableRequest';
import findOrganizationFromContact from "../../shared/functions/findOrganizationFromContact";

const useStyles = makeStyles(({ palette }) => ({
  '@keyframes loadIssuse': {
    '0%': { backgroundColor: '#a9d5a9' },
    '100%': { backgroundColor: 'white' },
  },
  newItem: {
    animation: `$loadIssuse ${transitionTime}ms`,
  },
  dataCell: {
    padding: '6px',
    textAlign: 'center',
  },
  iconCell: {
    borderRight: 'none',
    borderLeft: 'none',
    '&> button': {
      padding: '4px',
    },
  },
  hiddenInfoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  stripedRow: {
    backgroundColor: '#f0f2f5',
  },
}));

export default function TrackerTableDataRow(props) {
  const { row, visibleCols, dropdownTable, isStripedRow, eventHandler, linkedEventHandler, user } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const initialCells = row.cells;
  const [cells, setCells] = useState(row.cells);
  const [cachedCells, setCachedCells] = useState(initialCells);
  const [isEditMode, setIsEditMode] = useState(false);
  const [detailedView, setDetailedView] = useState(false);
  //const [isDragActive, setDragActive] = useState(false);

  useEffect(() => {
    if (!isEditMode) {
      setCachedCells(row.cells);
      setCells(row.cells);
    }
  }, [row, isEditMode]);

  const { isNewlyAdded } = eventHandler.useListenOnCreation(row.id);

  const onEdit = (val, index) => {
    let newVal = eventHandler.editItem(val, index);
    setCells((cells) => ({ ...cells, [index]: newVal }));

    index === IssueKeys.outlet && setCells((cells) => ({ ...cells, Journalist: null }));
    index === IssueKeys.journalist &&
      setCells((cells) => ({ ...cells, Outlet: findOrganizationFromContact(val, dropdownTable['outlet'], 'Org') }));

    index === EngagementKeys.organization && setCells((cells) => ({ ...cells, CommunityMember: null }));
    index === EngagementKeys.communityMember &&
    setCells((cells) => ({ ...cells, Organization: findOrganizationFromContact(val, dropdownTable['organization'], 'Organization') }));
  };

  const handleEditMode = (editMode) => {
    setIsEditMode(editMode);
    setDetailedView(editMode);
  };

  const onConfirmEdit = () => {
    let updatedEntry = { id: '' };

    let keys;
    switch (eventHandler.type) {
      case TrackerDataType.SERVICE:
        keys = ServiceKeys;
        break;
      case TrackerDataType.MEDIA_INTERACTION:
        keys = IssueKeys;
        break;
      case TrackerDataType.ENGAGEMENT:
        keys = EngagementKeys;
        break;
      default:
        keys = ServiceKeys;
        break
    }

    updatedEntry['id'] = row.id;
    let appendedEdit = false;
    for (const [k, v] of Object.entries(keys)) {
      try {
        let updatedValue = cells[v];
        if (TextInputKeys.includes(v) && row.cells[v] !== cachedCells[v]) {
          updatedValue =
            updatedValue +
            '\n---\nUh oh! Someone else was editing this entry at the same time as you. But don’t worry, we’ve saved both your edits (yours above and theirs below) so neither of you will lose your work!\n---\n' +
            row.cells[v];
          appendedEdit = true;
        }
        updatedEntry[k] = updatedValue;
      } catch (e) {
        console.log(`cannot send update - edited row is missing column at index ${k}`);
      }
    }

    if (eventHandler.type === TrackerDataType.MEDIA_INTERACTION && (!updatedEntry['journalist'] || !updatedEntry['outlet'])) {
      dispatch(openSnackbar({ severity: 'error', message: `Please ensure both Outlet and Journalist are filled` }));
    } else if (eventHandler.type === TrackerDataType.ENGAGEMENT && (!updatedEntry['communityMember'] || !updatedEntry['organization'])) {
      dispatch(openSnackbar({ severity: 'error', message: `Please ensure both Organization and Community Member are filled` }));
    } else {
      if (appendedEdit) {
        dispatch(
          openSnackbar({
            severity: 'warning',
            message:
              'Someone else edited this entry while you were making your changes. Please check the entry to see which changes you would like to keep.',
          })
        );
      }
      setIsEditMode(false);

      eventHandler.updateItem(updatedEntry, ({ postId }) => {
        const existingPostTeamMemberIds = row.cells[IssueAndServiceSharedValues.teamMembers].map(
          (teamMember) => teamMember.sub
        );

        if (eventHandler.type === TrackerDataType.MEDIA_INTERACTION || eventHandler.type === TrackerDataType.ENGAGEMENT) {
          sendNotification(
            updatedEntry.lead
              ?.map((teamMember) => teamMember._id)
              .filter((teamMemberIds) => !existingPostTeamMemberIds.includes(teamMemberIds))
              .filter((teamMemberIds) => teamMemberIds !== user.id) ?? [],
            postId,
            updatedEntry.topic,
            eventHandler.type
          );
        } else {
          sendNotification(
            updatedEntry.teamMember
              ?.map((teamMember) => teamMember._id)
              .filter((teamMemberIds) => !existingPostTeamMemberIds.includes(teamMemberIds))
              .filter((teamMemberIds) => teamMemberIds !== user.id) ?? [],
            postId,
            updatedEntry.service,
            eventHandler.type
          );
        }
      });
    }
  };

  const onCancelEdit = () => {
    setCells(cachedCells);
    setIsEditMode(false);
  };

  const onDelete = () => {
    eventHandler.deleteItem(row.id);
  };

  const onDuplicate = async () => {
    // should duplicate in backend
    let trackerTypeStr;
    switch (eventHandler.type) {
      case TrackerDataType.SERVICE:
        trackerTypeStr = 'Service';
        break;
      case TrackerDataType.MEDIA_INTERACTION:
        trackerTypeStr = 'Media Interaction';
        break;
      case TrackerDataType.ENGAGEMENT:
        trackerTypeStr = 'Engagement';
        break;
      default:
        trackerTypeStr = 'Service';
        break;
    }

    try {
      const duplicateId = row.id;
      if (eventHandler.type === TrackerDataType.SERVICE) {
        await duplicateServiceLog(duplicateId);
      } else if (eventHandler.type === TrackerDataType.ENGAGEMENT) {
        await duplicateEngagementLog(duplicateId);
      } else {
        await duplicateMediaInteraction(duplicateId);
      }
      dispatch(
        openSnackbar({
          severity: 'success',
          message: trackerTypeStr + ' Duplicated!',
        })
      );
    } catch (error) {
      console.error('duplicate failed', error);
      dispatch(
        openSnackbar({
          severity: 'error',
          message:
            'Failed to duplicate ' + trackerTypeStr,
        })
      );
    }
  };

  // const handleDrag = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (detailedView) {
  //     console.log(e.type);

  //     if (e.type === 'dragenter' || e.type === 'dragover') {
  //       setDragActive(true);
  //     } else if (e.type === 'dragleave') {
  //       setDragActive(false);
  //     }
  //   }
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setDragActive(false);
  //   if (e.dataTransfer.files && e.dataTransfer.files[0] && isDragActive) {
  //     const headers = { 'content-type': 'multipart/form-data' };
  //     let files = new FormData();

  //     //TODO handle zero or multiple files
  //     let f = e.dataTransfer.files[0];
  //     files.append('file', f);

  //     API.put(`/issue/${row.id}/upload`, files, { headers: headers })
  //       .then((response) => {
  //         if (response.statusText !== 'OK') {
  //           alert(response.statusText);
  //         }

  //         //TODO add file to interaction
  //         return response;
  //       })
  //       .catch((e) => {
  //         alert('post failed - ', e);
  //       });

  //     e.dataTransfer.clearData();
  //   }
  // };

  return (
    <>
      <TableRow
        // onDragEnter={handleDrag}
        // onDragLeave={handleDrag}
        // onDragOver={handleDrag}
        // onDrop={handleDrop}
        className={`${isStripedRow ? classes.stripedRow : ''} ${isNewlyAdded ? classes.newItem : ''}`}
        key={row.id}
      >
        <TableCell className={`${classes.dataCell} ${classes.iconCell}`}>
          <IconButton aria-label="expand row" size="small" onClick={() => setDetailedView(!detailedView)}>
            {detailedView ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TrackerTableEditorCell
          isEditMode={isEditMode}
          handleEditMode={handleEditMode}
          onConfirmEdit={onConfirmEdit}
          onCancelEdit={onCancelEdit}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          className={`${classes.dataCell} ${classes.iconCell}`}
          showDuplicate={
            eventHandler.type === TrackerDataType.MEDIA_INTERACTION ||
            eventHandler.type === TrackerDataType.SERVICE ||
            eventHandler.type === TrackerDataType.ENGAGEMENT
          }
          type={eventHandler.type}
          rowId={row.id}
        />
        {visibleCols.map((key) => (
          <TrackerTableDataCell
            type={eventHandler.type}
            key={key}
            cells={cells}
            index={key}
            isEditMode={isEditMode}
            onEdit={onEdit}
            dropdownTable={dropdownTable}
            className={classes.dataCell}
          />
        ))}
      </TableRow>
      {detailedView && (
        <TrackerCollapsedRow
          open={detailedView}
          dropdownTable={dropdownTable}
          isEditMode={isEditMode}
          onEdit={onEdit}
          cells={cells}
          colSpan={visibleCols.length + 2}
          type={eventHandler.type}
          linkedEventHandler={linkedEventHandler}
          id={row.id}
        />
      )}
    </>
  );
}
