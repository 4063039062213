import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  archiveCommunityMember,
  archiveExpert,
  archiveJournalist,
  archiveOrg, archiveOrganization, unarchiveCommunityMember,
  unarchiveExpert,
  unarchiveJournalist,
  unarchiveOrg, unarchiveOrganization,
} from '../contactsRequest';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';

type ContactCategory = 'Journalists' | 'Experts' | 'Outlets' | 'Community Members' | 'Organizations';

const ContactType = {
  'Journalists': 'Journalist',
  'Experts': 'Expert',
  'Outlets': 'Outlet',
  'Community Members': 'Community Members',
  'Organizations': 'Organizations',
};

const getArchiveContactMutationMethod = (category: ContactCategory, isArchiving: boolean) => {
  switch (category) {
    case 'Journalists':
      return isArchiving ? archiveJournalist : unarchiveJournalist;
    case 'Experts':
      return isArchiving ? archiveExpert : unarchiveExpert;
    case 'Outlets':
      return isArchiving ? archiveOrg : unarchiveOrg;
    case 'Community Members':
      return isArchiving ? archiveCommunityMember : unarchiveCommunityMember;
    case 'Organizations':
      return isArchiving ? archiveOrganization : unarchiveOrganization;
    default:
      throw new Error('unknown contact type');
  }
};

type UseArchiveContactArg = {
  contactId: string | undefined;
  category: ContactCategory;
  isArchiving: boolean;
  setRefetchContacts: React.Dispatch<React.SetStateAction<any>>;
};

export const useArchiveContact = ({ contactId, category, isArchiving, setRefetchContacts }: UseArchiveContactArg) => {
  const dispatch = useDispatch();
  const mutationMethod = getArchiveContactMutationMethod(category, isArchiving);

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => mutationMethod(contactId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dropdown-table'] });
      if (setRefetchContacts) {
        setRefetchContacts(true);
      }
      dispatch(
        openSnackbar({
          message: `${ContactType[category]} ${isArchiving ? 'Archived' : 'Unarchived'}!`,
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      console.error('failed to archive/unarchive contact', error);
      dispatch(
        openSnackbar({
          message: `Failed to ${isArchiving ? 'archive' : 'unarchive'} ${ContactType[category]}`,
          severity: 'error',
        })
      );
    },
  });
};
