import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Colors } from 'chart.js';
import dayjs from '../../utils/dayjs';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors);

const monthStrLen = 'short';

export function StackedBarChart({ chartData, title, query, type }) {
  let dates = parseDateRangeFromQuery(query);
  let { startDate, endDate } = dates;
  let monthDateArray = getMonthDatesArray(startDate, endDate);
  let placeholderName = 'empty month placeholder';

  const data = {
    datasets: [],
  };

  //add placeholder values to show empty months
  data.datasets.push({
    label: placeholderName,
    data: getMonthsWithInitialValue1(monthDateArray),
    borderColor: 'black',
    backgroundColor: 'white',
    borderWidth: 1,
    borderRadius: 3,
    hoverBackgroundColor: 'white',
  });

  const dataProcessingDoneInMongoQuery = chartData[0].hasOwnProperty('label');

  if (dataProcessingDoneInMongoQuery) {
    chartData.forEach((d) => {
      const color = randomColorGenerator();
      data.datasets.push({
        label: d.label,
        data: d.data,
        borderColor: color[1],
        backgroundColor: color[0],
        borderWidth: 1,
        borderRadius: 3,
        hoverBackgroundColor: color[2],
      });
    });
  } else {
    //code to be cleaned up next sprint, chart components need to be independent of data sources
    chartData.forEach((d) => {
      const datum = chartJSDataParser(d);
      const color = randomColorGenerator();
      const isValidDatum = d.name !== undefined && d.name !== 'All Team';

      if (isValidDatum) {
        data.datasets.push({
          label: d.name,
          data: datum,
          borderColor: color[1],
          backgroundColor: color[0],
          borderWidth: 1,
          borderRadius: 3,
          hoverBackgroundColor: color[2],
        });
      }
    });
  }

  const options = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          filter: function (item) {
            // remove empty month placeholder from the legend
            return item.text !== placeholderName;
          },
        },
      },
      title: {
        display: true,
        text: title,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRatio: false,
  };

  return <Bar options={options} data={data} />;
}

function dateStrToMonth(timestamp) {
  const date = dayjs(timestamp);
  return date.format('MMM YYYY');
}

function randomColorGenerator() {
  const min = 100;
  const max = 255;
  const r = Math.floor(Math.random() * (max - min + 1)) + min;
  const g = Math.floor(Math.random() * (max - min + 1)) + min;
  const b = Math.floor(Math.random() * (max - min + 1)) + min;

  return [`rgba(${r}, ${g}, ${b}, 0.2)`, `rgb(${r}, ${g}, ${b})`, `rgba(${r}, ${g}, ${b}, 0.7)`];
}

//parse data to fit chartJS data structure
function chartJSDataParser(d) {
  return d.x.reduce((accumulator, key, index) => {
    accumulator[dateStrToMonth(key)] = d.y[index];
    return accumulator;
  }, {});
}

//returns an object containing the start and end date according to the URL parameters in the query string
function parseDateRangeFromQuery(query) {
  const params = new URLSearchParams(query);
  const startDate = new Date(params.get('startDate'));
  startDate.setDate(1);
  const endDate = new Date(params.get('endDate'));
  return { startDate, endDate };
}

//returns an array of dates in the format 'Month Year'
function getMonthDatesArray(startDate, endDate) {
  let datesArray = [];

  while (startDate <= endDate) {
    let options = { month: monthStrLen, year: 'numeric' };
    let formattedDate = startDate.toLocaleDateString('en-US', options);
    datesArray.push(formattedDate);
    startDate.setMonth(startDate.getMonth() + 1);
  }
  return datesArray;
}

function getMonthsWithInitialValue1(monthDatesArray) {
  let result = monthDatesArray.reduce((acc, curr) => {
    acc[curr] = 0;
    return acc;
  }, {});
  return result;
}
