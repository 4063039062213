import React from 'react';

interface PositiveIconProps {
  className?: string;
}

const PositiveIcon: React.FC<PositiveIconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.375 0.6875C4.8125 0.25 5.375 0.03125 5.96875 0.03125H10C10.5938 0.03125 11.1562 0.25 11.5938 0.6875L15.3125 4.40625C15.75 4.84375 15.9688 5.40625 15.9688 6V10.0312C15.9688 10.625 15.75 11.1875 15.3125 11.625L11.5938 15.3438C11.1562 15.7812 10.5938 16 10 16H5.96875C5.375 16 4.8125 15.7812 4.375 15.3438L0.65625 11.625C0.21875 11.1875 0 10.625 0 10.0312V6C0 5.40625 0.21875 4.84375 0.65625 4.40625L4.375 0.6875ZM7.25 10.75C7.25 11.1875 7.5625 11.5 8 11.5C8.40625 11.5 8.75 11.1875 8.75 10.75V8.75H10.75C11.1562 8.75 11.5 8.4375 11.5 8C11.5 7.59375 11.1562 7.25 10.75 7.25H8.75V5.25C8.75 4.84375 8.40625 4.5 8 4.5C7.5625 4.5 7.25 4.84375 7.25 5.25V7.25H5.25C4.8125 7.25 4.5 7.59375 4.5 8C4.5 8.4375 4.8125 8.75 5.25 8.75H7.25V10.75Z"
        fill="#5FBA7D"
      />
    </svg>
  );
};

export default PositiveIcon;
