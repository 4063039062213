import {
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TableBody,
  Typography,
  TablePagination,
  LinearProgress,
  Chip,
  Button,
  Box,
  Stack,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { getRelatedIssuesByContactPersonnelID } from './contactsRequest';
import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TableViewIcon from '@mui/icons-material/TableView';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CombinedContactPersonnelModal from './Modal/CombinedContactPersonnelModal';
import { useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import { capitalize } from '../../shared/functions/misc';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { useArchiveContact } from './Hooks/useArchiveContact';
import makeStyles from '@mui/styles/makeStyles';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LanguageIcon from '@mui/icons-material/Language';
import PersonIcon from '@mui/icons-material/Person';
import SettingsCellIcon from '@mui/icons-material/SettingsCell';
import NotesIcon from '@mui/icons-material/Notes';
import { cellColorsNew } from '../../shared/constants/commonCellColors';
import stylizeCell from '../../shared/functions/cellStyling';
import dayjs from '../../utils/dayjs';

// Lookup units using unit id
const lookupUnit = (unitId, units) => {
  const unit = units.find((unit) => unit._id === unitId);
  return unit ? unit.name : '❗Outdated - Please update/Merge this contact.';
};

// get related issues using contact id
const relatedIssues = async (category, contactId) => {
  const issues = await getRelatedIssuesByContactPersonnelID(category, contactId).then((res) => {
    return res;
  });
  return issues;
};

const useStyles = makeStyles((theme) => ({
  contactPersonnelProfileAvatar: {
    width: '130px',
    height: '130px',
    marginRight: '10px',
  },
  contactPersonnelInformation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    width: '420px',
    marginRight: '20px',
  },
  contactPersonnelInformationNotes: {
    width: '700px',
  },
  contactPersonnelTableHead: {
    backgroundColor: '#DEDEDE',
  },
  ...cellColorsNew,
}));

const ContactPersonnelProfile = (props) => {
  const classes = useStyles();
  const { selectedContact, category, units, setRefreshContacts, setRefreshOrgs } = props;

  const [isLoadingRelatedIssues, setIsLoadingRelatedIssues] = useState(false);

  const [issuesPerPage, setIssuesPerPage] = useState(5);
  const [issues, setIssues] = useState([]);
  const [page, setPage] = useState(0);
  const visibleIssues = useMemo(
    () => [...issues].slice(page * issuesPerPage, page * issuesPerPage + issuesPerPage),
    [page, issuesPerPage, issues]
  );

  const [openEditContact, setOpenEditContact] = useState(false);

  const navigate = useNavigate();
  const dispatch = useFiltersDispatch();

  const unarchiveContactMutation = useArchiveContact({
    contactId: selectedContact?._id,
    category: category,
    isArchiving: false,
    setRefetchContacts: setRefreshContacts,
  });

  let selectContactRecord;
  switch (category) {
    case 'Journalists':
      selectContactRecord = {
        journalist: [
          {
            firstLetter: selectedContact?.name?.split(' ')[0][0] ?? '',
            name: selectedContact?.name ?? '',
            _id: selectedContact?._id ?? '',
          },
        ],
      };
      break;
    case 'Community Members':
      selectContactRecord = {
        communityMember: [
          {
            firstLetter: selectedContact?.name?.split(' ')[0][0] ?? '',
            name: selectedContact?.name ?? '',
            _id: selectedContact?._id ?? '',
          },
        ],
      };
      break;
    case 'Experts':
    default:
      selectContactRecord = {
        expert: [
          {
            firstLetter: selectedContact?.name?.split(' ')[0][0] ?? '',
            name: selectedContact?.name ?? '',
            _id: selectedContact?._id ?? '',
          },
        ],
      };
      break;
  }

  const handleUnarchiveButtonClick = () => {
    unarchiveContactMutation.mutateAsync();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setIssuesPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let editContactButton = (
    <Button
      onClick={() => {
        setOpenEditContact(true);
      }}
      data-cy="editContact-button-ContactPersonnelProfile"
    >
      <EditOutlinedIcon />
    </Button>
  );

  const getOrgTypeForCategory = () => {
    let orgType;
    let orgName;
    switch (category) {
      case 'Journalists':
        orgType = 'Outlet';
        orgName = capitalize(selectedContact?.Org?.name ?? 'N/A');
        break;
      case 'Experts':
        orgType = 'Unit';
        orgName = capitalize(selectedContact?.unit ? lookupUnit(selectedContact.unit, units) : 'N/A');
        break;
      case 'Community Members':
        orgType = 'Organization';
        orgName = capitalize(selectedContact?.Organization?.name ?? 'N/A');
        break;
      default:
        orgType = 'Outlet';
        orgName = 'N/A';
        break;
    }
    return `${orgName} (${orgType})`;
  };

  useEffect(() => {
    const getIssues = async () => {
      setIsLoadingRelatedIssues(true);

      const issues = await relatedIssues(category, selectedContact?._id ?? '');
      if (issues) {
        setIssues(issues.data ? issues.data : []);
      }

      setIsLoadingRelatedIssues(false);
    };

    // TODO Engagement: check if we're supporting this for engagements
    if (selectedContact?._id && category !== 'Community Members') {
      getIssues();
    } else {
      console.log('No contact personnel selected');
    }
  }, [selectedContact, category, setIssues]);

  if (!selectedContact) {
    return (
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          overflow: 'auto',
          minWidth: '100%',
        }}
      ></List>
    );
  }

  if (selectedContact.archived) {
    editContactButton = (
      <Tooltip title="Unarchive">
        <IconButton
          size="small"
          onClick={() => handleUnarchiveButtonClick()}
          data-cy="unarchive-button-ContactPersonnelProfile"
        >
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          overflow: 'auto',
          minWidth: '100%',
        }}
      >
        <ListItem key={'name'}>
          <Stack direction="row" alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className={classes.contactPersonnelProfileAvatar}>
                <Typography variant="h1">{selectedContact.name.split(' ')[0][0]}</Typography>
              </Avatar>
            </ListItemAvatar>
            <Stack direction="column">
              <Stack direction="row" alignItems="flex-start">
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  {selectedContact.name ?? ''}
                </Typography>
                {editContactButton}
              </Stack>
              <Stack direction="row" alignItems="flex-start">
                <Typography variant="h6">
                  <Box className={classes.contactPersonnelInformation}>
                    <WorkIcon />
                    {capitalize(`${category.slice(0, -1)}`)}
                  </Box>
                </Typography>
                {selectedContact.email && (
                  <Typography variant="h6">
                    <Box className={classes.contactPersonnelInformation}>
                      <EmailIcon />
                      {selectedContact.email}
                    </Box>
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" alignItems="flex-start">
                <Typography variant="h6">
                  <Box className={classes.contactPersonnelInformation}>
                    <ApartmentIcon />
                    {getOrgTypeForCategory()}
                  </Box>
                </Typography>
                {selectedContact.phone && (
                  <Typography variant="h6">
                    <Box className={classes.contactPersonnelInformation}>
                      <LocalPhoneIcon />
                      {selectedContact.phone}
                    </Box>
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" alignItems="flex-start">
                {(category === 'Journalists' || category === 'Community Members') && selectedContact.cell && (
                  <Typography variant="h6">
                    <Box className={classes.contactPersonnelInformation}>
                      <SettingsCellIcon />
                      {selectedContact.cell}
                    </Box>
                  </Typography>
                )}
                {category === 'Experts' && selectedContact.language && (
                  <Typography variant="h6">
                    <Box className={classes.contactPersonnelInformation}>
                      <LanguageIcon />
                      {selectedContact.language}
                    </Box>
                  </Typography>
                )}
                {category === 'Experts' && selectedContact.gender && (
                  <Typography variant="h6">
                    <Box className={classes.contactPersonnelInformation}>
                      <PersonIcon />
                      {selectedContact.gender}
                    </Box>
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" alignItems="flex-start">
                {selectedContact.notes && (
                  <Typography variant="h6">
                    <Box className={[classes.contactPersonnelInformation, classes.contactPersonnelInformationNotes]}>
                      <NotesIcon />
                      {selectedContact.notes}
                    </Box>
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </ListItem>

        <ListItem
          key={'topics'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: '100%',
            paddingTop: '10px',
          }}
        >
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Covered Topics
          </Typography>

          <Button
            variant="contained"
            onClick={() => {
              dispatch({ type: 'setFilters', payload: selectContactRecord });
              navigate(RouteInformation.mediaInteraction.path);
            }}
            data-cy="tableView-button-ContactPersonnelProfile"
            disabled={isLoadingRelatedIssues}
          >
            <TableViewIcon />
            View in Media Interaction
          </Button>
        </ListItem>

        {isLoadingRelatedIssues ? (
          <LinearProgress />
        ) : (
          <ListItem key="topics table">
            <Stack sx={{ width: '100%' }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.contactPersonnelTableHead}>
                      <TableCell>Topics</TableCell>
                      <TableCell align="right">Date</TableCell>
                      <TableCell align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleIssues.map((issue) => (
                      <TableRow key={issue.id} sx={{ height: '55px' }}>
                        <TableCell component="th" scope="row">
                          <Link
                            to={RouteInformation.mediaInteraction.path}
                            style={{ textDecoration: 'none' }}
                            onClick={() => {
                              // Set filters with selected contact and the specific topic
                              dispatch({
                                type: 'setFilters',
                                payload: {
                                  ...selectContactRecord,
                                  topic: issue.cells.Topic,
                                },
                              });
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                display: 'inline',
                              }}
                              style={{ textDecoration: 'underline' }}
                            >{`${issue.cells.Topic}`}</Typography>
                          </Link>
                        </TableCell>
                        <TableCell align="right">{`${dayjs(issue.cells.Date).format('MMM D, YYYY')}`}</TableCell>
                        <TableCell align="right" className={stylizeCell(issue.cells.Status, classes)}>
                          <Chip
                            label={issue.cells.Status === 'Participation Ribbon' ? 'Participation' : issue.cells.Status}
                            variant="filled"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={issues.length}
                rowsPerPage={issuesPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </ListItem>
        )}
      </List>
      <CombinedContactPersonnelModal
        openAddContact={openEditContact}
        setOpenAddContact={setOpenEditContact}
        orgs={props.orgs}
        units={units}
        setRefreshContacts={setRefreshContacts}
        setRefreshOrgs={setRefreshOrgs}
        category={category}
        isEditing={openEditContact}
        selectedContact={selectedContact}
        isInContactPage={true}
      />
    </>
  );
};

export default ContactPersonnelProfile;
