import { AxiosError } from 'axios';
import {API, TrackerDataType} from '../constants/constants';

export enum NotificationType {
  TEAM_MEMBER_TAGGING = 'TEAM_MEMBER_TAGGING',
}

export interface Notification {
  _id: string;
  date: string;
  notificationType: NotificationType;
  redirectTo: string;
  receiver: UserId;
  sender: UserId;
  content: string;
  isRead: boolean;
  tenantIdDisc: string;
}

export const getNotificationsForCurrentUser = async (setNotificationsToRead: boolean = false) => {
  try {
    const res = await API.get<{ notifications: Notification[] }>(`/notifications/user`, {
      params: { setNotificationsToRead },
    });
    return res.data.notifications;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new Error(err.response?.data?.message ?? 'Error occurred when getting user notifications');
    } else {
      throw err;
    }
  }
};

export const sendNotification = async (
  destUserIds: string[],
  postId: string,
  postTitle: string,
  type: TrackerDataType
) => {
  try {
    const res = await API.post('/notifications/user', {
      destUserIds: destUserIds,
      emailService: 'aws',
      postId: postId,
      postTitle: postTitle,
      type: type,
    });

    return res.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new Error(err.response?.data?.message ?? 'Error occurred when sending user notification');
    } else {
      throw err;
    }
  }
};

export const deleteNotification = async (notificationId: string) => {
  try {
    const res = await API.delete(`/notifications/${notificationId}`);

    return res.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new Error(err.response?.data?.message ?? 'Error occurred when sending user notification');
    } else {
      throw err;
    }
  }
};
