import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RouteInformation } from '../../shared/constants/RouteInformation';

const ProtectedRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  let location = useLocation();

  if (!user.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const hasUnreadPopups = !user.hasAgreedTermsOfService || !user.broadcastPopup.seen;
  if (hasUnreadPopups && location.pathname !== RouteInformation.broadcastPopup.path) {
    return <Navigate to={RouteInformation.broadcastPopup.path} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
