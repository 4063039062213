import { TableCell } from '@mui/material';
import { IconButton, Menu, MenuItem, Tooltip, ToggleButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/DoneAllTwoTone';
import RevertIcon from '@mui/icons-material/NotInterestedOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ToolTip from '@mui/material/Tooltip';
import { useState } from 'react';
import VerificationModal from '../VerificationModal/VerificationModal';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { TrackerDataType } from '../../shared/constants/constants';
import MoreOptionsIcon from '../TrackerFilter/Icons/MoreOptionsIcon';
import EditPencilIcon from '../TrackerFilter/Icons/EditPencilIcon';
import CopyUrlIcon from '../TrackerFilter/Icons/CopyUrlIcon';
import DuplicateIcon from '../TrackerFilter/Icons/DuplicateIcon';

export default function TrackerTableEditorCell({
  isEditMode,
  handleEditMode,
  onConfirmEdit,
  onCancelEdit,
  onDelete,
  onDuplicate,
  className,
  showDuplicate = false,
  type,
  rowId,
}) {
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}${
        type === TrackerDataType.MEDIA_INTERACTION
          ? RouteInformation.mediaInteraction.path
          : type === TrackerDataType.ENGAGEMENT
          ? RouteInformation.engagementLog.path
          : RouteInformation.serviceLog.path
      }/${rowId}`
    );
    handleClose();
  };

  return (
    <TableCell className={className}>
      {isEditMode ? (
        <>
          <ToolTip title="Done" placement="left">
            <IconButton onClick={() => onConfirmEdit()} size="large">
              <DoneIcon />
            </IconButton>
          </ToolTip>
          <ToolTip title="Cancel" placement="left">
            <IconButton onClick={() => onCancelEdit()} size="large">
              <RevertIcon />
            </IconButton>
          </ToolTip>
          <ToolTip title="Delete" placement="left">
            <IconButton onClick={() => setOpenVerificationModal(true)} size="large" data-cy="Delete-Button-Editor-Cell">
              <DeleteIcon />
            </IconButton>
          </ToolTip>
          <VerificationModal
            openVerificationModal={openVerificationModal}
            setOpenVerificationModal={setOpenVerificationModal}
            modalTitle={'Confirm Deletion'}
            modalDescription={`Are you sure you want to delete this ${
              type === TrackerDataType.MEDIA_INTERACTION
                ? 'Media Interaction'
                : type === TrackerDataType.ENGAGEMENT
                ? 'Engagement'
                : 'Service'
            }?`}
            handleYesClick={() => {
              onDelete();
              setOpenVerificationModal(false);
            }}
          />
        </>
      ) : (
        <>
          <Tooltip title="More options" placement="left">
            <ToggleButton
              value="more-options"
              selected={open}
              onChange={handleClick}
              size="small"
              sx={{
                color: (theme) => theme.palette.primaryDefault.main,
                border: 'none',
                padding: '0',
                borderRadius: '50%',
                width: '22px',
                height: '22px',
                transition: 'all 0.2s ease-in-out',
                '& .MuiSvgIcon-root': {
                  width: '26px',
                  height: '26px',
                },
                '&.Mui-selected': {
                  backgroundColor: (theme) => theme.palette.primaryDark.main,
                  color: (theme) => theme.palette.primaryDark.contrastText,
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primaryDark.main,
                  },
                },
                '&:hover': {
                  color: (theme) => theme.palette.toneNeutral.main,
                },
              }}
            >
              <MoreOptionsIcon />
            </ToggleButton>
          </Tooltip>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                handleEditMode(true);
                handleClose();
              }}
            >
              <EditPencilIcon sx={{ mr: 1, color: (theme) => theme.palette.toneNeutral.main }} /> Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCopyUrl();
                handleClose();
              }}
            >
              <CopyUrlIcon sx={{ mr: 1, color: (theme) => theme.palette.toneNeutral.main }} /> Copy URL
            </MenuItem>
            {showDuplicate && (
              <MenuItem
                onClick={() => {
                  onDuplicate();
                  handleClose();
                }}
              >
                <DuplicateIcon sx={{ mr: 1, color: (theme) => theme.palette.toneNeutral.main }} /> Duplicate
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </TableCell>
  );
}
