import { Box, IconButton, MenuItem, Select, TextField, InputAdornment, SelectChangeEvent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilters } from '../TrackerFilter/Context/FiltersContext';
import { useState } from 'react';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import {
  engagementLogCategories,
  mediaInteractionCategories,
  servicePageCategories,
} from '../../shared/constants/constants';
import isFilterEmpty from '../../shared/functions/isFilterEmpty';
import ClearIcon from '@mui/icons-material/Clear';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    border: '1px solid #ccc',
    borderRadius: 4,
    height: '32px',
  },
  select: {
    height: '100%',
    border: 'none',
    '& fieldset': { border: 'none' },
    borderRight: '1px solid #ccc',
    borderRadius: '0',
  },
  textfieldInput: {
    height: '100%',
    '& fieldset': { border: 'none' },
    padding: 0,
    width: '400px',
  },
  button: {
    height: '32px',
    borderLeft: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: '#f5f5f5',
    '&:hover': { backgroundColor: '#e0e0e0' },
  },
}));

export default function TrackerTableFuzzySearch() {
  const classes = useStyles();

  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const existingFilters = useFilters();
  const [globalQuery, setGlobalQuery] = useState(state?.queryString ? state.queryString : '');
  const [textFieldValue, setTextFieldValue] = useState(state?.queryString ? state.queryString : '');

  let categories;
  switch (pathname) {
    case RouteInformation.mediaInteraction.path:
      categories = mediaInteractionCategories;
      break;
    case RouteInformation.engagementLog.path:
      categories = engagementLogCategories;
      break;
    default:
      categories = servicePageCategories;
      break;
  }

  const [category, setCategory] = useState(state?.category || categories[0]);

  const handleClick = () => {
    navigate(pathname, {
      state: {
        queryString: globalQuery,
        category: category,
      },
    });
  };

  const updateGlobalQuery = (e: any) => {
    setTextFieldValue(e.target.value);
    setGlobalQuery(e.target.value);
  };

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && globalQuery) {
      handleClick();
    }
  };

  const handleCategoryChange = (e: SelectChangeEvent) => {
    setTextFieldValue('');
    setCategory(e.target.value);
  };

  const handleTextFieldClear = () => {
    setTextFieldValue('');
    setGlobalQuery('');
    navigate(pathname);
  };

  return (
    <Box className={classes.container}>
      <Select
        className={classes.select}
        disabled={!isFilterEmpty(existingFilters)}
        labelId="search-category"
        id="search-category-select"
        value={category}
        label="Category"
        onChange={handleCategoryChange}
        data-cy="search-category-select"
        sx={{ fontSize: '14px' }}
      >
        {categories.map((category) => (
          <MenuItem key={category} value={category} data-cy={`category-${category}`} sx={{ fontSize: '14px' }}>
            {category}
          </MenuItem>
        ))}
      </Select>
      <TextField
        disabled={!isFilterEmpty(existingFilters)}
        placeholder="Search for key words..."
        sx={{
          '& input::placeholder': {
            fontSize: '14px',
          },
        }}
        onChange={updateGlobalQuery}
        onKeyDown={(e) => handleEnterKeyDown(e)}
        value={textFieldValue}
        autoComplete="off"
        InputProps={{
          className: classes.textfieldInput,
          endAdornment: (
            <InputAdornment position="end">
              {state?.queryString && (
                <IconButton
                  size="small"
                  onClick={() => handleTextFieldClear()}
                  data-cy="FuzzySearch-TextField-clear-btn"
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              )}
              <IconButton
                className={classes.button}
                disabled={!globalQuery}
                onClick={() => handleClick()}
                data-cy="FuzzySearch-TextField-search-btn"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        data-cy="FuzzySearch-TextField"
      />
    </Box>
  );
}
