import TrackerFilterPopoverGrid from './TrackerFilterPopoverGrid';
import { Box, Typography } from '@mui/material';
import DatePickerInputField from '../../shared/inputs/DatePickerInputField';
import makeStyles from '@mui/styles/makeStyles';

interface TrackerFilterDateSelectionProps {
  label: string;
  startDate: Date | null;
  endDate: Date | null;
  onStartDateSelection: (date: Date | null) => void;
  onEndDateSelection: (date: Date | null) => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  datePickerContainer: {
    width: '158px',
    '& .MuiInputBase-root': {
      height: '32px',
      fontSize: '12px',
    },
  },
  datePickerInputEndIcon: {
    width: '20px',
    height: '20px',
  },
});

export default function TrackerFilterDateSelection(props: TrackerFilterDateSelectionProps) {
  const classes = useStyles();
  const { label, startDate, endDate, onStartDateSelection, onEndDateSelection } = props;

  return (
    <TrackerFilterPopoverGrid>
      {label}
      <Box className={classes.container}>
        <Typography fontSize="12px">From</Typography>
        <DatePickerInputField
          classes={{
            textInputFieldContainerClass: classes.datePickerContainer,
            textInputFieldClass: '',
            endEndAdornmentIconClass: classes.datePickerInputEndIcon,
          }}
          selected={startDate}
          onChange={(date) => onStartDateSelection(date)}
          selectsStart
          onClear={() => onStartDateSelection(null)}
        />
        <Typography fontSize="12px">To</Typography>
        <DatePickerInputField
          classes={{
            textInputFieldContainerClass: classes.datePickerContainer,
            textInputFieldClass: '',
            endEndAdornmentIconClass: classes.datePickerInputEndIcon,
          }}
          selected={endDate}
          onChange={(date) => onEndDateSelection(date)}
          selectsEnd
          onClear={() => onEndDateSelection(null)}
        />
      </Box>
    </TrackerFilterPopoverGrid>
  );
}
