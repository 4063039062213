import { Box, Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import { ServiceOrIssueStatus, ServiceOrIssueStatusArray, ServiceOrIssueStatusColorMap } from '../constants/constants';
import { StatusColors } from '../types';

interface StatusInputProps {
  isFilterModal?: boolean;
  selectedStatus?: ServiceOrIssueStatus[];
  onSelect?: (selectedStatus: ServiceOrIssueStatus[]) => void;
  multiSelect?: boolean;
}

export default function StatusInputSelection(props: StatusInputProps) {
  const { isFilterModal, selectedStatus, onSelect, multiSelect } = props;
  const [localSelectedStatus, setLocalSelectedStatus] = useState<ServiceOrIssueStatus[]>([]);
  const [chipColors, setChipColors] = useState<{ [key in ServiceOrIssueStatus]: StatusColors | 'default' }>({
    [ServiceOrIssueStatus.IN_PROGRESS]: 'default',
    [ServiceOrIssueStatus.PARTICIPATION_RIBBON]: 'default',
    [ServiceOrIssueStatus.COMPLETED]: 'default',
    [ServiceOrIssueStatus.CANCELLED]: 'default',
  });

  useEffect(() => {
    setLocalSelectedStatus(selectedStatus ?? []);
  }, [selectedStatus]);

  useEffect(() => {
    setChipColors({
      [ServiceOrIssueStatus.IN_PROGRESS]: getChipColor(ServiceOrIssueStatus.IN_PROGRESS),
      [ServiceOrIssueStatus.PARTICIPATION_RIBBON]: getChipColor(ServiceOrIssueStatus.PARTICIPATION_RIBBON),
      [ServiceOrIssueStatus.COMPLETED]: getChipColor(ServiceOrIssueStatus.COMPLETED),
      [ServiceOrIssueStatus.CANCELLED]: getChipColor(ServiceOrIssueStatus.CANCELLED),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSelectedStatus]);

  const getChipColor = (statusName: ServiceOrIssueStatus) => {
    const isSelected = localSelectedStatus.some((selectedStatus) => selectedStatus === statusName);
    if (isSelected) {
      return ServiceOrIssueStatusColorMap[statusName];
    } else {
      return 'default';
    }
  };

  const handleChipClick = (statusName: ServiceOrIssueStatus) => {
    const isSelected = localSelectedStatus.some((selectedStatus) => selectedStatus === statusName);

    let selectedChips: ServiceOrIssueStatus[] = [];
    if (isSelected) {
      selectedChips = localSelectedStatus.filter((selectedChip) => selectedChip !== statusName);
    } else if (multiSelect) {
      selectedChips = [...localSelectedStatus, statusName];
    } else {
      selectedChips = [statusName];
    }

    setLocalSelectedStatus(selectedChips);

    if (onSelect) {
      onSelect(selectedChips);
    }
  };

  const chips = ServiceOrIssueStatusArray.map((statusName, index) => (
    <Chip
      key={`${statusName}-${index}`}
      color={chipColors[statusName]}
      variant="filled"
      label={statusName === ServiceOrIssueStatus.PARTICIPATION_RIBBON ? 'Participation' : statusName}
      onClick={() => handleChipClick(statusName as ServiceOrIssueStatus)}
      data-cy={`status-${statusName}`}
    />
  ));

  if (isFilterModal) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>{chips}</Box>
    );
  }

  return <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '20px' }}>{chips}</Box>;
}
