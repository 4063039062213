import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { capitalize } from '../../shared/functions/misc';
import ContactsVirtualList from './ContactsVirtualList';
import WorkIcon from '@mui/icons-material/Work';
import ApartmentIcon from '@mui/icons-material/Apartment';

// generate a random color for each contact
function stringToColor(string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: '70px',
      height: '70px',
      marginRight: '10px',
    },
    children: <Typography variant="h3">{name[0]}</Typography>,
  };
}

const lookupUnit = (contact, unitId, units) => {
  if (contact.unitName) return contact.unitName;

  const unit = units.find((unit) => unit._id === unitId);

  contact.unitName = unit ? unit.name : '❗Outdated';
  return contact.unitName;
};

// check if the contact has a org and return the org's name and its role
function getContactSecondaryComponent(contact, category, units) {
  let orgName;
  let orgRole;
  if (contact.Org) {
    orgName = capitalize(`${contact.Org.name}`);
    orgRole = capitalize(`${category.slice(0, -1)}`);
  } else if (contact.unit) {
    orgName = capitalize(`${lookupUnit(contact, contact.unit, units)}`);
    orgRole = capitalize(`${category.slice(0, -1)}`);
  } else if (contact.Organization) {
    orgName = capitalize(`${contact.Organization.name}`);
    orgRole = capitalize(`${category.slice(0, -1)}`);
  } else {
    orgName = '';
    orgRole = capitalize(`${category.slice(0, -1)}`);
  }

  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" gap="5px">
        <WorkIcon />
        {orgRole}
      </Stack>
      <Stack direction="row" alignItems="center" gap="5px">
        <ApartmentIcon />
        {orgName}
      </Stack>
    </Stack>
  );
}

const ContactPersonnelList = (props) => {
  const contacts = props.data;
  const { handleContactClick, isLoadingList, units, category, selectedContact, style } = props;
  // Props from MergeContactPersonnelModal
  const { isMerging, mergingContacts, setMergingContacts } = props;

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '0',
        margin: '0',
        maxHeight: '100%',
        overflow: 'auto',
        width: '35rem',
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          overflow: 'auto',
          padding: 0,
          border: '1px solid #DEDEDE',
          ...style,
        }}
      >
        {isLoadingList ? <LinearProgress /> : null}
        <ContactsVirtualList
          contactsSize={contacts ? contacts.length : 0}
          searchResultItems={contacts ?? []}
          isMerging={isMerging}
          setMergingContacts={setMergingContacts}
          mergingContacts={mergingContacts}
          selectedContact={selectedContact}
          handleContactClick={handleContactClick}
          itemSize={120}
          getListItemAvatarProps={(name) => stringAvatar(name)}
          getListItemSecondaryComponent={(contact) => getContactSecondaryComponent(contact, category, units)}
        />
      </ListItem>
    </List>
  );
};

export default ContactPersonnelList;
