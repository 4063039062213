import React from 'react';

interface NeutralIconProps {
  className?: string;
}

const NeutralIcon: React.FC<NeutralIconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.375 0.6875C4.8125 0.25 5.375 0.03125 5.96875 0.03125H10C10.5938 0.03125 11.1562 0.25 11.5938 0.6875L15.3125 4.40625C15.75 4.84375 15.9688 5.40625 15.9688 6V10.0312C15.9688 10.625 15.75 11.1875 15.3125 11.625L11.5938 15.3438C11.1562 15.7812 10.5938 16 10 16H5.96875C5.375 16 4.8125 15.7812 4.375 15.3438L0.65625 11.625C0.21875 11.1875 0 10.625 0 10.0312V6C0 5.40625 0.21875 4.84375 0.65625 4.40625L4.375 0.6875ZM5.75 7.25C5.3125 7.25 5 7.59375 5 8C5 8.4375 5.3125 8.75 5.75 8.75H10.25C10.6562 8.75 11 8.4375 11 8C11 7.59375 10.6562 7.25 10.25 7.25H5.75Z"
        fill="#707070"
      />
    </svg>
  );
};

export default NeutralIcon;
