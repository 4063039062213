import { Spinner } from 'react-bootstrap';
import BroadcastPopupDialog from './BroadcastPopupDialog';
import { useSelector } from 'react-redux';
import { StoreState } from '../../redux-types';
import { ReactNode } from 'react';
import TermsOfServiceDialog from '../TermsOfServiceDialog/TermsOfServiceDialog';

const BroadcastPopupPage = () => {
  const user = useSelector((state: StoreState) => state.user);

  let dialogs: ReactNode = null;
  if (!user.hasAgreedTermsOfService && !user.broadcastPopup.seen) {
    dialogs = (
      <>
        <BroadcastPopupDialog />
        <TermsOfServiceDialog shouldNavigate={false} />
      </>
    );
  } else if (!user.hasAgreedTermsOfService) {
    dialogs = <TermsOfServiceDialog shouldNavigate={true} />;
  } else if (!user.broadcastPopup.seen) {
    dialogs = <BroadcastPopupDialog />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner animation="border"></Spinner>
      {dialogs}
    </div>
  );
};

export default BroadcastPopupPage;
