import { Stack, TableCell, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AIObject, EmailQueueEntry } from './Interfaces';
import {
  AutoTrackerKeys,
  IssueAndServiceSharedValues,
  IssueKeys,
  ServiceKeys,
} from '../../shared/constants/IssueAndServiceKeys';
import MissingContactButton from './MissingContactButton';
import { cellColors } from '../../shared/constants/commonCellColors';
import { DropdownTable } from '../../shared/types';
import dayjs from '../../utils/dayjs';

interface AIResultTableCellProps {
  fieldType: string;
  row: EmailQueueEntry;
  dropdownTable: DropdownTable;
}

const useStyles = makeStyles(() => ({
  dataCell: {
    padding: '10px',
    textAlign: 'center',
  },
}));

export default function AIResultTableCell({ fieldType, row, dropdownTable }: Readonly<AIResultTableCellProps>) {
  const classes = useStyles();

  const aiScrapeResult = row.aiScrapeResult;

  let textValue: string | AIObject | undefined;
  let contactInTable = true;
  let extraDisplayInfo = null;

  switch (fieldType) {
    case IssueAndServiceSharedValues.unit:
      textValue = aiScrapeResult?.Unit?.name ?? aiScrapeResult?.Unit;
      contactInTable = !!dropdownTable['units'].find((value: any) => value?.name === textValue);
      break;
    case IssueAndServiceSharedValues.department:
      textValue = aiScrapeResult?.Department?.name ?? aiScrapeResult?.Department;
      contactInTable = !!dropdownTable['dept'].find((value: any) => value?.name === textValue);
      break;
    case IssueAndServiceSharedValues.date: {
      const dateStr = aiScrapeResult?.Date ?? row.date;
      const parsedDate = dayjs(dateStr);
      if (parsedDate.isValid()) {
        textValue = parsedDate.format('MMM Do, YYYY');
      } else {
        textValue = dayjs().format('MMM Do, YYYY');
      }
      break;
    }
    case IssueAndServiceSharedValues.type:
      if (typeof aiScrapeResult?.Type === 'string') {
        textValue = aiScrapeResult.Type;
      } else {
        textValue = aiScrapeResult?.Type?.map((value) => value.name ?? value).join(', ');
      }
      break;
    case IssueAndServiceSharedValues.status:
      textValue = aiScrapeResult?.Status;
      break;
    case IssueKeys.topic:
      textValue = aiScrapeResult?.Topic;
      break;
    case ServiceKeys.service:
      textValue = aiScrapeResult?.Service;
      break;
    case IssueKeys.journalist:
      textValue = aiScrapeResult?.Journalist?.name ?? aiScrapeResult?.Journalist;
      contactInTable = !!dropdownTable['journalist'].find((value: any) => value?.name === textValue);

      if (aiScrapeResult?.Outlet) {
        const outletId = dropdownTable['outlet'].find(
          (value: any) => value?.name === (aiScrapeResult?.Outlet?.name ?? aiScrapeResult.Outlet)
        )?._id;

        contactInTable = outletId
          ? !!dropdownTable['orgAgents'][outletId]?.find((value: any) => value?.name === textValue)
          : false;
      }
      extraDisplayInfo = !contactInTable ? (
        <>
          <Typography variant={'body2'}>{aiScrapeResult?.['Journalist Phone'] as string}</Typography>
          <Typography variant={'body2'}>{aiScrapeResult?.['Journalist Email'] as string}</Typography>
        </>
      ) : null;
      break;
    case IssueKeys.outlet:
      textValue = aiScrapeResult?.Outlet?.name ?? aiScrapeResult?.Outlet;
      contactInTable = !!dropdownTable['outlet'].find((value: any) => value?.name === textValue);
      break;
    case IssueKeys.contactMethod:
      textValue = aiScrapeResult?.Contact;
      break;
    case IssueAndServiceSharedValues.leadExpert: {
      textValue = aiScrapeResult?.['Lead/Expert']?.map((expert) => expert?.name ?? expert).join(', ');
      contactInTable =
        aiScrapeResult?.['Lead/Expert']
          ?.map((expert) => {
            const expertValue = expert?.name ?? expert;
            return !!dropdownTable['expert'].find((value: any) => value?.name === expertValue);
          })
          .every(Boolean) || false;
      break;
    }
    case AutoTrackerKeys.sender:
      textValue = dropdownTable.lead.find((lead: any) => lead._id === row.sender)?.name;
      break;
    case AutoTrackerKeys.subject:
      textValue = row.subject;
      break;
    case ServiceKeys.campaign:
      textValue = aiScrapeResult?.Campaign?.name ?? aiScrapeResult?.Campaign;
      break;
    case ServiceKeys.complexity:
      textValue = aiScrapeResult?.Complexity;
      break;
    case AutoTrackerKeys.attachments:
      return (
        <Stack gap = {1}>
          {row.attachments?.map(a => {
            return <a href = {a.url} target = "_blank" rel="noreferrer">{a.name}</a>
          })}
        </Stack>
      )
    default:
      textValue = '';
      break;
  }

  const style =
    !contactInTable && textValue
      ? {
          backgroundColor: cellColors.complexity1.background,
        }
      : null;

  return (
    <TableCell key={fieldType} className={classes.dataCell} sx={style}>
      <Typography variant={'body2'}>{textValue as string}</Typography>
      {extraDisplayInfo}
      {!contactInTable && textValue && aiScrapeResult && (
        <MissingContactButton
          fieldType={fieldType}
          dropdownTable={dropdownTable}
          aiScrapeResult={aiScrapeResult}
          textValue={textValue as string}
        />
      )}
    </TableCell>
  );
}
