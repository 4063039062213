import {
  Button,
  Card,
  CardActions,
  CardContent,
  ImageList,
  ImageListItem,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import makeStyles from '@mui/styles/makeStyles';
import { switchTenant, updateTenant, updateTenantAddonControl } from './tenantsPageRequest';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import TenantAddonControlListItems from './TenantAddonControlListItems';
import { setUserAddonPageAccessControls } from '../Authorization/userSlice';

const useStyles = makeStyles({
  item: {
    padding: 0,
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignText: 'left',
  },
  button: {
    marginBottom: '2vh',
  },
});

function TenantCardComponent({ user, client }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editingClientInfo, setEditingClientInfo] = useState(client);
  const queryClient = useQueryClient();
  const editTenant = useMutation({
    mutationFn: (e) => handleEditMutation(e),
    onSuccess: () => {
      dispatch(openSnackbar({ severity: 'success', message: 'Client updated!' }));
    },
    onError: () => {
      dispatch(openSnackbar({ severity: 'error', message: `Client wasn't updated!` }));
    },
  });

  const handleEditMutation = async (e) => {
    e.preventDefault();

    setIsEditing(false);
    await updateTenant(client, {
      organizationOwner: editingClientInfo.organizationOwner,
      noOfSeats: editingClientInfo.noOfSeats,
    });
    await updateTenantAddonControl(client, {
      collaborationActivated: editingClientInfo.collaborationActivated,
      engagementLogActivated: editingClientInfo.engagementLogActivated,
      trackerAIActivated: editingClientInfo.trackerAIActivated,
    });
    dispatch(
      setUserAddonPageAccessControls({
        collaborationActivated: editingClientInfo.collaborationActivated,
        engagementLogActivated: editingClientInfo.engagementLogActivated,
        trackerAIActivated: editingClientInfo.trackerAIActivated,
      })
    );

    await queryClient.invalidateQueries(['tenants', 'tenantsPage', 'tenantSeats']);
  };

  const SwitchTenantButton = () => {
    return (
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={() => {
          switchTenant(user, client).then((res) => {
            if (res.severity === 'success') {
              window.location.reload();
            } else {
              dispatch(openSnackbar({ severity: 'error', message: 'You are not permitted to use this feature' }));
            }
          });
        }}
      >
        Switch To Client - authorized users only
      </Button>
    );
  };

  useEffect(() => {
    setIsEditing(false);
    setEditingClientInfo(client);
  }, [client]);

  const renderActions = () => {
    if (editTenant.isPending) {
      return <LinearProgress />;
    }
    return (
      <>
        <SwitchTenantButton />
        <CardActions style={{ justifyContent: 'center' }}>
          {isEditing ? (
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  editTenant.mutate(e);
                }}
              >
                SAVE
              </Button>
              <Button
                className={classes.cancelButton}
                variant="contained"
                onClick={() => {
                  setEditingClientInfo(client);
                  setIsEditing(false);
                }}
              >
                CANCEL
              </Button>
            </div>
          ) : (
            <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
              Edit Info
            </Button>
          )}
        </CardActions>
      </>
    );
  };

  return (
    <Card>
      <CardContent className={classes.outerContainer}>
        <Typography style={{ marginBottom: '2vh' }} variant="h4">
          {client.organizationName}
        </Typography>
        {renderActions()}
        <ImageList cols={1} cellheight={100}>
          <ImageListItem key="organizationName" id={client._id} cols={1}>
            <Typography variant="h6" fontWeight="bold">
              Organization Name
            </Typography>
            <Typography>{editingClientInfo.organizationName?.toString()}</Typography>
          </ImageListItem>

          <ImageListItem key="organizationOwner" id={client._id} cols={1}>
            <Typography variant="h6" fontWeight="bold">
              Organization Owner Email
            </Typography>
            {isEditing ? (
              <TextField
                variant="standard"
                value={editingClientInfo.organizationOwner}
                onChange={(e) => {
                  setEditingClientInfo({
                    ...editingClientInfo,
                    organizationOwner: e.target.value,
                  });
                }}
              />
            ) : (
              <Typography>{editingClientInfo.organizationOwner?.toString()}</Typography>
            )}
          </ImageListItem>

          <ImageListItem key="organizationCode" id={client._id} cols={1}>
            <Typography variant="h6" fontWeight="bold">
              Organization Code
            </Typography>
            <Typography>{editingClientInfo.organizationCode?.toString()}</Typography>
          </ImageListItem>

          <ImageListItem key="noOfSeats" id={client._id} cols={1}>
            <Typography variant="h6" fontWeight="bold">
              Total Number of Seats
            </Typography>

            {isEditing ? (
              <TextField
                variant="standard"
                value={editingClientInfo.noOfSeats}
                onChange={(e) => {
                  setEditingClientInfo({
                    ...editingClientInfo,
                    noOfSeats: e.target.value,
                  });
                }}
              />
            ) : (
              <Typography>{editingClientInfo.noOfSeats?.toString()}</Typography>
            )}
          </ImageListItem>

          <ImageListItem key="occupiedSeats" id={client._id} cols={1}>
            <Stack justifyContent="center" alignItems="center" direction="row" gap={1}>
              <Typography variant="h6" fontWeight="bold">
                Number of Occupied Seats
              </Typography>
              <Tooltip
                title={
                  <Typography variant={'body2'}>
                    Number of enabled users in the tenant excluding those with role broadsightAdmin
                  </Typography>
                }
                TransitionComponent={Zoom}
                enterDelay={200}
                placement={'right'}
              >
                <div>
                  <HelpOutlineIcon />
                </div>
              </Tooltip>
            </Stack>

            <Typography>{editingClientInfo.occupiedSeats?.toString()}</Typography>
          </ImageListItem>

          <ImageListItem key="dateCreated" id={client._id} cols={1}>
            <Typography variant="h6" fontWeight="bold">
              Date Created
            </Typography>
            <Typography>{editingClientInfo.dateCreated?.toString()}</Typography>
          </ImageListItem>

          <ImageListItem key="activeSubscription" id={client._id} cols={1}>
            <Typography variant="h6" fontWeight="bold">
              Active Subscription
            </Typography>
            <Typography>{editingClientInfo.activeSubscription?.toString()}</Typography>
          </ImageListItem>

          <ImageListItem
            key="totalMediaInteractionsCreated totalMediaInteractionsCreatedLastWeek"
            id={client._id}
            cols={1}
          >
            <Typography variant="h6" fontWeight="bold">
              Total Media Interactions Added (Last 7 days)
            </Typography>
            <Typography>{`${editingClientInfo.totalMediaInteractionsCreated?.toString()} (+${editingClientInfo.totalMediaInteractionsCreatedLastWeek?.toString()})`}</Typography>
          </ImageListItem>

          <ImageListItem key="totalServicesCreated totalServicesCreatedLastWeek" id={client._id} cols={1}>
            <Typography variant="h6" fontWeight="bold">
              Total Services Added (Last 7 days)
            </Typography>
            <Typography>{`${editingClientInfo.totalServicesCreated?.toString()} (+${editingClientInfo.totalServicesCreatedLastWeek?.toString()})`}</Typography>
          </ImageListItem>

          <ImageListItem key="totalQueuedInteractionEmails" id={client._id} cols={1}>
            <Typography variant="h6" fontWeight="bold">
              Total Interaction emails sitting in AutoTracker to process
            </Typography>
            <Typography>{editingClientInfo.totalQueuedInteractionEmails?.toString()}</Typography>
          </ImageListItem>

          <ImageListItem key="totalQueuedServiceEmails" id={client._id} cols={1}>
            <Typography variant="h6" fontWeight="bold">
              Total Service emails sitting in AutoTracker to process
            </Typography>
            <Typography>{editingClientInfo.totalQueuedServiceEmails?.toString()}</Typography>
          </ImageListItem>

          <ImageListItem
            key="totalAutoTrackerInteractionsAdded totalAutoTrackerInteractionsAddedLastWeek"
            id={client._id}
            cols={1}
          >
            <Typography variant="h6" fontWeight="bold">
              Total AutoTracker Interactions (Last 7 days)
            </Typography>
            <Typography>{`${editingClientInfo.totalAutoTrackerInteractionsAdded?.toString()} (+${editingClientInfo.totalAutoTrackerInteractionsAddedLastWeek?.toString()})`}</Typography>
          </ImageListItem>

          <ImageListItem
            key="totalAutoTrackerServicesAdded totalAutoTrackerServicesAddedLastWeek"
            id={client._id}
            cols={1}
          >
            <Typography variant="h6" fontWeight="bold">
              Total AutoTracker Services (Last 7 days)
            </Typography>
            <Typography>{`${editingClientInfo.totalAutoTrackerServicesAdded?.toString()} (+${editingClientInfo.totalAutoTrackerServicesAddedLastWeek?.toString()})`}</Typography>
          </ImageListItem>

          <TenantAddonControlListItems
            isEditing={isEditing}
            client={editingClientInfo}
            onClientAddonControlChange={(e, field) => {
              setEditingClientInfo({
                ...editingClientInfo,
                [field.field]: e.target.checked,
              });
            }}
          />
        </ImageList>
      </CardContent>
    </Card>
  );
}

export default TenantCardComponent;
