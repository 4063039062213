import { transitionTime, socketEvents, TrackerDataType, API } from '../constants/constants';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../components/AppSnackbar/snackbarSlice';
import { ServiceKeys } from '../constants/IssueAndServiceKeys';
import { htmlToText } from 'html-to-text';
import axios from 'axios';
import dayjs from '../../utils/dayjs';

const ServiceLogEventHandler = (socket) => {
  const dispatch = useDispatch();

  return {
    type: TrackerDataType.SERVICE,
    newItemModalTitle: 'Add New Service',
    createNewItem: async (fields, files, successCallback) => {
      try {
        let response = await API.post(`/services`, [fields]);
        if (response.status !== 201) {
          dispatch(
            openSnackbar({
              severity: 'alert',
              message: response.statusText,
            })
          );
          return response;
        }

        let f = new FormData();
        const id = response.data.id[0] || null;
        const headers = { 'content-type': 'multipart/form-data' };

        files
          .filter((file) => file.type !== 'url' && file.type !== 'autotrackerAttachment')
          .forEach((file) => {
            f.append('file', file);
          });

        if (id) {
          response = await API.post(`/service/${id}/upload`, f, {
            headers: headers,
          });

          if (response.status !== 201) {
            dispatch(
              openSnackbar({
                severity: 'warning',
                message: `File upload failed for newly created service`,
              })
            );
          }

          await Promise.all(
            files
              .filter((file) => file.type === 'url')
              .map((file) => {
                return API.post(`/service/${id}/url`, file).then((response) => {
                  if (response.status !== 201) {
                    openSnackbar({
                      severity: 'warning',
                      message: `Url upload failed for newly created service`,
                    });
                  }
                });
              })
          );

          if (successCallback) {
            successCallback({ postId: id });
          }
        }

        return response;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response.status === 400) {
          dispatch(
            openSnackbar({
              severity: 'error',
              message: error.response.data.message,
            })
          );
        }
      }
    },
    // use as a React Hook
    useListenOnCreation: (rowId) => {
      const [isNewlyAdded, setIsNewlyAdded] = useState(false);

      useEffect(() => {
        let timeout;
        const listener = ({ service }) => {
          if (service._id === rowId) {
            setIsNewlyAdded(true);
            timeout = setTimeout(() => {
              setIsNewlyAdded(false);
            }, transitionTime);
          }
        };
        socket.on(socketEvents.EVENT_SERVICE_ADDED, listener);

        return () => {
          socket.off(socketEvents.EVENT_SERVICE_ADDED, listener);
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }, [rowId]);

      return { isNewlyAdded };
    },
    editItem: (val, index) => {
      let newVal;
      if (!val) {
        newVal = null;
      } else {
        switch (index) {
          case ServiceKeys.type:
            newVal = [...val];
            break;
          case ServiceKeys.service:
          case ServiceKeys.actions:
          case ServiceKeys.keyMessaging:
          case ServiceKeys.date:
          case ServiceKeys.unitLead:
            newVal = val;
            break;
          case ServiceKeys.teamMember:
            newVal = val;
            break;
          case ServiceKeys.units:
          case ServiceKeys.campaign:
            let arr = [];
            val.forEach((obj) => arr.push(obj));
            newVal = arr;
            break;
          case ServiceKeys.file:
            newVal = val;
            break;
          case ServiceKeys.diversity:
            newVal = val._id;
            break;
          case ServiceKeys.complexity:
            newVal = val._id;
            break;
          case ServiceKeys.dept:
            newVal = val.map((c) => c.name);
            break;
          default:
            newVal = val.name;
            break;
        }
      }
      return newVal;
    },
    updateItem: async (updatedService, successCallback) => {
      updatedService.linkedInteraction = updatedService.linkedInteraction?.interactions
        ? updatedService.linkedInteraction.interactions
        : updatedService.linkedInteraction;
      socket.emit(socketEvents.EVENT_REQ_UPDATE_SERVICE, { data: updatedService }, successCallback);
    },
    deleteItem: async (id) => {
      socket.emit(socketEvents.EVENT_REQ_DELETE_SERVICE, id);
    },
    undoDelete: async (data) => {
      try {
        socket.emit(socketEvents.EVENT_NEW_SERVICE, { data: data, undo: true });
      } catch (e) {
        alert(e.message);
      }
    },
    monthlyView: async (currMonth) => {
      socket.emit(socketEvents.EVENT_MONTHLY_SERVICES, {
        month: currMonth,
        timezoneOffset: dayjs.tz.guess(),
      });
    },
    flattenForExport: (data, dropdownTable) => {
      return data.map((row) => {
        try {
          let flatRow = JSON.parse(JSON.stringify(row.cells));

          flatRow[ServiceKeys.type] = flatRow[ServiceKeys.type].map((c) => `${c.type}:${c.name}`).join(', \n');

          flatRow[ServiceKeys.teamMember] =
            flatRow[ServiceKeys.teamMember].map(
              (tm) => dropdownTable['lead'].find((user) => user['_id'] === tm)?.name
            ) ?? ''.join(', \n');

          flatRow[ServiceKeys.linkedInteraction] = flatRow[ServiceKeys.linkedInteraction]['interactions'].map(
            (c) => `${c.outlet}(Reach ${c.reach})`
          );

          flatRow[ServiceKeys.unitLead] = flatRow[ServiceKeys.unitLead].map((c) => c.name);
          flatRow[ServiceKeys.file] = flatRow[ServiceKeys.file].map((file) => {
            if (file.type === 'url') {
              return file.path;
            } else {
              return file.name;
            }
          });

          flatRow[ServiceKeys.campaign] = flatRow[ServiceKeys.campaign]
            .map((campaign) => `${campaign.name}`)
            .join(', \n');

          let messaging = htmlToText(flatRow[ServiceKeys.keyMessaging], { wordwrap: null });
          messaging = messaging.replace(/"/g, '""');
          flatRow[ServiceKeys.keyMessaging] = messaging;

          let response = htmlToText(flatRow[ServiceKeys.actions], { wordwrap: null });
          response = response.replace(/"/g, '""');
          flatRow[ServiceKeys.actions] = response;

          return flatRow;
        } catch {
          return {
            [ServiceKeys.date]: '',
            [ServiceKeys.service]: 'error flattening data for export!',
            [ServiceKeys.type]: '',
            [ServiceKeys.complexity]: '',
            [ServiceKeys.units]: '',
            [ServiceKeys.unitLead]: '',
            [ServiceKeys.teamMember]: '',
            [ServiceKeys.status]: '',
            [ServiceKeys.diversity]: '',
            [ServiceKeys.actions]: '',
            [ServiceKeys.keyMessaging]: '',
            [ServiceKeys.linkedInteraction]: '',
            [ServiceKeys.campaign]: '',
            [ServiceKeys.file]: '',
            [ServiceKeys.dept]: '',
          };
        }
      });
    },
  };
};

export default ServiceLogEventHandler;
