import { EmailQueueEntry } from './Interfaces';
import { Box, Card, CardContent, Table } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AIResultTableRow from './AIResultTableRow';
import { AutoTrackerIssueCols, AutoTrackerServiceCols, TrackerDataType } from '../../shared/constants/constants';
import AutoTrackerTableHeader from './AutoTrackerTableHeader';
import { DropdownTable } from '../../shared/types';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import React from 'react';

interface AIScrapeResultTableProps {
  row: EmailQueueEntry;
  eventHandler: any;
  dropdownTable: DropdownTable;
  tabColor: string;
}

const useStyles = makeStyles(({ palette }) => ({
  tableContainer: {
    borderRadius: '10px',
    width: '100%',
    border: `2px solid #B1B9C9`,
    margin: '0 auto',
    maxHeight: '75vh',
    marginTop: '10px',
    marginBottom: '10px',
  },
  hiddenCellCard: {
    flex: 1,
    alignSelf: 'flex-start',
    margin: '10px',
    textAlign: 'center',
    border: `1px solid ${palette.secondary.dark}`,
  },
  cardContent: {
    padding: '0',
    borderRadius: '10%',
  },
  cardName: {
    padding: 8,
    color: palette.primary.contrastText,
    fontWeight: 800,
  },
}));

export function AIScrapeResultTable({
  row,
  eventHandler,
  dropdownTable,
  tabColor,
}: Readonly<AIScrapeResultTableProps>) {
  const classes = useStyles();

  return (
    <>
      <Table className={classes.tableContainer}>
        <AutoTrackerTableHeader
          columns={
            eventHandler.type === TrackerDataType.MEDIA_INTERACTION ? AutoTrackerIssueCols : AutoTrackerServiceCols
          }
          tabColor={tabColor}
        />
        <AIResultTableRow
          row={row}
          columns={
            eventHandler.type === TrackerDataType.MEDIA_INTERACTION ? AutoTrackerIssueCols : AutoTrackerServiceCols
          }
          dropdownTable={dropdownTable}
        />
      </Table>
      <Card className={classes.hiddenCellCard}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.cardName} style={{ backgroundColor: tabColor }}>
            Key Messaging
          </Box>
          <Box style={{ padding: 6, paddingBottom: 10, minHeight: '5vh' }}>
            <RichTextEditor
              value={row.aiScrapeResult?.['Key Messaging'] ?? ''}
              defaultValue={''}
              readOnly={true}
              quillStyle={{ resize: 'vertical' }}
            />
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.hiddenCellCard}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.cardName} style={{ backgroundColor: tabColor }}>
            Background/Response
          </Box>
          <Box style={{ padding: 6, paddingBottom: 10, minHeight: '5vh' }}>
            <RichTextEditor
              value={row.aiScrapeResult?.['Background/Response'] ?? row.text ?? ''}
              defaultValue={''}
              readOnly={true}
              quillStyle={{ resize: 'vertical' }}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
